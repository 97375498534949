<template>
    <div class="signup preferences">
        <div class="content">
            <div class="title">
                소개받고 싶은 이성의<br />
                <span class="brand-primary f-bold">키(최대범위)</span>가 어떻게 되나요?
            </div>
            <Slider ref="heightSlider" :range="heightValue" :unit="'cm'" :slider-options="sliderOptions" />
            <div class="height-warning m-t-32" v-html="$translate('EDIT_HEIGHT_WARNING')" />
        </div>
        <div class="button-wrapper">
            <button class="btn btn-primary" @click="next">다음</button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PreferencesHeightPage',
    data: () => ({
        type: 'height',
        sliderOptions: {
            min: 140,
            max: 199,
            tooltip: 'none',
        },
        heightValue: [150, 170],
    }),
    mounted() {
        const preferences = this.$store.getters.payloads.preferences
        if (preferences.min_height && preferences.max_height) {
            this.heightValue[0] = preferences.min_height
            this.heightValue[1] = preferences.max_height
            this.$forceUpdate()
        }
    },
    methods: {
        next() {
            this.$store.getters.payloads.preferences.min_height = this.$refs.heightSlider.myRange[0]
            this.$store.getters.payloads.preferences.max_height = this.$refs.heightSlider.myRange[1]
            this.$router.push({ name: 'PreferencesFriendTypePage' })
        },
    },
}
</script>
