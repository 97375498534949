<template>
    <div class="evaluation-review-input">
        <div class="review-title m-b-8">리뷰를 남겨주세요! <span>(선택)</span></div>
        <div class="description m-b-28" v-html="reviewDescription[starRating]" />
        <div class="textarea-wrapper">
            <textarea :value="review" @input="onInput" placeholder="리뷰를 작성해주세요." />
        </div>
    </div>
</template>

<script>
export default {
    name: 'EvaluationReviewInput',
    data: () => ({
        reviewDescription: {
            1: `
            <div class="main">회원, 주선자 모두에게 공개되지 않습니다.<span>(평점만 반영)</span></div>
            <div class="detail">
                - 아쉬웠던 부분, 불만 사항을 솔직하게 적어주세요.<br>
                - 운영팀에서 확인한 후 부정적인 리뷰가 상습적으로 발생하는 주선자에게 불이익을 부여하고 있습니다.
            </div>
            `,
            2: `
            <div class="main">회원, 주선자 모두에게 공개되지 않습니다.<span>(평점만 반영)</span></div>
            <div class="detail">
                - 아쉬웠던 부분, 불만 사항을 솔직하게 적어주세요.<br>
                - 운영팀에서 확인한 후 부정적인 리뷰가 상습적으로 발생하는 주선자에게 불이익을 부여하고 있습니다.
            </div>
            `,
            3: `
            <div class="main">좋은 점, 아쉬운 점이 무엇인지 솔직히게 작성해주세요. 더 좋은 주선자가 되는데 도움이 될 수 있어요! 😊</div>
            <div class="detail">(3개의 평가가 쌓일 때마다, 익명으로 반영됩니다.)</div>
            `,
            4: `
            <div class="main">좋은 점, 아쉬운 점이 무엇인지 솔직히게 작성해주세요. 더 좋은 주선자가 되는데 도움이 될 수 있어요! 😊</div>
            <div class="detail">(3개의 평가가 쌓일 때마다, 익명으로 반영됩니다.)</div>
            `,
            5: `
            <div class="main">좋은 리뷰는 주선자를 춤추게 합니다 💃</div>
            <div class="detail">(3개의 평가가 쌓일 때마다, 익명으로 반영됩니다.)</div>
            `,
        },
        review: '',
    }),
    computed: {
        starRating() {
            return this.$store.getters.payloads.agentEvaluationData.starRating
        },
    },
    methods: {
        onInput(event) {
            this.review = event.target.value
            this.$emit('onInput', this.review)
        },
    },
}
</script>

<style lang="scss" scoped>
.evaluation-review-input {
    .review-title {
        font-size: 18px;
        color: black;
        font-family: NotoSans-Medium;

        span {
            font-size: 13px;
            color: $grey-05;
            font-family: NotoSans-Regular;
        }
    }

    .description {
        .main {
            margin-bottom: 4px;
            font-size: 14px;
            color: $grey-08;
        }

        .detail {
            font-size: 12px;
            line-height: 17px;
            color: #5448cb;
        }
    }

    .textarea-wrapper {
        height: 220px;

        &:focus-within {
            border-color: $purple-primary;
        }

        textarea {
            height: 100%;
        }
    }
}
</style>
