<template>
    <div class="daily-separator">
        <div class="text f-medium">
            {{ message.created_at | formatDate('YYYY년 M월 D일') }}
        </div>
    </div>
</template>
<script>
export default {
    props: ['message'],
}
</script>

<style lang="scss" scoped>
.daily-separator {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $grey-04;
    border-radius: 8px;
    padding: 3px 12px;
    margin-top: 16px;
    margin-bottom: 12px;
    white-space: nowrap;

    $daily-separtor-width: 114px;
    width: $daily-separtor-width;
    margin-left: calc((100% - #{$daily-separtor-width}) / 2);

    .text {
        color: white;
        font-size: 12px;
    }
}
</style>
