<template>
    <div class="signup onBoarding">
        <!--        <h1>hello world</h1>-->
        <div class="onBoarding-component">
            <span class="onBoarding-title">
                {{ name }}님을 위한 <br />
                <span class="brand-primary f-bold">담당 주선자 {{ friends.length || 0 }}명</span>이 <br />
                배정되었어요!
            </span>
            <div class="onBoarding-body">
                <div v-for="(friend, idx) in friends" :key="idx" class="onBoarding-body-agent">
                    <img :src="friend.agent.photo_url" @error="$event.target.src = $blankProfile" />
                    <div class="onBoarding-body-agent-info">
                        <div class="badge">담당 주선자</div>
                        <span class="name">
                            {{ friend.agent.name || '---' }}
                            <span class="f-regular f-14"> ({{ friend.agent.nickname || '---' }}) </span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="button-wrapper">
            <button class="btn btn-primary btn-block" @click="next">소개팅 주선 받기</button>
        </div>
    </div>
</template>

<script>
import friendService from '@/services/friend'

export default {
    name: 'OnboardingPage',
    async created() {
        const { provider, gender } = this.$store.getters.payloads.signupData
        this.$nativeBridge.postMessage({
            action: 'sendFirebaseEvent',
            value: {
                category: 'OnboardingPage_Enter_OnboardingPage',
                option: {
                    provider: provider || 'email',
                    gender: gender.name === 'MALE' ? 0 : 1,
                },
            },
        })

        await this.loadAgent()
    },
    data: () => ({
        friends: [],
        loading: true,
    }),
    computed: {
        name() {
            return '회원'
        },
    },
    methods: {
        // loadAgent() {
        //     this.friends = this.$store.getters.friends || []
        //     this.loading = this.friends.length === 0

        //     if (this.loading) {
        //         this.$loading(true)
        //         setTimeout(() => {
        //             this.$toast.success('주선자를 찾고 있어요!😃', null, 2000)
        //         }, 500)

        //         setTimeout(async () => {
        //             this.friends = await friendService.all()
        //             this.$loading(false)
        //         }, 3000)
        //     }
        // },
        async loadAgent() {
            this.loading = true
            this.$toast.success('주선자를 찾고 있어요!😃', null, 2000)

            try {
                this.friends = await friendService.all()
                this.loading = false
            } catch (error) {
                console.error('friendService.all', error)
                this.loading = false
            }
        },
        next() {
            this.$router.push({ name: 'HomePage' })
        },
    },
}
</script>

<style lang="scss" scoped>
.onBoarding {
    @include flex;
    @include flex-between;

    &-component {
        padding: 56px 20px 24px;
        @include flex;
    }

    &-title {
        color: black;
        font-size: 24px;
        line-height: normal;
        letter-spacing: -0.4px;
        margin-bottom: 28px;
        @include f-medium;
    }

    &-body {
        &-agent {
            width: 100%;
            height: 83px;
            padding: 14px 0;
            margin-bottom: 14px;
            @include flex-row;

            img {
                width: 56px;
                height: 56px;
                border-radius: 40%;
                margin-right: 20px;
            }

            &-info {
                @include flex;
                @include flex-between;

                .badge {
                    width: 78px;
                    padding: 2px 8px;
                    background-color: $grey-02;
                    color: $grey-09;
                    font-size: 13px;
                    letter-spacing: -0.22px;
                    margin-bottom: 6px;
                    @include f-medium;
                }

                .name {
                    font-size: 16px;
                    letter-spacing: -0.23px;
                    margin-right: 7px;
                    color: black;
                    @include f-medium;
                }
            }
        }
    }
}
</style>
