import friendService from '@/services/friend'
import userService from '@/services/user'
import * as helpers from './helpers'
import router from '@/router'
import Vue from 'vue'

const defaultState = () => ({
    friends: null,
})

const state = defaultState()

const getters = {
    friends: state => state.friends,
}

const actions = {
    async loadFriends({ state, commit, dispatch }, { force, isSignIn }) {
        if (!force && helpers.canSkipApiCall(state, 'friends')) return
        try {
            commit('setLoading', { friends: true })

            const friends = await friendService.all()
            const onlyBasic = friends.filter(friend => friend.duty)
            if (onlyBasic.length === 0) {
                const friends = await userService.signupFriends()
                dispatch('loadChats', true)
                commit('setFriends', friends)

                if (!isSignIn) {
                    const { checked, chatId } = await Vue.prototype.$modal.custom({
                        component: 'ModalMatchNewFriend',
                        options: {
                            preventCloseOnMousedownMask: true,
                            friend: friends[0],
                        },
                    })
                    if (checked) {
                        const unreadCount = await dispatch('loadChat', { chatId: chatId })
                        await router.push({
                            name: 'ChatroomPage',
                            params: { unreadCount },
                        })
                    }
                }
                return friends
            } else {
                commit('setFriends', friends)
            }
        } catch (e) {
            return Promise.reject(e)
        } finally {
            commit('setLoading', { friends: false })
        }
    },
    async loadNewFriend({ commit, dispatch }) {
        try {
            const { ret } = await friendService.disconnectFriend()
            if (ret) {
                await userService.changeFriends()
                const friends = await friendService.all()
                await dispatch('loadChats', true)
                commit('setFriends', friends)
            }
        } catch (e) {
            return Promise.reject(e)
        }
    },
}

const mutations = {
    setFriends(state, friends) {
        state.friends = friends
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
    defaultState,
}
