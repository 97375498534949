import Agent from './agent'
import Chat from './chat'
import Common from './common'
import Friend from './friend'
import PageStates from './page-states'
import User from './user'
import StackRouter from './stack-router'
import Feedback from './feedback'
import Dating from './dating'
import Suggest from './suggest'
import TodayMeeting from './today-meeting'
import SocialMeeting from './social-meeting'
import Product from './product'
import RealFriendMeeting from './realfriend-meeting'
import VerificationBadge from './verification-badge'

const merged = {
    state: {},
    getters: {},
    mutations: {},
    actions: {},
}

const objToMerge = [
    Agent,
    Chat,
    Common,
    Friend,
    PageStates,
    User,
    StackRouter,
    Feedback,
    Dating,
    Suggest,
    TodayMeeting,
    SocialMeeting,
    Product,
    RealFriendMeeting,
    VerificationBadge,
]

objToMerge.forEach(store => {
    ;['state', 'getters', 'mutations', 'actions'].forEach(storeKey => {
        Object.keys(store[storeKey]).forEach(key => {
            merged[storeKey][key] = store[storeKey][key]
        })
    })
})

merged.mutations.initAppData = state => {
    objToMerge.forEach(store => {
        Object.assign(state, store.defaultState())
    })
}

export default merged
