<template>
    <div class="social-info-separator">
        <p class="text f-medium">
            {{ msg }}
        </p>
    </div>
</template>
<script>
export default {
    name: 'SocialInfoSeparator',
    props: ['message'],
    computed: {
        msg() {
            return this.$mustParse(this.message.content).msg
        },
    },
}
</script>

<style lang="scss" scoped>
.social-info-separator {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $grey-04;
    border-radius: 8px;
    padding: 3px 12px;
    margin-top: 16px;
    margin-bottom: 12px;

    $social-separtor-width: 186px;
    width: $social-separtor-width;
    margin-left: calc((100% - #{$social-separtor-width}) / 2);

    .text {
        color: white;
        font-size: 12px;
        text-align: center;
    }
}
</style>
