<template>
    <div class="agent-detail" @scroll="onScroll">
        <Loading :loading="loading" />
        <div class="header-bar">
            <transition name="slide-down">
                <div v-show="showHiddenHeader" class="title" v-html="title"></div>
            </transition>
            <div @click="$stackRouter.pop()" class="left flex-row items-center flex-wrap">
                <i class="material-icons flex-wrap">chevron_left</i>
            </div>
            <div class="right" @click="openActionSheet">
                <i class="material-icons">more_horiz</i>
            </div>
        </div>
        <div class="body" v-if="agent">
            <div class="section p-b-0">
                <div class="flex-row flex-between items-start">
                    <div>
                        <span class="name"
                            >주선자 <span class="f-bold">{{ agent.name }}</span></span
                        >
                        <span class="divider" v-html="'|'"></span>
                        <span class="gender" v-html="agent.gender === 0 ? '남' : '여'"></span>
                        <div>
                            <AgentVerification :agent="agent" />
                        </div>
                    </div>
                    <div
                        @click="onClickAgentProfileImage"
                        class="main img-profile"
                        v-img-cover="((agent.photos || [])[0] || {}).url"
                    />
                </div>
                <MatchingStat :agent="agent" v-if="!$isPremium(agentId)" />
                <button class="btn btn-brd btn-rating" v-if="showRatingButton" @click="onClickRating">
                    {{ agent.name }}님 평가하기
                </button>
            </div>
            <template v-if="(agent.dating_reviews || []).length > 0">
                <div class="hr" />
                <div class="head flex-row items-center m-b-20">
                    <span class="title f-medium">커플 후기</span>
                    <span @click="onClickMore" class="flex-wrap more">
                        {{ 'MORE' | translate }}
                        <i class="material-icons flex-wrap">keyboard_arrow_right</i></span
                    >
                </div>
                <DatingReview
                    v-if="(agent.dating_reviews || []).length > 0"
                    :agent="agent"
                    :review="agent.dating_reviews[0]"
                />
            </template>
            <div class="hr" v-if="!$isTester()" />
            <AgentRatings v-if="!$isTester()" :agent="agent" />
            <div class="hr" v-if="!$isPremium(agentId) && !$isTester()" />
            <div class="agent-intro m-t-36 m-b-60" v-if="!$isPremium(agentId) && !$isTester()">
                <div class="title">주선자 한마디</div>
                <div class="pre-line" v-html="$store.getters.me.gender === 0 ? agent.male_intro : agent.female_intro" />
            </div>
            <div class="hr" v-if="!$isTester()" />
            <div class="agent-photo agent-intro m-t-4" v-if="agentPhotos.length">
                <div class="title m-b-24">주선자 어필</div>
                <div class="photos">
                    <div class="photo" v-for="(photo, i) in agentPhotos" :key="i">
                        <img
                            @click="onClickAgentPhoto(photo)"
                            class="img-cover"
                            v-if="photo.url"
                            :src="photo.url"
                            alt=""
                        />
                        <div class="desc f-demilight c-black">
                            <p class="created-at">{{ $moment(photo.created_at).format('YYYY.MM.DD') }}</p>
                            <p v-if="photo.desc">{{ photo.desc }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import agentService from '@/services/agent'
import chatService from '@/services/chat'
import AgentVerification from './components/AgentVerification'
import DatingReview from '../dating-review/DatingReview'
import MatchingStat from './components/MatchingStat'
import AgentRatings from './components/AgentRatings'

export default {
    name: 'AgentDetailPage',
    components: {
        AgentVerification,
        DatingReview,
        MatchingStat,
        AgentRatings,
    },
    props: {
        agentId: {
            type: Number,
            required: true,
        },
        from: String,
    },
    data: () => ({
        agent: null,
        showHiddenHeader: false,
        loading: false,
        ratingEnabled: true,
        me: null,
    }),
    computed: {
        title() {
            if (!this.agent) return

            const photoDom =
                (this.agent.photos || []).length > 0
                    ? `<img class="img-profile" src="${this.agent.photos[0].url}">`
                    : ''

            return photoDom + this.$translate(`${this.agent.profile.nickname}님의 프로필`)
        },
        agentPhotos() {
            return this.agent.agent_photos || []
        },
        showRatingButton() {
            if (!this.agent || this.$isTester()) return false
            return this.agent && this.agent.friend_type === 1
        },
        agentChat() {
            return (this.$store.getters.chats || []).find(chat => chat.agent_id === this.agentId)
        },
    },
    methods: {
        async init() {
            this.me = this.$store.getters.me

            if (!this.agentId) {
                this.$stackRouter.pop()
                return
            }

            this.loading = true
            try {
                this.agent = await this.$store.dispatch('loadAgentDetail', { agentId: this.agentId })
                this.ratingEnabled = await agentService.ratingEnabled(this.agentId)
            } catch (e) {
                this.$toast.error(e.data)
            }
            this.loading = false
        },
        onScroll(event) {
            this.showHiddenHeader = event.target.scrollTop > 120
        },
        onClickAgentProfileImage() {
            this.$modal.images({
                images: (this.agent.photos || []).map(photo => photo.url),
            })
        },
        onClickAgentPhoto(photo) {
            this.$modal.custom({
                component: 'ModalImages',
                options: {
                    images: (this.agentPhotos || []).map(photo => photo.url),
                    selectedIndex: photo.position,
                },
            })
        },
        onClickRating() {
            if (this.ratingEnabled.rating) {
                this.$alert('이미 평가하셨습니다.')
                return
            } else if (!this.ratingEnabled.enabled) {
                this.$alert('아직 평가할 수 없습니다.')
                return
            }

            this.$stackRouter.push({
                name: 'AgentEvaluationIntroPage',
                props: { from: 'AgentDetail', agent: this.agent },
            })
        },
        openActionSheet() {
            const buttons = [
                {
                    label: 'GIVE_GIFT',
                    handler: () => {
                        this.$stackRouter.push({
                            name: 'GiftListPage',
                            props: { agent: this.agent },
                        })
                    },
                },
                {
                    label: 'REPORT_AGENTS',
                    class: 'c-red',
                    handler: () => {
                        const target = this.agent
                        target.agent_id = this.agent.id

                        this.$stackRouter.push({
                            name: 'ReportSelectReasonPage',
                            props: {
                                type: 'agent',
                                target,
                            },
                        })
                    },
                },
            ]

            if (this.$isTester()) {
                buttons.splice(0, 1)
                buttons.unshift({
                    label: 'BLOCK',
                    handler: () => {
                        setTimeout(async () => {
                            const idx = await this.$modal.basic({
                                body: '차단 하시겠습니까?',
                                buttons: [
                                    {
                                        label: 'CANCEL',
                                        class: 'btn-default',
                                    },
                                    {
                                        label: 'CONFIRM',
                                        class: 'btn-primary',
                                    },
                                ],
                            })
                            if (idx === 1)
                                try {
                                    const { msg } = await chatService.block(this.agentChat.id)
                                    this.$toast.success(msg)
                                    await this.$store.dispatch('loadChats', true)
                                    await this.$router.push({ name: 'ChatsPage' })
                                    this.$stackRouter.pop()
                                } catch (e) {
                                    this.$toast.error(e.data.msg)
                                }
                        }, 200)
                    },
                })
            }

            this.$actionSheet({ buttons })
        },
        onClickMore() {
            this.$stackRouter.push({
                name: 'DatingReviewsPage',
                props: {
                    agent: this.agent,
                },
            })
        },
    },
    mounted() {
        this.init()
    },
}
</script>
<style scoped lang="scss">
.agent-photo {
    padding: 0 !important;
    margin-bottom: 40px;

    .title {
        padding: 0 16px;
    }
    .photos {
        .photo {
            width: 100%;
            margin-bottom: 40px;

            img {
                width: 100%;
                height: auto;
            }
        }
        .desc {
            padding: 16px;
            font-size: 14px;

            .created-at {
                color: $grey-06;
                font-size: 13px;
            }
        }
    }
}
.head {
    padding: 0 20px;
    .title {
        font-size: 18px;
        color: black;
    }

    .more {
        color: $grey-08;
        font-size: 14px;
        @include flex-row;
        @include items-center;
    }
}
</style>
