<template>
    <ProfileOptionSelector :model="'smoking'" :nextRoute="() => 'ReligionPage'" />
</template>

<script>
export default {
    name: 'SmokingPage',
    mounted() {
        const { provider, gender } = this.$store.getters.payloads.signupData

        this.$nativeBridge.postMessage({
            action: 'sendFirebaseEvent',
            value: {
                category: 'SmokingPage_Enter_SmokingPage',
                option: {
                    provider: provider || 'email',
                    gender: gender.name === 'MALE' ? 0 : 1,
                },
            },
        })
    },
}
</script>
