<template>
    <div class="chat-body" :class="initMarginTop">
        <div
            ref="messages"
            class="messages ver-scroll"
            :class="{ 'stop-scrolling': loading, vacation: chatType === 'pro_agent' && user.vacation }"
            @scroll="onScroll"
        >
            <div v-if="showAlimiOpenChatMessage" class="message-row left">
                <div class="profile-and-name flex-row">
                    <img class="img-profile flex-wrap" :src="require('@/assets/images/logo_symbol.svg')" />
                    <div class="name flex-wrap" v-html="$translate('VANILLABRIDGE')" />
                </div>
                <Message class="flex-wrap" :message="alimiOpenChatMessage" :me="false" />
            </div>
            <div
                class="message-row"
                :class="[shouldAddMoreMargin(idx), isMine(message) ? 'right' : 'left']"
                :key="(message || {}).id"
                v-for="(message, idx) in messages"
            >
                <UnreadSeparator v-if="showUnreadSeparator(message, idx)" />

                <DailySeparator v-if="showDailySeparator(idx)" :message="message" />

                <SocialInfoSeparator v-if="message.mtype === 'social-info'" :message="message" />

                <template v-else>
                    <div v-if="!isMine(message) && message.mtype !== 'request'" class="profile-and-name flex-row">
                        <div
                            v-if="(isUserInRealFriendChat || leavedSocialChatUser(message)) && !vb(message)"
                            class="img-profile flex-wrap real-friend-profile"
                            @click="onClickProfileImage(user)"
                        >
                            <i class="material-icons">person</i>
                        </div>

                        <img
                            v-else
                            @click="onClickProfileImage(vb(message) || user)"
                            class="img-profile flex-wrap"
                            v-lazy="sender(message).photo_url"
                        />

                        <div class="name flex-wrap m-b-8" v-html="sender(message).name">
                            <span v-if="isSocialChatHost(message) && !vb(message)" class="social-host">
                                <img src="@/assets/images/chat/social_host.svg" />
                            </span>
                        </div>
                    </div>

                    <Message class="flex-wrap" :message="message" :premium="isPremium" :me="isMine(message)" />
                </template>
            </div>
        </div>
    </div>
</template>
<script>
import DailySeparator from './DailySeparator'
import UnreadSeparator from './UnreadSeparator'
import SocialInfoSeparator from './SocialInfoSeparator'

import Message from './message/Message'

export default {
    name: 'ChatBody',
    props: {
        unreadCount: Number,
    },
    components: { DailySeparator, UnreadSeparator, Message, SocialInfoSeparator },
    data: () => ({
        loading: false,
        firstUnreadMessage: null,
        userSuggestLen: 0,
    }),
    computed: {
        initMarginTop() {
            if (!this.instantChat && this.isPremium) return `m-t-120`
            else if (this.instantChat) {
                if (!this.chat.suggest_count) return
                if (this.isPremium) return `m-t-80`
                else return `m-t-120`
            } else return ''
        },
        messages() {
            return (this.chat.$$messages || []).slice().reverse()
        },
        user() {
            return this.chat.user
        },
        chat() {
            return this.$store.getters.chat || {}
        },
        chatType() {
            return this.chat.chat_type
        },
        instantChat() {
            return this.$isInstantChat(this.chat)
        },
        agentId() {
            return this.chat.agent_id
        },
        isRealfriendOrSocial() {
            return this.chatType === 'real_friend' || this.chatType === 'social_group'
        },
        isPremium() {
            if (!this.chat) return false
            if (this.chat.expire_at || this.isRealfriendOrSocial) return false

            return this.$isPremium(this.agentId)
        },
        isAlimi() {
            return this.chatType === 'alimi'
        },
        alimiOpenChatMessage() {
            if (!this.chat) return

            return {
                chat_id: this.chat.id,
                content:
                    '안녕하세요, 바닐라브릿지입니다!\n앞으로 이곳에서 공지사항과 다양한 소식들을 전달해드릴게요! 🤓',
                user_id: 0,
                mtype: 'text',
                is_template: null,
                direction: null,
            }
        },
        showAlimiOpenChatMessage() {
            if (!this.isAlimi || !this.chat || this.chat.$$loadMore) return false

            return true
        },
        me() {
            return this.$store.getters.me || {}
        },
        isUserInRealFriendChat() {
            if (this.chatType !== 'real_friend') return false

            return this.chat.introduce.user_id !== this.me.id
        },
    },
    methods: {
        async initScrollPosition() {
            if (this.unreadCount > this.messages.length) {
                const loadMessages = () => {
                    this.chat.$$firstMessageId = this.messages[0].id
                    return this.$store.dispatch('loadMessages', {
                        firstId: this.chat.$$firstMessageId,
                        chatId: this.chat.id,
                    })
                }

                const loopCount = parseInt(this.unreadCount / this.messages.length)

                for (let i = 0; i < loopCount; i += 1) {
                    await loadMessages()
                }
            }

            const dom = this.$refs.messages
            const unreadElem = document.querySelector('.unread-separator')

            if (unreadElem) {
                await this.$waitFor(200)
                unreadElem.scrollIntoView(true)
                if (dom.scrollTop + dom.clientHeight < dom.scrollHeight) {
                    dom.scrollTop -= dom.clientHeight / 3
                }
            } else {
                await this.$waitFor(200)
                this.$scroll.down(dom, false) // 메시지 랜더링이 채 끝나기 전에 scrolldown 하면 밑으로 제대로 내려가지 못함
            }
        },
        async loadMoreMessages() {
            if (this.chat.$$loadMore === false) {
                this.loading = false
                return
            }

            const dom = this.$refs.messages
            const scrollHeightBefore = dom.scrollHeight // 메시지 추가 전 스크롤 높이 기억

            try {
                await this.$store.dispatch('loadMessages', {
                    firstId: this.chat.$$firstMessageId,
                    chatId: this.chat.id,
                })
            } catch (e) {
                this.$toast.error(e.data)
            } finally {
                this.$nextTick(() => {
                    const scrollHeightAfter = dom.scrollHeight // 메시지 추가 후 스크롤 높이
                    setTimeout(() => {
                        dom.scrollTop = scrollHeightAfter - scrollHeightBefore
                    }, 0) // 이상해보이지만 ios 에서는 큐에 넣어두어야 올바르게 실행됨
                    setTimeout(() => {
                        this.loading = false
                    }, 100) // 충분한 시간을 두고 false 처리해줘야 여러번 호출 되는 것을 방지할 수 있음
                })
            }
        },
        vb(message) {
            const content = this.$mustParse(message.content) || {}
            if (!content.is_vb) return

            return {
                photo_url: require('@/assets/images/logo_symbol.svg'),
                name: this.$translate('VANILLABRIDGE'),
            }
        },
        sender(message) {
            const vb = this.vb(message)
            if (vb) {
                return this.vb(message)
            }

            if (this.chatType === 'alimi') {
                return {
                    photo_url: require('@/assets/images/logo_symbol.svg'),
                    name: this.$translate('VANILLABRIDGE'),
                }
            }

            if (this.chatType === 'social_group') {
                if (!this.chat.users[message.user_id]) {
                    return {
                        name: '알수없음',
                        photo_url: require('@/assets/images/blank_profile.svg'),
                    }
                }

                const user = this.chat.users[message.user_id]
                const nameOrNick = this.$nameOrNick(user) !== '---' ? this.$nameOrNick(user) : '탈퇴한 유저'
                return { ...user, name: nameOrNick } // 소셜 채팅방에서는 성빼야함
            }

            if (this.chatType === 'real_friend') {
                return {
                    name: `<span class="f-medium">${this.user.nickname || '---'}</span>`,
                    photo_url: this.user.photo_url,
                }
            }

            return {
                name: `${this.agentTitle()} <span class="f-medium m-l-2"> ${this.user.name}</span>`,
                photo_url: this.user.photo_url,
            }
        },
        agentTitle() {
            return '주선자'
        },
        isMine(message) {
            const content = this.$mustParse(message.content) || {}
            if (content.is_vb) return false

            return message.user_id === this.me.id
        },
        onScroll(event) {
            if (this.loading) return

            const scrollTop = event.target.scrollTop
            if (scrollTop > 100 || !this.messages || this.messages.length === 0) {
                return
            }

            this.loading = true
            this.chat.$$firstMessageId = this.messages[0].id
            this.loadMoreMessages()
        },
        onClickProfileImage(user) {
            if (!user.id) return

            if (this.chatType === 'real_friend') {
                if (this.chat.introduce.user_id === this.me.id) {
                    this.$stackRouter.push({
                        name: 'UserDetailPage',
                        props: {
                            userId: this.user.id,
                            photos: [{ url: this.user.photo_url }],
                        },
                    })

                    return
                }

                this.$stackRouter.push({
                    name: 'BlindUserDetailPage',
                    props: {
                        nickname: this.user.nickname,
                        userId: this.user.id,
                        from: 'ChatsPage',
                    },
                })

                return
            }

            this.$stackRouter.push({
                name: 'AgentDetailPage',
                props: {
                    agentId: this.chat.agent_id,
                    from: 'ChatsPage',
                },
            })
        },
        showDailySeparator(idx) {
            if (idx === 0) {
                return true
            }

            const curMessage = this.messages[idx]
            if (!curMessage || !curMessage.created_at) return false

            const prevMessage = this.messages[idx - 1]
            return (
                this.$moment(prevMessage.created_at).format('YYYY-MM-DD') !==
                this.$moment(curMessage.created_at).format('YYYY-MM-DD')
            )
        },
        showUnreadSeparator(message, idx) {
            if (this.unreadCount === 0 || this.messages.length === 1) return false

            if (
                this.firstUnreadMessage &&
                this.firstUnreadMessage.id === message.id &&
                this.firstUnreadMessage.user_id !== this.me.id &&
                !this.firstUnreadMessage.mtype.includes('open-chat')
            )
                return true

            if (this.messages.length - this.unreadCount === idx) {
                if (!this.firstUnreadM1essage) {
                    this.firstUnreadMessage = message
                    return true
                }
            }

            return false
        },
        shouldAddMoreMargin(msgIdx) {
            const curMessage = this.messages[msgIdx]
            const nextMessage = this.messages[msgIdx + 1]
            if (!curMessage || !nextMessage) return

            if (curMessage.user_id !== nextMessage.user_id) {
                return 'm-b-20'
            }

            if (['text', 'photo', 'multi-photo', 'intro-new-user'].indexOf(curMessage.mtype) === -1) {
                return 'm-b-16'
            }
        },
        onChatInputFocused() {
            const dom = this.$refs.messages
            const { scrollHeight, clientHeight, scrollTop } = dom

            if (scrollHeight <= clientHeight + scrollTop) {
                setTimeout(() => {
                    this.$scroll.down(dom, true)
                }, 400)
            }
        },
        isSocialChatHost(message) {
            if (this.chatType !== 'social_group') return false

            return message.user_id === this.chat.event.user_id
        },
        leavedSocialChatUser(message) {
            if (this.chatType !== 'social_group') return false

            return !this.chat.users[message.user_id]
        },
    },
    mounted() {
        this.$bus.$on('chatInputFocus', this.onChatInputFocused)
        this.$nextTick(() => {
            this.initScrollPosition()
        })
    },
    beforeDestroy() {
        this.$bus.$off('chatInputFocus', this.onChatInputFocused)
    },
}
</script>

<style lang="scss" scoped>
.chat-body {
    position: relative;
    &.m-t-80 {
        margin-top: 80px;
    }
    &.m-t-120 {
        margin-top: 120px;
    }
    .message-row {
        .profile-and-name {
            .img-profile {
                width: 28px;
                height: 28px;
                background-color: white;
                @include center;

                .material-icons {
                    color: $grey-04;
                    font-size: 20px;
                }
                &.real-friend-profile {
                    border: solid 1px $gray-divider;
                }
            }
            .name {
                margin-left: 8px;
                font-size: 12px;
                color: $grey-08;
                @include items-center;
                @include f-regular;

                .social-host {
                    margin-left: 2px;
                    width: 16px;
                    height: 16px;
                    border: solid 1px $blue-facebook;
                    border-radius: 50%;
                    img {
                        width: 10px;
                    }
                    @include center;
                }
            }
        }
        &:last-child {
            padding-top: 0;
        }
        &.left {
            .message {
                margin-top: -6px;
                margin-left: 36px;
            }
        }
    }
    .messages {
        padding: 16px;
        overflow-y: auto;
        overflow-x: hidden;
        height: 100%;
        display: flex;
        flex-direction: column;

        &.vacation {
            padding-top: 32px !important;
        }
    }
    .stop-scrolling {
        height: 100%;
        overflow: hidden;
    }
}
</style>
