<template>
    <div class="profile-update-request">
        <div class="header">
            <i class="material-icons" :class="{ hide: requested || forced }" @click="back">close</i>
            <h3 class="title" v-html="$translate(requested ? 'VANILLABRIDGE' : 'PROFILE_UPDATE_REQUEST')" />
            <button
                :class="{ hide: requested }"
                class="btn-text c-primary"
                v-html="$translate('EDIT')"
                @click="submit"
            />
        </div>
        <div class="body" v-if="!requested">
            <h1 class="m-b-12 pur-title" v-html="$translate('PROFILE_UPDATE_REQUEST_TITLE')" />
            <p class="pur-desc m-b-20" v-html="$translate('PROFILE_UPDATE_REQUEST_DESCRIPTION')" />
            <div class="needs-update flex-row items-center">
                <span class="badge flex-wrap" v-html="$translate('NEED_UPDATE')" />
                <div class="items items-center" v-html="updateRequiredFields" />
            </div>
            <div
                class="profiles"
                :class="{ photos: profile.key === 'photos' }"
                v-for="profile in profiles"
                :key="profile.key"
                v-show="profile.shouldUpdate"
            >
                <div class="title">{{ profile.title }}</div>
                <div class="sub-title" v-html="profile.subTitle" />
                <div v-if="profile.example" class="example" v-html="profile.example" />
                <div
                    v-if="profile.description"
                    class="description-wrapper flex-row items-start"
                    @click="showDetailDescription = !showDetailDescription"
                    :class="{ detail: showDetailDescription }"
                >
                    <div v-if="!showDetailDescription" class="description" v-html="profile.description" />
                    <div v-if="showDetailDescription" class="description" v-html="profile.detailDescription" />
                    <i class="material-icons flex-wrap">
                        {{ showDetailDescription ? 'close' : 'info' }}
                    </i>
                </div>
                <div v-if="profile.key === 'photos' && profile.shouldUpdate" class="photos-grid">
                    <div
                        v-for="(photo, idx) in photos"
                        :key="photo.id"
                        class="photo-wrapper"
                        :class="{
                            'should-update': photo.shouldUpdate,
                            updated: photo.updated,
                        }"
                        @click="onClickPhoto(idx)"
                    >
                        <img class="photo" :src="photo.url" />
                        <div v-if="photo.shouldUpdate && !photo.updated" class="camera">
                            <i class="material-icons">camera_alt</i>
                            <span class="f-bold" v-html="$translate('NEED_UPDATE')" />
                        </div>
                        <div v-if="!photo.url" class="add">
                            <i class="material-icons">add</i>
                        </div>
                        <div
                            v-if="photo.shouldUpdate || photo.updated"
                            class="remove-btn"
                            @click.stop="removePhoto(idx)"
                        >
                            <i class="material-icons">close</i>
                        </div>
                    </div>
                </div>
                <div
                    v-if="profile.key !== 'photos' && profile.shouldUpdate"
                    class="m-t-20 profile-option flex-row flex-between items-center"
                    :class="{ updated: profile.updated }"
                    @click="onClickProfileOption(profile)"
                >
                    <span class="name">{{ profile.item.name }}</span>
                    <span
                        class="need-update"
                        v-if="profile.shouldUpdate && !profile.updated"
                        v-html="$translate('NEED_UPDATE')"
                    />
                </div>
            </div>
            <input
                ref="imageUploader"
                type="file"
                class="image-input display-none"
                @change="onChangeImage($event)"
                accept="image/*"
            />
        </div>
        <ProfileUpdatePending v-if="requested" :items="profiles.filter(p => p.shouldUpdate).map(p => p.title)" />
    </div>
</template>

<script>
import ProfileUpdatePending from './components/ProfileUpdatePending'
import userService from '@/services/user'

export default {
    name: 'ProfileUpdateRequestPage',
    components: { ProfileUpdatePending },
    computed: {
        updateRequiredFields() {
            return this.profiles
                .filter(item => item.shouldUpdate)
                .map(item => `<span class="text">${item.title}</span>`)
                .join('<span class="c-danger">ㅣ</span>')
        },
    },
    data: () => ({
        profileKeys: ['universityName', 'majorName', 'companyName', 'jobTypeName', 'jobName'],
        profiles: [
            {
                key: 'photos',
                shouldUpdate: false,
                title: '사진',
                subTitle: '<span class="f-medium">얼굴이 분명하게 나온 사진 2장</span>이 꼭 필요해요!',
                description:
                    '패션 스타일, 몸매 비율 등을 보여주는 <span class="f-bold">얼굴이 작게 나온 사진은 3장 째부터 추가</span>할 수 있습니다.',
                detailDescription:
                    '<div class="first-line">얼굴 분명 사진 1 + 얼굴 불분명 사진 1 = X</div><div class="second-line">얼굴 분명 사진 2 + 얼굴 불분명 사진 1 = O</div>',
                showDetail: false,
            },
            {
                key: 'universityName',
                shouldUpdate: false,
                title: '학교명',
                subTitle: '학교의 이름을 입력해주세요.',
                example: '',
                item: {},
                updated: false,
                model: 'university',
            },
            {
                key: 'majorName',
                shouldUpdate: false,
                title: '전공',
                subTitle: '학과의 이름을 입력해주세요.',
                example: '(공대 X, 전기공학과 O)',
                item: {},
                updated: false,
                model: 'major',
            },
            {
                key: 'companyName',
                shouldUpdate: false,
                title: '직장명',
                subTitle: '직장의 이름을 입력해주세요.',
                example: '(대기업 X, 삼성전자 O)',
                item: {},
                updated: false,
                model: 'company',
            },
            {
                key: 'jobTypeName',
                shouldUpdate: false,
                title: '업종',
                subTitle: '직업이 아닌, 일하는 분야를 입력해주세요.',
                example: '(회사원 X, 중소기업 X, 금융 O, 미용 O, 자동차 O, 반도체 O)',
                item: {},
                updated: false,
                model: 'jobType',
            },
            {
                key: 'jobName',
                shouldUpdate: false,
                title: '직업',
                subTitle: '일하는 곳이나 분야가 아니라, 어떤 일을 하는지 충분히 알 수 있도록 입력해주세요.',
                example: '(보안 X, 보안요원 O, 미용 X, 헤어숍매니저 O, 헤어디자이너 O)',
                item: {},
                updated: false,
                model: 'job',
            },
        ],
        photos: [],
        deletePhotoIds: [],
        requested: false,
        selectedPhotoIndex: null,
        forced: false,
        showDetailDescription: false,
    }),
    mounted() {
        this.init()
    },
    methods: {
        init() {
            const pur = this.$route.params.pur
            if (pur) {
                this.checkProfileStatus(pur)
                this.populateProfile(pur)
            } else {
                this.back()
            }
        },
        checkProfileStatus(pur) {
            const status = this.$store.getters.me.profile.status
            if (!status) this.$router.push({ name: 'ChatsPage' })

            /**
             * 1. profile.status === update_forced
             *   - if (profile_update_request.confirmed) 아직 제출하지 않은 상태 -> 수정 페이지 (forced = true, requested = false)
             *   - if (!profile_update_request.confirmed) 제출한 상태 -> 심사 중 페이지 (forced = true, requested = true)
             * 2. profile.status === update_required
             *   - if (profile_update_request.confirmed) 아직 제출x -> 수정 페이지 (forced = false, requested = false)
             *   - if (!profile_update_request.confirmed) 제출 O -> 수정 페이지 안보여줘도 됨
             */

            if (status === 'update_forced') {
                if (pur.confirmed) {
                    this.forced = true
                    this.$registerBackHandler(() => {})
                } else if (!pur.confirmed) {
                    this.requested = true
                }
            } else if (status === 'update_required') {
                if (!pur.confirmed) {
                    this.$router.push({ name: 'ChatsPage' })
                }
            }
        },
        async populateProfile(pur) {
            const skipKeyList = ['id', 'userId', 'confirmed', 'createdAt', 'updatedAt']

            for (const [key, value] of Object.entries(pur)) {
                const keyName = this.$case.toCamel(key)
                if (skipKeyList.indexOf(keyName) > -1) continue

                if (keyName === 'photoIds' && (value || []).length > 0) {
                    const myPhotos = this.$store.getters.me.photos
                    const rejectedPhotoIds = value
                    const unabledPhotos = await userService.getUnabledPhotos(rejectedPhotoIds)

                    this.photos = myPhotos.concat(unabledPhotos).map(photo => {
                        rejectedPhotoIds.includes(photo.id) ? (photo.shouldUpdate = true) : (photo.shouldUpdate = false)
                        photo.updated = false

                        return photo
                    })
                    this.photos = this.photos.concat(
                        Array(6 - myPhotos.length - unabledPhotos.length)
                            .fill({})
                            .map(obj => ({
                                url: null,
                                shouldUpdate: false,
                                updated: false,
                            })),
                    )
                    this.profiles[0].shouldUpdate = true

                    continue
                }

                if (value) {
                    const name = value

                    this.profiles.forEach(profile => {
                        if (profile.key === keyName && this.profileKeys.indexOf(keyName) > -1) {
                            profile.shouldUpdate = true
                            profile.item.name = name
                        }
                    })
                }
            }
        },
        back() {
            this.$router.push({ name: 'ChatsPage' })
        },
        removePhoto(idx) {
            if (this.photos[idx].id) {
                this.deletePhotoIds.push(this.photos[idx].id)
            }

            this.$set(this.photos, idx, {
                url: null,
                shouldUpdate: false,
                updated: false,
                blob: null,
                fileName: null,
            })
            this.sortPhotos()
        },
        onClickPhoto(idx) {
            if (this.photos[idx].url && !this.photos[idx].shouldUpdate) return

            this.selectedPhotoIndex = idx
            this.$refs.imageUploader.click()
        },
        sortPhotos() {
            this.photos.sort((a, b) => {
                // a가 업데이트 필요하고 b는 아닐 때 a가 먼저
                if (a.shouldUpdate && !b.shouldUpdate) return -1
                // a가 업데이트 필요하고 b도 업데이트 필요하면 업데이트 안된 a가 먼저
                if (a.shouldUpdate && b.shouldUpdate && !a.updated && b.updated) return -1
                // a, b 둘다 업데이트 필요하지 않은 항목이고 a가 업데이트 되었으면 a 먼저
                if (!a.shouldUpdate && !b.shouldUpdate && a.updated && !b.updated) return -1
                // a는 사진이 있는 항목이고 b는 아닐 때 a 먼저
                if (a.url && !b.url) return -1
                return 0
            })
        },
        onChangeImage(event) {
            if (!event || event.target.files.length === 0) return

            const file = event.target.files[0]

            this.$refs.imageUploader.value = ''

            this.$modal
                .custom({
                    component: 'ModalCropper',
                    options: {
                        imgFile: file,
                    },
                })
                .then(croppedFile => {
                    if (!croppedFile) return
                    this.photos[this.selectedPhotoIndex].url = URL.createObjectURL(croppedFile)
                    this.photos[this.selectedPhotoIndex].blob = croppedFile
                    this.photos[this.selectedPhotoIndex].updated = true
                    this.photos[this.selectedPhotoIndex].fileName = file.name
                    if (this.photos[this.selectedPhotoIndex].id) {
                        this.deletePhotoIds.push(this.photos[this.selectedPhotoIndex].id)
                    }
                    this.selectedPhotoIndex = null
                    this.sortPhotos()
                })
        },
        onClickProfileOption(profile) {
            this.$modal
                .custom({
                    component: 'ModalProfileItemSelector',
                    options: {
                        model: profile.model,
                        profile: this.$store.getters.me.profile,
                    },
                })
                .then(({ id, name }) => {
                    profile.item.id = id
                    profile.item.name = name
                    profile.updated = true
                })
        },
        async submit() {
            try {
                this.$loading(true)
                const payload = this.preparePayload()
                const { status, msg } = await userService.updateProfileUpdateRequest(this.$route.params.pur.id, payload)

                if (status === 'update_forced') {
                    this.requested = true
                    return
                }
                this.$store.dispatch('loadMe')
                this.$toast.success(msg)
                this.$router.push({ name: 'ChatsPage' })
            } catch (e) {
                this.$toast.error(e.msg)
            } finally {
                this.$loading(false)
            }
        },
        preparePayload() {
            const payload = new FormData()

            this.profiles.forEach(profile => {
                if (profile.key === 'photos' && this.photos.length > 0) {
                    if (this.photos.some(photo => photo.shouldUpdate && !photo.updated)) {
                        throw new Error({ msg: '수정이 필요한 모든사진을 수정 혹은 삭제해 주세요' })
                    }

                    if (this.photos.filter(photo => photo.url).length < 2) {
                        throw new Error({ msg: '사진은 반드시 2장 이상 필요합니다.' })
                    }

                    this.photos
                        .filter(photo => photo.updated)
                        .forEach((photo, idx) => {
                            payload.append(`photo${idx + 1}`, photo.blob, photo.fileName)
                        })

                    payload.append('delete_photo_ids', JSON.stringify(this.deletePhotoIds))
                } else {
                    if (profile.shouldUpdate && !profile.updated) {
                        throw new Error({ msg: `${profile.title}을 수정해주세요` })
                    }

                    if (profile.shouldUpdate && profile.updated) {
                        payload.append(this.$case.toSnake(profile.key), profile.item.name)
                    }
                }
            })

            return payload
        },
    },
}
</script>
