<template>
    <div class="option-selection-box">
        <span class="value" :class="{ 'has-option': option }" v-html="value" />
        <i class="material-icons">expand_more</i>
    </div>
</template>

<script>
export default {
    name: 'OptionSelectionBox',
    props: ['placeholder', 'option'],
    computed: {
        value() {
            if (!this.placeholder && !this.option) return ''

            return this.option ? this.option : this.placeholder
        },
    },
    methods: {},
}
</script>

<style lang="scss" scoped>
.option-selection-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px 16px;
    background-color: $grey-01;
    border: solid 1px $grey-02;
    border-radius: 8px;

    .value {
        font-size: 15px;
        color: $grey-05;
        font-family: NotoSans-DemiLight;

        &.has-option {
            color: black;
        }
    }

    .material-icons {
        color: $grey-05;
    }
}
</style>
