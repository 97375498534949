import { render, staticRenderFns } from "./ChatItemPhoto.vue?vue&type=template&id=7442f50d&scoped=true"
import script from "./ChatItemPhoto.vue?vue&type=script&lang=js"
export * from "./ChatItemPhoto.vue?vue&type=script&lang=js"
import style0 from "./ChatItemPhoto.vue?vue&type=style&index=0&id=7442f50d&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7442f50d",
  null
  
)

export default component.exports