<template>
    <header class="root-header-bar" v-if="$isRootRoute()">
        <div class=""></div>
        <div class="right">
            <img v-if="isFemale && isChatPage" class="privacy" @click="onClickPrivacy" :src="privacySrc" />
            <BadgeVanilla @click="onClickBadgeVanilla" class="m-r-12" />
            <div class="bell p-relative" @click="onClickNotification">
                <i class="material-icons">notifications_none</i>
                <div v-if="showUnreadNotifications" class="badge" />
            </div>
        </div>
    </header>
</template>

<script>
export default {
    name: 'RootHeaderBar',
    computed: {
        me() {
            return this.$store.getters.me
        },
        photo_url() {
            if (!this.me) return ''
            if (!this.me.photos.length) return ''

            return this.me.photos[0].url
        },
        title() {
            if (this.$route.name === 'ChatsPage') return 'CHATS_TITLE'
            else if (this.$route.name === 'MyDatingPage') return 'MY_DATING_TITLE'
            else if (this.$route.name === 'MyPage') return 'MY_PAGE_TITLE'
            else if (this.$route.name === 'TodayMeetingPage') return 'TODAY_MEETING_TITLE'
            else if (this.$route.name === 'SocialMeetingPage') return 'SOCIAL_MEETING_TITLE'
            else if (this.$route.name === 'RealFriendMeetingPage') return 'REAL_FRIEND_MEETING'

            return ''
        },
        showUnreadNotifications() {
            return (this.$store.getters.notifications || []).filter(n => !n.read).length > 0
        },
        gender() {
            return (this.me || {}).gender
        },
        isFemale() {
            return this.gender === 1
        },
        isChatPage() {
            return this.$route.name === 'ChatsPage'
        },
        showTitle() {
            const betaVersion = ['TodayMeetingPage', 'SocialMeetingPage', 'RealFriendMeetingPage']
            return betaVersion.includes(this.$route.name)
        },
        betaSrc() {
            if (['TodayMeetingPage', 'RealFriendMeetingPage'].includes(this.$route.name)) {
                return require('@/assets/images/today-meeting/badge_beta.png')
            } else {
                return require('@/assets/images/social-meeting/social_beta.png')
            }
        },
        privacySrc() {
            if (this.$isPrivacy()) return require('@/assets/images/super-privacy/superprivacy-icon-active.svg')
            else return require('@/assets/images/super-privacy/superprivacy-icon-inactive.svg')
        },
    },
    methods: {
        onClickNotification() {
            this.$router.push({ name: 'NotificationsPage' })
        },
        onClickBadgeVanilla() {
            this.$stackRouter.push({ name: 'InAppStorePage' })
        },
        onClickPrivacy() {
            if (this.$isPrivacy()) {
                const privacy = this.$store.getters.me.products.filter(p => p.ptype === 'privacy' && p.enabled === true)
                if (privacy === null || privacy === []) return

                const validUntil = privacy[0].valid_until.split('T')[0]

                this.$modal.custom({
                    component: 'ModalAlertNoX',
                    options: {
                        title: 'SUPER_PRIVACY_ON',
                        body: `회원님의 동의가 없으면 누구에게도 프로필이 공개되지 않습니다.<br>(남은 기간 ${this.$moment(
                            validUntil,
                        ).diff(this.$moment(), 'days')}일)`,
                    },
                })
            } else {
                this.$modal
                    .basic({
                        body: this.$translate('SUPER_PRIVACY_OFF_MESSAGE'),
                        buttons: [
                            {
                                label: 'CLOSE',
                                class: 'btn-default',
                            },
                            {
                                label: 'ACTIVATE',
                                class: 'btn-primary',
                            },
                        ],
                    })
                    .then(idx => {
                        if (idx === 1) {
                            this.$nativeBridge.postMessage({
                                action: 'sendFirebaseEvent',
                                value: {
                                    category: 'privacyEvent',
                                    option: {
                                        funnel: 'click_privacy_off_button',
                                    },
                                },
                            })

                            this.$stackRouter.push({ name: 'SuperPrivacyIntroductionPage' })
                        }
                    })
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.root-header-bar {
    width: 100vw;
    height: 48px;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;

    .right {
        display: flex;
        padding-top: 12px;

        .privacy {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            @include center;
            background-color: $grey-02;
            margin-right: 20px;

            &.active {
                background-color: $blue-facebook;
            }
        }
        .material-icons {
            color: $grey-08;
        }

        .bell {
            flex: none;
        }
    }
}
</style>
