<template>
    <div class="my-page-menu">
        <ul class="menu">
            <li v-show="item.show" class="item" v-for="item in upper" :key="item.key" @click="onClickMenu(item.route)">
                <i class="material-icons-outlined icon">{{ item.icon }}</i>
                <span class="key" v-html="$translate(item.key)" />
            </li>
        </ul>

        <hr class="hr thick" />

        <ul class="menu">
            <li class="item" v-for="item in lower" :key="item.key" @click="onClickMenu(item.route)">
                <i class="material-icons-outlined icon">{{ item.icon }}</i>
                <span class="key" v-html="$translate(item.key)" />
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'MyPageMenu',
    data: () => ({
        lower: [
            {
                key: 'NOTICES',
                icon: 'sms_failed',
                route: 'NoticesPage',
            },
            {
                key: 'CUSTOMER_CENTER',
                icon: 'headset_mic',
                route: 'CustomerCenterPage',
            },
            {
                key: 'SETTINGS',
                icon: 'settings',
                route: 'SettingsPage',
            },
        ],
    }),
    computed: {
        isFemale() {
            return (this.$store.getters.me || {}).gender === 1
        },
        upper() {
            return [
                {
                    key: 'IN_APP_STORE',
                    icon: 'storefront',
                    route: 'InAppStorePage',
                    show: true,
                },
                {
                    key: 'INVITE_FRIEND',
                    icon: 'group_add',
                    route: 'InvitationFriendPage',
                    show: !this.$isTester(),
                },
                {
                    key: 'SUPER_PRIVACY',
                    icon: 'security',
                    route: 'SuperPrivacyIntroductionPage',
                    show: this.isFemale,
                },
                {
                    key: 'BLOCK_FRIEND',
                    icon: 'person_add_disabled',
                    route: 'BlockContactsPage',
                    show: true,
                },
            ]
        },
    },
    methods: {
        onClickMenu(route) {
            if (route === 'CustomerCenterPage') {
                this.$router.push({ name: route })
                return
            }
            this.$stackRouter.push({ name: route })
        },
    },
}
</script>

<style lang="scss" scoped>
.my-page-menu {
    .hr.thick {
        background: $grey-02;
        border: 0;
        height: 8px;
    }

    .item {
        padding: 24px 16px;
        display: flex;
        align-items: center;

        &:not(:last-child) {
            border-bottom: solid 1px $grey-02;
        }

        .icon {
            color: $grey-06;
            margin-right: 20px;
        }

        .key {
            font-size: 16px;
            color: black;
        }
    }
}
</style>
