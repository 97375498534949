<template>
    <div class="group-chat-item-photos" :class="photoClass">
        <img class="photo" v-for="photo in photos" :key="photo" v-lazy="photo" />
    </div>
</template>

<script>
export default {
    name: 'GroupChatItemPhotos',
    props: {
        photos: Array,
    },
    computed: {
        photoClass() {
            return `photo-${(this.photos || []).length}`
        },
    },
}
</script>

<style lang="scss" scoped>
.group-chat-item-photos {
    position: relative;
    width: 60px;
    height: 60px;

    .photo {
        position: absolute;
        width: 32px;
        height: 32px;
        border-radius: 40%;
        border: solid 1px $grey-02;
    }

    &.photo-1 {
        .photo {
            width: 58px;
            height: 58px;
        }
    }

    &.photo-2 {
        .photo {
            width: 38px;
            height: 38px;
        }

        .photo:last-child {
            right: 0;
            bottom: 0;
            z-index: 2;
        }
    }

    &.photo-3 {
        .photo:nth-child(1) {
            left: 50%;
            transform: translateX(-50%);
            z-index: 3;
        }

        .photo:nth-child(2) {
            top: 28px;
            z-index: 1;
        }

        .photo:nth-child(3) {
            top: 28px;
            right: 0;
            z-index: 2;
        }
    }

    &.photo-4 {
        .photo:nth-child(1) {
            left: 0;
            z-index: 4;
        }

        .photo:nth-child(2) {
            right: 0;
            z-index: 3;
        }

        .photo:nth-child(3) {
            left: 0;
            bottom: 0;
            z-index: 1;
        }

        .photo:nth-child(4) {
            right: 0;
            bottom: 0;
            z-index: 2;
        }
    }
}
</style>
