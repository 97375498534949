<template>
    <div class="user-profile">
        <SelfIntroduction v-if="intro" class="section section-intro" :intro="intro" />

        <BasicProfile
            v-if="profile"
            class="section"
            :userId="userId"
            :profile="profile"
            :verificationBadges="verificationBadges"
        />

        <template v-if="user.keywords.length > 0">
            <hr />
            <Keywords class="section" :keywords="user.keywords" />
        </template>

        <template v-if="user.dating_preferences.length > 0 && from !== 'TodayMeetingCard'">
            <hr />
            <DatingPreference class="section" :user="user" />
        </template>
    </div>
</template>

<script>
import SelfIntroduction from './SelfIntroduction'
import BasicProfile from './BasicProfile'
import DatingPreference from './DatingPreference'
import Keywords from './Keywords'

export default {
    name: 'UserProfile',
    components: {
        SelfIntroduction,
        BasicProfile,
        DatingPreference,
        Keywords,
    },
    props: {
        user: {
            type: Object,
            required: true,
        },
        from: String,
    },
    computed: {
        intro() {
            const removeBlank = intro => (intro || '').trim()

            let intro = ''
            if (this.user) intro = this.user.profile.intro

            return removeBlank(intro)
        },
        userId() {
            return this.user.id
        },
        profile() {
            return this.user.profile
        },
        verificationBadges() {
            return this.user.verification_badges || []
        },
    },
}
</script>

<style lang="scss" scoped>
.user-profile {
    .section {
        padding: 32px 16px 0;
        z-index: 4;

        &.self-introduction {
            padding-bottom: 16px;
        }

        &.basic-profile {
            padding-bottom: 32px;
        }
    }

    hr {
        border-bottom: solid 1px $grey-02;
    }
}
</style>
