<template>
    <div class="nps">
        <div class="scores">
            <span
                class="score"
                v-for="score in scores"
                :key="score"
                :class="{ selected: score === selectedScore }"
                @click="onClick(score)"
            >
                {{ score }}
            </span>
        </div>
        <div class="recommend-or-not">
            <p class="f-medium wording">추천하고 싶지<br />않아요</p>
            <p class="f-medium wording">추천하고<br />싶어요</p>
        </div>
    </div>
</template>

<script>
const SCORE_LENGTH = 10

export default {
    data: () => ({
        scores: [],
        selectedScore: null,
    }),
    mounted() {
        this.init()
    },
    methods: {
        init() {
            for (let i = 1; i <= SCORE_LENGTH; i += 1) this.scores.push(i)
            this.selectedScore = this.$store.getters.payloads.npsData.score
        },
        onClick(score) {
            this.selectedScore = score
            this.$emit('select', score)
        },
    },
}
</script>

<style lang="scss" scoped>
.nps {
    display: flex;
    flex-direction: column;
    width: 100%;

    .scores {
        display: flex;
        flex-direction: row;
    }

    .score {
        flex: 1;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $grey-01;
        border: solid 1px white;
        border-radius: 2px;
        font-size: 14px;
        color: $grey-07;

        &:first-child {
            border-radius: 8px 2px 2px 8px;
        }

        &:last-child {
            border-radius: 2px 8px 8px 2px;
        }

        &.selected {
            color: white;
            background-color: #8a74ff;
            font-family: 'NotoSans-Bold';
        }
    }

    .recommend-or-not {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 16px;

        .wording {
            margin: 0;
            color: #8a74ff;
            font-size: 13px;
            line-height: 1.23;
            &:last-child {
                text-align: right;
            }
        }
    }
}
</style>
