<template>
    <div class="distance flex-row items-center">
        <span v-if="distance >= 0" class="value flex-wrap" :class="{ 'c-primary': distance <= 20 }">
            {{ distance }}km
        </span>
        <img class="flex-wrap" :src="distance > 20 ? ugly : smile" />
    </div>
</template>

<script>
export default {
    name: 'Distance',
    props: ['distance'],
    computed: {
        smile() {
            return require('@/assets/images/face_smile.svg')
        },
        ugly() {
            return require('@/assets/images/face_ugly.svg')
        },
    },
}
</script>

<style lang="scss" scoped>
.distance {
    font-size: 10px;

    .value {
        color: $grey-05;
        margin-right: 4px;
        font-family: NotoSans-Bold;

        &.c-primary {
            color: $purple-primary;
        }
    }
}
</style>
