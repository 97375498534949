<template>
    <div class="signup basics password">
        <div class="content">
            <div class="title">비밀번호</div>
            <form class="body">
                <div v-if="email" class="item-desc">{{ email }}</div>
                <div class="input-wrapper m-b-12">
                    <input
                        ref="password"
                        type="password"
                        class="flex-fill"
                        :placeholder="$translate('PLACEHOLDER_PASSWORD')"
                        v-model="password"
                        @input="onInputPassword"
                    />
                </div>
                <div class="input-wrapper">
                    <input
                        type="password"
                        class="flex-fill"
                        :placeholder="$translate('PLACEHOLDER_PASSWORD_CONFIRM')"
                        v-model="passwordConfirm"
                        @input="onInputPasswordConfirm"
                    />
                </div>
                <div class="error" v-if="error">
                    <i class="material-icons">error_outline</i>
                    {{ error | translate }}
                </div>
            </form>
        </div>
        <div class="button-wrapper">
            <button
                class="btn btn-primary"
                :class="{ disabled: disabled }"
                @click="next()"
                v-html="$translate('NEXT')"
            ></button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PasswordPage',
    data: () => ({
        password: '',
        passwordConfirm: '',
        error: null,
        disabled: true,
    }),
    mounted() {
        this.$refs.password.focus()
        this.$nativeBridge.postMessage({
            action: 'sendFirebaseEvent',
            value: {
                category: 'PasswordPage_Enter_PasswordPage',
                option: {
                    provider: this.$store.getters.payloads.signupData.provider || 'email',
                },
            },
        })
    },
    computed: {
        email() {
            return this.$store.getters.payloads.signupData.email
        },
    },
    methods: {
        checkPassword() {
            if (this.password && this.password.length < 6) {
                this.error = '6자 이상 입력해주세요'
                this.disabled = !!this.error
                return
            }

            if (this.password && this.passwordConfirm && this.password !== this.passwordConfirm) {
                this.error = 'ERROR_PASSWORD_CONFIRM'
                this.disabled = !!this.error
                return
            }

            this.error = null
            this.disabled = !this.password || !this.passwordConfirm
        },
        onInputPassword(event) {
            this.password = event.target.value
            this.checkPassword()
        },

        onInputPasswordConfirm(event) {
            this.passwordConfirm = event.target.value
            this.checkPassword()
        },
        next() {
            if (this.error || this.disabled) return

            if (!this.error) {
                this.$store.commit('setPayloads', {
                    signupData: {
                        password: this.$store.getters.payloads.signupData.password || this.password,
                    },
                })
                this.$router.push({ name: 'GenderPage' })
                // this.$router.push({ name: 'NicknamePage' })
            }
        },
        back() {
            this.$router.go(-1)
        },
    },
}
</script>
