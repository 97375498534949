<template>
    <nav class="chat-header-bar" :class="{ instant: isInstantChat && !isPremium, kakaoMode: $kakaoMode() }">
        <button class="left-btn" @click="onClickLeftBtn">
            <i class="material-icons">chevron_left</i>
        </button>

        <div class="title-wrapper">
            <h1 class="title" :class="{ flexbox: isInstantChat }" v-html="title" />
        </div>
        <div v-if="isPremium" class="premium-agent flex-wrap m-l-8">
            <img class="premium-agent-inner flex-wrap m-r-4" src="@/assets/images/premium.svg" />
            <span class="premium-agent-inner flex-wrap f-medium" v-html="$translate('PREMIUM')" />
        </div>

        <button class="right-btn" @click="onClickRightBtn">
            <i v-if="showChatMenuIcon" class="material-icons">more_horiz</i>
            <i v-else-if="showExitChatIcon" class="material-icons">exit_to_app</i>
        </button>
    </nav>
</template>

<script>
import chatService from '@/services/chat'

export default {
    name: 'ChatHeaderBar',
    data: () => ({
        showInstantChatInfo: false,
    }),
    computed: {
        chat() {
            return this.$store.getters.chat || {}
        },
        agentId() {
            return this.chat.agent_id
        },
        agentTitle() {
            return '주선자'
        },
        title() {
            if (!this.chat || (!this.chat.user && !this.chat.users)) return

            if (this.chat.chat_type === 'alimi') {
                return this.$translate('VANILLABRIDGE')
            } else if (this.chat.chat_type === 'social_group') {
                return this.chat.event.title || ''
            } else if (this.chat.chat_type === 'real_friend') {
                const myId = this.$store.getters.me.id

                const nameOrNick = this.$nameOrNick(this.chat.user)

                if (nameOrNick === '---') return '탈퇴한 유저'

                if (myId === this.chat.introduce.user_id) {
                    return nameOrNick
                } else {
                    return `주선자 ${nameOrNick}`
                }
            } else if (this.isInstantChat) {
                return `${this.agentTitle} ${this.chat.user.name}
                    <span class="expire-badge">임시</span>`
            }

            return `주선자 ${this.chat.user.name}`
        },
        isPremium() {
            if (!this.chat) return false
            if (this.isInstantChat || this.isSocialOrRealFriendChat) return false

            return this.$isPremium(this.agentId)
        },
        isInstantChat() {
            return !!this.chat.expire_at
        },
        isSocialOrRealFriendChat() {
            return this.chat.chat_type === 'social_group' || this.chat.chat_type === 'real_friend'
        },
        showChatMenuIcon() {
            return !this.$isTester() && !this.isSocialOrRealFriendChat
        },
        showExitChatIcon() {
            return this.chat.chat_type === 'social_group'
        },
    },
    methods: {
        onClickLeftBtn() {
            this.$router.go(-1)
        },
        onClickRightBtn() {
            if (this.showChatMenuIcon) {
                this.openChatMenu()
            } else if (this.showExitChatIcon) {
                this.exitChat()
            }
        },
        openChatMenu() {
            this.$actionSheet({
                buttons: [
                    {
                        label: 'GIVE_GIFT',
                        handler: () => {
                            const agent = this.$store.getters.chat.user

                            if (!agent) return
                            agent.id = this.$store.getters.chat.agent_id

                            this.$stackRouter.push({
                                name: 'GiftListPage',
                                props: { agent },
                            })
                        },
                    },
                    {
                        label: this.$kakaoMode() ? 'VANILLA_SKIN' : 'KAKAO_SKIN',
                        handler: () => {
                            if (!this.$kakaoMode()) {
                                setTimeout(() => {
                                    this.$modal
                                        .basic({
                                            body:
                                                '채팅방 스킨을 카카오톡 테마로 설정하고, 공공장소에서도 편하게 사용하세요!',
                                            buttons: [
                                                {
                                                    label: '취소',
                                                    class: 'btn-default',
                                                },
                                                {
                                                    label: '확인',
                                                    class: 'btn-primary',
                                                },
                                            ],
                                        })
                                        .then(idx => {
                                            if (idx) {
                                                const changed = !this.$store.getters.kakaoMode
                                                localStorage.setItem('kakao_mode', changed)
                                                this.$store.commit('setKakaoMode', changed)
                                                this.$nativeBridge.postMessage({
                                                    action: 'setBackgroundColor',
                                                    value: {
                                                        top: '#c5d3df',
                                                        bottom: 'white',
                                                    },
                                                })
                                                this.$toast.success(
                                                    `카카오톡 스킨이 ${changed ? '적용' : '해제'} 되었어요`,
                                                )
                                            }
                                        })
                                }, 500)
                            } else {
                                const changed = !this.$store.getters.kakaoMode
                                localStorage.removeItem('kakao_mode', changed)
                                this.$store.commit('setKakaoMode', changed)
                                this.$nativeBridge.postMessage({
                                    action: 'setBackgroundColor',
                                    value: '#FFFFFF',
                                })
                                this.$toast.success(`카카오톡 스킨이 ${changed ? '적용' : '해제'} 되었어요`)
                            }

                            this.$nativeBridge.postMessage({
                                action: 'sendFirebaseEvent',
                                value: {
                                    category: 'Click_ChangeKakaoSkin',
                                    option: {
                                        value: this.$kakaoMode() ? 'on' : 'off',
                                        channel: 'chat_detail',
                                    },
                                },
                            })
                        },
                    },
                    {
                        label: 'REPORT_AGENTS',
                        class: 'c-red',
                        handler: () => {
                            const type = 'agent'
                            const target = this.$store.getters.chat.user
                            target.agent_id = this.$store.getters.chat.agent_id

                            this.$stackRouter.push({
                                name: 'ReportSelectReasonPage',
                                props: { type, target },
                            })
                        },
                    },
                ],
            })
        },
        async exitChat() {
            const isHost = this.$store.getters.me.id === this.chat.event.user_id

            if (isHost) {
                this.$modal.basic({
                    body:
                        '호스트는 대화방을 나갈 수 없습니다. 부득이하게 참석이 어려운 경우 양해를 구하고, 모임을 대신 진행할 분을 지정해주세요.',
                    buttons: [
                        {
                            label: 'CONFIRM',
                            class: 'c-primary',
                        },
                    ],
                })

                return
            }

            const idx = await this.$modal.basic({
                title: '채팅방 나가기',
                body:
                    '채팅방 나가기 전에 다른 분들에게 참석이 어렵다고 이야기 하셨나요? 모임의 기본 매너를 지켜주세요!',
                buttons: [
                    {
                        label: '아직 안했어요',
                    },
                    {
                        label: '이야기 했어요',
                    },
                ],
            })

            if (typeof idx === 'number' && idx === 1) {
                const index = await this.$modal.basic({
                    body: '채팅방을 나가시겠어요? 채팅방을 나가면 다시 참가할 수 없습니다.',
                    buttons: [
                        {
                            label: 'CANCEL',
                        },
                        {
                            label: 'CONFIRM',
                        },
                    ],
                })

                if (index === 1) {
                    try {
                        this.$loading(true)
                        await chatService.closeChat(this.chat.id)
                        await this.$store.dispatch('loadChats', true)
                        this.$router.go(-1)
                    } catch (e) {
                        console.error(e)
                    } finally {
                        this.$loading(false)
                    }
                }
            }
        },
    },
    mounted() {},
    beforeDestroy() {},
}
</script>

<style lang="scss" scoped>
.chat-header-bar {
    $chat-header-height: 42px;

    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: $chat-header-height;
    border-bottom: solid 1px $grey-03;
    background-color: white;

    &.kakaoMode {
        border-bottom: none;
        background-color: $blue-kakao;
    }

    .title-wrapper {
        display: flex;
        align-items: center;
        max-width: 60%;
    }

    .title {
        color: black;
        font-size: 16px;
        font-weight: 500;

        @include lines-1;
        @include f-medium;

        &.flexbox {
            display: flex;
            align-items: center;
            flex-wrap: nowrap;
        }
    }

    .premium-agent {
        height: 20px;
        margin-left: 8px;
        padding: 1px 6px;
        font-size: 12px;
        color: $yellow-premium;
        border-radius: 6px;
        border: solid 1px $yellow-premium;

        @include center;
        .premium-agent-inner {
            vertical-align: middle;
        }
    }

    button {
        border: 0;
        background: 0;
        padding: 0;
    }

    .left-btn,
    .right-btn {
        width: calc(#{$chat-header-height} + 16px);
        height: $chat-header-height;

        @include center;

        .material-icons {
            color: $grey-08;
        }
    }

    .left-btn {
        position: absolute;
        height: $chat-header-height;
        left: 0;
    }

    .right-btn {
        position: absolute;
        right: 0;
    }

    &.instant {
        ::v-deep .expire-badge {
            margin-left: 6px;
            padding: 0 6px;
            height: 20px;
            border-radius: 6px;
            //border: solid 1px $blue-suggest;
            background: $grey-02;
            font-size: 11px;
            color: $grey-08;

            @include f-medium;
            @include center;
        }
    }
}
</style>
