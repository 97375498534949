<template>
    <div class="my-dating" @scroll="onScroll">
        <div class="top-border" v-if="scrollTop !== 0" />
        <div class="padding" />
        <!--        <PremiumAdBanner v-if="!$isTester()" :gender="gender" />-->
        <MyAgents />
        <Suggested class="content" />
        <!-- <p @click="goReportPage" class="report-feedback">불편신고 & 아이디어 제안</p> -->
    </div>
</template>

<script>
import Suggested from './components/Suggested'
// import PremiumAdBanner from '@/components/common/PremiumAdBanner'
import MyAgents from '@/routes/my-dating/components/MyAgents'
export default {
    name: 'MyDatingPage',
    // components: { Suggested, PremiumAdBanner, MyAgents },
    components: { Suggested, MyAgents },
    data: () => ({
        scrollTop: 0,
    }),
    computed: {
        gender() {
            return (this.$store.getters.me || {}).gender
        },
    },
    methods: {
        initMyDatingBadge() {
            window.localStorage.setItem('myDatingBadge', 0)
            this.$store.commit('setMyDatingBadge', false)
        },
        goReportPage() {
            this.$nativeBridge.postMessage({
                action: 'openInAppBrowser',
                value: 'https://docs.google.com/forms/d/1tzfu_rV9U2NKb4qfy16ht0MoIUUhVb1nAFou3d50lwo/edit',
            })
        },
        async onScroll({ target }) {
            this.scrollTop = target.scrollTop
        },
    },
    mounted() {
        this.initMyDatingBadge()
    },
}
</script>

<style lang="scss" scoped>
.my-dating {
    .padding {
        height: 8px;
    }

    .top-border {
        position: absolute;
        top: 48px;
        height: 1px;
        width: 100vw;
        background-color: $grey-02;
    }

    ::v-deep .def-text {
        @include f-regular;
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.2px;
    }

    ::v-deep .content {
        //overflow-y: auto;

        .top {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 16px;

            .badge {
                margin-left: 4px;
            }

            .title {
                color: black;
                font-family: NotoSans-Medium;
                font-size: 20px;
            }
        }
    }

    .report-feedback {
        text-align: center;
        text-decoration: underline;
        font-size: 13px;
        margin-bottom: 60px;
    }
}
</style>
