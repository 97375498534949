<template>
    <div class="premium-superprivacy m-b-8">
        <div v-if="gender === 1" class="icon-container" :class="[isPrivacy ? 'blue' : 'grey']">
            <img
                v-if="!isPrivacy"
                @click="goToPrivacyIntroductionPage"
                :src="require('@/assets/images/super-privacy/superprivacy-icon-inactive.svg')"
            />
            <img
                v-else
                @click="onClickPrivacy"
                :src="require('@/assets/images/super-privacy/superprivacy-icon-active.svg')"
            />
        </div>
        <div class="icon-container" :class="[isPremium ? 'yellow' : 'grey', gender === 1 ? 'm-r-10' : '']">
            <img
                v-if="!isPremium"
                @click="goToPremiumIntroductionPage"
                :src="require('@/assets/images/premium/premium-icon-inactive.svg')"
            />
            <img
                v-else
                @click="onClickPremium"
                :src="require('@/assets/images/premium/premium-icon-active.svg')"
                alt=""
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'ProductIcons',
    props: {
        me: Object,
    },
    computed: {
        gender() {
            return this.me.gender
        },
        isPremium() {
            if (!this.me) return false
            return this.$isPremium()
        },
        isPrivacy() {
            if (!this.me) return false
            return !!this.$isPrivacy()
        },
    },
    methods: {
        onClickPremium() {
            const premium = this.$store.getters.me.products.filter(p => p.ptype === 'premium' && p.enabled === true)
            if (premium === null || premium === []) return

            const validUntil = premium[0].valid_until.split('T')[0]
            const validUntilText = `${validUntil.split('-')[0]}년 ${validUntil.split('-')[1]}월 ${
                validUntil.split('-')[2]
            }일`

            this.$modal.basic({
                // title: 'INFO',
                body: `프리미엄 기간은 ${validUntilText}까지입니다.`,
                buttons: [
                    {
                        label: 'CONFIRM',
                        class: 'btn-primary',
                    },
                ],
            })
            // this.$alert(`프리미엄 기간은 ${validUntilText}까지입니다.`)
        },
        goToPremiumIntroductionPage() {
            if (this.$isTester()) return

            this.$stackRouter.push({ name: 'PremiumIntroductionPage' })
        },
        onClickPrivacy() {
            const privacy = this.$store.getters.me.products.filter(p => p.ptype === 'privacy' && p.enabled === true)
            if (privacy === null || privacy === []) return

            const validUntil = privacy[0].valid_until.split('T')[0]

            this.$modal.custom({
                component: 'ModalAlertNoX',
                options: {
                    title: 'SUPER_PRIVACY_ON',
                    body: `회원님의 동의가 없으면 누구에게도 프로필이 공개되지 않습니다.<br>(남은 기간 ${this.$moment(
                        validUntil,
                    ).diff(this.$moment(), 'days')}일)`,
                },
            })
        },
        goToPrivacyIntroductionPage() {
            this.$modal
                .basic({
                    body: this.$translate('SUPER_PRIVACY_OFF_MESSAGE'),
                    buttons: [
                        {
                            label: 'CLOSE',
                            class: 'btn-default',
                        },
                        {
                            label: 'ACTIVATE',
                            class: 'btn-primary',
                        },
                    ],
                })
                .then(idx => {
                    if (idx === 1) {
                        this.$nativeBridge.postMessage({
                            action: 'sendFirebaseEvent',
                            value: {
                                category: 'privacyEvent',
                                option: {
                                    funnel: 'click_privacy_off_button',
                                },
                            },
                        })

                        this.$stackRouter.push({ name: 'SuperPrivacyIntroductionPage' })
                    }
                })
        },
    },
}
</script>

<style scoped lang="scss">
.premium-superprivacy {
    display: flex;
    flex-direction: row-reverse;
    padding-right: 16px;

    .icon-container {
        width: 28px;
        height: 28px;
        border-radius: 50%;
        @include center;

        &.grey {
            background-color: $grey-02;
        }
        &.yellow {
            background-color: $yellow-premium;
        }
        &.blue {
            background-color: $blue-facebook;
        }
    }
}
</style>
