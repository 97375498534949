<template>
    <div class="dating-review">
        <div class="photo-cont" v-if="review.photo_url">
            <img :src="review.photo_url" />
        </div>
        <div class="name-date-cont">
            <div class="name">
                <div>{{ review.user_gender === 'male' ? '👨' : '👩' }}</div>
                <span class="m-l-10">{{ review.user_nickname }}</span
                >님
                <img src="@/assets/images/grey-heart.png" />
                <span>{{ review.target_nickname }}</span
                >님
            </div>
            <div class="date" v-html="'2021.05.01'"></div>
        </div>
        <div class="agent-date">
            <div class="box" v-html="`주선자 ${agent_name}`" />
            <div class="box m-l-6">{{ review.dating_date | formatDate('MM월 DD일') }} 매칭</div>
        </div>
        <div class="review" v-html="review.review.replaceAll('\n', '<br>')" />
        <div v-if="divider" class="divider" />
    </div>
</template>

<script>
export default {
    name: 'DatingReview',
    props: ['agent', 'review', 'divider'],
    computed: {
        agent_name() {
            if (this.agent) return this.agent.name
            else return this.review.agent_name
        },
    },
    methods: {},
}
</script>

<style lang="scss" scoped>
.dating-review {
    padding: 0 16px;
    @include f-regular;

    .photo-cont {
        margin-top: 12px;
        width: 100%;

        img {
            width: 100%;
            border-radius: 8px;
        }
    }

    .name-date-cont {
        margin-top: 14px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .name {
            font-size: 15px;
            color: black;
            display: flex;
            align-items: center;

            div {
                height: 30px;
                width: 30px;
                border-radius: 15px;
                border: solid 1px $grey-03;
                background: $grey-01;
                text-align: center;
                font-size: 18px;
            }

            img {
                margin: 0 6px;
                height: 16px;
            }

            span {
                @include f-medium;
            }
        }

        .date {
            height: fit-content;
            font-size: 13px;
            color: $grey-06;
        }
    }

    .agent-date {
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        font-size: 14px;
        color: $grey-09;

        .box {
            background: $grey-01;
            border-radius: 6px;
            padding: 2px 8px;
        }
    }

    .review {
        margin-top: 16px;
        font-size: 14px;
        color: black;
        letter-spacing: -0.2px;
        line-height: 22px;
    }

    .divider {
        margin: 36px -16px;
        height: 1px;
        background: $grey-02;
    }
}
</style>
