<template>
    <div class="message-action p-relative" :class="message.mtype">
        <ActionDefaultLayout v-if="useDefaultLayout" :message="message">
            <component :is="componentByMessageType" :message="message" />
        </ActionDefaultLayout>
        <component v-else :is="componentByMessageType" :message="message" />
    </div>
</template>
<script>
import ActionDefaultLayout from './ActionDefaultLayout'

export default {
    name: 'MessageAction',
    props: ['message'],
    components: {
        ActionDefaultLayout,
        ActionContactDone: () => import('./message-actions/ActionContactDone'),
        ActionContactRequest: () => import('./message-actions/ActionContactRequest'),
        ActionOpenChat: () => import('./message-actions/ActionOpenChat'),
        ActionRequest: () => import('./message-actions/ActionRequest'),
        ActionShareDone: () => import('./message-actions/ActionShareDone'),
        ActionShareRequest: () => import('./message-actions/ActionShareRequest'),
        ActionNoti: () => import('./message-actions/ActionNoti'),
        ActionAlimiBasic: () => import('./message-actions/ActionAlimiBasic'),
        ActionPhoto: () => import('./message-actions/ActionPhoto'),
        ActionUnknown: () => import('./message-actions/ActionUnknown'),
        ActionGiftInfo: () => import('./message-actions/ActionGiftInfo'),
        ActionRefundStatus: () => import('./message-actions/ActionRefundStatus'),
        ActionPremiumStart: () => import('./message-actions/ActionPremiumStart'),
        ActionPremiumEnd: () => import('./message-actions/ActionPremiumEnd'),
        ActionPremiumChat: () => import('./message-actions/ActionPremiumChat'),
        ActionPremiumSales: () => import('./message-actions/ActionPremiumSales'),
        ActionAcceptIntroduce: () => import('./message-actions/ActionAcceptIntroduce'),
        ActionRequestRefund: () => import('./message-actions/ActionRequestRefund'),
        ActionConfirmMeeting: () => import('./message-actions/ActionConfirmMeeting'),
        ActionRefundReject: () => import('./message-actions/ActionRefundReject'),
        ActionPremiumSurveyEnd: () => import('./message-actions/ActionPremiumSurveyEnd'),
        ActionPremiumRating: () => import('./message-actions/ActionPremiumRating'),
    },
    computed: {
        content() {
            return this.$mustParse(this.message.content)
        },
        componentByMessageType() {
            if (this.unknown) return 'ActionUnknown'
            if (this.message.mtype.includes('noti')) return 'ActionNoti'
            if (this.message.mtype.includes('photo')) return 'ActionPhoto'
            if (['send-floating', 'daily-request'].indexOf(this.message.mtype) !== -1) return 'ActionDailyRequest'
            if (this.message.mtype === 'premium-survey-end') return 'ActionPremiumSurveyEnd'

            return 'Action' + this.$case.toPascal(this.message.mtype, '-', 2)
        },
        useDefaultLayout() {
            return (
                ['alimi', 'photo'].every(key => !this.message.mtype.includes(key)) &&
                this.message.mtype !== 'request' &&
                this.message.mtype !== 'gift-info' &&
                !this.unknown &&
                this.message.mtype !== 'accept-introduce'
            )
        },
        mtypes() {
            return [
                'contact-done-receiver',
                'contact-done-sender',
                'contact-request',
                'open-chat',
                'open-chat-pro',
                'photo',
                'multi-photo',
                'profile-photo',
                'recommend-reject',
                'request',
                'share-done',
                'share-request',
                'text',
                'gift-info',
                'refund-status',
                'refund-status-user',
                'premium-start',
                'premium-end',
                'premium-chat',
                'premium-sales',
                'accept-introduce',
                'request-refund',
                'confirm-meeting',
                'open-chat-introduce',
                'refund-reject',
                'premium-survey-end',
                'premium-rating',
            ]
            // 'premium-start', 프리미엄 주선 채팅방 처음 시작시
            // 'premium-end',
            // 'premium-chat', 프리미엄 주선자가 인스턴트 채팅방 강제로 열때
            // 'premium-sales', 프리미엄 상담사가 상담 채팅방 처음 열때
            // 'premium-survey-end', 프리미엄 유저가 사전 설문지 끝냈을떄
        },
        unknown() {
            return (
                this.mtypes.indexOf(this.message.mtype) === -1 &&
                !this.message.mtype.includes('noti') &&
                !this.message.mtype.includes('alimi')
            )
        },
    },
}
</script>
