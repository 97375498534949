<template>
    <transition name="fade">
        <div v-if="show && agent" class="agent-evaluation-banner">
            <div class="intro">
                <img class="img" :src="agent.photos[0].url" />
                <div>
                    <div class="m-b-4">
                        <span class="f-bold" v-html="agent.name" />님이 해주는 소개팅은 어떠셨나요?😉
                    </div>
                    <div>(주선자 프로필에서도 평가할 수 있어요!)</div>
                </div>
            </div>
            <div class="btns">
                <button class="btn btn-text btn-later" v-html="$translate('LATER')" @click="later" />
                <button class="btn btn-text" v-html="$translate('EVALUATE_AGENT')" @click="onClickEvaluation" />
            </div>
        </div>
    </transition>
</template>

<script>
import agentService from '@/services/agent'

export default {
    name: 'AgentEvaluationBanner',
    data: () => ({
        show: false,
        agent: null,
    }),
    computed: {
        chat() {
            return this.$store.getters.chat
        },
    },
    mounted() {
        this.init()
    },
    methods: {
        async init() {
            if (!this.chat || this.chat.user.id === 0) return

            try {
                const disabled = window.localStorage.getItem(`ratingDisabledAt:${this.chat.id}`)
                const { enabled, rating } = await agentService.ratingEnabled(this.chat.agent_id)
                this.show = !disabled && enabled && !rating

                if (this.show) {
                    this.agent = await this.$store.dispatch('loadAgentDetail', { agentId: this.chat.agent_id })
                }
            } catch (e) {
                console.error(e)
            }
        },
        onClickEvaluation() {
            this.$stackRouter.push({
                name: 'AgentEvaluationIntroPage',
                props: {
                    agent: this.agent,
                },
            })
        },
        later() {
            window.localStorage.setItem(`ratingDisabledAt:${this.chat.id}`, true)
            this.show = false
        },
    },
}
</script>
