<template>
    <div v-if="!$isTester()" class="premium-ad">
        <!-- ab테스트 추가 예정 -->
        <div v-html="cta" />
        <div @click="onClickMoreInfo" class="more-info">더 알아보기</div>
    </div>
</template>

<script>
export default {
    name: 'PremiumAd',
    methods: {
        onClickMoreInfo() {
            this.$stackRouter.push({
                name: 'PremiumIntroductionPage',
                props: {
                    beforeBackgroundColor: '#fff',
                },
            })
        },
    },
    data: () => ({
        cta: '더 예쁜 분과 매칭되고 싶으세요?',
    }),
}
</script>

<style scoped lang="scss">
.premium-ad {
    @include f-medium;
    margin: 12px 16px;
    border: 1px solid $brand-premium-blue;
    border-radius: 12px;
    color: $brand-premium-blue;
    background: white;
    padding: 8px;
    font-size: 14px;

    @include flex-row;
    justify-content: space-between;
    align-items: center;

    .more-info {
        color: white;
        background: $brand-premium-blue;
        border-radius: 8px;
        padding: 3px 12px;
        font-size: 12px;
    }
}
</style>
