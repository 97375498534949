<template>
    <div class="pg-store" @scroll="onScroll">
        <StackRouterHeaderBar :showTitle="showTitle" title="구매 페이지" />
        <div class="page-header">
            <div class="title" v-html="headerTitle"></div>
        </div>
        <RefundInfoBanner @click.native="refundInfoEvent" v-if="isTicket" :type="'ticket'" />
        <div class="body">
            <div class="payment-info">
                <div
                    :class="{
                        'bg-dating': isTicket && !isPremium,
                        'bg-ic': !isTicket && !isPremium,
                        premium: isPremium,
                    }"
                    :style="[
                        isTicket
                            ? { 'background-image': 'url(' + require('@/assets/images/store/bg_ticket.svg') + ')' }
                            : {
                                  'background-image':
                                      'url(' + require('@/assets/images/store/premium_ticket.png') + ')',
                                  height: 'calc(100vw * 0.3)',
                              },
                    ]"
                    class="ticket m-b-32"
                >
                    <div v-if="isTicket">
                        <div class="product-container flex-row">
                            <h3 class="name f-bold">
                                {{ product.name.replace('(학생)', '') }}
                            </h3>
                            <h4 class="name f-bold m-l-4" v-if="isPremium">주선</h4>
                            <span class="price-badge f-medium" v-if="isTicket">{{ items.tickets_count }}장 보유</span>
                        </div>
                        <p v-if="isTicket" class="desc">
                            만남이 성사되지 않을 경우<br />
                            <span class="f-bold">100% 환급!</span>
                        </p>
                        <p v-if="!isTicket" class="desc f-medium" v-html="product.description"></p>
                    </div>
                    <div v-if="isTicket" class="price f-bold">
                        <div
                            :class="{
                                'text-linethrough c-text-lightest text-decoration-color-white f-14':
                                    product.price < product.pre_price && !isTicket,
                            }"
                        >
                            {{ product.pre_price.toLocaleString() }}<span class="f-regular">원</span>
                        </div>
                        <div v-if="product.price < product.pre_price && !isTicket" class="m-t-8">
                            {{ product.price.toLocaleString() }}원
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="product" class="payment-price m-b-32">
                <div class="m-l-8">
                    <span class="price-title">결제 금액</span>
                    <div :class="isTicket ? 'c-primary' : 'c-ic'" class="final-price f-bold m-t-8">
                        {{ price.toLocaleString() }}<span class="f-regular"> 원</span>
                    </div>
                    <div class="detail m-t-8">
                        <div class="row original" v-if="(isTicket || isPremium) && product.discount > 0">
                            {{ product.name.replace('(학생)', '') }}
                            <span class="price f-medium m-l-4">{{ product.pre_price.toLocaleString() }}원</span>
                        </div>
                        <div class="discounts">
                            <span v-if="product.discount > 0" class="row discount m-r-4">
                                - {{ product.discount_name }}
                                <span class="price f-bold m-l-4">{{ product.discount.toLocaleString() }}원 </span>
                            </span>
                            <span v-if="selectedCoupon" class="row discount">
                                {{ selectedCoupon.discount.name }}
                                <span class="price f-bold m-l-4"> {{ selectedCoupon.discount.amount }}원 </span>
                            </span>
                        </div>
                    </div>
                </div>
                <div v-if="!$isTester()" class="select-coupon m-t-24" @click="selectCoupon">
                    <div v-if="!selectedCoupon" class="placeholder" v-html="$translate('SELECT_DISCOUNT_COUPON')" />

                    <div v-else class="coupon-name gray-dark">
                        <span class="gray-dark">{{ selectedCoupon.discount.name }}, </span>
                        <span class="c-black">{{ selectedCoupon.discount.amount }} 원</span>
                    </div>
                    <div class="flex-row items-center">
                        <i class="material-icons m-l-4">expand_more</i>
                    </div>
                </div>
            </div>
            <div class="hr" />
            <div class="title">결제 수단</div>
            <div class="payment-method">
                <div class="methods flex" v-if="isTicket">
                    <button
                        v-for="method in payMethods"
                        :key="method.key"
                        class="flex method"
                        :class="clickedPay === method.key ? `${method.key}-active` : method.key"
                        @click="selectPay(method.key)"
                    >
                        <div class="flex-row" style="align-items: center">
                            <img class="m-r-12" :src="method.icon" />
                            <span class="f-medium">{{ method.text }}</span>
                        </div>
                    </button>
                </div>
                <div class="methods flex" v-if="!isTicket">
                    <button
                        v-for="method in payMethods"
                        :key="method.key"
                        class="flex method"
                        :class="method.key"
                        @click="selectAndPay(method.key)"
                    >
                        <div class="flex-row center" style="align-items: center">
                            <img class="m-r-12" :src="method.icon" />
                            <span class="f-medium pay-text">{{ method.text }}</span>
                        </div>
                    </button>
                </div>
            </div>
        </div>
        <div class="payment-click" v-if="isTicket">
            <div class="container-policy flex-row items-center">
                <div class="text">
                    <span class="payment-check-url" @click="onClickPolicy">소개팅권 환급 정책</span>
                    <span class="payment-check-text">을 확인했습니다.</span>
                </div>
                <Checkbox class="flex-wrap" v-model="refundRuleChecked" />
            </div>
            <div class="container-btn items-center">
                <button @click="clickPay()" v-if="refundRuleChecked && this.clickedPay" class="checked-btn">
                    결제하기
                </button>
                <button @click="clickPay()" v-else class="buying-btn">결제하기</button>
            </div>
        </div>
        <div class="footer">
            <div class="m-b-24" v-if="isTicket">
                <div class="title">소개팅권 환급 안내</div>
                <div class="content">
                    <ul>
                        <li>연락처 교환 후 실제 만남까지 이어지지 않은 경우,</li>
                        <li>
                            사용한 소개팅권을 <span class="f-bold">100%</span> 환급해드립니다. (고객님 과실이 없을 경우)
                        </li>
                        <li>
                            자세한 내용은 <span class="payment-check-url" @click="onClickPolicy">환급 정책</span>을
                            확인해주세요
                        </li>
                    </ul>
                </div>
            </div>
            <div v-if="!isPremium">
                <div class="title">결제 오류 문의</div>
                <div class="content">
                    <ul>
                        <li>카카오톡 플러스친구 <span>바닐라브릿지</span>로 문의 부탁드립니다.</li>
                        <li>불편을 드려 죄송합니다.</li>
                    </ul>
                </div>
            </div>
            <div v-if="isPremium">
                <div class="title">서비스 이용 및 환불 안내 사항</div>
                <div class="content">
                    <ul>
                        <li class="flex-row">
                            <div class="dot" />
                            <span v-html="'매칭 성공이 보장되는 상품은 아닙니다.'" />
                        </li>
                        <li class="flex-row">
                            <div class="dot" />
                            <span
                                v-html="
                                    '상대 이성 혹은 주선자에게 진정성 없거나, 예의/매너 없는 언행을 하실 경우 매칭이 중단될 수있습니다.'
                                "
                            />
                        </li>
                        <li class="flex-row">
                            <div class="dot" />
                            <span v-html="'매칭 성사 시에 소개팅권은 별도 구매하셔야 합니다.'" />
                        </li>
                        <li class="flex-row">
                            <div class="dot" />
                            <span v-html="'프리미엄 서비스가 시작된 후, 주선자 코멘트가 작성되면 환불이 불가합니다.'" />
                        </li>
                        <li class="flex-row">
                            <div class="dot" />
                            <span
                                v-html="
                                    '가벼운 만남 의도가 있거나, 예의에 어긋나는 언행을 하시는 분은 취소될 수 있습니다.'
                                "
                            />
                        </li>
                        <li class="flex-row">
                            <div class="dot" />
                            <span
                                v-html="
                                    '어플 미접속, 계정 휴면 및 탈퇴, 이용 정지 등 이용자 귀책으로 인해 추가 주선이 불가할 경우 프리미엄 기간은 연장되지 않습니다.'
                                "
                            />
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import RefundInfoBanner from './components/RefundInfoBanner'
import productService from '@/services/product'
import orderService from '@/services/order'
// import commonService from '@/services/common'
import userService from '@/services/user'

export default {
    name: 'PgStorePage',
    components: {
        RefundInfoBanner,
    },
    props: {
        productType: {
            type: String,
            default: 'ticket',
        },
        agent: Object,
        beforeBackgroundColor: {
            type: String,
            default: '#0e0b54',
        },
    },
    data: () => ({
        showTitle: false,
        product: null,
        discountUsers: null,
        order: null,
        // chaiPromotions: [],
        selectedCoupon: null,
        refundRuleChecked: false,
        payload: {
            payment_type: null,
            discount_user_ids: null,
            extra: null,
        },
        clickedPay: null,
        history: false,
    }),
    computed: {
        payMethods() {
            return [
                // {
                //     key: 'chai',
                //     text: this.$translate('CHAI'),
                //     icon: require('@/assets/images/store/chai.svg'),
                // },
                {
                    key: 'toss',
                    text: this.$translate('TOSS'),
                    icon: require('@/assets/images/store/Toss_App_Icon.png'),
                },
                {
                    key: 'credit',
                    text: this.$translate('CREDIT_CARD'),
                    icon: require('@/assets/images/store/credit_card.svg'),
                },
                {
                    key: 'withdraw',
                    text: this.$translate('WITHDRAW'),
                    icon: require('@/assets/images/store/withdraw.svg'),
                },
            ]
        },
        isTicket() {
            return this.productType === 'ticket'
        },
        isPremium() {
            return this.productType === 'premium'
        },
        items() {
            return this.$store.getters.items || {}
        },
        price() {
            let price = this.product.price
            if (this.selectedCoupon) {
                price -= this.selectedCoupon.discount.amount
            }

            return price
        },
        nameOrNickname() {
            if (!this.agent) return

            const friends = this.$store.getters.friends
            return friends.find(friend => friend.agent_id === this.agent.id)
                ? this.agent.name
                : this.agent.profile.nickname
        },
        headerTitle() {
            if (this.isTicket) return this.$translate('BUYING_TICKET')

            if (this.isPremium) return this.$translate('BUYING_PREMIUM')

            return '결제 정보'
        },
    },
    created() {
        this.initProducts()
        // this.chaiPromotion()
    },
    mounted() {
        window.addEventListener('message', this.onMessageFromRN)
        document.addEventListener('message', this.onMessageFromRN)
        this.$nativeBridge.postMessage({
            action: 'setBackgroundColor',
            value: '#FFFFFF',
        })
    },
    beforeDestroy() {
        if (this.isPremium) {
            this.$nativeBridge.postMessage({
                action: 'setBackgroundColor',
                value: this.beforeBackgroundColor,
            })
        }
        window.removeEventListener('message', this.onMessageFromRN)
        document.removeEventListener('message', this.onMessageFromRN)
    },
    methods: {
        onScroll(event) {
            this.showTitle = event.target.scrollTop > 200
        },
        refundInfoEvent() {
            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: 'TicketOrderPage_Click_RefundIntro',
                },
            })
        },
        async initProducts() {
            try {
                const products = await productService.all()
                this.discountUsers = await productService.discountUsers(this.$case.toSnake(this.productType))

                this.product = products[this.$case.toSnake(this.productType)][0]
            } catch (e) {
                this.$toast.error(e.data)
            }
        },
        async selectAndPay(method) {
            const payload = {
                payment_type: method,
                discount_user_ids: this.selectedCoupon ? [this.selectedCoupon.id] : null,
                extra: null,
            }
            const onConfirm = async () => {
                try {
                    const { order, checkout_page, user_virtual_account } = await productService.createOrder(
                        this.product.id,
                        payload,
                    )
                    if (method === 'withdraw') {
                        // 가상계좌결제는 확인 페이지로 이동
                        this.$stackRouter.push({
                            name: 'VirtualAccountPage',
                            props: { userVirtualAccount: user_virtual_account },
                        })
                    } else {
                        this.order = order
                        this.$nativeBridge.postMessage({
                            action: 'pgPurchase',
                            value: checkout_page,
                        })
                    }

                    if (this.isPremium && !this.$isPremium()) {
                        // 프리미엄 결제를 하러 들어온 경우, product type === premium && 현재 premium 이 아님.
                        this.$nativeBridge.postMessage({
                            action: 'sendFirebaseEvent',
                            value: {
                                category: 'premiumEvent',
                                option: {
                                    funnel: 'click_select_pay_method',
                                    pay_method: method,
                                },
                            },
                        })
                    }
                } catch (e) {
                    this.$toast.error(e.data)
                }
                this.sendErrorSms(method)
            }

            onConfirm()
        },
        async selectPay(method) {
            this.clickedPay = method
            const payload = {
                payment_type: method,
                discount_user_ids: this.selectedCoupon ? [this.selectedCoupon.id] : null,
                extra: null,
            }

            if (this.productType === 'premium') payload.extra = { agent_id: (this.agent || {}).id }
            this.payload = payload
        },
        async clickPay() {
            if (!this.payload.payment_type) {
                this.$toast.error('결제 수단을 선택해주세요')
            } else if (!this.refundRuleChecked) {
                this.$toast.error('환급 정책 확인 완료 체크를 해주세요')
            } else {
                this.$nativeBridge.postMessage({
                    action: 'sendFirebaseEvent',
                    value: {
                        category: 'firstTicketDiscountEvent',
                        option: {
                            funnel: 'click_purchase_ticket',
                        },
                    },
                })

                try {
                    const { order, checkout_page, user_virtual_account } = await productService.createOrder(
                        this.product.id,
                        this.payload,
                    )
                    if (this.payload.payment_type === 'withdraw') {
                        // 가상계좌결제는 확인 페이지로 이동
                        this.$stackRouter.push({
                            name: 'VirtualAccountPage',
                            props: { userVirtualAccount: user_virtual_account },
                        })
                    } else {
                        this.order = order
                        this.$nativeBridge.postMessage({
                            action: 'pgPurchase',
                            value: checkout_page,
                        })
                    }
                } catch (e) {
                    this.$toast.error(e.data)
                }

                this.sendErrorSms(this.payload.payment_type)
            }
        },
        onMessageFromRN(event) {
            const data = this.$mustParse(event.data)
            if (!data || !data.action) return

            if (data.action === 'orderComplete') {
                this.$loading(true)
                setTimeout(() => {
                    this.checkOrder()
                }, 1000)
            }

            if (data.action === 'orderCancel') {
                // To-Do
            }
        },
        async checkOrder() {
            if (!this.order) return

            const ticketPurchasedEvent = () => {
                this.$nativeBridge.postMessage({
                    action: 'sendAirbridgeEvent',
                    value: {
                        category: 'user_buy_ticket',
                        option: { value: this.product.price },
                    },
                })

                this.$nativeBridge.postMessage({
                    action: 'sendFirebaseEvent',
                    value: {
                        category: 'user_buy_ticket',
                    },
                })

                this.$nativeBridge.postMessage({
                    action: 'sendFirebaseEvent',
                    value: {
                        category: 'TicketOrderPage_Click_Purchase',
                    },
                })
            }

            // 프리미엄 구매 에어브릿지 이벤트 추가?
            const premiumPurchasedEvent = () => {
                this.$nativeBridge.postMessage({
                    action: 'sendAirbridgeEvent',
                    value: {
                        category: 'premium_cnt_as_order_user',
                        option: { value: this.product.price },
                    },
                })

                this.$nativeBridge.postMessage({
                    action: 'sendFirebaseEvent',
                    value: {
                        category: 'premiumEvent',
                        option: {
                            funnel: 'complete_purchase_premium',
                        },
                    },
                })
            }

            try {
                const { msg } = await orderService.get(this.order.id)
                this.beforeBackgroundColor = '#fff'
                await this.$store.dispatch('loadItems')
                await this.$store.dispatch('loadMe')

                const idx = await this.$modal.basic({
                    title: 'MODAL_DEFAULT_TITLE',
                    body: msg,
                    buttons: [
                        {
                            label: 'CONFIRM',
                            class: 'btn-primary',
                        },
                    ],
                    preventCloseOnMousedownMask: true,
                })
                if (idx === 0) this.$stackRouter.clear()

                // 이벤트 정보 전송
                if (this.productType === 'ticket') ticketPurchasedEvent()
                if (this.productType === 'premium') premiumPurchasedEvent()
            } catch (e) {
                this.$toast.error(e.data.msg)
            } finally {
                this.$loading(false)
            }
        },
        // async chaiPromotion() {
        //     try {
        //         const res = await commonService.properties({ key: 'chai_promotions' })
        //         if ((res || []).length === 0) return
        //         this.chaiPromotions = JSON.parse(res[0].value)
        //     } catch (e) {}
        // },
        selectCoupon() {
            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: 'TicketOrderPage_Click_CouponBox',
                },
            })

            this.$modal
                .custom({
                    component: 'ModalSelectCoupon',
                    options: {
                        discountUsers: this.discountUsers,
                        selectedCouponId: this.selectedCoupon ? this.selectedCoupon.id : null,
                    },
                })
                .then(coupon => {
                    this.selectedCoupon = coupon.id ? coupon : null
                })
        },
        onClickPolicy() {
            this.refundInfoEvent()

            this.$stackRouter.push({ name: 'RefundPolicyPage' })
        },
        sendErrorSms(method) {
            if (
                this.$isAndroid() &&
                (method === 'toss' || method === 'credit') &&
                this.$store.getters.isAppFirstLoaded
            ) {
                userService.sendSms(this.$store.getters.me.id, { sms_type: 1 }).then(() => {
                    this.$store.commit('setIsAppFirstLoaded', false)
                })
            }
        },
    },
}
</script>
