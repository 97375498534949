<template>
    <div class="refund-3-dating center-refund">
        <div class="page-header">
            <div class="title">환급 신청할 소개팅권 선택</div>
        </div>
        <div class="desc" v-if="datings.length">
            <div class="row1"><span class="f-bold c-primary">만남이 성사되지 않은 소개팅권</span>을 선택해주세요.</div>
            <div class="row2">* 14일 이내 연락처를 교환한 소개팅권만 환급 신청 가능해요.</div>
        </div>
        <div class="tickets">
            <div
                :key="item.id"
                v-for="(item, i) in datings"
                class="ticket-wrapper flex-row items-center"
                :class="{ selected: (dating || {}).id === item.id }"
                @click="select(i)"
            >
                <i class="material-icons flex-wrap" v-if="(dating || {}).id === item.id">radio_button_checked</i>
                <i class="material-icons flex-wrap" v-else>radio_button_unchecked</i>
                <div
                    class="ticket"
                    :style="{
                        backgroundImage: `url(${(dating || {}).id === item.id ? tickets.active : tickets.inactive})`,
                    }"
                >
                    <div class="m-b-8 m-t-20 m-l-16">
                        <div class="user f-medium m-b-2">
                            {{ item.name }}<span class="f-medium"><span class="f-regular">ㅣ</span>소개상대</span>
                        </div>
                        <div class="agent f-13">주선자 {{ item.agent_name }}</div>
                    </div>
                    <div class="date m-b-20 m-l-16">
                        {{ datingDate(item.created_at) }}
                    </div>
                </div>
            </div>
            <div v-if="(datings || []).length === 0" class="ticket-wrapper">
                <div class="no-ticket">
                    환급 신청 가능한 소개팅권이 없어요 😢
                    <p>* 14일 이내 연락처를 교환한 소개팅권만 환급 신청 가능해요.</p>
                </div>
            </div>
        </div>
        <div class="refund-policy-check" @click="$stackRouter.push({ name: 'RefundPolicyPage' })">
            <span>소개팅권 환급 정책 확인하기</span>
        </div>
        <BottomButton @click="goToNextPage" :label="'NEXT'" :disabled="!(dating || {}).id" />
    </div>
</template>

<script>
import datingService from '@/services/dating'

export default {
    name: 'Refund3DatingPage',
    data: () => ({
        datings: [],
    }),
    computed: {
        tickets() {
            return {
                active: require('@/assets/images/refund/ticket_active.svg'),
                inactive: require('@/assets/images/refund/ticket_inactive_wide.svg'),
                inactiveWide: require('@/assets/images/refund/ticket_inactive_wide.svg'),
            }
        },
        dating() {
            return this.$store.getters.payloads.refund.dating
        },
    },
    mounted() {
        this.loadDatings()
    },
    methods: {
        async loadDatings() {
            try {
                this.$loading(true)
                this.datings = await datingService.datings()
            } catch (e) {
                this.$toast.error('소개팅 정보를 로드하는데 실패했어요. 오류 지속 시 플러스친구로 문의하세요.')
            }
            this.$loading(false)
        },
        async getAgents() {
            return Promise.all()
        },
        select(index) {
            this.$store.commit('setPayloads', {
                refund: { dating: this.datings[index] },
            })
        },
        goToNextPage() {
            if ((this.datings || []).length === 0) {
                this.$alert('환급 신청 가능한 소개팅이 없습니다.')
                return
            }

            if (!this.dating) {
                this.$alert('환급 신청할 소개팅을 선택해주세요.')
                return
            }

            this.$router.push({ name: 'Refund4ReasonPage' })
        },
        datingDate(date) {
            return this.$moment(date).format('YYYY.MM.DD')
        },
    },
}
</script>
