<template>
    <ProfileOptionSelector :model="'university'" :nextRoute="nextRoute" />
</template>

<script>
export default {
    name: 'University',
    mounted() {
        this.$nativeBridge.postMessage({
            action: 'sendFirebaseEvent',
            value: {
                category: 'signUpEvent',
                option: {
                    funnel: this.$route.name,
                    provider: this.$store.getters.payloads.signupData.provider || 'email',
                },
            },
        })
    },
    methods: {
        nextRoute() {
            const signupData = this.$store.getters.payloads.signupData
            // 재학 중이 아닌 사람은 직업 선택으로
            if (signupData.school.id !== 1 && signupData.school.id !== 3) {
                return 'JobCategoryPage'
            }

            // 대학/대학원 재학 중인 경우 job값을 자동으로 할당해주고 직업 입력은 skip
            let job = {}
            if (signupData.school.id === 1) job = { id: 1, name: '대학생' }
            if (signupData.school.id === 3) job = { id: 404, name: '대학원생' }
            signupData.job = job
            this.$store.commit('setPayloads', { signupData })

            return 'HeightPage'
        },
    },
}
</script>
