import ConsentPage from '@/routes/signup/ConsentPage'
import EmailPage from '@/routes/signup/basics/EmailPage'
import PasswordPage from '@/routes/signup/basics/PasswordPage'
import NicknamePage from '@/routes/signup/basics/NicknamePage'
import StatePage from '@/routes/signup/locations/StatePage'
import StationPage from '@/routes/signup/locations/StationPage'
import RegionPage from '@/routes/signup/locations/RegionPage'
import SchoolPage from '@/routes/signup/schools/SchoolPage'
import SchoolTypePage from '@/routes/signup/schools/SchoolTypePage'
import UniversityPage from '@/routes/signup/schools/UniversityPage'
import JobCategoryPage from '@/routes/signup/jobs/JobCategoryPage'
import CompanyPage from '@/routes/signup/jobs/CompanyPage'
import JobPage from '@/routes/signup/jobs/JobPage'
import BirthdayPage from '@/routes/signup/additional-profiles/BirthdayPage'
import GenderPage from '@/routes/signup/additional-profiles/GenderPage'
import HeightPage from '@/routes/signup/additional-profiles/HeightPage'
import KindsPage from '@/routes/signup/additional-profiles/KindsPage'
import ReligionPage from '@/routes/signup/additional-profiles/ReligionPage'
import ShapesPage from '@/routes/signup/additional-profiles/ShapesPage'
import SignupCheckPage from '@/routes/signup/additional-profiles/SignupCheckPage'
import SmokingPage from '@/routes/signup/additional-profiles/SmokingPage'
import UploadPhotoPage from '@/routes/signup/UploadPhotoPage'
import VerificationPage from '@/routes/signup/VerificationPage'
import OnboardingPage from '@/routes/signup/OnboardingPage'
import PreferencesIntroPage from '@/routes/signup/preferences/PreferencesIntroPage'
import PreferencesAgePage from '@/routes/signup/preferences/PreferencesAgePage'
import PreferencesHeightPage from '@/routes/signup/preferences/PreferencesHeightPage'
import PreferencesFriendTypePage from '@/routes/signup/preferences/PreferencesFriendTypePage'
import PreferencesSpecTypePage from '@/routes/signup/preferences/PreferencesSpecTypePage'
import PreferencesSmokingTypePage from '@/routes/signup/preferences/PreferencesSmokingTypePage'

export default [
    {
        path: '/signup/consent',
        name: 'ConsentPage',
        component: ConsentPage,
    },
    {
        path: '/signup/basics/email',
        name: 'EmailPage',
        component: EmailPage,
    },
    {
        path: '/signup/basics/password',
        name: 'PasswordPage',
        component: PasswordPage,
    },
    {
        path: '/signup/basics/nickname',
        name: 'NicknamePage',
        component: NicknamePage,
    },
    {
        path: '/signup/locations/state',
        name: 'StatePage',
        component: StatePage,
    },
    {
        path: '/signup/locations/station',
        name: 'StationPage',
        component: StationPage,
    },
    {
        path: '/signup/locations/region',
        name: 'RegionPage',
        component: RegionPage,
    },
    {
        path: '/signup/schools/school',
        name: 'SchoolPage',
        component: SchoolPage,
    },
    {
        path: '/signup/schools/school-type',
        name: 'SchoolTypePage',
        component: SchoolTypePage,
    },
    {
        path: '/signup/schools/university',
        name: 'UniversityPage',
        component: UniversityPage,
    },
    {
        path: '/signup/jobs/job-category',
        name: 'JobCategoryPage',
        component: JobCategoryPage,
    },
    {
        path: '/signup/jobs/company',
        name: 'CompanyPage',
        component: CompanyPage,
    },
    {
        path: '/signup/jobs/job',
        name: 'JobPage',
        component: JobPage,
    },
    {
        path: '/signup/additional-profiles/birthday',
        name: 'BirthdayPage',
        component: BirthdayPage,
    },
    {
        path: '/signup/additional-profiles/gender',
        name: 'GenderPage',
        component: GenderPage,
    },
    {
        path: '/signup/additional-profiles/height',
        name: 'HeightPage',
        component: HeightPage,
    },
    {
        path: '/signup/additional-profiles/kinds',
        name: 'KindsPage',
        component: KindsPage,
    },
    {
        path: '/signup/additional-profiles/religion',
        name: 'ReligionPage',
        component: ReligionPage,
    },
    {
        path: '/signup/additional-profiles/shapes',
        name: 'ShapesPage',
        component: ShapesPage,
    },
    {
        path: '/signup/additional-profiles/signup-check',
        name: 'SignupCheckPage',
        component: SignupCheckPage,
    },
    {
        path: '/signup/additional-profiles/smoking',
        name: 'SmokingPage',
        component: SmokingPage,
    },
    {
        path: '/signup/upload-photo',
        name: 'UploadPhotoPage',
        component: UploadPhotoPage,
    },
    {
        path: '/signup/verification',
        name: 'VerificationPage',
        component: VerificationPage,
    },
    {
        path: '/signup/onboarding',
        name: 'OnboardingPage',
        component: OnboardingPage,
    },
    {
        path: '/signup/preferences-intro',
        name: 'PreferencesIntroPage',
        component: PreferencesIntroPage,
    },
    {
        path: '/signup/preferences-age',
        name: 'PreferencesAgePage',
        component: PreferencesAgePage,
    },
    {
        path: '/signup/preferences-height',
        name: 'PreferencesHeightPage',
        component: PreferencesHeightPage,
    },
    {
        path: '/signup/preferences-friend-type',
        name: 'PreferencesFriendTypePage',
        component: PreferencesFriendTypePage,
    },
    {
        path: '/signup/preferences-spec-type',
        name: 'PreferencesSpecTypePage',
        component: PreferencesSpecTypePage,
    },
    {
        path: '/signup/preferences-smoking-type',
        name: 'PreferencesSmokingTypePage',
        component: PreferencesSmokingTypePage,
    },
]
