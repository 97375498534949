<template>
    <div class="new-terms">
        <div class="new-terms-wrapper">
            <div class="header">
                <span class="title">서비스 이용약관 및 <br />개인정보 관련 처리방침 변경 안내</span>
                <span class="content m-b-8"
                    >안녕하세요, 바닐라브릿지입니다.<br />서비스 신뢰도와 주선 서비스의 품질 개선을 위해 <br />'서비스
                    이용약관, 개인정보 처리방침, 개인정보 제3자 제공 및 활용'이 변경되었습니다.</span
                >
                <span class="content">변경된 내용의 효력은 2020년 9월 5일부터 발생합니다.</span>
            </div>
            <hr class="divider" />
            <div class="body">
                <span class="title m-b-16">변경 내용</span>
                <div class="m-b-36 flex">
                    <span class="desc m-b-8">1. 고의적인 프로필 허위 기재 시 회사의 조치 내용 추가</span>
                    <span class="desc">2. 서비스 품질 개선 및 범죄 예방을 위한 개인 정보 수집 항목 및 안내 변경</span>
                </div>
                <div class="urls">
                    <div class="wrapper">
                        <Checkbox class="m-r-15" v-model="consentServiceTerms" />
                        <span v-html="serviceTerms.title" @click="serviceTerms.handler" class="url m-b-12"> </span>
                    </div>
                    <div class="wrapper">
                        <Checkbox class="m-r-15" v-model="consentPrivacyTerms" />
                        <span v-html="privacyTerms.title" @click="privacyTerms.handler" class="url m-b-12"> </span>
                    </div>
                    <div class="wrapper">
                        <Checkbox class="m-r-15" v-model="consentPrivacyThirdTerms" />
                        <span v-html="privacyThirdTerms.title" @click="privacyThirdTerms.handler" class="url m-b-12">
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="new-terms-wrapper border-top">
            <button
                class="consent btn btn-primary"
                v-html="$translate('CONSENT')"
                :disabled="!consentAllTerms"
                @click="next"
            ></button>
        </div>
    </div>
</template>
<script>
// import axios from 'axios'
import userService from '@/services/user'

export default {
    name: 'NewTermsPage',
    data: () => ({
        serviceTerms: {},
        privacyTerms: {},
        privacyThirdTerms: {},
        consentServiceTerms: false,
        consentPrivacyTerms: false,
        consentPrivacyThirdTerms: false,
    }),
    computed: {
        consentAllTerms() {
            return this.consentServiceTerms && this.consentPrivacyTerms && this.consentPrivacyThirdTerms
        },
    },
    mounted() {
        this.initTerms()
    },
    methods: {
        async initTerms() {
            this.serviceTerms = {
                title: '<span class="text-underline">서비스 이용약관 보기</span>',
                key: 'service-terms',
                handler: () => this.openConsent(this.$route.params.serviceTermsId),
                required: true,
                // checked: true,
            }
            this.privacyTerms = {
                title: '<span class="text-underline">개인정보 처리방침 보기</span>',
                key: 'privacy-terms',
                handler: () => this.openConsent(this.$route.params.privacyTermsId),
                required: true,
                // checked: true,
            }
            this.privacyThirdTerms = {
                title: '<span class="text-underline">개인정보 제3자 제공  처리방침 보기</span>',
                key: 'privacy-third-terms',
                handler: () => this.openConsent(this.$route.params.privacyThirdTermsId),
                required: true,
                // checked: true,
            }
        },
        openConsent(id) {
            this.$nativeBridge.postMessage({
                action: 'openInAppBrowser',
                value: `https://api.vanillabridge.com/api/v2/terms/${id}`,
            })
        },
        async next() {
            await userService.updateTerms({
                service_terms_version: this.$route.params.updatedServiceTermsVersion,
                privacy_terms_version: this.$route.params.updatedPrivacyTermsVersion,
                privacy_third_terms_version: this.$route.params.updatedPrivacyThirdTermsVersion,
            })

            this.$router.push({ name: 'ChatsPage' })
        },
    },
}
</script>

<style lang="scss" scoped>
.new-terms {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-family: NotoSans-Regular;
    font-size: 15px;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -0.2px;
    color: $grey-09;

    &-wrapper {
        padding: 20px;
    }

    .border-top {
        border-top: 1px solid $grey-02;
    }

    .header {
        display: flex;
        flex-direction: column;

        .title {
            font-family: NotoSans-Medium;
            font-size: 24px;
            letter-spacing: -0.22px;
            color: black;
            margin-bottom: 38px;
        }

        .content {
            line-height: 1.6;
        }
    }

    .body {
        display: flex;
        flex-direction: column;

        .title {
            font-family: NotoSans-Bold;
            letter-spacing: -0.23px;
        }

        .desc {
            font-size: 14px;
        }

        .urls {
            display: flex;
            flex-direction: column;

            .url {
                font-family: NotoSans-Medium;
                text-decoration: underline;
            }
        }

        .wrapper {
            display: flex;
            flex-direction: row;
        }
    }

    button {
        width: 100%;
        height: 48px;
    }

    .divider {
        border: none;
        margin-top: 36px;
        margin-bottom: 12px;
        width: 40px;
        height: 4px;
        background-color: $grey-02;
    }
}
</style>
