<template>
    <div class="signup additional-profiles height">
        <div class="content">
            <div class="title">키</div>
            <div class="body">
                <div class="input-wrapper">
                    <input
                        ref="height"
                        type="number"
                        pattern="\d*"
                        class="flex-fill"
                        placeholder="키를 입력해주세요"
                        v-model="height"
                    />
                    <i v-if="height" class="material-icons flex-wrap" @click="height = null">cancel</i>
                </div>
                <div class="error" v-if="disabled && height">
                    <i class="material-icons">error_outline</i>
                    <p v-html="error"></p>
                </div>
            </div>
        </div>
        <div class="button-wrapper">
            <button
                class="btn btn-primary"
                :class="{ disabled: disabled }"
                :disabled="disabled"
                @click="next"
                v-html="$translate('NEXT')"
            ></button>
        </div>
    </div>
</template>

<script>
const MIN_HEIGHT = 140
const MAX_HEIGHT = 199

export default {
    name: 'HeightPage',
    data: () => ({
        height: null,
        error: `${MIN_HEIGHT}cm ~ ${MAX_HEIGHT}cm 사이의 키를 입력해주세요`,
    }),
    created() {
        this.$toast.success('거의 다 왔어요! 조금만 힘내세요 :)')
        this.height = this.$store.getters.payloads.signupData.height
    },
    mounted() {
        this.$refs.height.focus()

        const { provider, gender } = this.$store.getters.payloads.signupData

        this.$nativeBridge.postMessage({
            action: 'sendFirebaseEvent',
            value: {
                category: 'HeightPage_Enter_HeightPage',
                option: {
                    provider: provider || 'email',
                    gender: gender.name === 'MALE' ? 0 : 1,
                },
            },
        })
    },
    computed: {
        disabled() {
            return !this.height || this.height < MIN_HEIGHT || this.height > MAX_HEIGHT
        },
    },
    methods: {
        async next() {
            this.$store.commit('setPayloads', {
                signupData: {
                    height: this.height,
                },
            })
            this.$router.push({ name: 'ShapesPage' })
        },
    },
}
</script>
