<template>
    <div class="system-maintenance-notice">
        <h1 class="title" v-html="title" />
        <div class="contents">
            {{ contents }}<br /><br />
            <p class="detail" @click="onClick">업데이트 내용 자세히 보기</p>
        </div>
        <div class="maintenance-time">
            <span class="badge">작업 시간</span>
            <div class="time" v-html="time" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'SystemMaintenanceNoticePage',
    data: () => ({}),
    computed: {
        title() {
            return '대규모 업데이트 점검 안내'
        },
        contents() {
            return '더 좋은 서비스 제공을 위해, 세 시간 동안 서버 점검 및 업데이트가 진행됩니다.'
        },
        time() {
            return '11월 16일(월) 오후 1시 0분 ~<br/>11월 16일(월) 오후 4시 0분'
        },
    },
    methods: {
        onClick() {
            this.$nativeBridge.postMessage({
                action: 'openInAppBrowser',
                value: 'https://www.notion.so/vbproduct/11-16-318d0e9b7375497a84f50c7b5408c85c',
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.system-maintenance-notice {
    padding: 64px 20px;

    .title {
        margin: 0 0 24px;
        font-size: 24px;
        line-height: 34px;
        color: black;
        font-weight: normal;

        @include f-medium;
    }

    .contents {
        margin-bottom: 40px;
        font-size: 15px;
        line-height: 23px;
        color: $grey-09;

        span {
            color: $purple-primary;

            @include f-bold;
        }

        .detail {
            text-decoration: underline;
        }
    }

    .maintenance-time {
        display: flex;
        flex-direction: column;
        padding: 14px 16px;
        border-radius: 12px;
        background-color: $grey-02;

        .badge {
            width: 74px;
            margin-bottom: 10px;
            padding: 2px 10px;
            font-size: 14px;
            white-space: nowrap;

            @include f-bold;
        }

        .time {
            font-size: 15px;
            color: $grey-09;
            line-height: 24px;
        }
    }
}
</style>
