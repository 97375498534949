import Vue from 'vue'
import Router from 'vue-router'
import { store as $store } from '@/store'

import FrontPage from '@/routes/FrontPage'
import LoginPage from '@/routes/LoginPage'
import MyPage from '@/routes/my-page/MyPage'
import MyDatingPage from '@/routes/my-dating/MyDatingPage'
import NotificationsPage from '@/routes/notifications/NotificationsPage'
import ChatsPage from '@/routes/chat/chats/ChatsPage.vue'
import ChatroomPage from '@/routes/chat/chatroom/ChatroomPage'
import AgentDetailPage from '@/routes/agent-detail/AgentDetailPage'
import UserDetailPage from '@/routes/user-detail/UserDetailPage'
import AgentRatingsReviewsPage from '@/routes/agent-ratings-reviews/AgentRatingsReviewsPage'
import NotFoundPage from '@/routes/NotFoundPage'
import PgStorePage from '@/routes/store/PgStorePage'
import ChaiEventPage from '@/routes/store/ChaiEventPage'
import ProfileUpdateRequestPage from '@/routes/profile-update-request/ProfileUpdateRequestPage'
import ForceAppUpdatePage from '@/routes/force-app-update/ForceAppUpdatePage'
import FindEmailPage from '@/routes/FindEmailPage'
import FindPasswordPage from '@/routes/FindPasswordPage'
import ResetPasswordPage from '@/routes/ResetPasswordPage'
import AgentEvaluationIntroPage from '@/routes/agent-evaluation/AgentEvaluationIntroPage'
import AgentEvaluationOptionsPage from '@/routes/agent-evaluation/AgentEvaluationOptionsPage'
import SystemMaintenanceNoticePage from '@/routes/SystemMaintenanceNoticePage'
import NPSMainPage from '@/routes/nps/NPSMainPage'
import NPSFeedbackPage from '@/routes/nps/NPSFeedbackPage'
import NTSMainPage from '@/routes/nts/NTSMainPage'
import NTSFeedbackPage from '@/routes/nts/NTSFeedbackPage'
import HomePage from '@/routes/HomePage'
import GiftListPage from '@/routes/gifticon/GiftListPage'
import GiftPayPage from '@/routes/gifticon/GiftPayPage'
import SuggestedDatingPage from '@/routes/chat/suggested-dating/SuggestedDatingPage'
import DateCoachPage from '@/routes/chat/date-coach/DateCoachPage'
import NewTermsPage from '@/routes/terms/NewTermsPage'
import IntroductionPage from '@/routes/introduction/IntroductionPage'
import TodayMeetingPage from '@/routes/today-meeting/TodayMeetingPage'
import SocialMeetingPage from '@/routes/social-meeting/SocialMeetingPage'
import RealFriendMeetingPage from '@/routes/realfriend-meeting/RealFriendMeetingPage'
import ReuploadPhotoPage from '@/routes/reupload-photo/ReuploadPhotoPage'
import CustomerCenterPage from '@/routes/customer-center/CustomerCenterPage'
import DormantPage from '@/routes/setting/DormantPage'
import ReferrerFirstPage from '@/routes/ReferrerFirstPage'
import ReferrerSecondPage from '@/routes/ReferrerSecondPage'
import ReferrerThirdPage from '@/routes/ReferrerThirdPage'
import ManagerWaitlistPage from '@/routes/manager-waitlist/ManagerWaitlistPage'

import refundRoutes from './refund'
import signupRoutes from './signup'

Vue.use(Router)

const routes = [
    {
        path: '/',
        name: 'HomePage',
        component: HomePage,
    },
    {
        path: '/not-found',
        name: 'NotFoundPage',
        component: NotFoundPage,
    },
    {
        path: '/reupload-photo',
        name: 'ReuploadPhotoPage',
        component: ReuploadPhotoPage,
    },
    {
        path: '/front',
        name: 'FrontPage',
        component: FrontPage,
    },
    {
        path: '/login',
        name: 'LoginPage',
        component: LoginPage,
    },
    {
        path: '/my-dating',
        name: 'MyDatingPage',
        component: MyDatingPage,
    },
    {
        path: '/my-page',
        name: 'MyPage',
        component: MyPage,
    },
    {
        path: '/chats',
        name: 'ChatsPage',
        component: ChatsPage,
    },
    {
        path: '/chatroom',
        name: 'ChatroomPage',
        component: ChatroomPage,
    },
    {
        path: '/agent-detail',
        name: 'AgentDetailPage',
        component: AgentDetailPage,
    },
    {
        path: '/user-detail',
        name: 'UserDetailPage',
        component: UserDetailPage,
    },
    {
        path: '/agent-ratings-reviews',
        name: 'AgentRatingsReviewsPage',
        component: AgentRatingsReviewsPage,
    },
    {
        path: '/notifications',
        name: 'NotificationsPage',
        component: NotificationsPage,
    },
    {
        path: '/store/pg-store',
        name: 'PgStorePage',
        component: PgStorePage,
    },
    {
        path: '/store/chai_event',
        name: 'ChaiEventPage',
        component: ChaiEventPage,
    },
    {
        path: '/profile-update-request',
        name: 'ProfileUpdateRequestPage',
        component: ProfileUpdateRequestPage,
    },
    {
        path: '/force-app-update',
        name: 'ForceAppUpdatePage',
        component: ForceAppUpdatePage,
    },
    {
        path: '/find-email',
        name: 'FindEmailPage',
        component: FindEmailPage,
    },
    {
        path: '/find-password',
        name: 'FindPasswordPage',
        component: FindPasswordPage,
    },
    {
        path: '/reset-password',
        name: 'ResetPasswordPage',
        component: ResetPasswordPage,
    },
    {
        path: '/agent-evaluation-intro',
        name: 'AgentEvaluationIntroPage',
        component: AgentEvaluationIntroPage,
    },
    {
        path: '/agent-evaluation-options',
        name: 'AgentEvaluationOptionsPage',
        component: AgentEvaluationOptionsPage,
    },
    {
        path: '/system-maintenance-notice',
        name: 'SystemMaintenanceNoticePage',
        component: SystemMaintenanceNoticePage,
    },
    {
        path: '/nps-main',
        name: 'NPSMainPage',
        component: NPSMainPage,
    },
    {
        path: '/nps-feedback',
        name: 'NPSFeedbackPage',
        component: NPSFeedbackPage,
    },
    {
        path: '/nts-main',
        name: 'NTSMainPage',
        component: NTSMainPage,
    },
    {
        path: '/nts-feedback',
        name: 'NTSFeedbackPage',
        component: NTSFeedbackPage,
    },
    {
        path: '/gift-list',
        name: 'GiftListPage',
        component: GiftListPage,
    },
    {
        path: '/gift-pay',
        name: 'GiftPayPage',
        component: GiftPayPage,
    },
    {
        path: '/suggested-dating',
        name: 'SuggestedDatingPage',
        component: SuggestedDatingPage,
    },
    {
        path: '/date-coach',
        name: 'DateCoachPage',
        component: DateCoachPage,
    },
    {
        path: '/new-terms',
        name: 'NewTermsPage',
        component: NewTermsPage,
    },
    {
        path: '/introduction-page',
        name: 'IntroductionPage',
        component: IntroductionPage,
    },
    {
        path: '/party-meeting',
        name: 'TodayMeetingPage',
        component: TodayMeetingPage,
    },
    {
        path: '/social-meeting',
        name: 'SocialMeetingPage',
        component: SocialMeetingPage,
    },
    {
        path: '/realfriend-meeting',
        name: 'RealFriendMeetingPage',
        component: RealFriendMeetingPage,
    },
    {
        path: '/customer-center',
        name: 'CustomerCenterPage',
        component: CustomerCenterPage,
    },
    {
        path: '/dormant',
        name: 'DormantPage',
        component: DormantPage,
    },
    {
        path: '/referrer-first',
        name: 'ReferrerFirstPage',
        component: ReferrerFirstPage,
    },
    {
        path: '/referrer-second',
        name: 'ReferrerSecondPage',
        component: ReferrerSecondPage,
    },
    {
        path: '/referrer-third',
        name: 'ReferrerThirdPage',
        component: ReferrerThirdPage,
    },
    {
        path: '/manager-waitlist',
        name: 'ManagerWaitlistPage',
        component: ManagerWaitlistPage,
    },
]

const createRouter = () =>
    new Router({
        routes: [...routes, ...refundRoutes, ...signupRoutes],
    })
const router = createRouter()

router.beforeEach((to, from, next) => {
    const found = router.options.routes.some(r => r.name === to.name)
    if (!found) {
        return next('/not-found')
    }

    window.onpopstate = () => {
        // If the dialog is opened,
        // just close the dialog and don't move route
        if ($store.getters.modals.length > 0) {
            $store.commit('popModal')
            return next(false)
        }

        const currentCustomBackHandler = Vue.prototype.$backHandlers[to.name]
        if (currentCustomBackHandler) {
            const allowBack = currentCustomBackHandler()

            // 등록된 핸들러가 True여서 라우트 이동을 해야하는데, 스택라우트면 pop
            if (!!allowBack && $store.getters.pageStack.length > 0) {
                $store.dispatch('stackRouterPop')
                return next(false)
            }

            return next(!!allowBack)
        }

        // 스택 라우터에 등록되있으면
        if ($store.getters.pageStack.length > 0) {
            $store.dispatch('stackRouterPop')
            return next(false)
        }

        if (Vue.prototype.$isRootRoute()) return next(false)
    }

    next()
})

router.afterEach(() => {
    Vue.prototype.$nativeBridge.postMessage({
        action: 'setCanGoBack',
        value: !Vue.prototype.$isRootRoute(),
    })
})

export default router
