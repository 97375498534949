<template>
    <div class="stack-router-header-bar">
        <div @click="onClickLeftButton" class="left-btn">
            <i v-show="showLeftButton" class="material-icons">{{ leftButtonIcon }}</i>
        </div>
        <transition name="fade">
            <div class="title-wrapper" v-show="showTitle">
                <div class="title" v-html="$translate(title)" />
            </div>
        </transition>
        <div class="right-btn flex-wrap">
            <i v-show="showRightButton" class="material-icons">{{ rightButtonIcon }}</i>
        </div>
    </div>
</template>

<script>
export default {
    name: 'StackRouterHeaderBar',
    props: {
        leftButton: {
            type: String,
            default: 'back',
            validator: function (value) {
                return ['back', 'close', 'none'].indexOf(value) !== -1
            },
        },
        leftButtonHandler: {
            type: Function,
        },
        title: String,
        showTitle: {
            type: Boolean,
            default: false,
        },
        rightButton: {
            type: String,
            default: 'none',
            validator: function (value) {
                // 아직 미정
                return ['none'].indexOf(value) !== -1
            },
        },
        rightButtonHandler: {
            type: Function,
        },
    },
    computed: {
        showLeftButton() {
            return this.leftButton !== 'none'
        },
        showRightButton() {
            return this.rightButton !== 'none'
        },
        leftButtonIcon() {
            if (this.leftButton === 'back') return 'chevron_left'
            else if (this.leftButton === 'close') return 'close'

            return ''
        },
        rightButtonIcon() {
            return ''
        },
    },
    methods: {
        onClickLeftButton() {
            if (this.leftButtonHandler) {
                this.leftButtonHandler()
            } else {
                this.$stackRouter.pop()
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.stack-router-header-bar {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: $stack-router-header-height;
    background-color: white;
    padding: 0 16px;
    z-index: 2;

    & > * {
        display: flex;
        align-items: center;
        height: 100%;
    }

    .left-btn,
    .right-btn {
        z-index: 3;
        width: $stack-router-header-height;
        flex-shrink: 0;
    }

    .left-btn > .material-icons {
        color: $grey-08;
    }

    .title-wrapper {
        justify-content: center;
        max-width: 60%;
    }

    .title {
        color: black;
        font-size: 16px;
        font-weight: 500;
        margin-right: 16px;

        @include lines-1;
        @include f-medium;
    }
}
</style>
