<template>
    <div class="my-agent">
        <div class="top">
            <div class="title-wrapper">
                <div class="title">담당 주선자</div>
                <div v-if="$isPremium()" class="premium-badge">
                    <img :src="require('@/assets/images/premium.svg')" />
                    <span v-html="$translate('PREMIUM')" />
                </div>
            </div>
            <div class="agents-all">
                <div
                    class="agent"
                    v-for="agent in premiumFirstSorted"
                    :class="[$isPremium(agent.id) ? 'premium' : 'basic']"
                    :key="agent.id"
                    @click="goAgentPage(agent.id)"
                >
                    <div class="left">
                        <img :src="agent.photo_url" alt="" />
                        <div class="agent-name">
                            <span>{{ agent.name }}</span
                            >님
                        </div>
                        <div class="divider">|</div>
                        <div class="agent-rating">
                            <img
                                v-if="agent.ratings"
                                :src="require('@/assets/images/rating/icon_star_rate_active_noborder.svg')"
                            />
                            <img v-else :src="require('@/assets/images/rating/icon_star_inactive_silver.svg')" />
                            <span class="f-14" :class="{ estimating: !agent.ratings }">{{
                                agent.ratings || '측정 중'
                            }}</span>
                        </div>
                    </div>
                    <div class="right">
                        <div @click.stop="changeAgent" class="change-agent" :class="{ isPremium: $isPremium() }">
                            변경하기
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MyAgents',
    computed: {
        friends() {
            return this.$store.getters.friends || []
        },
        premiumFirstSorted() {
            const premium = []
            const basic = []
            this.friends.forEach(friend => {
                const { is_premium: isPremium } = friend.agent
                if (isPremium) premium.push(friend.agent)
                else basic.push(friend.agent)
            })
            return [...premium, ...basic]
        },
        newbieUser() {
            return this.$moment().diff(this.$store.getters.me.created_at, 'hours') < 72
        },
    },
    methods: {
        goAgentPage(id) {
            this.$stackRouter.push({
                name: 'AgentDetailPage',
                props: {
                    agentId: id,
                    from: 'MyAgents',
                },
            })
        },
        async initMe() {
            try {
                await this.$store.dispatch('loadMe')
                const me = this.$store.getters.me || {}
                this.me = me
            } catch (e) {
                return Promise.resolve()
            }
        },
        async changeAgent() {
            if (this.newbieUser) {
                const msg =
                    '가입 3일 이후 주선자 변경이 가능해요.<br> 주선자가 마음에 들지 않는다면, 바로 변경보다는 원하는 걸 이야기해보세요!<br><br>  (가입한지 얼마 지나지 않았거나, 새벽 시간대에 배정이 된 경우 답변이 조금 늦을 수 있으니 양해해주세요.)'
                // this.$alert(msg)
                this.$modal.basic({
                    body: msg,
                    buttons: [
                        {
                            label: 'CONFIRM',
                            class: 'btn-primary',
                        },
                    ],
                })
                return
            }
            if (this.$isPremium()) {
                this.$modal.basic({
                    body: '프리미엄 기간 중에는 주선자를 변경할 수 없습니다.',
                    buttons: [
                        {
                            label: 'CONFIRM',
                            class: 'btn-primary',
                        },
                    ],
                })
                return
            }
            const idx = await this.$modal.basic({
                title: '주선자 변경',
                body: `추천 주선자를 자동으로 배정해드립니다. 바닐라 300개가 차감됩니다. 담당 주선자를 변경하시겠어요?`,
                buttons: [
                    {
                        label: 'CLOSE',
                        class: 'btn-default',
                    },
                    {
                        label: 'CONFIRM',
                        class: 'btn-primary',
                    },
                ],
            })
            if (idx === 1) {
                const { vanilla: currentVanilla } = this.$store.getters.badges
                const {
                    vanilla: { change_agent: changeAgent },
                } = this.$store.getters.settings

                // 서버 작업 전 하드코딩, 남자 300, 여자 100개
                if (currentVanilla >= changeAgent) {
                    try {
                        this.$loading(true)
                        await this.$store.dispatch('loadNewFriend')
                        await this.initMe()
                        this.$store.dispatch('loadBadges')
                        this.$toast.success('새로운 주선자가 매칭됬어요')
                        if (this.me.is_waitlist === 1) {
                            this.$router.push({
                                name: 'ManagerWaitlistPage',
                                params: {
                                    user: this.me,
                                },
                            })

                            return
                        }
                    } catch (e) {
                        this.$toast.error(e.data.msg)
                    } finally {
                        this.$loading(false)
                    }
                } else {
                    const idx = await this.$modal.basic({
                        body: `바닐라 300개가 부족합니다.`,
                        buttons: [
                            {
                                label: 'CLOSE',
                                class: 'btn-default',
                            },
                            {
                                label: 'GO_TO_STORE',
                                class: 'btn-primary',
                            },
                        ],
                    })
                    if (idx === 1) this.$stackRouter.push({ name: 'InAppStorePage' })
                }
            }
        },
    },
}
</script>

<style scoped lang="scss">
.my-agent {
    padding: 0;

    .title-wrapper {
        display: flex;
        align-items: center;
        padding: 0 16px;

        .title {
            color: black;
            @include f-medium;
            font-size: 20px;
        }

        .premium-badge {
            margin-left: 8px;
            padding: 0 6px;
            font-size: 12px;
            color: $yellow-premium;
            border-radius: 6px;
            border: solid 1px $yellow-premium;
        }
    }

    .agents-all::-webkit-scrollbar {
        display: none;
    }
    .agents-all {
        overflow-x: auto;
        display: flex;
        margin-top: 20px;
        margin-bottom: 32px;

        &::before {
            content: '';
            min-width: 16px;
        }
        &::after {
            content: '';
            min-width: 16px;
        }
        .agent:nth-last-child(1) {
            margin-right: 0;
        }

        .agent {
            flex: none;
            width: calc(100vw - 32px);
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 12px;
            border-radius: 16px;
            background: $purple-review;
            margin-right: 16px;

            &.premium {
                background-image: linear-gradient(102deg, #ffefce 1%, #fff7e1 52%, #ffefce 100%);
            }

            .left {
                display: flex;
                flex-direction: row;
                align-items: center;

                img {
                    width: 64px;
                    height: 64px;
                    border-radius: 25.6px;
                    margin-right: 12px;
                }

                .agent-name {
                    color: black;
                    font-size: 16px;

                    span {
                        @include f-medium;
                    }
                }
                .divider {
                    margin: 0 4px;
                    color: $grey-04;
                    font-size: 14px;
                }
                .agent-rating {
                    img {
                        width: 11px;
                        height: 11px;
                        margin-right: 3px;
                    }
                    span {
                        font-size: 14px;
                        color: #fc0;
                        @include f-bold;

                        &.estimating {
                            color: $grey-05 !important;
                            font-size: 13px !important;
                            @include f-regular;
                        }
                    }
                }
            }
            .right {
                white-space: nowrap;

                .change-agent {
                    padding: 6px 16px;
                    margin-left: 20px;
                    font-size: 14px;
                    height: 34px;
                    border-radius: 8px;
                    color: $grey-09;
                    border: transparent;
                    background: white;
                    font-weight: unset !important;
                    @include f-regular;
                }
            }
        }
    }
}
</style>
