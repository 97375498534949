<template>
    <div class="banner-imgs">
        <img @click="banner.handler" :src="banner.src" alt="" />
        <div class="banner-counter">
            <span class="f-bold" v-html="`${idx + 1} / ${bannerLength}`" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'BannerImages',
    props: {
        banner: Object,
        bannerLength: Number,
        idx: Number,
    },
}
</script>

<style scoped lang="scss">
img {
    width: 100%;
    height: auto;
    border-radius: 12px;
}

.banner-counter {
    position: absolute;
    bottom: 10px;
    right: 10px;
    color: white;
    border-radius: 18px;
    background: rgba(black, 0.25);
    padding: 1px 8px;
    font-size: 11px;
}
</style>
