<template>
    <!-- <ProfileOptionSelector :model="'gender'" :nextRoute="() => 'StatePage'" /> -->
    <ProfileOptionSelector :model="'gender'" :nextRoute="() => nextRoute" />
</template>

<script>
export default {
    name: 'GenderPage',
    data: () => ({
        nextRoute: 'NicknamePage',
    }),
    mounted() {
        this.$nativeBridge.postMessage({
            action: 'sendFirebaseEvent',
            value: {
                category: 'GenderPage_Enter_GenderPage',
                option: {
                    provider: this.$store.getters.payloads.signupData.provider || 'email',
                },
            },
        })

        const thirdPartySignInData = this.$store.getters.thirdPartySignInData
        if (
            thirdPartySignInData &&
            (thirdPartySignInData.birthday === null || thirdPartySignInData.birthyear === null)
        ) {
            this.nextRoute = 'BirthdayPage'
        }
    },
}
</script>
