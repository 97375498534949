<template>
    <div class="post">
        <div class="gender-view m-b-4 flex-row flex-between">
            <div class="gender-state flex-wrap m-l-8" :class="{ male: post.gender === 0, female: post.gender === 1 }">
                <span :class="{ male: post.gender === 0, female: post.gender === 1 }">
                    {{ `${post.gender === 0 ? '남' : '여'}(${post.age + '세'})` }}
                    <span style="color: #c8c8de">ㅣ</span> {{ `${stateParsed}` }}
                </span>
            </div>
            <div class="view-count">
                <img class="eye" src="@/assets/images/eye-image.png" />
                <span class="count" v-html="post.view_count" />
            </div>
        </div>

        <div class="item-container">
            <div class="content-container">
                <div class="title-profile flex-center">
                    <div class="title lines-1" v-html="post.title" />
                    <div class="event-badge" v-if="isAdminPost" v-html="'이벤트'" />
                </div>
                <div class="content">
                    <p class="text" :class="{ 'no-photo': !isPhoto }" v-html="post.content" />
                </div>
            </div>
            <div v-if="isPhoto" class="img-photo">
                <img :src="post.photo_url" alt="" />
            </div>
        </div>

        <div class="interest-count" v-if="type === 'myPost' && post.requests.length">
            <span>{{ post.requests.length }} 명이 관심 있어요!</span>
            <img v-if="showNewBadge" class="new-badge" src="@/assets/images/today-meeting/new_badge.png" />
        </div>
        <div class="interest-count" v-else-if="type === 'interested'">
            <span v-html="interestStatusMsg" />
            <img v-if="showNewBadge" class="new-badge" src="@/assets/images/today-meeting/new_badge.png" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'RealFriendMeetingPost',
    props: {
        post: Object,
        type: {
            type: String,
            required: true,
        },
        states: Array && '',
    },
    computed: {
        stateParsed() {
            const state = this.states.find(state => Number(state.id) === Number(this.post.state_id))
            return state.name || ''
        },
        parsedContent() {
            if (this.post.content.length >= 50) {
                return this.post.content.substr(0, 50) + '...'
            } else {
                return this.post.content
            }
        },
        isPhoto() {
            return !!this.post.photo_url
        },
        showNewBadge() {
            if (this.type === 'myPost') {
                const { requests } = this.post
                const isActiveUser = request => request.enabled && !request.hidden && !request.is_dormant
                const activeReqs = requests.filter(req => isActiveUser(req))

                return activeReqs.some(p => p.accept_status === 'request')
            }

            if (this.type === 'interested') {
                const { host_checked: hostChecked, participant_checked: participantChecked } = this.post.my_request
                return hostChecked && !participantChecked
            }

            return false
        },
        interestStatusMsg() {
            const { accept_status: status } = this.post.my_request

            if (status === 'checked') return '주선자 확인 완료'
            else if (status === 'request') return '주선자 확인 전'
            else if (status === 'accepted') return '채팅방 열림'
            else if (status === 'contact') return '연락처 전달 완료'
            else if (status === 'success') return '연락처 교환 완료'
            else if (status === 'meeting') return '만남 컨펌 완료'
            else if (status === 'refund') return '환급 진행 중'
            else if (status === 'refund_accept') return '환급 완료'
            else if (status === 'refund_reject') return '환급 거절'
            else return ''
        },
        isAdminPost() {
            // 이벤트용 어드민 계정 예외처리 다시적용
            // if (this.post.user_id === 188399 || this.post.user_id === 188091) {
            //     return true
            // }
            if ([990, 1097, 1169, 1223, 1279, 1305, 1322].includes(this.post.id)) return true

            return false
        },
    },
}
</script>

<style scoped lang="scss">
.post {
    padding: 24px 16px;
    border-bottom: 1px solid $grey-02;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .gender-state {
        border-radius: 8px;
        margin-left: 0px !important;
        background-color: white;
        font-size: 13px;

        @include items-center;
        @include f-bold;

        &.male {
            color: #93b3ec;
        }

        &.female {
            color: #f2adac;
        }
    }

    .view-count {
        .eye {
            width: 15px;
            height: 10px;
            margin-right: 4px;
        }
        .count {
            font-size: 12px;
            color: $grey-05;
            @include f-regular;
        }
    }

    .item-container {
        display: flex;
        flex-direction: row;
        width: 100%;

        .content-container {
            max-width: 100%;
            margin-right: 16px;
            .text {
                height: 44px;
                line-height: 22px;
                font-size: 15px;

                @include lines-2;

                &.no-photo {
                    width: 100%;
                }
            }

            .title-profile {
                justify-content: inherit;
                margin-bottom: 12px;

                .title {
                    color: black;
                    font-size: 18px;

                    @include f-medium;
                }

                .event-badge {
                    min-width: 48px;
                    height: 20px;
                    margin-left: 8px;
                    text-align: center;
                    border-radius: 6px;
                    border-style: solid;
                    border-width: 1px;
                    font-size: 12px;
                    color: white;
                    @include f-medium;
                    background-image: linear-gradient(114deg, #ba90ff, $purple-primary);
                }
            }
        }

        .img-photo {
            align-self: flex-end;
            img {
                width: 64px;
                height: 64px;
                border-radius: 8px;
            }
        }
    }

    .interest-count {
        margin-top: 16px;
        font-size: 13px;
        color: $purple-primary;
        @include f-bold;
        letter-spacing: -0.2px;
        display: flex;
        align-items: center;

        .new-badge {
            height: 18px;
        }
    }
}
</style>
