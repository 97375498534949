import datingService from '@/services/dating'

const defaultState = () => ({
    datingSuccess: null,
})

const state = defaultState()

const getters = {
    datingSuccess: state => state.datingSuccess,
}

const actions = {
    async loadDatingSuccess({ state, commit }) {
        try {
            const success = await datingService.success()
            commit('setDatingSuccess', success)
            return success
        } catch (e) {
            return e
        }
    },
}

const mutations = {
    setDatingSuccess(state, success) {
        state.datingSuccess = success
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
    defaultState,
}
