<template>
    <div class="refund-7-photos center-refund">
        <div class="page-header">
            <div class="title">첨부자료 제출</div>
            <div class="info">
                만남이 이루어지지 않았다는 것을 보일 수 있는<br />
                <span class="f-bold c-primary">카톡, 메신저 스크린샷</span>을 첨부해주세요!
            </div>
        </div>
        <div class="detail">
            <div class="row">
                <span class="circle"></span>
                <div>마지막 대화 내역 <span class="f-bold c-primary">최소 1장</span> 첨부</div>
            </div>
            <div class="row">
                <span class="circle"></span>
                <div>
                    전체 화면 <span class="f-bold c-primary">풀 스크린샷</span>(반드시 스크린샷 상단에
                    <span class="f-bold">시간</span>이 나와야 하며, 사진을 자르거나 편집 시 환급 거절)
                </div>
            </div>
            <div class="row">
                <span class="circle"></span>
                <div>
                    스크린샷 상단의 시간과 환급 신청 시간이
                    <span class="f-bold c-primary">30분 이상 차이날 경우</span> 환급 거절
                </div>
            </div>
        </div>
        <div class="photos" :class="reason.id === 1 ? 'single-required' : 'double-required'">
            <div
                :key="i"
                v-for="(photo, i) in photos"
                class="img-cover"
                :class="{ required: photo.required }"
                @click="onClickPhoto(i)"
                :style="{
                    'background-image': photo.url ? 'url(' + photo.url + ')' : '',
                }"
            >
                <span v-if="photo.required && !photo.url" class="required-badge f-medium">필수</span>
                <i v-if="!photo.url" class="material-icons">add</i>
                <input
                    ref="imageUploader"
                    type="file"
                    class="image display-none"
                    @change="e => onChangeImage(e, i)"
                    accept="image/*"
                />
            </div>
        </div>

        <div class="bottom">
            <i class="material-icons">info</i>
            <div class="warning">
                제출한 자료는 운영팀과 주선자에게 전달되며, 환급 사유 확인 용도로 사용됩니다. 주선자, 소개 상대와의 확인
                절차 후 환급 처리됩니다. 자료가 불충분하거나 위 조건에 맞지 않는 경우, 환급 거절될 수 있습니다.
            </div>
        </div>
        <BottomButton @click="goToNextPage" :disabled="disabled" :label="'NEXT'" />
    </div>
</template>

<script>
export default {
    name: 'Refund7PhotosPage',
    data: () => ({
        photos: [],
    }),
    computed: {
        reason() {
            return this.refund.reason
        },
        refund() {
            return this.$store.getters.payloads.refund
        },
        disabled() {
            return this.photos.length === 0 || !this.photos.filter(p => p.required).every(p => p.url)
        },
    },
    mounted() {
        if (!this.reason) return this.$router.push({ name: 'HomePage' })

        this.initPhotos()
    },
    methods: {
        initPhotos() {
            if ((this.refund.photos || []).length > 0) {
                this.photos = this.refund.photos

                return
            }

            const newPhoto = idx => ({
                url: null,
                required: (this.reason.id === 1 && idx === 0) || (this.reason.id > 1 && idx < 2),
                blob: null,
                fileName: null,
            })

            this.$numArray(this.reason.id === 1 ? 3 : 6).forEach(idx => this.photos.push(newPhoto(idx)))
        },
        async onClickPhoto(i) {
            this.$refs.imageUploader[i].click()
        },
        onChangeImage(event, i) {
            const file = event.target.files[0]
            this.photos[i].fileName = file.name
            this.photos[i].blob = file

            const reader = new FileReader()
            reader.onload = () => {
                const img = new Image()
                img.src = reader.result
                img.onload = () => {
                    if (img.height > 16000) {
                        this.$toast.error('사진의 길이가 너무 길어요. 사진을 나눠서 올려주세요!')
                        this.$refs.imageUploader[i].value = ''
                        return
                    }
                    this.photos[i].url = reader.result
                }
            }
            reader.readAsDataURL(file)
        },
        goToNextPage() {
            if (this.disabled) {
                this.$alert('사진을 반드시 첨부해주세요.')
                return
            }

            this.$store.commit('setPayloads', { refund: { ...this.refund, photos: this.photos } })

            this.$router.push({ name: 'Refund8ConfirmPage' })
        },
    },
}
</script>
