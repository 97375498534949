<template>
    <div class="tabs">
        <div
            @click="onClickTab(tab, idx)"
            class="tab center"
            :class="{ selected: tab.selected }"
            :key="idx"
            v-for="(tab, idx) in tabs"
        >
            <div class="flex-row">
                <span class="flex-wrap" v-html="$translate(tab.title)" />
                <span
                    v-if="tab.count"
                    class="badge badge-count flex-wrap"
                    :class="{ 'badge-distorted': isMyDatingPage }"
                    v-html="tab.count"
                />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Tabs',
    props: ['tabs'],
    computed: {
        isMyDatingPage() {
            return this.$route.name === 'MyDatingPage'
        },
    },
    methods: {
        onClickTab(tab, selectedIdx) {
            this.tabs.forEach((tab, idx) => this.$set(tab, 'selected', idx === selectedIdx))
            this.$emit('update:tabs', this.tabs)
            this.$emit('onClickTab', { tab, index: selectedIdx })
        },
    },
}
</script>
