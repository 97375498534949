<template>
    <div class="reupload-photos">
        <div class="title f-24 f-medium" v-html="`불편을 드려 죄송해요`" />
        <div class="desc">
            <div>가입 중 오류로, 사진업로드에 실패했어요😭</div>
            <div>
                <span class="c-pink-deep f-medium">얼굴이 분명하게 나온 사진 2장</span>
                <span>을 다시 올려주세요!</span>
            </div>
        </div>
        <div class="photos">
            <div class="grid-col">
                <div
                    class="photo"
                    :class="{ required: photo.required, isPhoto: !!photo.url }"
                    v-for="(photo, i) of photos"
                    @click.stop="onClickPhoto(i)"
                    :key="i"
                >
                    <span v-if="photo.required && !photo.url" class="badges badge">필수</span>
                    <i v-if="!photo.url" class="material-icons" :class="{ required: photo.required }">add</i>
                    <img class="img-cover" v-if="photo.url" :src="photo.url" alt="" />
                    <i @click.stop="removePhoto(i)" v-if="photo.url" class="material-icons close-icon">close</i>
                </div>
                <input
                    ref="imageUploader"
                    type="file"
                    class="image-input display-none"
                    accept="image/*"
                    @change="onChangeImage"
                />
            </div>
        </div>
        <BottomButton :label="$translate('SUBMIT')" :disabled="buttonDisabled" @click="submit" />
    </div>
</template>

<script>
import userService from '@/services/user'

export default {
    name: 'ReuploadPhotoPage',
    components: {},
    data: () => ({
        photos: null,
        selectedIndex: null,
    }),
    computed: {
        me() {
            return this.$store.getters.me
        },
        buttonDisabled() {
            return !this.photos[0].url || !this.photos[1].url
        },
        token() {
            const header = JSON.parse(localStorage.getItem('header'))
            return header['X-User-Token']
        },
    },
    mounted() {
        if (this.me.photos.length) {
            this.$router.push({ name: 'ChatsPage' })
            return
        }

        this.$nativeBridge.postMessage({
            action: 'sendFirebaseEvent',
            value: {
                category: 'Enter_PhotoRequestPage',
            },
        })

        this.init()
    },
    methods: {
        init() {
            this.photos = Array(6)
                .fill({})
                .map((p, i) => {
                    p = { blob: null, name: null, url: null }
                    this.$set(p, 'required', i < 2)
                    return p
                })
        },
        onClickPhoto(i) {
            this.selectedIndex = i
            this.$refs.imageUploader.click()
        },
        onChangeImage(event) {
            const selectedPhoto = this.photos[this.selectedIndex]
            const imgFile = event.target.files[0]
            selectedPhoto.name = imgFile.name

            this.$refs.imageUploader.value = ''

            this.$modal
                .custom({
                    component: 'ModalCropper',
                    options: {
                        imgFile,
                    },
                })
                .then(croppedFile => {
                    if (!croppedFile) return
                    selectedPhoto.url = URL.createObjectURL(croppedFile)
                    selectedPhoto.blob = croppedFile
                    this.selectedIndex = null
                })
        },
        removePhoto(i) {
            this.photos[i].blob = null
            this.photos[i].url = null
            this.photos[i].name = null
        },
        async submit() {
            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: 'PhotoRequestPage_Click_SendCTA',
                },
            })

            this.$loading(true)

            // 3초 경과 전까지는 트로버 진행, 3초 이상 소요 시 1초에 1번 토스트메시지
            let seconds = 0
            const timer = setInterval(() => {
                if (seconds > 2) {
                    this.$toast.success('잠시만 기다려주세요. 사진이 입력되고 있어요😄', null, 1000)
                }
                seconds += 1
            }, 1000)
            await userService.uploadPhotos(this.me.id, this.photosPayload(this.photos, this.token))
            this.$loading(false)
            clearTimeout(timer)

            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: 'PhotoRequestPage_Complete_Upload',
                },
            })
            // 사진업로드 완료 후 홈페이지로 이동
            this.$router.push({ name: 'HomePage' })
        },
        photosPayload(photos = [], token) {
            if (photos.length === 0 || !token) return

            const formData = new FormData()

            photos.forEach((photo, i) => {
                if (photo.blob) formData.append(`photo${i + 1}`, photo.blob, photo.name)
            })

            formData.append('token', token)

            return formData
        },
    },
}
</script>

<style scoped lang="scss">
.reupload-photos {
    .title {
        color: black;
        margin-bottom: 18px;
    }
    .desc {
        font-size: 15px;
        margin-bottom: 40px;
        color: $grey-09;
    }
    $edit-profile-padding: 16px;

    margin-top: 32px;
    padding: 0 16px;

    .photos-desc {
        margin-top: 12px;
        font-size: 12px;
        color: $grey-06;
        text-align: center;

        @include f-medium;
    }

    $grid-no: 4;
    $grid-gap: 8px;

    .grid-col {
        $img-size-small: calc((100vw - #{$grid-gap} * (#{$grid-no} - 1) - #{$edit-profile-padding} * 2) / #{$grid-no});
        $img-size-large: calc((100vw - #{$grid-gap} - #{$edit-profile-padding} * 2) / 2);

        grid-template-columns: repeat($grid-no, 1fr);
        grid-template-rows: repeat(3, 1fr);
        grid-gap: $grid-gap;

        .photo {
            position: relative;
            border-radius: 8px;
            overflow: hidden;
            width: $img-size-small;
            height: $img-size-small;
            border: 1px solid $grey-02;
            background-color: $grey-01;
            margin-bottom: 6px;

            @include center;

            &.required {
                width: $img-size-large;
                height: $img-size-large;
                border: 1px solid $pink-deep;
                background-color: transparent;

                &:nth-child(1) {
                    grid-column: 1/3;
                    grid-row: 1/3;
                }
                &:nth-child(2) {
                    grid-column: 3 / 5;
                    grid-row: 1 / 3;
                }
            }

            &.isPhoto {
                border: $grey-02 !important;
            }

            &.border {
                border: 1px solid $grey-03;
            }

            .img-cover {
                width: 100%;
                height: 100%;
                border: 1px solid $grey-02;
            }

            .material-icons {
                color: $grey-05;
                font-size: 24px;

                &.required {
                    color: $pink-deep;
                }

                &.close-icon {
                    position: absolute;
                    top: 10px;
                    right: 10px;
                    color: white;

                    width: 25px;
                    height: 25px;
                    background: rgba(0, 0, 0, 0.5);
                    border-radius: 50%;
                    font-size: 20px;
                    @include center;
                }
            }
        }
    }

    .badges {
        position: absolute;
        left: 8px;
        top: 8px;
        display: flex;
        padding: 2px 8px;
        font-size: 12px;
        @include f-medium;
        background-color: $pink-deep;
    }
}
</style>
