<template>
    <div class="realfriend-meeting" @scroll="onScroll">
        <!-- <img
            v-if="gender == 0"
            ref="thumbNail"
            class="thumb-nail"
            @click="onClickThumbnail()"
            src="@/assets/images/real-friend/thumbnail_2.png"
        /> -->
        <nav ref="navBar" class="tabs" :class="{ absolute }">
            <div
                class="tab"
                :class="{ selected: item.selected }"
                v-for="item in tabItems"
                :key="item.label"
                @click="onClickTab(item)"
            >
                <div class="content">
                    <p class="label" v-html="$translate(item.label)" />
                    <div v-if="item.type !== 'all' && showNewBadge(item.type)" class="new-badge" />
                </div>
            </div>
        </nav>

        <main class="main" :class="{ absolute }">
            <div class="posts">
                <RealFriendMeetingPost
                    v-for="(post, idx) in posts"
                    :key="idx"
                    :post="post"
                    :states="states"
                    :type="selectedTab"
                    @click.native="onClickPost(post)"
                />
            </div>
        </main>

        <button v-show="selectedTab === 'all'" @click="onClickMakePost" class="btn">
            <i class="material-icons">edit</i>
        </button>
    </div>
</template>

<script>
import realfriendMeetingService from '@/services/realfriend-meeting'
import commonService from '@/services/common'
import RealFriendMeetingPost from '@/routes/realfriend-meeting/components/RealFriendMeetingPost'

export default {
    name: 'RealFriendMeetingPage',
    components: {
        RealFriendMeetingPost,
    },
    data: () => ({
        selectedTab: 'all',
        states: '',
        tabItems: [
            {
                type: 'all',
                label: 'ALL',
                selected: true,
            },
            {
                type: 'myPost',
                label: 'MY_POST',
                selected: false,
            },
            {
                type: 'interested',
                label: 'INTERESTED',
                selected: false,
            },
        ],
        loading: false,
        lastScrollTop: 0,
        absolute: false,
    }),
    mounted() {
        this.init()
    },
    computed: {
        realfriendMeeting() {
            return this.$store.getters.realfriendMeeting
        },
        allPosts() {
            return this.realfriendMeeting.allPosts || []
        },
        myPosts() {
            return this.realfriendMeeting.myPosts || []
        },
        interestedPosts() {
            return this.realfriendMeeting.interestedPosts || []
        },
        posts() {
            if (this.selectedTab === 'all') return this.allPosts
            if (this.selectedTab === 'myPost') return this.myPosts
            if (this.selectedTab === 'interested') return this.interestedPosts

            return []
        },
        hasUncheckedMyPost() {
            if (!this.myPosts.length) return false

            // 활성 유저인지 체크
            const isActiveUser = request => request.enabled && !request.hidden && !request.is_dormant

            return this.myPosts.some(post => {
                if ((post.requests || []).length === 0) return false

                const activeReqs = post.requests.filter(req => isActiveUser(req))
                return activeReqs.some(p => p.accept_status === 'request')
            })
        },
        hasUncheckedInterestedPost() {
            if (!this.interestedPosts.length) return false

            return this.interestedPosts.some(post => {
                const { host_checked: hostChecked, participant_checked: participantChecked } = post.my_request
                return hostChecked === true && participantChecked === false
            })
        },
        thumbnailHeight() {
            const thumbNail = document.querySelector('.thumb-nail')
            if (thumbNail) {
                return thumbNail.clientHeight
            }
            return 0
        },
        gender() {
            return (this.$store.getters.me || {}).gender
        },
    },
    methods: {
        async init() {
            const { states } = await commonService.constants()
            this.states = states
            try {
                this.$loading(true)
                await this.$store.dispatch('loadRealfriendMeetingPosts')
            } catch (e) {
                this.$toast.error(e.data)
            } finally {
                this.$loading(false)
            }
        },
        async onScroll({ target }) {
            if (this.allPosts.length < 15 || this.loading) return
            const { scrollHeight, clientHeight, scrollTop } = target

            this.absolute = scrollTop >= this.thumbnailHeight

            // 스크롤 방향이 upwards면 리턴
            if (scrollTop < this.lastScrollTop) {
                return
            }

            this.lastScrollTop = scrollTop <= 0 ? 0 : scrollTop

            if (scrollTop + clientHeight > scrollHeight - 100) {
                this.loading = true
                await this.loadMore()
                this.loading = false
            }
        },
        async loadMore() {
            try {
                this.$loading(true)
                switch (this.selectedTab) {
                    case 'all': {
                        const lastId = this.allPosts[this.allPosts.length - 1].id
                        const res = await realfriendMeetingService.all(30, lastId)
                        if ((res || []).length > 0) {
                            this.$store.commit('setRealfriendMeetingAllPosts', [...this.allPosts, ...res])
                        }
                        break
                    }
                    case 'myPost': {
                        const lastId = this.myPosts[this.myPosts.length - 1].id
                        const res = await realfriendMeetingService.mine(30, lastId)
                        if ((res || []).length > 0) {
                            this.$store.commit('setRealfriendMeetingMyPosts', [...this.myPosts, ...res])
                        }
                        break
                    }
                    case 'interested': {
                        // const lastId = this.interestedPosts[this.interestedPosts.length - 1].id
                        // const res = await realfriendMeetingService.interest(30, lastId)
                        // if ((res || []).length > 0) {
                        //     this.$store.commit('setRealfriendMeetingInterestedPosts', [...this.interestedPosts, ...res])
                        // }
                    }
                }
            } catch (e) {
                this.$toast.error(e.data)
            } finally {
                this.$loading(false)
            }
        },
        onClickTab(item) {
            item.selected = true

            this.tabItems.forEach(tab => {
                if (tab.type !== item.type) {
                    tab.selected = false
                }
            })

            this.selectedTab = item.type
        },
        onClickMakePost() {
            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: 'IntroduceMyFriend',
                    option: {
                        funnel: 'click_post_fab',
                    },
                },
            })

            this.$stackRouter.push({
                name: 'RealFriendMeetingPostMakePage',
                props: {
                    state: this.states,
                },
            })
            this.lastScrollTop = 0
        },
        onClickPost(post) {
            this.$stackRouter.push({
                name: 'RealFriendMeetingPostDetailPage',
                props: {
                    post,
                    from: this.selectedTab,
                },
            })
            this.lastScrollTop = 0

            realfriendMeetingService.updateViewCount(post.id)
        },
        showNewBadge(type) {
            if (type === 'myPost') {
                return this.hasUncheckedMyPost
            }
            if (type === 'interested') {
                return this.hasUncheckedInterestedPost
            }
        },
        onClickThumbnail() {
            this.$nativeBridge.postMessage({
                action: 'openInAppBrowser',
                value: 'https://bit.ly/3ggrRch',
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.realfriend-meeting {
    $tab-height: 48px;

    .thumb-nail {
        padding: 24px 16px 0 16px;
        width: 100vw;
    }
    .tabs {
        display: flex;
        width: 100%;
        background-color: white;

        &.absolute {
            position: absolute;
            top: 47px;
            left: 0;
        }

        & > .tab {
            height: $tab-height;
            flex: 1;

            @include center;

            &.selected {
                color: black;
                border-color: black;
            }

            .content {
                position: relative;
            }

            .new-badge {
                position: absolute;
                width: 5px;
                height: 5px;
                border-radius: 50%;
                background-color: $pink-deep;
                top: 0;
                right: -6px;
            }
        }
    }

    .main {
        &.absolute {
            margin-top: $tab-height;
            margin-bottom: $tab-height;
        }
    }

    .btn {
        position: absolute;
        bottom: calc(#{$header-height} + 16px);
        right: 16px;
        width: 64px;
        height: 64px;
        border-radius: 50%;
        box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.1), 2px 2px 8px 0 rgba(0, 0, 0, 0.15);
        background-image: linear-gradient(135deg, #ba90ff, $purple-primary);
        @include center;
        i {
            font-size: 28px;
        }
    }
}
</style>
