import { store as $store } from '@/store'
import './modal'
import blankProfile from '@/assets/images/blank_profile.svg'
import router from '@/router'
// import userService from '@/services/user'

export const Shortcuts = {
    install(Vue) {
        Vue.prototype.$translate = key => Vue.options.filters.translate(key)

        Vue.prototype.$blankProfile = blankProfile

        Vue.prototype.$backHandlers = {}

        // 현재 라우트의 백 버튼에 대한 동작을 오버라이드하는 콜백을 등록한다
        // 스택라우터의 경우 vue-router를 사용하지 않기 때문에 스택라우터에서 백핸들러를 사용하려면 parameter로 라우트 이름을 직접 전달한다
        Vue.prototype.$registerBackHandler = (callback, routeName) => {
            const name = routeName || router.currentRoute.name
            Vue.prototype.$backHandlers[name] = callback
        }

        Vue.prototype.$unregisterBackHandler = routeName => {
            const name = routeName || router.currentRoute.name
            delete Vue.prototype.$backHandlers[name]
        }

        Vue.prototype.$alert = arg => {
            Vue.prototype.$modal.custom({
                component: 'ModalAlert',
                options:
                    typeof arg === 'object'
                        ? {
                              title: arg.title,
                              body: Vue.options.filters.translate(arg.body),
                          }
                        : {
                              title: 'MODAL_DEFAULT_TITLE',
                              body: Vue.options.filters.translate(arg),
                          },
            })
        }

        Vue.prototype.$log = (...args) => {
            console.log(args)
            Vue.prototype.$nativeBridge.postMessage({
                action: 'propWebLogToRN',
                value: args,
            })
        }

        Vue.prototype.$actionSheet = ({ buttons, style }) => {
            return Vue.prototype.$modal.custom({
                component: 'ModalActionSheet',
                options: {
                    buttons,
                    style,
                },
            })
        }

        Vue.prototype.$isTester = () => {
            // ios 심사용 계정 체크
            if (!$store.getters.me) return

            return [4749, 200560].indexOf($store.getters.me.id) !== -1
        }

        Vue.prototype.$newUserTester = id => {
            if (!id) return false

            if (process.env.NODE_ENV === 'test' || process.env.NODE_ENV === 'development') {
                if (id <= 200000) return false
                return [7, 8, 9].includes(id % 10)
            } else {
                if (id <= 204020) return false
                return [7, 8, 9].includes(id % 10)
            }
        }

        Vue.prototype.$isRootRoute = () =>
            [
                'FrontPage',
                'ChatsPage',
                'MyDatingPage',
                'MyPage',
                'SystemMaintenanceNoticePage',
                'TodayMeetingPage',
                'SocialMeetingPage',
                'RealFriendMeetingPage',
                'ManagerWaitlistPage',
            ].indexOf(router.currentRoute.name) !== -1

        Vue.prototype.$isChatroom = () => router.currentRoute.name === 'ChatroomPage'

        Vue.prototype.$numArray = len => Array.apply(null, { length: len }).map(Number.call, Number)

        Vue.prototype.$loading = payload => {
            $store.commit('setLoading', payload)
        }

        Vue.prototype.$copy = obj => JSON.parse(JSON.stringify(obj))

        Vue.prototype.$mustParse = str => {
            let obj
            try {
                obj = JSON.parse(str)
            } catch (e) {}
            return obj
        }

        Vue.prototype.$vbConstants = {
            types: {
                friend: [
                    { title: '잘 안 봐요', value: 0 },
                    { title: '평균 정도면 괜찮아요', value: 1 },
                    { title: '눈이 높은 편이에요', value: 2 },
                ],
                spec: [
                    { title: '중요하지 않아요', value: 0 },
                    { title: '저와 비슷한 분이 좋아요', value: 1 },
                    { title: '저와 비슷하거나 더 좋은 분이 좋아요', value: 3 },
                    { title: '저보다 좋은 스펙은 부담스러워요', value: 2 },
                ],
                smoking: [
                    { title: '둘 다 상관 없어요', value: 0 },
                    { title: '흡연자는 만나고 싶지 않아요', value: 1 },
                    { title: '흡연자를 선호해요', value: 2 },
                ],
            },
        }

        Vue.prototype.$case = Vue.options.filters.$case

        Vue.prototype.$isAndroid = () => {
            return navigator.userAgent.match(/Android/i)
        }

        Vue.prototype.$isIOS = () => {
            return navigator.userAgent.match(/iPhone|iPad|iPod|AppleWebKit/i) && !navigator.userAgent.match(/Android/i)
        }

        Vue.prototype.$nameOrNick = otherUser => {
            const usernameVerified = !!$store.getters.me.name
            const hasOtherRealName = !!otherUser.name

            if (usernameVerified && hasOtherRealName) return otherUser.name

            if (otherUser.profile) {
                return otherUser.nickname || otherUser.profile.nickname || '---'
            } else {
                return otherUser.nickname || '---'
            }
        }

        Vue.prototype.$kakaoMode = () => {
            return $store.getters.kakaoMode
        }

        Vue.prototype.$isPremium = agentId => {
            if (agentId === 17020) return false

            const usePremium = (($store.getters.me || {}).products || []).some(p => p.ptype === 'premium')

            if (!agentId) {
                // chat이 없는 경우는 유저가 프리미엄 이용 중인지 여부 체크
                return usePremium
            }

            // 기존 프리미엄 주선자에게 프리미엄 받고 있는 케이스 예외처리
            const prevAgents = [17696, 17697, 17698, 17531, 17937, 17532]
            const allFriends = $store.getters.friends
            const flag = allFriends.some(friend => prevAgents.includes(friend.agent_id))
            if (flag) {
                return prevAgents.includes(agentId)
            }

            // chat이 있는 경우는 해당 chat이 담당 주선자와의 chat인지 체크
            const myDuties = $store.getters.friends.filter(friend => friend.duty && friend.status === 1)
            const chatDutyAgent = myDuties.some(my => my.agent_id === agentId)

            return usePremium && chatDutyAgent
        }

        Vue.prototype.$isPrivacy = () => {
            return (($store.getters.me || {}).products || []).find(p => p.ptype === 'privacy')
        }

        Vue.prototype.$isStudentByProfile = () => {
            const {
                profile: { is_student: isStudent },
            } = $store.getters.me
            return isStudent
        }

        Vue.prototype.$refundPolicySplit = () => {
            if (!$store.getters.me) return false

            const {
                profile: { is_student: isStudent },
                verification_badges,
            } = $store.getters.me
            const isStudentVerified = verification_badges.some(badge => badge.id === 15)

            return isStudent && isStudentVerified
        }

        Vue.prototype.$waitFor = timeout => new Promise(resolve => setTimeout(resolve, timeout)) // timeout 동안 기다리기

        Vue.prototype.$isInstantChat = chat => {
            if (!chat) return false

            if (chat.chat_type === 'real_friend' || chat.chat_type === 'social_group') {
                return false
            } else if (chat.chat_type === 'pro_agent') {
                return !!chat.expire_at
            }
        }

        Vue.prototype.$openGallery = () => {
            Vue.prototype.$nativeBridge.postMessage({ action: 'openGallery' })
        }
    },
}

export const Toast = {
    install(Vue) {
        Vue.prototype.$toast = {
            // 토스트메시지 위치 조정이 가능하도록 옵션 값 인자로 전달 가능
            success: (message, position, duration) =>
                $store.dispatch('setToast', {
                    message,
                    type: 'success',
                    options: {
                        position,
                        duration,
                    },
                }),
            error: (message, position, duration) =>
                $store.dispatch('setToast', {
                    message,
                    type: 'error',
                    options: {
                        position,
                        duration,
                    },
                }),
        }
    },
}

export const Distance = {
    install(Vue) {
        Vue.prototype.$distance = (loc1, loc2) => {
            if (!loc1 || !loc2) return

            const lat1 = parseFloat(loc1.lat)
            const lon1 = parseFloat(loc1.lng)
            const lat2 = parseFloat(loc2.lat)
            const lon2 = parseFloat(loc2.lng)

            const R = 6371
            const dLat = ((lat2 - lat1) * Math.PI) / 180
            const dLon = ((lon2 - lon1) * Math.PI) / 180
            const a =
                Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                Math.cos((lat1 * Math.PI) / 180) *
                    Math.cos((lat2 * Math.PI) / 180) *
                    Math.sin(dLon / 2) *
                    Math.sin(dLon / 2)
            const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
            const d = R * c
            return Math.floor(d)
        }
    },
}

export const Scroll = {
    install(Vue) {
        const duration = 50
        const increment = 5

        const easeInOutQuad = (t, b, c, d) => {
            t /= d / 2
            if (t < 1) return (c / 2) * t * t + b
            t--
            return (-c / 2) * (t * (t - 2) - 1) + b
        }

        const animateScroll = (el, currentTime, start, change, limit, direction) => {
            currentTime += increment
            if (direction === 'up' || direction === 'down') {
                el.scrollTop = easeInOutQuad(currentTime, start, change, duration)
            }

            if (direction === 'left' || direction === 'right') {
                el.scrollLeft = easeInOutQuad(currentTime, start, change, duration)
            }

            if (currentTime < duration) {
                if (!limit) {
                    setTimeout(() => animateScroll(el, currentTime, start, change, limit, direction), increment)
                } else {
                    if (direction === 'up' && start - el.scrollTop < limit) {
                        setTimeout(() => animateScroll(el, currentTime, start, change, limit, direction), increment)
                    }
                    if (direction === 'down' && el.scrollTop - start < limit) {
                        setTimeout(() => animateScroll(el, currentTime, start, change, limit, direction), increment)
                    }
                    if (direction === 'left' && start - el.scrollLeft < limit) {
                        setTimeout(() => animateScroll(el, currentTime, start, change, limit, direction), increment)
                    }
                    if (direction === 'right' && el.scrollLeft - start < limit) {
                        setTimeout(() => animateScroll(el, currentTime, start, change, limit, direction), increment)
                    }
                }
            }
        }

        Vue.prototype.$scroll = {
            up: (el, animate, limit) => {
                if (!el) return

                const currentTime = 0
                const start = el.scrollTop
                const change = 0 - start

                if (animate) {
                    animateScroll(el, currentTime, start, change, limit, 'up')
                } else {
                    el.scrollTop = el.scrollTop - limit || 0
                }
            },
            down: (el, animate, limit) => {
                if (!el) return

                const currentTime = 0
                const start = el.scrollTop
                const change = el.scrollHeight - start

                if (animate) {
                    animateScroll(el, currentTime, start, change, limit, 'down')
                } else {
                    el.scrollTop = limit || el.scrollHeight
                }
            },
            left: (el, animate, limit) => {
                if (!el) return

                const currentTime = 0
                const start = el.scrollLeft
                const change = 0 - start

                if (animate) {
                    animateScroll(el, currentTime, start, change, limit, 'left')
                } else {
                    el.scrollLeft = el.scrollLeft - limit || 0
                }
            },
            right: (el, animate, limit) => {
                if (!el) return

                const currentTime = 0
                const start = el.scrollLeft
                const change = el.scrollWidth - start

                if (animate) {
                    animateScroll(el, currentTime, start, change, limit, 'right')
                } else {
                    el.scrollLeft = limit || el.scrollWidth
                }
            },
        }
    },
}

export const Hooks = {
    install(Vue) {
        Vue.prototype.$hooks = {
            handlePossibleVanillaShortageError: data => {
                if (data.key === 'not_enough_vanilla') {
                    Vue.prototype.$modal
                        .basic({
                            title: 'LACK_OF_VANILLA',
                            body: data.msg,
                            buttons: [
                                {
                                    label: 'GO_TO_STORE',
                                    class: 'btn-primary',
                                },
                            ],
                        })
                        .then(idx => {
                            if (idx === 0) {
                                Vue.prototype.$stackRouter.push({ name: 'InAppStorePage' })
                            }
                        })
                } else {
                    Vue.prototype.$toast.error(data.msg)
                }
            },
        }
    },
}

export const NativeBridge = {
    install(Vue) {
        Vue.prototype.$nativeBridge = {
            postMessage: obj => {
                if (!window.ReactNativeWebView) return

                // 스테이징, 개발환경에서 이벤트 안쏘게
                if (process.env.NODE_ENV === 'test' || process.env.NODE_ENV === 'development') {
                    if (['sendAirbridgeEvent', 'sendFirebaseEvent'].includes(obj.action)) return
                }

                // firebase 옵션에 유저 id 넣어서 전송
                if (obj.action === 'sendFirebaseEvent') {
                    if ($store.getters.me) {
                        obj.value.option = { ...obj.value.option, user_id: $store.getters.me.id }
                    }
                }

                window.ReactNativeWebView.postMessage(JSON.stringify(obj))
            },
        }

        Vue.prototype.$updateAppIconBadgeCount = () => {
            const totalUnreads = ($store.getters.chats || []).reduce((acc, chat) => acc + (chat.unread || 0), 0)

            window.ReactNativeWebView.postMessage(
                JSON.stringify({
                    action: 'setIconBadgeCount',
                    value: totalUnreads,
                }),
            )
        }
    },
}

export const StackRouter = {
    install(Vue) {
        Vue.prototype.$stackRouter = {
            push: pageObj => {
                $store.dispatch('stackRouterPush', pageObj)
            },
            pop: () => {
                $store.dispatch('stackRouterPop')
            },
            replace: pageObj => {
                $store.dispatch('stackRouterReplace', pageObj)
            },
            popTo: length => {
                $store.dispatch('stackRouterPopTo', length)
            },
            clear: () => {
                $store.dispatch('stackRouterClear')
            },
        }
    },
}
