<template>
    <div class="refund-9-refunded center-refund">
        <div class="page-header m-b-40">
            <div class="title">환급 신청 완료</div>
        </div>
        <div class="refund-process">
            <div class="step" v-for="idx in 3" :key="idx">
                <div class="title">{{ `${idx}. ` + $translate(`REFUND_COMPLETE_TITLE_${idx}`) }}</div>
                <div class="content" v-html="$translate(`REFUND_COMPLETE_CONTENT_${idx}`)" />
            </div>
        </div>
        <BottomButton @click="onClickConfirm" :label="'CONFIRM'" />
    </div>
</template>

<script>
export default {
    name: 'Refund9RefundedPage',
    data: () => ({
        dating: null,
        dating_feedback: null,
    }),
    methods: {
        async onClickConfirm() {
            this.$router.push({ name: 'HomePage' })

            if (this.dating_feedback) return

            try {
                await this.$store.dispatch('loadFeedbackOptions')
                this.$store.commit('setFeedbackPayload', { code: 'reject', message_id: null, from: 'after_refund' })

                const dating = this.dating
                dating.paid = true
                this.$store.commit('setFeedbackDating', dating)
                this.$stackRouter.push({ name: 'CommunicationFeedbackPage' })
            } catch (e) {
                console.error(e)
            }
        },
    },
    mounted() {
        this.dating = this.$route.params.dating
        this.dating_feedback = this.$route.params.dating_feedback
    },
    beforeDestroy() {
        // 환급 상태 재업로드 => 채팅방 페이지에서 환급 상대 업로드 가능
        this.$store.dispatch('loadRefunds', { force: true })
    },
}
</script>
