<template>
    <div class="today-meeting">
        <nav class="tabs m-t-12">
            <div
                class="tab"
                :class="{ selected: item.selected }"
                v-for="item in tabItems"
                :key="item.label"
                @click="onClickTab(item)"
            >
                <div class="content">
                    <p class="label" v-html="$translate(item.label)" />
                    <div v-if="item.type !== 'all' && showNewBadge(item.type)" class="new-badge" />
                </div>
            </div>
        </nav>

        <main class="main" @scroll="onScroll">
            <SocialMeetingFilter v-if="selectedTab === 'all'" @selectFilter="selectFilter" />
            <div class="posts">
                <SocialMeetingPost
                    v-for="post in posts"
                    :key="post.id"
                    :post="post"
                    :type="selectedTab"
                    @click.native="onClickPost(post)"
                />
            </div>
            <div v-if="posts.length === 0" class="empty-posts">아직 등록된 게시물이 없습니다</div>
        </main>

        <button v-show="selectedTab === 'all'" class="btn" @click="onClickMakePost">
            <i class="material-icons">edit</i>
        </button>
    </div>
</template>

<script>
import eventService from '@/services/event'

export default {
    name: 'SocialMeetingPage',
    components: {
        SocialMeetingPost: () => import('./components/SocialMeetingPost'),
        SocialMeetingFilter: () => import('./components/SocialMeetingFilter'),
    },
    data: () => ({
        selectedTab: 'all',
        tabItems: [
            {
                type: 'all',
                label: 'ALL',
                selected: true,
            },
            {
                type: 'myPost',
                label: 'MY_POST',
                selected: false,
            },
            {
                type: 'interested',
                label: 'PARTICIPANTED',
                selected: false,
            },
        ],
        loading: false,
        lastScrollTop: 0,
        filterState: [],
        filterType: ['group_meeting', 'friend_meeting'],
    }),
    computed: {
        socialMeeting() {
            return this.$store.getters.socialMeeting
        },
        allPosts() {
            return this.socialMeeting.allPosts || []
        },
        myPosts() {
            return this.socialMeeting.myPosts || []
        },
        interestedPosts() {
            return this.socialMeeting.interestedPosts || []
        },
        posts() {
            if (this.selectedTab === 'all') {
                return this.allPosts.filter(
                    e => this.filterType.includes(e.event_type) && this.filterState.includes(e.state),
                )
            }
            if (this.selectedTab === 'myPost') return this.myPosts
            if (this.selectedTab === 'interested') return this.interestedPosts

            return []
        },
        hasUncheckedMyPostParticipants() {
            if (this.myPosts.length === 0) return false

            return this.myPosts.some(post => {
                if ((post.participants || []).length === 0) return false

                return post.participants.some(p => !p.host_checked)
            })
        },
        hasUncheckedInterestedPost() {
            if (this.interestedPosts.length === 0) return false

            return this.interestedPosts.some(({ participant }) => {
                if (!participant) return false

                return !participant.participant_checked
            })
        },
    },
    methods: {
        async init() {
            try {
                this.$loading(true)
                await this.$store.dispatch('loadSocialMeetingPosts')
            } catch (e) {
                this.$toast.error(e.data)
            } finally {
                this.$loading(false)
            }
            this.filterState = this.$store.getters.states.map(s => s.name)
        },
        selectFilter(item) {
            if (item.type === 'state') {
                if (item.key === '') this.filterState = this.$store.getters.states.map(s => s.name)
                else this.filterState = [item.key]
            } else {
                if (item.key === '') this.filterType = ['group_meeting', 'friend_meeting']
                else this.filterType = [item.key]
            }
        },
        onClickTab(item) {
            item.selected = true

            this.tabItems.forEach(tab => {
                if (tab.type !== item.type) {
                    tab.selected = false
                }
            })

            this.selectedTab = item.type
        },
        onClickMakePost() {
            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: 'SocialFeed_Click_CreatePostFab',
                },
            })

            this.lastScrollTop = 0
            this.$stackRouter.push({
                name: 'SocialMeetingPostMakePage',
            })
        },
        onClickPost(post) {
            if (this.selectedTab === 'myPost') {
                eventService.hostChecked(post.id)
                this.$store.commit('updateSocialHostCheck', post.id)
            }

            if (this.selectedTab === 'interested') {
                eventService.participantChecked(post.id, post.participant.id)
                this.$store.commit('updateSocialParticipantCheck', post.id)
            }

            eventService.updateViewCount(post.id)

            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: 'SocialFeed_Click_Post',
                },
            })

            this.$stackRouter.push({
                name: 'SocialMeetingPostDetailPage',
                props: {
                    post,
                },
            })
        },
        async onScroll({ target }) {
            if (this.allPosts.length < 30 || this.loading) return
            const { scrollHeight, clientHeight, scrollTop } = target

            // 스크롤 방향이 upwards면 리턴
            if (scrollTop < this.lastScrollTop) return

            this.lastScrollTop = scrollTop <= 0 ? 0 : scrollTop

            if (scrollTop + clientHeight > scrollHeight - 100) {
                this.loading = true
                await this.loadMore()
                this.loading = false
            }
        },
        async loadMore() {
            try {
                this.$loading(true)
                switch (this.selectedTab) {
                    case 'all': {
                        const lastId = this.allPosts[this.allPosts.length - 1].id
                        const res = await eventService.all('today_meeting', lastId)

                        if ((res.all || []).length > 0) {
                            this.$store.commit('setTodayMeetingAllPosts', this.allPosts.concat(res.all))
                        }

                        break
                    }
                    case 'myPost': {
                        const lastId = this.myPosts[this.myPosts.length - 1].id
                        const res = await eventService.mine('today_meeting', lastId)

                        if ((res || []).length > 0) {
                            this.$store.commit('setTodayMeetingMyPosts', this.myPosts.concat(res))
                        }

                        break
                    }
                    case 'interested': {
                        const lastId = this.interestedPosts[this.interestedPosts.length - 1].id
                        const res = await eventService.interest('today_meeting', lastId)

                        if ((res || []).length > 0) {
                            this.$store.commit('setTodayMeetingInterestedPosts', this.interestedPosts.concat(res))
                        }

                        break
                    }
                }
            } catch (e) {
                this.$toast.error(e.data)
            } finally {
                this.$loading(false)
            }
        },
        showNewBadge(type) {
            if (type === 'myPost') {
                return this.hasUncheckedMyPostParticipants
            } else if (type === 'interested') {
                return this.hasUncheckedInterestedPost
            }
        },
    },
    mounted() {
        this.init()
    },
}
</script>

<style lang="scss" scoped>
.today-meeting {
    $tab-height: 48px;
    overflow: hidden;

    .tabs {
        display: flex;
        width: 100%;

        & > .tab {
            height: $tab-height;
            flex: 1;

            @include center;

            &.selected {
                color: black;
                border-color: black;
            }

            .content {
                position: relative;
            }

            .new-badge {
                position: absolute;
                width: 5px;
                height: 5px;
                border-radius: 50%;
                background-color: $pink-deep;
                top: 0;
                right: -6px;
            }
        }
    }

    .main {
        height: calc(100vh - (#{$tab-height} + #{$root-header-height} + #{$control-panel-height} + 12px));
        overflow-y: auto;
        padding-bottom: 32px;

        .posts {
            padding: 0 16px 16px 16px;
        }

        .empty-posts {
            color: $grey-07;
            font-size: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            margin-top: -40px;
        }
    }

    .btn {
        position: absolute;
        bottom: calc(#{$header-height} + 16px);
        right: 16px;
        width: 64px;
        height: 64px;
        border-radius: 50%;
        box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.1), 2px 2px 8px 0 rgba(0, 0, 0, 0.15);
        background-image: linear-gradient(316deg, #1877f2, #36b3ff);

        .material-icons {
            font-size: 28px;
        }
        @include center;
    }
}
</style>
