<template>
    <ProfileOptionSelector :model="'schoolType'" :nextRoute="nextRoute" />
</template>

<script>
export default {
    name: 'SchoolType',
    mounted() {
        this.$nativeBridge.postMessage({
            action: 'sendFirebaseEvent',
            value: {
                category: 'signUpEvent',
                option: {
                    funnel: this.$route.name,
                    provider: this.$store.getters.payloads.signupData.provider || 'email',
                },
            },
        })
    },
    methods: {
        nextRoute(item) {
            // 인증이 필요한 school_type의 경우 바로 대학교 입력으로
            if (item.verification_required) return 'UniversityPage'

            const signupData = this.$store.getters.payloads.signupData
            // 인증이 필요하지 않고 school이 대학생/대학원생 재학 중이 아니라면 직업 선택으로
            if (!signupData.school.is_student) return 'JobCategoryPage'

            this.$store.commit('setPayloads', { signupData })

            return 'HeightPage'
        },
    },
}
</script>
