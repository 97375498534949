<template>
    <div class="suggested">
        <SuggestedWaiting ref="suggestedWaiting" />
        <SuggestedSuccess />
    </div>
</template>

<script>
import SuggestedWaiting from './SuggestedWaiting'
import SuggestedSuccess from './SuggestedSuccess'

export default {
    name: 'Suggested',
    components: {
        SuggestedSuccess,
        SuggestedWaiting,
    },
    methods: {
        loadSuggestedWaiting() {
            this.$refs.suggestedWaiting.loadWaiting()
        },
    },
}
</script>

<style lang="scss" scoped>
.suggested {
    hr {
        border-bottom: none;
        width: 100%;
        height: 1px;
        background-color: $grey-02;
    }

    ::v-deep .snackbar-info {
        margin-left: 4px;
        font-size: 18px;
        color: $grey-07;
    }

    ::v-deep &-snackbar {
        position: absolute;
        z-index: 1;
        background: $grey-09;
        border-radius: 8px;
        padding-top: 14.5px;

        font-size: 14px !important;
        color: white;
    }

    ::v-deep .waiting,
    .success {
        padding: 20px 16px;
        margin-bottom: 8px;
    }

    ::v-deep .btn-requested {
        width: 100%;
        height: 48px;
        border-radius: 8px;
        border: solid 1px $grey-03;
        font-family: NotoSans-Medium;
        font-size: 15px;
        color: $grey-08;
    }
}
</style>
