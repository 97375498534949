import * as $http from 'axios'

export default {
    all: params => $http.get('v2/agents', { params }),
    detail: agentId => $http.get(`v2/agents/${agentId}`),
    rankings: type =>
        $http.get('v2/agents/ranking', {
            params: { type },
        }),
    friends: agentId => $http.get(`v2/agents/${agentId}/friends`),
    agentRatings: (agentId, payload) => $http.post(`v2/agents/${agentId}/agent_ratings`, payload),
    loadReportTargets: () => $http.get('v2/agents/load_report_targets'),
    ratingEnabled: agentId => $http.get(`v2/agents/${agentId}/rating_enabled`),
    reportAgent: {
        create: (agentId, payload) => $http.post(`v2/agents/${agentId}/report_agents`, payload),
        update: (agentId, reportAgentId, payload) =>
            $http.put(`v2/agents/${agentId}/report_agents/${reportAgentId}`, payload),
    },
}
