<template>
    <div class="gift-pay">
        <StackRouterHeaderBar />
        <div class="page-head flex">
            <span class="pay-statement">결제하기</span>
            <div class="order">
                <div class="gift-img">
                    <img :src="product.photo_url" />
                </div>
                <div class="gift-info">
                    <div class="franchise m-t-4">{{ product.description }}</div>
                    <div class="gift-name m-t-4">{{ product.name }}</div>
                    <div class="gift-price">{{ product.price.toLocaleString() }}원</div>
                </div>
            </div>
            <div class="final-payment m-b-12">최종 결제금액</div>
            <div class="final-price">{{ product.price.toLocaleString() }}원</div>
            <div class="payment-refund-msg">기프티콘 결제 및 환불 안내는 하단을 확인해주세요!</div>
        </div>
        <div class="pay-method">
            <div class="pay-title m-b-24">결제 수단</div>
            <div class="methods flex">
                <button
                    v-for="method in payMethods"
                    :key="method.key"
                    class="pay-button"
                    @click="distinguishMethod(method.key)"
                    :class="{
                        'visualize-toss': visualizeToss(method.key),
                        'visualize-credit': visualizeCredit(method.key),
                    }"
                >
                    <img class="icon" :src="method.icon" />
                    <span>{{ method.text }}</span>
                </button>
            </div>
        </div>
        <div class="confirm flex">
            <div class="confirm-button">
                <button :class="{ clickable: checked }" :disabled="!checked" :key="paymentMethod" @click="pay">
                    결제하기
                </button>
            </div>
        </div>
        <div class="page-bottom flex">
            <div class="bottoms flex">
                <span>결제 및 환불 안내</span>
                <span>결제 완료 후에는 결제 취소나 환불이 불가합니다.</span>
            </div>
            <div class="bottoms flex">
                <span>결제 오류 문의</span>
                <span>카카오톡 플러스친구 <strong>바닐라브릿지</strong>로 문의 부탁드립니다.</span>
                <span>불편을 드려 죄송합니다.</span>
            </div>
        </div>
    </div>
</template>

<script>
import productService from '@/services/product'
import orderService from '@/services/order'
export default {
    name: 'GiftPayPage',
    props: {
        product: {
            type: Object,
            required: true,
        },
        agentId: {
            type: Number,
            require: true,
        },
    },
    data: () => ({
        order: null,
        checked: false,
        clickedToss: null,
        clickedCredit: null,
        paymentMethod: null,
    }),
    computed: {
        payMethods() {
            const me = this.$store.getters.me
            if (me.id % 2 === 1) {
                return [
                    {
                        key: 'credit',
                        text: this.$translate('CREDIT_CARD'),
                        icon: require('@/assets/images/store/credit_card.svg'),
                    },
                ]
            }

            return [
                {
                    key: 'toss',
                    text: this.$translate('TOSS'),
                    icon: require('@/assets/images/store/Toss_App_Icon.png'),
                },
                {
                    key: 'credit',
                    text: this.$translate('CREDIT_CARD'),
                    icon: require('@/assets/images/store/credit_card.svg'),
                },
            ]
        },
    },
    mounted() {
        window.addEventListener('message', this.onMessageFromRN)
        document.addEventListener('message', this.onMessageFromRN)
    },
    beforeDestroy() {
        window.removeEventListener('message', this.onMessageFromRN)
        document.removeEventListener('message', this.onMessageFromRN)
    },
    methods: {
        distinguishMethod(methodKey) {
            methodKey === 'toss' ? this.onClickToss() : this.onClickCredit()
        },
        onClickToss() {
            this.clickedToss = true
            this.clickedCredit = false
            this.checked = true
            this.paymentMethod = 'toss'
        },
        onClickCredit() {
            this.clickedToss = false
            this.clickedCredit = true
            this.checked = true
            this.paymentMethod = 'credit'
        },
        visualizeToss(key) {
            if (key === 'toss' && this.clickedToss === true) return true
            return false
        },
        visualizeCredit(key) {
            if (key === 'credit' && this.clickedCredit === true) return true
            return false
        },
        async pay() {
            const payload = {
                payment_type: this.paymentMethod,
                discount_user_ids: null,
                extra: { agent_id: this.agentId },
            }
            try {
                const { order, checkout_page } = await productService.createOrder(this.product.id, payload)
                this.order = order
                this.$nativeBridge.postMessage({
                    action: 'pgPurchase',
                    value: checkout_page,
                })
            } catch (e) {
                this.$toast.error(e.data)
            }
        },
        onMessageFromRN(event) {
            const data = this.$mustParse(event.data)
            if (!data || !data.action) return
            if (data.action === 'orderComplete') {
                this.$loading(true)
                setTimeout(() => {
                    this.checkOrder()
                }, 1000)
            }
            if (data.action === 'orderCancel') {
                // To-Do
            }
        },
        async checkOrder() {
            if (!this.order) return
            try {
                const { msg } = await orderService.get(this.order.id)
                this.$modal
                    .custom({
                        component: 'ModalAlertCustom',
                        options: {
                            title: 'INFO',
                            body: msg,
                            log: -2,
                        },
                    })
                    .then(idx => {
                        this.$stackRouter.popTo(Number(idx))
                    })
                this.$store.dispatch('loadMe')
            } catch (e) {
                this.$toast.error(e.data.msg)
            } finally {
                this.$store.dispatch('loadItems')
                this.$loading(false)
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.gift-pay {
    hr {
        color: white;
    }
    .page-head {
        padding: 32px 20px;
        border-bottom: 1px solid $grey-02;

        .pay-statement {
            font-family: NotoSans-Medium;
            font-size: 24px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: -0.2px;
            color: black;
            padding-bottom: 28px;
        }
        .order {
            display: flex;
            flex-direction: row;
            padding-bottom: 44px;
            .gift-img {
                width: calc((100vw - 60px) / 3);
                height: calc((100vw - 60px) / 3);
                flex-shrink: 0;
                img {
                    border-radius: 8px;
                    width: 100%;
                    height: 100%;
                }
            }
            .gift-info {
                display: flex;
                flex-direction: column;
                padding-left: 20px;

                .franchise {
                    font-family: NotoSans-Regular;
                    font-size: 12px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    color: $grey-06;
                }

                .gift-name {
                    font-family: NotoSans-Regular;
                    font-size: 15px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.27;
                    letter-spacing: normal;
                    color: $grey-09;
                    height: 46px;
                }

                .gift-price {
                    font-family: NotoSans-Medium;
                    font-size: 16px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    color: black;
                }
            }
        }

        .final-payment {
            font-family: NotoSans-Medium;
            font-size: 18px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: -0.2px;
            color: black;
        }

        .final-price {
            font-family: NotoSans-Bold;
            font-size: 24px;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $purple-primary;
            margin-bottom: 4px;
        }

        .payment-refund-msg {
            font-family: NotoSans-Regular;
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: -0.2px;
            color: $grey-07;
        }
    }
    .pay-method {
        display: flex;
        flex-direction: column;
        padding: 32px 16px;
        border-bottom: 1px solid $grey-02;
        .pay-title {
            font-family: NotoSans-Medium;
            font-size: 18px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: -0.2px;
            color: black;
        }
        .methods {
            .pay-button {
                width: 100%;
                height: 48px;
                border-radius: 8px;
                border: solid 1px $grey-03;
                background-color: white;
                font-family: NotoSansCJKkr;
                font-size: 15px;
                letter-spacing: -0.2px;
                color: $grey-09;
                display: flex;
                flex-direction: row;
                align-items: center;
                &:first-child {
                    margin-bottom: 10px;
                    color: $blue-toss;
                }
                img {
                    margin-left: 18px;
                    margin-right: 12px;
                }
                .material-icons {
                    margin-left: 18px;
                    margin-right: 10px;
                }
            }
            .visualize-toss {
                border: solid 1px $blue-facebook;
                background-color: rgba(24, 119, 242, 0.04);
            }
            .visualize-credit {
                border: solid 1px $grey-09;
                background-color: rgba(67, 66, 69, 0.04);
            }
        }
    }
    .confirm {
        padding: 29px 16px 20px 16px;
        .confirm-top {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding-bottom: 13px;
            .confirm-msg {
                display: flex;
                flex-direction: row;
                span {
                    font-family: NotoSansCJKkr;
                    font-size: 12px;
                    font-weight: bold;
                    letter-spacing: -0.2px;
                    color: #8a74ff;
                    text-decoration: underline;
                    text-decoration-color: #8a74ff;
                    &:nth-child(2) {
                        font-weight: normal;
                        color: $grey-07;
                        text-decoration: none;
                    }
                }
            }
        }
        .confirm-button {
            button {
                width: 100%;
                height: 48px;
                border-radius: 8px;
                border: none;
                background-color: $grey-02;
                font-family: NotoSansCJKkr;
                font-size: 15px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: -0.2px;
                text-align: center;
                color: $grey-05;
            }
            .clickable {
                background-color: $purple-primary;
                color: white;
            }
        }
    }
    .page-bottom {
        background-color: #fafafa;
        padding-bottom: 32px;
        .bottoms {
            padding-left: 20px;
            padding-top: 20px;
            span {
                font-family: NotoSans-Regular;
                letter-spacing: -0.2px;
                font-size: 11px;
                font-weight: normal;
                color: $grey-07;
                line-height: 1.55;
                &:first-child {
                    font-family: NotoSans-Medium;
                    font-size: 14px;
                    font-weight: 500;
                    color: $grey-09;
                    margin-bottom: 8px;
                }
            }
        }
    }
}
</style>
