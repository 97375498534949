<template>
    <div>
        <!--   아이콘뱃지   -->
        <div v-if="iconBadges.length" class="grid-row" :class="{ divider: summaryBadge.length }">
            <div class="verification-badges" v-for="badge in iconBadges" :key="badge.name">
                <div @click="fireModal(badge)" :class="{ wrapper: badge.display_method.includes('photo') }">
                    <div
                        v-lazy:background-image="
                            require(`@/assets/images/verification-badge/active/${badge.image_name}.svg`)
                        "
                        class="badge-img"
                    />
                    <img v-if="badge.display_method.includes('photo')" :src="badge.photo_url" />
                </div>
                <span class="f-bold text-nowrap" v-html="badge.name" />
            </div>
        </div>
        <!--   서머리뱃지   -->
        <div v-if="summaryBadge.length" class="summary-badges">
            <div class="cert-grid">
                <div class="cert-badge" v-for="badge in summaryBadge" :key="badge.name">
                    <i class="material-icons m-r-4">check_circle_outline</i>
                    <span v-html="badge.name" />
                </div>
            </div>
            <span v-if="summaryBadge.length" class="cert-count">
                <i class="material-icons">admin_panel_settings</i>
                <span>{{ summaryBadge.length }}건을 인증 완료한 회원입니다</span>
            </span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'VerificationBadges',
    props: {
        verificationBadges: Array,
        from: String,
    },
    computed: {
        iconBadges() {
            if (!this.verificationBadges.length) return []

            return this.verificationBadges.filter(
                badge =>
                    (badge.display_method || []).includes('icon') || (badge.display_method || []).includes('photo'),
            )
        },
        summaryBadge() {
            if (!this.verificationBadges.length) return []

            return this.verificationBadges.filter(badge => (badge.display_method || []).includes('summary'))
        },
    },
    methods: {
        fireModal(badge) {
            this.$modal.custom({
                component: 'ModalBadgeInfo',
                options: {
                    badge,
                },
            })
        },
    },
}
</script>

<style scoped lang="scss">
.grid-row {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 16px;
    padding-bottom: 32px;
    margin-top: 40px;

    &.divider {
        border-bottom: 1px solid $grey-02;
    }

    .verification-badges {
        @include flex;

        justify-content: center;
        align-items: center;

        .wrapper {
            position: relative;

            img {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 50px;
                height: 50px;
                border-radius: 50%;
            }
        }

        span {
            margin-top: 4px;
            font-size: 13px;
            color: $purple-primary;
        }
    }

    .badge-img {
        width: 60px;
        height: 60px;
        background-size: cover;
    }
}
.summary-badges {
    padding: 40px 0;
    //border-bottom: 1px solid $grey-02;

    .cert-grid {
        display: flex;
        flex-wrap: wrap;

        .cert-badge {
            padding: 0 12px 0 8px;
            height: 36px;
            border-radius: 18px;
            border: solid 1px $grey-03;
            margin-right: 8px;
            margin-bottom: 8px;

            @include center;

            i {
                font-size: 18px;
                color: $purple-primary;
            }

            span {
                font-size: 14px;
                color: black;
                white-space: nowrap;
            }
        }
    }
}

.cert-count {
    @include items-center;
    @include f-medium;
    font-size: 13px;
    color: $purple-primary;

    i {
        color: $purple-primary;
    }
}
</style>
