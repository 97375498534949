<template>
    <div class="introduction">
        <img :src="photos[0]" />
        <div class="introduction-male">
            <img :src="photos[1]" />
            <div v-if="gender === 0" class="male-additional">
                <span class="refund-policy text-underline m-l-24" @click="$router.push({ name: 'RefundPolicyPage' })">
                    소개팅권 환급 정책 보기
                </span>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'MaleIntroductionPage',
    data: () => ({
        photos: [],
    }),
    computed: {
        gender() {
            return this.$store.getters.me.gender
        },
    },
    mounted() {
        this.initPhotos()
    },
    methods: {
        initPhotos() {
            if (this.gender === 0) {
                this.photos.push(require('@/assets/images/introduction/male_introduction_1.png'))
                this.photos.push(require('@/assets/images/introduction/male_introduction_2.png'))
            } else {
                this.photos.push(require('@/assets/images/introduction/female_introduction_1.png'))
                this.photos.push(require('@/assets/images/introduction/female_introduction_2.png'))
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.introduction {
    &-male {
        background-color: $grey-09;
    }

    img {
        width: 100vw;
    }

    .male-additional {
        height: 120px;
        background-color: $grey-09;

        .refund-policy {
            font-family: NotoSans-Medium;
            font-size: 14px;
            line-height: normal;
            letter-spacing: -0.2px;
            color: $pink-deep;
        }
    }
}
</style>
