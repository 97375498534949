<template>
    <div class="chat-item" v-if="chat">
        <div class="flex-row">
            <ChatItemPhoto
                v-if="!isGroupChat"
                class="center flex-wrap m-r-12"
                :src="chatUserPhoto"
                @clickPhoto="onClickAgentPhoto"
            />
            <GroupChatItemPhotos v-else-if="isGroupChat" class="flex-wrap m-r-12" :photos="groupChatPhotos" />

            <div class="content-wrapper" @click="onClickChatItem">
                <div class="info">
                    <div class="name-and-badge">
                        <div class="name f-medium">
                            {{ chatName }}
                        </div>

                        <div v-if="isPremium" class="premium-agent flex-wrap flex-wrap m-l-8">
                            <img class="flex-wrap m-r-4" src="@/assets/images/premium.svg" />
                            <span class="flex-wrap f-medium" v-html="$translate('PREMIUM')" />
                        </div>

                        <span
                            v-if="isPersonalAgent && !isPremium"
                            class="personal-agent flex-wrap f-medium"
                            v-html="$translate('PERSONAL_AGENT')"
                        />

                        <span v-if="isInstant" class="instant-friend f-medium flex-wrap">
                            {{ $translate('TEMPORARY') }}
                        </span>

                        <span v-if="isIntroducingRealFriendChat" class="real-friend">{{
                            introducingRealFriendChatBadge
                        }}</span>

                        <span v-if="isGroupChat" class="group-chat">
                            {{ `${chat.count + 1}/${chat.event.member}` }}
                        </span>

                        <span class="m-l-8" v-if="chat.chat_type === 'pro_agent' && chat.user.vacation" v-html="`🏖`" />
                    </div>
                    <div class="last-message lines-2 m-t-8" v-html="lastMessage" />
                </div>
                <div class="right flex-wrap">
                    <div class="column">
                        <div class="timestamp flex-wrap" :class="{ 'o-0': lastMessageAt === 'NONE' }">
                            {{ lastMessageAt }}
                        </div>
                        <div v-if="chat.unread > 0" class="badge badge-count flex-wrap m-t-12">
                            {{ chat.unread }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ChatItemPhoto from './ChatItemPhoto'
import GroupChatItemPhotos from './GroupChatItemPhotos.vue'

export default {
    name: 'ChatItem',
    props: {
        chat: {
            type: Object,
            required: true,
        },
    },
    components: { ChatItemPhoto, GroupChatItemPhotos },
    computed: {
        isPremium() {
            if (this.isAlimi || this.isInstant) return false
            if (this.chat.chat_type === 'real_friend') return false

            return this.$isPremium(this.agentId)
        },
        isAlimi() {
            return this.chat.user && !this.chat.user.id
        },
        isDateCoach() {
            return this.chat.is_coaching
        },
        isRequestedDating() {
            return !this.chat.user
        },
        lastMessage() {
            const lastMessage = this.chat.last_message

            if (!lastMessage) return

            switch (lastMessage.mtype) {
                case 'text':
                case 'intro-new-user':
                case 'premium-counsel':
                    return lastMessage.content
                case 'alimi-basic':
                    return (
                        (this.$mustParse(lastMessage.content) || {}).title ||
                        (this.$mustParse(lastMessage.content) || {}).msg
                    )
                case 'photo':
                    return this.$translate('PHOTO')
                case 'gift-info':
                    return (
                        (this.$mustParse(lastMessage.content) || {}).agent.name +
                        '님에게 ' +
                        (this.$mustParse(lastMessage.content) || {}).msg
                    )
                default:
                    return (this.$mustParse(lastMessage.content) || {}).msg
            }
        },
        lastMessageAt() {
            const result =
                this.$options.filters.formatDate((this.chat.last_message || {}).created_at, 'chatList') || 'NONE'
            return result
        },
        isInstant() {
            return !!this.chat.expire_at
        },
        isPersonalAgent() {
            return !(
                this.isRequestedDating ||
                this.chat.expire_at ||
                this.isDateCoach ||
                this.isAlimi ||
                this.isIntroducingRealFriendChat ||
                this.isGroupChat
            )
        },
        agentId() {
            return this.chat.agent_id
        },
        chatUserPhoto() {
            if (['pro_agent', 'alimi'].includes(this.chat.chat_type)) return (this.chat.user || {}).photo_url
            else if (this.chat.chat_type === 'social_group') return Object.values(this.chat.users || {})[0].photo_url
            else if (this.chat.chat_type === 'real_friend') {
                // 소개받는 유저의 경우
                if (this.chat.introduce.user_id !== this.$store.getters.me.id) {
                    return require('@/assets/images/app/blank_profile_big.svg')
                }

                return this.chat.user.photo_url
            } else return ''
        },
        chatName() {
            if (['pro_agent'].includes(this.chat.chat_type)) {
                const agentTitle = '주선자 '

                return agentTitle + (this.chat.user || {}).name
            } else if (['alimi'].includes(this.chat.chat_type)) {
                return (this.chat.user || {}).name
            } else if (this.isGroupChat) return (this.chat.event || {}).title || ''
            else if (this.isIntroducingRealFriendChat) {
                return this.chat.introduce.title
            }

            return ''
        },
        isGroupChat() {
            return this.chat.chat_type === 'social_group'
        },
        isIntroducingRealFriendChat() {
            return this.chat.chat_type === 'real_friend'
        },
        introducingRealFriendChatBadge() {
            if (!this.isIntroducingRealFriendChat) return

            const myId = this.$store.getters.me.id

            if (this.chat.introduce.user_id === myId) {
                return '내친소 주선중'
            }

            return '내친소'
        },
        groupChatPhotos() {
            if (!this.isGroupChat) return []

            return [this.$store.getters.me.photos[0].url, ...Object.values(this.chat.users).map(u => u.photo_url)]
        },
    },
    methods: {
        async onClickChatItem() {
            try {
                this.$loading(true)
                const unreadCount = this.chat.unread
                await this.$store.dispatch('loadChat', {
                    chatId: this.chat.id,
                    force: true,
                })

                this.$router.push({
                    name: 'ChatroomPage',
                    params: { unreadCount },
                })
            } catch (e) {
                this.$toast.error(e.data)
            } finally {
                this.$loading(false)
            }
        },
        async onClickChatItemProfileImage(event) {
            event.stopPropagation()
            const photoUrls = await this.$store.dispatch('loadUserImages', this.chat.user.id)
            if (!photoUrls) return

            this.$modal.images({
                images: photoUrls,
            })
        },
        onClickAgentPhoto() {
            if (this.isAlimi || this.isDateCoach || this.isRequestedDating) return

            if (this.isIntroducingRealFriendChat) {
                this.onClickChatItem()

                return
            }

            this.$stackRouter.push({
                name: 'AgentDetailPage',
                props: {
                    agentId: this.chat.agent_id,
                    from: 'ChatsPage',
                },
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.chat-item {
    $photo-width: 60px;

    user-select: none;
    padding: 12px 16px;

    .content-wrapper {
        display: flex;
        justify-content: space-between;
    }

    .info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: calc(100vw - 32px - 70px - 50px);

        .name-and-badge {
            display: flex;
            align-items: center;
            line-height: normal;
            min-width: 0;
        }

        .name {
            color: black;
            font-size: 15px;

            @include lines-1;
        }

        .personal-agent,
        .instant-friend,
        .real-friend,
        .group-chat {
            height: 20px;
            white-space: nowrap;
            flex-shrink: 0;

            @include center;
        }

        .personal-agent {
            margin-left: 8px;
            padding: 0 6px;
            font-size: 12px;
            border-radius: 6px;
            border: solid 1px $grey-04;
            color: $grey-07;
        }

        .premium-agent {
            margin-left: 8px;
            padding: 0 6px;
            font-size: 12px;
            color: $yellow-premium;
            border-radius: 6px;
            border: solid 1px $yellow-premium;
        }

        .instant-friend {
            margin-left: 8px;
            background-color: $grey-02;
            border-radius: 6px;
            font-size: 12px;
            color: $grey-08;
            padding: 0 6px;
            line-height: normal;

            @include f-medium;
        }

        .real-friend {
            margin-left: 8px;
            padding: 0 6px;
            font-size: 11px;
            color: $purple-primary;
            border: solid 1px $purple-primary;
            border-radius: 6px;

            @include f-medium;
        }

        .group-chat {
            margin-left: 8px;
            font-size: 12px;
            color: $grey-06;

            @include f-medium;
        }
    }

    .age-region {
        font-size: 11px;
        margin-top: 2px;
        color: $grey-07;
    }

    .last-message {
        font-size: 12px;
        line-height: 16px;
    }

    .right {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .column {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            width: 50px;
        }

        .timestamp {
            font-size: 11px;
            letter-spacing: -0.4px;
            color: $grey-05;
            text-align: right;
            display: table;
            margin-top: 2px;
        }

        .badge-count {
            height: 18px;
            min-width: 18px;
            border-radius: 9px;
            background: $purple-primary;
            padding: 0 5px;
        }
    }

    &.selected {
        background: rgba(0, 0, 0, 0.06);
    }
}
</style>
