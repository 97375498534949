<template>
    <div class="refund-8-confirm center-refund">
        <div class="page-header">
            <div class="title">환급 신청 내용 확인</div>
        </div>
        <div class="section section1">
            <div class="flex keys">
                <span class="key">소개상대</span>
                <span class="key">주선자</span>
                <span class="key">매칭일</span>
            </div>
            <div class="flex values">
                <span class="value f-medium">{{ dating.name }}</span>
                <span class="value f-medium">
                    {{ dating.agent_name ? dating.agent_name : dating.agent_nickname }}
                    {{ dating.agent_name ? `(` + dating.agent_nickname + `)` : '' }}
                </span>
                <span class="value">{{ datingDate(dating.created_at) }}</span>
            </div>
        </div>
        <div class="section section2">
            <div class="refund-reason">
                <span class="key">환급사유</span>
                <span class="value m-b-12">{{ reason }}</span>
            </div>
            <div class="flex">
                <div class="description">{{ description }}</div>
            </div>
        </div>
        <div class="section section3" :class="{ noThirdRefund: !thirdRefund }">
            <div class="m-b-16">
                <span class="key">첨부자료</span>
            </div>
            <div class="photos">
                <div v-for="photo in photos" :key="photo.fileName">
                    <div
                        v-if="photo.url"
                        class="img-cover"
                        :style="{
                            'background-image': 'url(' + photo.url + ')',
                        }"
                    ></div>
                </div>
            </div>
        </div>
        <div v-if="thirdRefund" class="section section4">
            <div class="title" v-html="$translate('REFUND_THIRD_TIME')" />
            <div class="desc" v-html="$translate('REFUND_THIRD_TIME_DESC')" />
            <div class="options">
                <div
                    class="option"
                    :class="{ selected: !selected ? false : idx === selected.id }"
                    :key="item.name"
                    v-for="(item, idx) in options"
                    @click="select(idx)"
                >
                    <div class="option-title" :class="{ selected: !selected ? false : idx === selected.id }">
                        {{ item.desc }}
                    </div>
                </div>
            </div>
        </div>
        <BottomButton :disabled="disabled" @click="onClickRefund" :label="'환급 신청하기'" />
    </div>
</template>

<script>
import commonService from '@/services/common'
import realFriendMeetingService from '@/services/realfriend-meeting'

export default {
    name: 'Refund8ConfirmPage',
    data: () => ({
        selected: null,
    }),
    computed: {
        options() {
            return [
                {
                    id: 0,
                    name: 'discount_ticket',
                    desc: `소개팅권 ${!this.$refundPolicySplit() ? '1만원' : '3,500원'} 할인 쿠폰`,
                },
                {
                    id: 1,
                    name: 'discount_premium',
                    desc: `프리미엄 ${!this.$refundPolicySplit() ? '1만원' : '3,500원'} 할인 쿠폰`,
                },
                {
                    id: 2,
                    name: 'vanilla',
                    desc: `바닐라 ${!this.$refundPolicySplit() ? '700개 (15,000원 상당)' : '300개 (7,000원 상당)'}`,
                },
            ]
        },
        dating() {
            return this.refund.dating
        },
        disabled() {
            if (this.thirdRefund) return this.selected === null

            return false
        },
        reason() {
            return this.refund.reason.title
        },
        description() {
            return this.refund.description
        },
        photos() {
            return this.refund.photos
        },
        refund() {
            return this.$store.getters.payloads.refund || {}
        },
        refundType() {
            return this.$store.getters.refundType
        },
        thirdRefund() {
            const reduceFalse = this.refund.reason.reduce_count === false
            if (reduceFalse) return false

            return this.dating.refund_cnt === 2 || false
        },
    },
    mounted() {
        if (this.refund.photos.length === 0) this.$router.push({ name: 'HomePage' })
    },
    methods: {
        select(idx) {
            this.selected = this.options[idx]
        },
        async onConfirm() {
            if (this.refundType === 'realFriend') {
                this.onConfirmRealFriend()

                return
            }

            try {
                this.$loading(true)
                const { dating_feedback } = await commonService.createRefund(this.preparedPayload())
                const dating = { ...this.$store.getters.payloads.refund.dating }
                this.clearRefundData()

                this.$router.push({ name: 'Refund9RefundedPage', params: { dating, dating_feedback } })
            } catch (e) {
                this.$alert(e.data.msg)
            }
            this.$loading(false)
        },
        async onConfirmRealFriend() {
            try {
                this.$loading(true)
                const { id, request_id } = this.dating
                const payload = this.preparedPayload()

                const { msg } = await realFriendMeetingService.confirmMeeting(id, request_id, payload)
                this.$toast.success(msg)
                this.clearRefundData()

                const chat = { ...this.$store.getters.chat }
                if (chat) {
                    chat.introduce.request.accept_status = 'refund'
                    this.$store.commit('updateChatAndChats', chat)
                }

                this.$router.push({ name: 'HomePage' })
            } catch (e) {
                this.$toast.error(e.data)
            } finally {
                this.$loading(false)
            }
        },
        clearRefundData() {
            this.$store.commit('setPayloads', {
                refund: {
                    dating: null,
                    reason: null,
                    description: '',
                    photos: [],
                },
            })
        },
        onClickRefund() {
            let name
            if (this.refundType === 'realFriend') {
                name = this.refund.dating.agent_name ? this.refund.dating.agent_name : this.refund.dating.agent_nickname
            } else {
                name = this.refund.dating.name || this.refund.dating.nickname
            }
            this.$modal
                .basic({
                    body:
                        `
                <div class="f-18 f-medium c-black text-left m-t-20" style="line-height: normal">
                    환급 신청 시,<br>
                    <span class="c-primary f-bold">${name ? name + '님' : '상대방'}에게</span><br>
                    <span class="c-primary f-bold">만남 의사가 없음을 알리는</span><br>
                    메시지가 전송됩니다.
                </div>` +
                        `<div class="f-15 c-text-dark m-t-20 text-left" style="line-height: normal">
                    확실하게 정리된 게 아니라면<br>
                    조금 더 신중하게 결정해주세요! 🙏
                </div>`,
                    hideHeader: true,
                    buttons: [
                        {
                            label: 'CANCEL',
                            class: 'btn-default',
                        },
                        {
                            label: 'REQUEST_REFUND',
                            class: 'btn-primary',
                        },
                    ],
                })
                .then(idx => {
                    if (idx === 1) {
                        this.onConfirm()
                    }
                })
        },
        preparedPayload() {
            const refund = this.$store.getters.payloads.refund
            if (!refund) return

            const payload = new FormData()
            if (this.refundType === 'realFriend') {
                payload.append('meeting_status', 'refund')
            } else {
                payload.append('dating_id', refund.dating.id)
                if (this.thirdRefund) payload.append('refund_to', this.selected.name)
            }

            payload.append('reason_id', refund.reason.id)
            payload.append('description', refund.description)
            refund.photos
                .filter(p => p.url)
                .forEach((p, index) => {
                    payload.append(`photo${index + 1}`, p.blob, p.fileName)
                })

            return payload
        },
        datingDate(date) {
            return this.$moment(date).format('YYYY.MM.DD')
        },
    },
}
</script>
