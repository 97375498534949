<template>
    <div class="checkbox" :class="{ checked: value }" @click="onClickCheckbox">
        <i class="material-icons" v-if="value">check</i>
    </div>
</template>
<script>
export default {
    name: 'Checkbox',
    props: ['value'],
    methods: {
        onClickCheckbox(event) {
            event.stopPropagation()
            this.$emit('input', this.value !== true)
            this.$emit('select')
        },
    },
}
</script>
