<template>
    <div v-if="review" class="user-review">
        <div class="top flex-row items-center flex-between">
            <StarRating
                :length="5"
                :default="review.rate"
                :fixed="true"
                :activeIcon="require('@/assets/images/rating/icon_star_rate_active_noborder.svg')"
                :inactiveIcon="require('@/assets/images/rating/icon_star_rate_inactive_gray_bg.svg')"
            />
            <div class="writer">
                <span class="month">{{ getMonth() }}월</span>
                <span class="divider">ㅣ</span>
                <span
                    class="f-medium gender"
                    :class="{
                        male: review.gender === 0,
                        female: review.gender === 1,
                    }"
                    v-html="$translate(review.gender === 0 ? 'MALE' : 'FEMALE')"
                />
            </div>
        </div>
        <div v-if="review.review" class="content" :class="{ 'lines-2': shorter }" v-html="review.review" />
    </div>
</template>

<script>
export default {
    name: 'UserReview',
    props: ['shorter', 'review'],
    methods: {
        getMonth() {
            const date = this.review.date
            if (!date) return ''

            return date.split('/')[1]
        },
    },
}
</script>
