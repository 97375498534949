import Vue from 'vue'
import router from '@/router'
import * as filters from '@/filters/index'
import chatService from '@/services/chat'
import userService from '@/services/user'
import * as helpers from './helpers'

const defaultState = () => ({
    chats: null,
    chat: null,
    message: null, // => last message from WebSocket
    connected: false,
    instantRoomPremiumUsers: null,
})

const state = defaultState()

const getters = {
    chats: state => state.chats,
    chat: state => state.chat,
    connected: state => state.connected,
    instantRoomPremiumUsers: state => state.instantRoomPremiumUsers,
}

const actions = {
    async loadChats({ state, commit }, force) {
        if (!force && helpers.canSkipApiCall(state, 'chats')) return

        try {
            commit('setLoading', true)
            const chats = await chatService.all()
            if (!chats || chats.length === 0) return

            commit('setChats', chats)

            return chats
        } catch (e) {
            return Promise.reject(e)
        } finally {
            commit('setLoading', false)
        }
    },
    async loadChat({ state, dispatch, commit }, { chatId, force, updateUnread }) {
        try {
            if (!state.chats) {
                await dispatch('loadChats')
            }

            let chat = (state.chats || []).find(c => c.id === chatId)
            if (!chat) {
                chat = await chatService.detail(chatId)
                if (!chat) return Promise.reject(filters.translate('ERROR_NON_EXIST_CHAT'))

                commit('setChats', [chat, ...state.chats])
            }

            commit('setChat', {
                chat,
                updateUnread: typeof updateUnread === 'boolean' ? updateUnread : true,
            })

            if (!chat.$$messages || force) {
                // chatroom으로 이동 전에 메시지를 비동기로 불러와야 chatroom이 마운트 될 때, 메시지가 들어있음
                await dispatch('loadMessages', {
                    chatId: chat.id,
                    force,
                })
            }

            if (!chatId) return

            dispatch('loadBadges')

            return chat.unread
        } catch (e) {
            return Promise.reject(e)
        }
    },
    async findChat(_, { chatId }) {
        try {
            const chat = await chatService.detail(chatId)
            return Promise.resolve(chat)
        } catch (e) {
            // Resolve even if it's not found.
            return Promise.resolve({})
        }
    },
    async handleNewChat({ state, dispatch, commit }, message) {
        if (!state.chats) {
            await dispatch('loadChats')
        }

        const found = state.chats.find(chat => chat.id === message.chat_id)

        if (found) return

        const chat = await dispatch('findChat', { chatId: message.chat_id })

        // 인스턴트 방은 expired_at, suggest_count가 있지만 API 요청 보내는 시점에서 suggest_count가 업데이트 되지 않음
        // 따라서 suggest_count를 1 더해 사용자에게 노출
        // if (chat.expire_at) chat.suggest_count += 1

        if (chat) commit('setChats', [chat, ...state.chats])
    },
    async addMessage({ state, commit }, message) {
        if (!state.chats) return

        const chat = helpers.findChatByMessage(state.chats, message)
        if (!chat) return

        commit('addMessage', {
            chat,
            message,
        })
    },
    async loadMessages({ commit }, payload) {
        try {
            const data = await chatService.messages(payload.chatId, payload.firstId)

            commit('setMessages', {
                messages: (data || {}).messages,
                loadMore: (data || {}).load_more,
                firstMessageId: payload.firstId,
                force: payload.force,
            })
        } catch (e) {
            return Promise.reject(e)
        }
    },
    async loadUserDetail({ state }, { userId, agentId }) {
        try {
            const user = await userService.detail(userId, agentId)

            if (state.chat && state.chat.chat_type !== 'social_group') {
                user.$$distance = Vue.prototype.$distance(state.chat.user.location, user.location)
            }

            return Promise.resolve(user)
        } catch (e) {
            return Promise.reject(e)
        }
    },
    async loadUserImages({ dispatch }, userId) {
        const profile = await dispatch('loadUserDetail', { userId })

        const photos = profile.photos
        if (!photos || photos.length === 0) return

        return Promise.resolve(photos)
    },
}

const mutations = {
    closeOpenedChat(state) {
        state.chat = null
    },
    setChats(state, chats) {
        state.chats = chats
    },
    setChat(state, { chat, updateUnread }) {
        if (!chat) {
            state.chat = chat
            return
        }

        state.chat = chat

        if (updateUnread) {
            Vue.prototype.$bus.$emit('updateUnread')
        }
    },
    addMessage(state, { chat, message }) {
        if (!message) return

        // We increase chat.unread for <ChatItem> only when it's opened.
        const setLastMessage = (state, chat) => {
            chat.last_message = message.message

            if (!state.chat || state.chat.id !== chat.id || router.currentRoute.name !== 'ChatroomPage') {
                chat.unread++
            }
        }

        if (!chat) return

        setLastMessage(state, chat)

        const moveThisToTop = state.chats.map(c => c.id).indexOf(chat.id)
        if (moveThisToTop) {
            // state는 새 메시지가 오면 '반드시 항상' 바뀌어야한다.
            // 단지 채팅방 리스트에 '필터가 적용 중'일 경우 '보이는 화면에서만' 맨 위로 안올라가게 해야 할 뿐이다.
            state.chats.splice(moveThisToTop, 1)
            state.chats.unshift(chat)
        }

        if (chat.$$messages && chat.$$messages.length > 0 && chat.$$messages[0].id !== message.message.id) {
            chat.$$messages.unshift(message.message)
        }
    },
    setMessages(state, { messages, loadMore, firstMessageId, force }) {
        if (!messages || messages.length === 0) return

        const chat = helpers.findChatByMessage(state.chats, messages[0])
        if (!chat) return

        Vue.set(chat, '$$loadMore', loadMore)
        Vue.set(chat, '$$firstMessageId', firstMessageId)
        if (!chat.$$messages || force) {
            Vue.set(chat, '$$messages', messages)
            return
        }

        if (chat.$$messages.find(m => m.id === messages[0].id)) {
            return
        }

        chat.$$messages = chat.$$messages.concat(messages)
    },
    connected(state, payload) {
        if (payload === true || payload === false) {
            state.connected = payload
        }
    },
    updateChatAndChats(state, chat) {
        if (!chat || !state.chat) return

        const chats = [...state.chats]
        const idx = chats.findIndex(c => c.id === chat.id)

        if (idx > -1) {
            chats.splice(idx, 1, chat)
        }

        state.chats = [...chats]
        state.chat = { ...chat }
    },
    setInstantRoomPremiumUsers(state, payload) {
        if (!payload) return

        state.instantRoomPremiumUsers = payload
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
    defaultState,
}
