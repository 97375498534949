<template>
    <div class="chatroom" :class="{ kakaoMode: $kakaoMode() }">
        <header class="header">
            <ChatHeaderBar />
            <FloatingComponents :chat="chat" />
        </header>
        <ChatBody :unreadCount="unreadCount" class="flex-fill" />
        <ChatInput class="flex-wrap" />
    </div>
</template>

<script>
import ChatBody from './components/ChatBody'
import ChatInput from './components/ChatInput'
import FloatingComponents from './components/floating-components/FloatingComponents'
import ChatHeaderBar from '@/components/app/ChatHeaderBar'

export default {
    name: 'ChatroomPage',
    components: {
        ChatBody,
        ChatInput,
        FloatingComponents,
        ChatHeaderBar,
    },
    data: () => ({
        unreadCount: 0,
        agent: null,
    }),
    computed: {
        chat() {
            return this.$store.getters.chat || {}
        },
    },
    mounted() {
        this.init()
        this.showModal()
        this.unreadCount = this.$route.params.unreadCount || 0
        this.$bus.$on('reloadMessage', this.reloadMessages)
    },
    beforeDestroy() {
        if (this.$kakaoMode()) {
            this.$nativeBridge.postMessage({
                action: 'setBackgroundColor',
                value: '#FFFFFF',
            })
        }
        if (this.unreadCount > 0) this.updateUnreadCount()
        this.$unregisterBackHandler()
        this.$nativeBridge.postMessage({ action: 'allowSwipeGesture', value: true })
        this.$bus.$off('reloadMessage', this.reloadMessages)
    },
    methods: {
        onClickPhotoInputPage() {
            this.$router.push({ name: 'UploadPhotoPage' })
        },
        init() {
            if (this.$kakaoMode()) {
                this.$nativeBridge.postMessage({
                    action: 'setBackgroundColor',
                    value: {
                        top: '#c5d3df',
                        bottom: 'white',
                    },
                })
            }
            if (this.chat.chat_type === undefined || (this.chat.chat_type === 'pro_agent' && !this.chat.user)) {
                this.$router.go(-1)
                return
            }

            // 클릭 이벤트 리스너를 달아놓으면 chatInput 포커스 된 상태에서 다른 영역 클릭 시 자동으로 포커스 해제됨
            const chatroomElement = document.querySelector('.chatroom')
            chatroomElement.addEventListener('click', event => {})

            this.updateUnreadCount()
        },
        reloadMessages() {
            const chat = this.$store.getters.chat

            if (!chat) return

            this.$nextTick(async () => {
                const unread = await this.$store.dispatch('loadChat', {
                    chatId: chat.id,
                    force: true,
                    updateUnread: false,
                })

                this.unreadCount = unread
            })
        },
        updateUnreadCount() {
            this.$bus.$emit('updateUnread')
        },
        showModal() {
            const { chat_type: ctype } = this.chat

            if (ctype === 'real_friend') {
                const visited = window.localStorage.getItem(`visitedRealFriendChat${this.chat.id}`) || false

                if (!visited) {
                    this.$modal
                        .custom({
                            component: 'ModalRealFriendChatAlert',
                            options: {
                                chat: this.chat,
                                title: '꼭 확인해주세요!',
                            },
                        })
                        .then(() => {
                            window.localStorage.setItem(`visitedRealFriendChat${this.chat.id}`, 'true')
                        })
                }
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.chatroom {
    background: #f6f6f8;
    position: relative;
    display: flex;
    flex-direction: column;

    &.kakaoMode {
        background: $blue-kakao;
    }

    .header {
        position: relative;
    }
}
.temp-btn {
    width: 169px;
    height: 48px;
    border-radius: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    color: black;

    margin: 16px auto;

    i {
        color: black;
    }
}
</style>
