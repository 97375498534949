<template>
    <div v-if="!$isTester()" class="refund-info-banner flex-row flex-between" @click="onClickBanner">
        <div class="flex flex-wrap">
            <div class="top" v-html="banner[type].top"></div>
            <div class="bottom" v-html="banner[type].bottom"></div>
        </div>
        <img class="flex-wrap m-r-20" :src="banner[type].image" />
    </div>
</template>

<script>
export default {
    name: 'RefundInfoBanner',
    props: ['type'],
    data: () => ({
        banner: {
            inApp: {
                top: '만남 성사되지 않으면, 100% 환급!',
                bottom: '바닐라 환급 정책 안내',
                image: require('@/assets/images/vanilla_refund_banner.svg'),
            },
            ticket: {
                top: '만남 성사되지 않으면, 100% 환급!',
                bottom: '소개팅권 <span class="c-primary">환급 정책</span> 안내',
                image: require('@/assets/images/ticket_refund_banner.png'),
            },
        },
    }),
    methods: {
        onClickBanner() {
            this.$stackRouter.push({ name: 'RefundPolicyPage' })
        },
    },
}
</script>
