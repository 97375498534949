<template>
    <div class="unread-separator">
        <i class="material-icons">bookmark</i>
        <span class="text f-medium">여기까지 읽었습니다.</span>
    </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.unread-separator {
    display: flex;
    align-items: center;
    background-color: $grey-04;
    border-radius: 8px;
    padding: 3px 12px;
    margin-top: 16px;
    margin-bottom: 12px;
    white-space: nowrap;

    $unread-seperator-width: 150px;
    width: $unread-seperator-width;
    margin-left: calc((100% - #{$unread-seperator-width}) / 2);

    .material-icons {
        color: white;
        font-size: 16px;
        margin-right: 6px;
    }

    .text {
        font-size: 12px;
        color: white;
        letter-spacing: -0.2px;
    }
}
</style>
