<template>
    <div v-if="show" class="real-friend-social-top">
        <div class="introduce-or-event m-b-8" @click.stop="onClick">
            <div class="flex-row items-center">
                <div
                    class="article-wrapper m-r-12"
                    :class="{
                        'bg-primary': isRealFriend,
                        'bg-blue-facebook': isSocial,
                    }"
                >
                    <i class="material-icons">article</i>
                </div>
                <div class="info">
                    <p class="f-14 c-black lines-1">{{ title }}</p>
                    <p class="f-medium f-12 c-text-lighter lines-1">{{ info }}</p>
                </div>
            </div>
            <div class="flex-row items-center flex-wrap">
                <div
                    v-if="isSocial"
                    class="members m-r-8 f-12 f-medium c-text-lighter"
                    @click.stop="onClickSocialMembers"
                >
                    <span class="f-bold c-blue-facebook">참여 회원 {{ Object.keys(chat.users).length + 1 }}</span
                    >/{{ event.member }}
                </div>
                <i class="material-icons right-icon">chevron_right</i>
            </div>
        </div>

        <div
            v-if="isRealFriend && !isAgentUser && introduce.request.accept_status === 'success'"
            class="confirm-meeting m-b-8"
            @click="onClickConfirm"
        >
            <p class="c-primary">
                <span class="f-bold">{{ $nameOrNick(chat.user) }}님의 친구</span>와 만남(소개팅)을 가지셨나요?
            </p>
            <i class="material-icons">chevron_right</i>
        </div>

        <div v-if="expireAt" class="remain m-r-16 m-b-8">
            <i class="material-icons">access_time</i>
            <p>{{ expireAt }} 후 종료</p>
        </div>

        <div v-if="isRealFriend" class="cautions" @click="onClickCautions">
            <i class="material-icons-outlined">info</i>
        </div>
    </div>
</template>

<script>
export default {
    name: 'RealFriendSocialTop',
    props: {
        chat: {
            type: Object,
            default: null,
        },
    },
    computed: {
        show() {
            return this.chat
        },
        chatType() {
            return this.chat.chat_type
        },
        // 내친소
        isRealFriend() {
            return this.chatType === 'real_friend'
        },
        introduce() {
            return this.chat.introduce
        },
        isAgentUser() {
            return this.introduce.user_id === this.$store.getters.me.id
        },
        // 소셜
        isSocial() {
            return this.chatType === 'social_group'
        },
        event() {
            return this.chat.event
        },
        state() {
            const state = (this.$store.getters.states || []).find(s => s.id === this.introduce.state_id)

            return state.name
        },
        gender() {
            return this.introduce.gender === 0 ? '남' : '여'
        },
        isHost() {
            if (this.isRealFriend) return this.introduce.user_id === this.$store.getters.me.id
            return this.event.user_id === this.$store.getters.me.id
        },
        // 공통
        title() {
            if (this.isRealFriend) return this.introduce.title
            if (this.isSocial) return this.event.title

            return ''
        },
        info() {
            if (this.isRealFriend) return `${this.gender}(${this.introduce.age}세), ${this.state}`
            if (this.isSocial) {
                const expireAt = this.$moment(this.event.expire_at).format('MM.DD')
                const diff = this.$moment(this.event.expire_at).diff(this.$moment(), 'days') + 1
                let diffString = ''

                if (diff === 0) {
                    diffString = '(오늘)'
                } else if (diff === 1) {
                    diffString = '(내일)'
                } else if (diff > 1) {
                    diffString = `(${diff}일전)`
                }

                return `${expireAt} ${diffString}, ${this.event.state} (${this.event.event_region})`
            }

            return ''
        },
        expireAt() {
            if (!this.chat.expire_at) return

            if (this.isSocial) {
                return
            }

            const remain = this.$moment(this.chat.expire_at).diff(Date.now(), 'hours')

            if (remain >= 24) {
                const remainDays = this.$moment(this.chat.expire_at).diff(Date.now(), 'days')
                return `${remainDays}일`
            }

            return `${remain}시간`
        },
    },
    methods: {
        async onClick() {
            try {
                this.$loading(true)

                let route = ''
                let posts
                let post
                let targetId

                if (this.isRealFriend) {
                    await this.$store.dispatch('loadRealfriendMeetingPosts')
                    targetId = this.chat.introduce.id
                    posts = this.$store.getters.realfriendMeeting
                    route = 'RealFriendMeetingPostDetailPage'
                } else if (this.isSocial) {
                    await this.$store.dispatch('loadSocialMeetingPosts')
                    targetId = this.chat.event.id
                    posts = this.$store.getters.socialMeeting
                    route = 'SocialMeetingPostDetailPage'
                }

                const { myPosts, interestedPosts } = posts

                if (this.isHost) post = myPosts.find(p => p.id === targetId)
                else post = interestedPosts.find(p => p.id === targetId)

                this.$stackRouter.push({
                    name: route,
                    props: {
                        post,
                    },
                })
            } catch (e) {
                this.$toast.error(e.data)
            } finally {
                this.$loading(false)
            }
        },
        onClickConfirm() {
            this.$stackRouter.push({
                name: 'RealFriendMeetingConfirmPage',
                props: {
                    chat: this.chat,
                },
            })
        },
        onClickCautions() {
            this.$modal.custom({
                component: 'ModalRealFriendChatAlert',
                options: {
                    chat: this.chat,
                    title: this.$translate('CAUTIONS'),
                },
            })
        },
        onClickSocialMembers() {
            this.$stackRouter.push({
                name: 'SocialChatMembersPage',
                props: {
                    chat: this.chat,
                },
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.real-friend-social-top {
    display: flex;
    flex-direction: column;

    .introduce-or-event {
        padding: 8px 16px;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: solid 1px $grey-03;

        .article-wrapper {
            width: 28px;
            height: 28px;
            border-radius: 50%;

            @include center;

            .material-icons {
                font-size: 18px;
                color: white;
            }
        }

        .info {
            max-width: calc(100vw - 32px - 40px - 122px - 12px);
        }

        p {
            line-height: normal;
        }

        .right-icon {
            color: $grey-08;
        }

        .members {
            height: 28px;
            padding: 0 10px;
            border: solid 1px $blue-facebook;
            border-radius: 14px;

            @include center;
        }
    }

    .confirm-meeting {
        width: calc(100vw - 32px);
        align-self: center;
        padding: 10px 10px 10px 14px;
        background-color: white;
        border: solid 1px $purple-primary;
        border-radius: 0 12px 12px 12px;
        color: white;
        justify-content: space-between;

        @include items-center;

        .material-icons {
            color: white;
        }
    }

    .remain {
        display: flex;
        align-items: center;
        align-self: flex-end;
        background-color: $grey-09;
        border-radius: 16px;
        padding: 6px 10px;
        height: 32px;

        & > * {
            color: white;
        }

        .material-icons {
            font-size: 18px;
            margin-right: 6px;
        }
    }

    .cautions {
        margin-right: 16px;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        background-color: $pink-deep;
        align-self: flex-end;

        @include center;

        .material-icons-outlined {
            color: white;
            transform: rotate(180deg);
            vertical-align: middle;
        }
    }
}
</style>
