<template>
    <div class="preferences signup">
        <div class="content content-noheader">
            <div class="title">
                어떤 분을<br />
                소개받고 싶으세요?
            </div>
            <div class="desc">
                <span class="brand-primary f-bold">소개받고 싶은 이성 정보</span>를 입력하고,<br />
                담당 주선자에게<br />
                원하는 이성을 소개받아보세요!
            </div>
        </div>
        <div class="button-wrapper">
            <button class="btn btn-primary m-b-10" @click="next">이상형 입력</button>
            <button class="btn btn-brd" @click="skip">나중에 하기</button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PreferencesIntroPage',
    methods: {
        skip() {
            this.sendFirebaseEvent('PreferenceIntroPage_Click_SkipBtn')

            setTimeout(() => this.$router.push({ name: 'OnboardingPage' }), 200)
        },
        next() {
            this.sendFirebaseEvent('PreferenceIntroPage_Click_InputBtn')

            this.$router.push({ name: 'PreferencesAgePage' })
        },
        sendFirebaseEvent(category) {
            const { provider, gender } = this.$store.getters.payloads.signupData

            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category,
                    option: {
                        provider: provider || 'email',
                        gender: gender.name === 'MALE' ? 0 : 1,
                    },
                },
            })
        },
    },
    mounted() {
        const { provider, gender } = this.$store.getters.payloads.signupData

        this.$nativeBridge.postMessage({
            action: 'sendFirebaseEvent',
            value: {
                category: 'Enter_PreferenceIntroPage',
                option: {
                    provider: provider || 'email',
                    gender: gender.name === 'MALE' ? 0 : 1,
                },
            },
        })
    },
}
</script>
