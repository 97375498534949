<template>
    <div></div>
</template>

<script>
export default {
    name: 'ProfileOptionSelector',
    props: ['model', 'nextRoute'],
    mounted() {
        this.openModal()
    },
    methods: {
        openModal() {
            this.$modal
                .custom({
                    component: 'ModalProfileItemSelector',
                    options: {
                        model: this.model,
                        icon: 'chevron_left',
                        profile: this.$store.getters.payloads.signupData,
                    },
                })
                .then(result => {
                    if (!result) {
                        this.$router.go(-1)
                        return
                    }

                    let signupData = {}

                    // 카카오 때문에 젠더는 전체다 닉네임에서 찍는걸로 수정
                    // if (this.model === 'gender') {
                    //     const gender = result.name.toLowerCase()

                    //     this.$nativeBridge.postMessage({
                    //         action: 'sendAirbridgeEvent',
                    //         value: {
                    //             category: `signup_choose_gender_${gender}`,
                    //         },
                    //     })
                    // }

                    if (['shape', 'kind', 'religion'].indexOf(this.model) !== -1) {
                        signupData = this.$store.getters.payloads.signupData

                        // 일단 하드코딩
                        if (this.model === 'shape') {
                            signupData.profile_option_ids[0] = result.id
                        } else if (this.model === 'kind') {
                            signupData.profile_option_ids[1] = result[0].id
                            signupData.profile_option_ids[2] = result[1].id
                        } else {
                            signupData.profile_option_ids[3] = result.id
                        }
                    } else {
                        signupData[this.$case.toSnake(this.model)] = result
                    }
                    this.$store.commit('setPayloads', { signupData })

                    this.$router.push({ name: this.nextRoute(result) })
                })
        },
    },
}
</script>
