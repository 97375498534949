import agentService from '@/services/agent'
import * as helpers from './helpers'

const defaultState = () => ({
    agents: null,
    agentRankings: null,
    agentDetailParams: {},
})

const state = defaultState()

const getters = {
    agents: state => state.agents,
    agentRankings: state => state.agentRankings,
    agentDetailParams: state => state.agentDetailParams,
}

const actions = {
    async loadAgents({ state, commit }, force) {
        if (!force && helpers.canSkipApiCall(state, 'agents')) return

        try {
            commit('setLoading', { agents: true })
            const agents = await agentService.all()
            if (!agents || agents.length === 0) return

            commit('setAgents', agents)
            return Promise.resolve(agents)
        } catch (e) {
            return Promise.reject(e)
        } finally {
            commit('setLoading', { agents: false })
        }
    },
    async loadAgentDetail(_, { agentId }) {
        try {
            const agent = await agentService.detail(agentId)
            return Promise.resolve(agent)
        } catch (e) {
            return Promise.reject(e)
        }
    },
    async loadAgentRankings({ state, commit }, force) {
        if (!force && helpers.canSkipApiCall(state, 'agentRankings')) return

        try {
            commit('setLoading', { agentRankings: true })
            const rating = await agentService.rankings('rating')
            const matching = await agentService.rankings('matching')
            const successRate = await agentService.rankings('success_rate')
            commit('setAgentRankings', { rating, matching, successRate })
        } catch (e) {
            return Promise.reject(e)
        } finally {
            commit('setLoading', { agentRankings: false })
        }
    },
}

const mutations = {
    setAgents(state, agents) {
        state.agents = agents
    },
    setAgentRankings(state, agentRankings) {
        state.agentRankings = agentRankings
    },
    setAgentDetailParams(state, params) {
        state.agentDetailParams = params
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
    defaultState,
}
