<template>
    <PreferencesSelectType :type="'FRIEND'" />
</template>

<script>
import PreferencesSelectType from './components/PreferencesSelectType'

export default {
    name: 'PreferencesFriendTypePage',
    components: { PreferencesSelectType },
}
</script>
