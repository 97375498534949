<template>
    <div class="refund-policy">
        <StackRouterHeaderBar />
        <div class="policy-header">
            <div class="title" v-html="$translate('TICKET_REFUND_POLICY').replace('(재지급)', '')"></div>
        </div>
        <div class="policy-body m-t-40">
            <div
                class="policy-body-text-wrapper detail-page m-l-0 m-b-16"
                v-for="(titleItem, idx) in titleItems"
                :key="idx"
            >
                <div class="policy-body-title-wrapper">
                    <div class="policy-body-title" v-if="![4, 5, 6].includes(idx)">
                        <span class="brand-primary space">{{ idx + 1 }}.</span>
                    </div>
                    <div class="policy-body-title" v-html="$translate(titleItem).split('\n').join('<br>')"></div>
                </div>
                <div
                    class="desc m-b-16"
                    v-for="item in contentItems[idx]"
                    :key="item"
                    v-html="$translate(item).split('\n').join('<br>')"
                />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'RefundPolicyDetailsPage',
    data: () => {
        return {
            titleItems: [],
            contentItems: [[], [], [], [], [], []],
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            // 가독성 위해 나눠서 initialize
            for (let i = 1; i < 6; ++i) {
                this.titleItems.push(`REFUND_TITLE_ITEMS_${i}`)
            }

            for (let i = 1; i < 15; ++i) {
                if (i === 10) {
                    if (!this.$refundPolicySplit()) this.contentItems[0].push(`CONTENT_ITEMS_MANUAL_${i}`)
                    else this.contentItems[0].push(`CONTENT_ITEMS_MANUAL_STUDENT_10`)
                } else this.contentItems[0].push(`CONTENT_ITEMS_MANUAL_${i}`)
            }

            for (let i = 1; i < 9; ++i) {
                this.contentItems[1].push(`CONTENT_ITEMS_POSSIBLE_${i}`)
            }

            for (let i = 1; i < 15; ++i) {
                this.contentItems[2].push(`CONTENT_ITEMS_IMPOSSIBLE_${i}`)
            }

            for (let i = 1; i < 7; ++i) {
                this.contentItems[3].push(`CONTENT_ITEMS_PROCESS_${i}`)
            }

            for (let i = 1; i < 4; ++i) {
                this.contentItems[5].push(`CONTENT_ITEMS_REFUND_${i}`)
            }

            for (let i = 1; i < 3; ++i) {
                this.contentItems[4].push(`CONTENT_ITEMS_LEGAL_${i}`)
            }
        },
    },
}
</script>
