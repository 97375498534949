<template>
    <div class="signup basics email">
        <div class="content">
            <div class="title">이메일</div>
            <div class="body">
                <div class="input-wrapper">
                    <input
                        ref="email"
                        type="email"
                        class="flex-fill"
                        :placeholder="$translate('PLACEHOLDER_EMAIL')"
                        v-model="email"
                        @keydown="onKeydown"
                    />
                    <i v-if="email" class="material-icons" @click="email = ''">cancel</i>
                </div>
                <div class="error" v-if="error">
                    <i class="material-icons">error_outline</i>
                    이메일 형식이 올바르지 않습니다
                </div>
            </div>
        </div>
        <div class="button-wrapper">
            <button
                class="btn btn-primary"
                :class="{ disabled: disabled }"
                @click="next"
                v-html="$translate('NEXT')"
            ></button>
        </div>
    </div>
</template>

<script>
import userService from '@/services/user'

export default {
    name: 'EmailPage',
    data: () => ({
        error: null,
        disabled: true,
        email: '',
    }),
    mounted() {
        this.email = this.$store.getters.payloads.signupData.email
        this.$refs.email.focus()
        this.checkEmailFormat(this.email)

        this.$nativeBridge.postMessage({
            action: 'sendFirebaseEvent',
            value: {
                category: 'EmailPage_Enter_EmailPage',
                option: {
                    provider: this.$store.getters.payloads.signupData.provider || 'email',
                },
            },
        })
    },
    methods: {
        onKeydown() {
            setTimeout(() => {
                this.email = this.$refs.email.value
                this.checkEmailFormat()
            })
        },
        checkEmailFormat() {
            if (!this.email) {
                this.error = null
                this.disabled = true
                return
            }

            const regValidEmail = /[a-zA-Z0-9][\w.-]+@[\w-]+\.[a-zA-Z]{2,4}(\.[a-zA-Z]{2,4})?/
            if (!regValidEmail.test(this.email)) {
                this.error = '이메일 형식이 올바르지 않습니다'
                this.disabled = !!this.error
                return
            }

            this.error = null
            this.disabled = !!this.error
        },
        async next() {
            if (this.error || this.disabled) return

            const temp = this.email
            const { result, msg } = await userService.checkEmail(temp)
            if (!result) return this.$toast.error(msg)

            this.$store.commit('setPayloads', {
                signupData: {
                    email: temp,
                },
            })

            const thirdPartySignInData = this.$store.getters.thirdPartySignInData
            // 이메일로 가입은 패스워드페이지
            // 페북이나 애플가입은 이메일 없는 경우 입력하고 성별페이지
            // 카카오는 이메일 없는 경우 입력하고 분기처리
            if (thirdPartySignInData) {
                if (thirdPartySignInData.provider.match(/kakao/i)) {
                    if (thirdPartySignInData.gender === null || thirdPartySignInData.gender.name === null) {
                        this.$router.push({ name: 'GenderPage' })
                    } else if (thirdPartySignInData.birthyear === null || thirdPartySignInData.birthday === null) {
                        this.$router.push({ name: 'BirthdayPage' })
                    } else {
                        this.$router.push({ name: 'NicknamePage' })
                    }
                } else {
                    this.$router.push({ name: 'GenderPage' })
                }
            } else {
                this.$router.push({ name: 'PasswordPage' })
            }
        },
    },
}
</script>
