import commonService from '@/services/common'

const defaultState = () => ({
    feedbackPayload: {
        code: null,
        feedbacks: null,
        message_id: null,
    },
    feedbackOptions: null,
    feedbackDating: null,
    selectedFeedbacks: {
        stopReason: null,
        recommendable: null,
        contact: null,
        sincerity: null,
        insincerity: null,
        manner: null,
        noManner: null,
    },
})

const state = defaultState()

const getters = {
    feedbackPayload: state => state.feedbackPayload,
    feedbackOptions: state => state.feedbackOptions,
    feedbackDating: state => state.feedbackDating,
    selectedFeedbacks: state => state.selectedFeedbacks,
}

const actions = {
    async loadFeedbackOptions({ state, commit }) {
        try {
            if (state.feedbackOptions) {
                return
            }

            const options = await commonService.feedbackOption()
            commit('setFeedbackOptions', options)

            return options
        } catch (e) {
            return Promise.reject(e)
        }
    },
    async filterFeedbackOptionByCategory({ state, dispatch }, category) {
        let options = [...state.feedbackOptions]

        if (options.length === 0) {
            options = await dispatch('loadFeedbackOptions')
        }

        return options.filter(option => option.category === category)
    },
    prepareFeedbackPayload({ state, commit }) {
        let feedbacks = []
        for (const value of Object.values(state.selectedFeedbacks)) {
            if (value) {
                feedbacks = feedbacks.concat(value)
            }
        }

        commit('setFeedbackPayload', { feedbacks })

        const payload = state.feedbackPayload
        const dating = state.feedbackDating

        return { payload, dating }
    },
}

const mutations = {
    setFeedbackOptions(state, options) {
        state.feedbackOptions = [...options]
    },
    setFeedbackPayload(state, payload) {
        const p = state.feedbackPayload
        state.feedbackPayload = { ...p, ...payload }
    },
    setFeedbackDating(state, dating) {
        if (!dating) return

        state.feedbackDating = dating
    },
    setFeedbackDataNull(state) {
        state.feedbackPayload = { code: null, feedbacks: null, message_id: null }
        state.feedbackDating = null
    },
    setSelectedFeedbacks(state, { category, feedbacks }) {
        state.selectedFeedbacks[category] = feedbacks
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
    defaultState,
}
