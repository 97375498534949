<template>
    <div class="agent-ratings">
        <div class="rating-review">
            <div class="head flex-row items-center m-b-20">
                <span class="title f-medium">평점</span>
                <span v-if="ratings && (ratings.review || []).length > 1" @click="onClickMore" class="flex-wrap more">
                    {{ 'MORE' | translate }}
                    <i class="material-icons flex-wrap">keyboard_arrow_right</i>
                </span>
            </div>

            <div class="body">
                <div class="m-l-16 m-b-16 flex-row items-center">
                    <StarRating
                        class="avg-rating flex-wrap"
                        :inactiveIcon="require('@/assets/images/rating/icon_star_rate_inactive_gray_bg.svg')"
                        :default="ratings ? ratings.avg : 0"
                        :fixed="true"
                    />
                    <span
                        class="rating m-l-8"
                        :class="{ 'evaluating f-regular': !ratings, 'f-bold': ratings }"
                        v-html="ratings ? ratings.avg.toFixed(1) : '측정중'"
                    />
                </div>
                <div v-if="ratings && (ratings.review || []).length === 0" class="no-review">
                    아직 등록된 리뷰가 없어요 😥
                </div>
                <Carousel
                    v-if="ratings && (ratings.review || []).length > 0"
                    ref="carousel"
                    :per-page="1"
                    :space-padding="16"
                >
                    <Slide class="review-item" :key="review.id" v-for="review in ratings.review.slice(0, 5)">
                        <UserReview :shorter="true" :review="review" />
                    </Slide>
                </Carousel>
            </div>
        </div>
    </div>
</template>

<script>
import UserReview from './UserReview'

export default {
    name: 'AgentRatings',
    props: ['agent'],
    components: { UserReview },
    computed: {
        ratings() {
            return this.agent.ratings
        },
    },
    methods: {
        onClickMore() {
            this.$stackRouter.push({
                name: 'AgentRatingsReviewsPage',
                props: {
                    ratings: this.ratings,
                },
            })
        },
    },
}
</script>
