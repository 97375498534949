<template>
    <div class="signup additional-profiles signup-check">
        <div class="content">
            <div class="title">이미 가입하셨나요?</div>
            <div class="body">
                <div
                    v-if="checkType === 'email'"
                    class="body-text-1 m-b-8"
                    v-html="
                        `${
                            email.split('@')[0].substring(0, 3) + '****@' + email.split('@')[1] || ''
                        }로 가입한 적이 있나요?`
                    "
                />
                <div
                    v-if="checkType === 'phone'"
                    class="body-text-1 m-b-8"
                    v-html="
                        `${
                            phoneNumber.substring(0, 3) +
                                '-' +
                                phoneNumber.substring(3, 5) +
                                '**-' +
                                phoneNumber.substring(7, 9) +
                                '**' || ''
                        }로 가입한 적이 있나요?`
                    "
                />
                <div
                    class="body-text-2 m-b-32"
                    v-html="`주민등록번호를 입력하시면<br>기존 회원으로 서비스를 이용할 수 있어요!`"
                />
                <div class="flex-row">
                    <div class="input-wrapper front">
                        <input
                            ref="birth"
                            type="tel"
                            class="flex-fill"
                            v-model="birth"
                            maxlength="6"
                            @keydown="onKeydown"
                        />
                    </div>
                    <div class="bar" />
                    <div class="input-back flex-row">
                        <div class="input-wrapper back">
                            <input
                                ref="gender"
                                type="tel"
                                class="flex-fill input-gender"
                                v-model="gender"
                                maxlength="1"
                            />
                        </div>
                        <div class="dot" />
                        <div class="dot" />
                        <div class="dot" />
                        <div class="dot" />
                        <div class="dot" />
                        <div class="dot" />
                    </div>
                </div>
            </div>
        </div>
        <div class="button-wrapper">
            <button
                class="btn m-b-12 btn-primary"
                :class="{ disabled: disabled }"
                :disabled="disabled"
                @click="signin"
                v-html="'로그인하기'"
            ></button>
            <div class="bottom-comment" v-html="$translate('SIGNUP_CHECK_BOTTOM_COMMENT')" />
            <!-- <button class="btn grey-border" @click="signup" v-html="'신규 회원 가입'"></button> -->
        </div>
    </div>
</template>

<script>
import debounce from '@/modules/debounce'
import userService from '@/services/user'

export default {
    name: 'SignupCheckPage',
    props: [],
    data: () => ({
        provider: null,
        birth: null,
        gender: null,
        email: '',
        phoneNumber: '',
        uid: null,
        checkType: null,
    }),
    created() {},
    mounted() {
        this.provider = this.$route.params.provider
        this.email = this.$route.params.email
        this.phoneNumber = this.$route.params.phoneNumber
        this.uid = this.$route.params.uid
        this.checkType = this.$route.params.checkType
        if (this.checkType === 'email') {
            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: 'Enter_OnlyMailMatchPage',
                },
            })
        } else if (this.checkType === 'phone') {
            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: 'Enter_OnlyPhoneMatchPage',
                },
            })
        }

        this.$nextTick(() => this.$refs.birth.focus())
    },
    computed: {
        disabled() {
            return !this.birth || this.birth.length < 6 || !this.gender || this.gender.length < 1
        },
        chat() {
            return this.$store.getters.chat || {}
        },
    },
    methods: {
        async signin() {
            const payload = {
                birth: this.birth,
                gender: this.gender === 1 || this.gender === 3 ? 0 : 1,
                email: this.email,
                phone_number: this.phoneNumber,
                uid: this.uid,
                provider: 'kakao',
                device_id: this.$store.getters.device.id,
            }

            if (this.checkType === 'email') {
                this.$nativeBridge.postMessage({
                    action: 'sendFirebaseEvent',
                    value: {
                        category: 'OnlyMailMatchPage_Click_Login',
                    },
                })
            } else if (this.checkType === 'phone') {
                this.$nativeBridge.postMessage({
                    action: 'sendFirebaseEvent',
                    value: {
                        category: 'OnlyPhoneMatchPage_Click_Login',
                    },
                })
            }
            try {
                this.$loading(true)
                const res = await userService.checkBirth(payload)

                if (res.user && res.res === 'sign_in') {
                    await this.$store.dispatch('signInThirdParty', { user: res.user })
                } else if (res.res === 'error' || res.res === 'already_agent') {
                    this.$toast.error(res.msg)
                }
            } catch (e) {
                this.$toast.error(e.data)
            } finally {
                this.$loading(false)
            }
        },
        onKeydown: debounce(function () {
            this.birth = this.$refs.birth.value
            if (this.birth.length >= 6) {
                this.$refs.gender.focus()
            }
        }, 0),
        signup() {
            const thirdPartySignInData = this.$store.getters.thirdPartySignInData

            const gender =
                thirdPartySignInData.gender === '남성'
                    ? { name: 'MALE' }
                    : thirdPartySignInData.gender === '여성'
                    ? { name: 'FEMALE' }
                    : { name: thirdPartySignInData.gender }
            const payload = {
                email: thirdPartySignInData.email,
                uid: thirdPartySignInData.uid,
                provider: 'kakao',
                // provider: this.provider,
                token: thirdPartySignInData.token,
                birthyear: thirdPartySignInData.birthyear,
                birthday: thirdPartySignInData.birthday,
                gender: gender,
                phone_number: thirdPartySignInData.phone_number,
            }

            this.$store.commit('setPayloads', { signupData: payload })

            if (gender === null) {
                this.$router.push({ name: 'GenderPage' })
            } else if (thirdPartySignInData.birthyear === null || thirdPartySignInData.birthday === null) {
                this.$router.push({ name: 'BirthdayPage' })
            } else {
                this.$router.push({ name: 'NicknamePage' })
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.signup-check {
    .content {
        .body {
            .bar {
                margin-left: 10px;
                margin-right: 10px;
                max-width: 8px;
                height: 1px;
                background-color: $grey-05;
                align-self: center;
            }
            .dot {
                margin-left: 2px;
                margin-right: 2px;
                width: 10px;
                max-width: 10px;
                height: 10px;
                border-radius: 5px;
                background-color: $grey-05;
                align-self: center;
            }
            .front {
                width: 20px;
            }
            .input-back {
                max-width: 45%;
                .back {
                    width: 30px;
                    margin-right: 10px;
                    .input-gender {
                        margin-right: 0px;
                    }
                }
            }
        }
    }
    .bottom-comment {
        color: $grey-09;
        font-size: 12px;
        text-align: center;
    }
    // .grey-border {
    //     border: solid 1px $grey-03 !important;
    //     @include f-regular;
    // }
}
</style>
