<template>
    <div class="basic-profile">
        <div style="z-index: -1" class="watermark">
            <div class="" style="text-align: center">{{ phoneNumber }}</div>
            <div class="flex-row">
                <div class="pn">{{ phoneNumber }}</div>
                <div class="pn">{{ phoneNumber }}</div>
                <div class="pn">{{ phoneNumber }}</div>
                <div class="pn">{{ phoneNumber }}</div>
            </div>
            <div class="flex-row">
                <div class="pn">{{ phoneNumber }}</div>
                <div class="pn">{{ phoneNumber }}</div>
                <div class="pn">{{ phoneNumber }}</div>
                <div class="pn">{{ phoneNumber }}</div>
            </div>
            <div class="flex-row">
                <div class="pn">{{ phoneNumber }}</div>
                <div class="pn">{{ phoneNumber }}</div>
                <div class="pn">{{ phoneNumber }}</div>
                <div class="pn">{{ phoneNumber }}</div>
            </div>
            <div class="" style="text-align: center">{{ phoneNumber }}</div>
        </div>
        <h3 class="title" v-html="$translate('BASIC_INFORMATION')" />
        <ul style="z-index: 2" class="items" v-for="(group, idx) in profileGroup" :key="`${group}${idx}`">
            <hr v-if="idx > 0" class="group-divider" />
            <li class="item" v-for="[key, value] in group" :key="key" v-show="value.content">
                <template v-if="key === 'JOB_DESCRIPTION'">
                    <div class="job-description">
                        <p class="desc" :class="{ 'lines-3': shouldClamp }" v-html="value.content" ref="description" />
                        <span
                            v-if="shouldClamp"
                            class="more"
                            v-html="$translate('MORE')"
                            @click="shouldClamp = false"
                        />
                    </div>
                </template>
                <template v-else>
                    <div class="key" v-html="$translate(key)" />
                    <div class="value">
                        <span class="content">{{ value.content }}</span>
                        <span v-if="value.verified" class="verified-badge">
                            <i class="material-icons">check</i>
                            인증
                        </span>
                    </div>
                    <Mbti
                        v-if="!isMe && key === 'MBTI'"
                        class="m-l-5"
                        :user-mbti="$store.getters.me.profile.mbti || ''"
                        :target-mbti="value.content"
                    />
                </template>
            </li>
        </ul>
    </div>
</template>

<script>
import Mbti from '@/components/app/Mbti'
export default {
    name: 'BasicProfile',
    components: { Mbti },
    props: {
        profile: {
            type: Object,
            required: true,
        },
        userId: {
            type: Number,
            required: true,
        },
        verificationBadges: {
            type: Array,
        },
    },
    data: () => ({
        profileGroup: null,
        shouldClamp: true,
    }),
    watch: {
        userId: 'init',
        profile: 'init',
    },
    mounted() {
        this.init()
    },
    computed: {
        me() {
            return this.$store.getters.me || {}
        },
        phoneNumber() {
            return this.me.phone_number
        },
        isMe() {
            return this.userId === this.me.id
        },
    },
    methods: {
        init() {
            this.populateProfile()
            this.$nextTick(() => this.checkOverflown())
        },
        populateProfile() {
            const group1 = new Map()
            const group2 = new Map()
            const group3 = new Map()

            group1.set('JOB_NAME', {
                content: this.profile.job_name,
                verified: this.verificationBadges.some(vb => vb.verification === 'job'),
            })

            let companyString = `${this.profile.job_category.name}`

            if (this.profile.job_type_name) {
                if (this.profile.company_name) {
                    companyString += ` (${this.profile.company_name} - ${this.profile.job_type_name})`
                } else {
                    companyString += ` (${this.profile.job_type_name})`
                }
            }

            group1.set('COMPANY_NAME', {
                content: companyString,
                verified: this.verificationBadges.some(vb => vb.verification === 'company'),
            })

            group1.set('JOB_DESCRIPTION', { content: this.profile.job_description })

            let schoolString = ''

            // 고등학교 졸업 및 기타의 경우
            if (this.profile.school.id === 5) {
                schoolString = '고등학교 졸업'
            } else {
                schoolString += this.profile.school_type.name

                if (this.profile.university_name && this.profile.major_name) {
                    schoolString += ` (${this.profile.university_name} - ${this.profile.major_name})`
                } else if (this.profile.university_name) {
                    schoolString += ` (${this.profile.university_name})`
                }

                // 대학원 재학/졸업의 경우
                if (this.profile.school_id === 3 || this.profile.school_id === 4) {
                    schoolString += ` ${this.profile.school.name}`
                } else {
                    if (this.profile.is_student) {
                        schoolString += ' 재학'
                    } else {
                        schoolString += ' 졸업'
                    }
                }
            }

            group1.set('UNIVERSITY', {
                content: schoolString,
                verified: this.verificationBadges.some(vb => vb.verification === 'school'),
            })

            // if (this.me.id % 2 === 1) {
            group2.set('KIND', { content: this.profile.kind })
            group2.set('SHAPE', { content: this.profile.shape })
            // }

            group2.set('MBTI', { content: this.profile.mbti })
            group2.set('SMOKING', { content: !this.profile.smoking ? '비흡연' : '흡연' })
            group2.set('RELIGION_NAME', { content: this.profile.religion_name })

            if (this.profile.car) {
                group3.set('CAR', { content: '보유' })
            }
            if (this.profile.pet && this.profile.pet !== '키우지않아요') {
                group3.set('PET', { content: this.profile.pet })
            }

            this.profileGroup = [group1, group2]
            if (group3.size !== 0) this.profileGroup.push(group3)
        },
        checkOverflown() {
            const dom = this.$refs.description[0]
            if (dom) {
                this.shouldClamp = dom.scrollHeight - 2 > dom.clientHeight
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.basic-profile {
    z-index: 2;
    position: relative;
    overflow: hidden;

    .watermark {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.05);
        position: absolute;
        height: 100%;
        width: 130%;
        transform: rotate(-45deg);
        top: 0;
        left: -15%;

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .pn {
            margin-right: 35px;
        }
    }

    .title {
        margin-bottom: 24px;
        color: black;
        font-size: 18px;
        font-weight: 500;

        @include f-medium;
    }

    .group-divider {
        border-color: $grey-01;
        margin-bottom: 28px;
    }

    .item {
        display: flex;
        margin-bottom: 24px;
    }

    .key {
        font-size: 15px;
        color: $grey-06;
        width: 82px;

        @include flex-wrap;
    }

    .value {
        $content-line-height: 17.25px;
        display: flex;
        line-height: $content-line-height;

        .content {
            color: black;
        }

        .verified-badge {
            display: flex;
            align-self: flex-start;
            align-items: center;
            margin-left: 8px;
            color: $purple-primary;
            font-size: 12px;

            @include flex-wrap;
            @include f-medium;

            .material-icons {
                font-size: 10px;
                border-radius: 50%;
                border: solid 1px $purple-primary;
                margin-right: 2px;
            }
        }
    }

    .job-description {
        margin-top: -12px;
        margin-left: 82px;
        padding: 12px 14px;
        width: 100%;
        border: solid 1px $grey-02;
        border-radius: 0 12px 12px;
        font-size: 14px;
        line-height: normal;
        letter-spacing: -0.2px;
        align-items: flex-end;
        white-space: pre-line;

        @include flex;

        .desc {
            color: $grey-09;
            align-self: flex-start;
        }

        .more {
            color: $purple-primary;
            text-decoration: underline;
        }
    }
}
</style>
