<template>
    <div v-if="chat.suggest_count" class="instant-user" :class="{ fold }">
        <div class="top" @click="onClickInfo">
            <div class="title">
                <p class="f-13">소개 중인 회원</p>
                <i class="material-icons">info</i>
                <i class="material-icons f-20" @click.stop="onClickFoldBtn">{{ expandIcon }}</i>
            </div>
            <div class="timer">
                <i class="material-icons c-white">schedule</i>
                <span>{{ expireAt }} 후 종료</span>
            </div>
            <ul v-show="showSnackbar" class="suggest-snackbar">
                <li class="m-b-16">
                    <div class="info">
                        <div class="dot" />
                        <p class="content">주선자가 소개 중인 회원을 확인할 수 있어요</p>
                    </div>
                    <p class="detail">3일 안에 소개팅이 성사되지 않으면, 목록에서 사라져요.</p>
                </li>
                <li>
                    <div class="info">
                        <div class="dot" />
                        <p class="content">대화방은 3일 동안 유지돼요</p>
                    </div>
                    <p class="detail">
                        다른 회원을 추가로 소개받으면 다시 3일 동안 유지돼요.<br />
                        소개팅이 성사되면, 12일 동안 유지돼요.
                    </p>
                </li>
            </ul>
        </div>

        <ul class="suggests" :class="{ fold }">
            <li class="item" v-for="suggest in userSuggests" :key="suggest.id" @click="onClickSuggest(suggest)">
                <div class="photo-wrapper">
                    <div v-if="suggest.user.premium" class="premium-user">
                        <img :src="require('@/assets/images/premium/premium_gold_crown.svg')" />
                    </div>
                    <img class="photo" :src="suggest.user.photo_url" @error="$event.target.src = $blankProfile" />
                </div>
                <span class="nickname">{{ suggest.user.nickname }}</span>
            </li>
        </ul>
    </div>
</template>

<script>
import suggestService from '@/services/suggest'

export default {
    name: 'InstantUser',
    data: () => ({
        userSuggests: [],
        showSnackbar: false,
        fold: false,
    }),
    computed: {
        chat() {
            return this.$store.getters.chat
        },
        expireAt() {
            const expire = this.$moment(this.chat.expire_at).diff(this.$moment(), 'days')
            return expire > 0
                ? `${expire + 1}일`
                : `${this.$moment(this.chat.expire_at).diff(this.$moment(), 'hours')}시간`
        },
        expandIcon() {
            return `expand_${this.fold ? 'more' : 'less'}`
        },
    },
    methods: {
        async init() {
            try {
                this.userSuggests = await suggestService.userSuggests(this.chat.agent_id)
                this.makePremiumUsersArray()
            } catch (e) {
                this.$toast.error(e.data)
            }
        },
        makePremiumUsersArray() {
            const premiumUsers = []
            this.userSuggests.forEach(user => {
                const {
                    user: { premium_info: premiumInfo },
                } = user
                if (premiumInfo) {
                    premiumUsers.push({
                        id: user.source_id,
                        info: premiumInfo,
                    })
                }
            })

            this.$store.commit('setInstantRoomPremiumUsers', premiumUsers)
        },
        onClickFoldBtn() {
            const chatBody = document.querySelector('.chat-body')
            this.fold = !this.fold
            if (this.fold) {
                chatBody.classList.remove('m-t-120')
                chatBody.classList.add('m-t-40')
            } else {
                chatBody.classList.remove('m-t-40')
                chatBody.classList.add('m-t-120')
            }
        },
        onClickSuggest(suggest) {
            this.$stackRouter.push({
                name: 'UserDetailPage',
                props: {
                    photos: [{ url: suggest.user.photo_url }],
                    userId: suggest.user.id,
                    suggest,
                },
            })
        },
        onClickInfo() {
            this.showSnackbar = !this.showSnackbar
        },
    },
    created() {
        this.init()
    },
}
</script>

<style lang="scss" scoped>
.instant-user {
    padding: 8px 16px;
    background-color: white;
    border-bottom: solid 1px $grey-03;

    &.fold {
        padding-bottom: 0;
    }

    .top {
        position: relative;
        margin-bottom: 10px;
        @include between;

        color: black;
        font-size: 12px;
        @include f-medium;

        .title {
            display: flex;
            align-items: center;

            .material-icons {
                font-size: 16px;
                margin-left: 4px;
            }
        }

        .timer {
            @extend .title;
            background: $grey-09;
            border-radius: 14px;
            padding: 5px 10px;
            color: white;
            font-size: 12px;
            @include f-bold;

            .material-icons {
                font-size: 16px;
                margin-right: 4px;
            }
        }
    }

    .suggest-snackbar {
        position: absolute;
        width: calc(100vw - 32px);
        top: 22px;
        background-color: white;
        padding: 16px;
        border-radius: 12px;
        box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.2), 0 1px 4px 0 rgba(0, 0, 0, 0.05);
        z-index: 1;

        .info {
            display: flex;
            align-items: center;
        }

        .dot {
            width: 4px;
            height: 4px;
            border-radius: 1px;
            background-color: $blue-suggest;
            margin-right: 10px;
        }

        .content {
            font-size: 14px;
            color: black;

            @include f-medium;
        }

        .detail {
            margin-top: 4px;
            margin-left: 14px;
            font-size: 12px;
            color: $grey-08;
        }
    }

    .suggests {
        display: flex;
        align-items: center;

        &.fold {
            display: none;
        }
    }

    .item {
        display: flex;
        flex-direction: column;
        align-items: center;

        &:not(:last-child) {
            margin-right: 16px;
        }

        .photo-wrapper {
            position: relative;

            .premium-user {
                position: absolute;
                top: 0;
                width: 18px;
                height: 18px;
                border-radius: 50%;
                background: white;
                border: 1.5px solid $yellow-premium;
                @include center;
            }
            .photo {
                width: 60px;
                height: 60px;
                border-radius: 40%;
                border: solid 1px $grey-02;
            }
        }
        .nickname {
            font-size: 12px;
            color: $grey-09;

            @include f-regular;
        }
    }
}
</style>
