<template>
    <ProfileOptionSelector :model="'state'" :nextRoute="item => (item.is_station ? 'StationPage' : 'RegionPage')" />
</template>

<script>
export default {
    name: 'StatePage',
    mounted() {
        this.$nativeBridge.postMessage({
            action: 'sendFirebaseEvent',
            value: {
                category: 'StatePage_Enter_StatePage',
                option: {
                    provider: this.$store.getters.payloads.signupData.provider || 'email',
                },
            },
        })
    },
}
</script>
