<template>
    <div class="agent-verification flex items-start">
        <div class="verification-row" :key="item.key" v-for="item in items">
            <img :src="item.img" />
            <div class="text" v-html="item.text" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'AgentVerification',
    props: ['agent'],
    computed: {
        items() {
            return [
                {
                    key: 'educated',
                },
                {
                    key: 'profile_verified',
                },
            ].map(o => ({
                img: require(`@/assets/images/agent-detail/${o.key}.svg`),
                text: this.$translate(o.key.toUpperCase()),
            }))
        },
    },
}
</script>
