<template>
    <div class="agent-ratings-reviews">
        <StackRouterHeaderBar />
        <div v-if="ratings" class="head flex-row">
            <div class="rating flex items-center flex-wrap">
                <span class="rating-number f-bold m-b-4">{{ ratings.avg.toFixed(1) }}</span>
                <StarRating
                    class="rating-star"
                    :default="ratings.avg"
                    :fixed="true"
                    :inactiveIcon="require('@/assets/images/rating/icon_star_rate_inactive_gray_bg.svg')"
                />
            </div>
            <div class="divider flex-wrap"></div>
            <div class="rating-options">
                <div :key="ro" v-for="(ro, i) in ratingOptions" class="row flex-row items-start">
                    <span class="index center flex-wrap">{{ i + 1 }}</span>
                    <span class="rating-option flex-fill">{{ ro }}</span>
                </div>
            </div>
        </div>

        <div class="body">
            <div class="title m-b-16">
                <span class="f-bold">{{ (ratings.review || []).length }}개</span>의 긍정 리뷰
            </div>
            <UserReview
                :key="review.id"
                v-for="review in ratings.review"
                class="review-container m-b-12"
                :review="review"
            />
        </div>
    </div>
</template>

<script>
import UserReview from '@/routes/agent-detail/components/UserReview'

export default {
    name: 'AgentRatingsReviewsPage',
    components: {
        UserReview,
    },
    props: {
        ratings: Object,
    },
    data: () => ({
        ratingOptions: [],
    }),
    mounted() {
        if (!this.ratings) return this.$stackRouter.pop()

        this.init()
    },
    methods: {
        init() {
            const arr = []
            for (const [key, value] of Object.entries(this.ratings.options)) {
                arr.push({ value, content: key })
            }

            this.ratingOptions = arr.sort((a, b) => b.value - a.value).map(sa => sa.content)
        },
    },
}
</script>

<style lang="scss" scoped>
.agent-ratings-reviews {
    .head {
        padding: 0 16px;
        align-items: center;

        .rating {
            .rating-number {
                font-size: 40px;
                line-height: 56px;
                margin-right: 20px;
                color: $purple-primary;
            }

            .rating-star {
                .star-wrapper {
                    width: 18px;
                    height: 18px;
                }
            }
        }

        .divider {
            margin: 12px 20px 0;
            width: 1px;
            height: 68px;
            background-color: $grey-02;
        }

        .rating-options {
            padding-top: 8px;

            .row {
                .index {
                    width: 14px;
                    height: 14px;
                    border-radius: 4px;
                    background-color: $grey-05;
                    margin-right: 10px;
                    font-size: 10px;
                    color: white;
                    font-family: NotoSans-Bold;
                }

                .rating-option {
                    color: $grey-09;
                    font-size: 13px;
                    line-height: 18px;
                }

                &:not(:last-child) {
                    margin-bottom: 12px;
                }
            }
        }
    }

    .body {
        padding: 0 16px 16px;
        margin-top: 28px;

        .title {
            color: $grey-09;
        }
    }
}
</style>
