<template>
    <div class="edit-profile-menu">
        <ul class="list" v-for="category in Object.keys(menu)" :key="category">
            <h3 class="category" v-html="$translate(category)" />
            <li class="item" v-for="item in menu[category]" :key="item.key" @click="item.handler()">
                <div class="key">{{ $translate(item.key) }}</div>
                <div class="status">{{ item.status }}</div>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'EditProfileMenu',
    computed: {
        me() {
            return this.$store.getters.me || {}
        },
        noOnsParticipate() {
            return this.me.profile.no_ons_campaign || 0
        },
        settings() {
            return this.$store.getters.settings || {}
        },
        styleCompleted() {
            const { style } = this.me

            if (!style) return false

            return Object.values(style).every(val => val !== '' && val !== null)
        },
        profileUpdateRequested() {
            return this.me.profile_update_request !== null
        },
        menu() {
            if (!this.me) return {}
            if (this.$isTester())
                return {
                    EDIT_PROFILE: [
                        {
                            key: 'PHOTO',
                            status: `${(this.me.photos || []).length}/6`,
                            handler: () => {
                                if (this.profileUpdateRequested) {
                                    this.$toast.error('프로필 심사중 입니다')
                                    this.$nativeBridge.postMessage({
                                        action: 'sendFirebaseEvent',
                                        value: {
                                            category: 'SettingPage_Click_Photo_error',
                                        },
                                    })
                                    return
                                }

                                this.$nativeBridge.postMessage({
                                    action: 'sendFirebaseEvent',
                                    value: {
                                        category: 'SettingPage_Click_Photo',
                                    },
                                })

                                this.$stackRouter.push({
                                    name: 'EditProfilePage',
                                    props: { initialTab: 'PHOTO' },
                                })
                            },
                        },
                        {
                            key: 'PROFILE_INTRODUCTION',
                            status: this.settings.profile_completed ? '' : '미완성',
                            handler: () => {
                                if (this.profileUpdateRequested) {
                                    this.$toast.error('프로필 심사중 입니다')
                                    this.$nativeBridge.postMessage({
                                        action: 'sendFirebaseEvent',
                                        value: {
                                            category: 'SettingPage_Click_ProfileUpdate_error',
                                        },
                                    })
                                    return
                                }

                                this.$nativeBridge.postMessage({
                                    action: 'sendFirebaseEvent',
                                    value: {
                                        category: 'SettingPage_Click_ProfileUpdate',
                                    },
                                })
                                this.$stackRouter.push({
                                    name: 'EditProfilePage',
                                    props: { initialTab: 'PROFILE' },
                                })
                            },
                        },
                        {
                            key: 'INTEREST_TAG',
                            handler: () => {
                                if (this.profileUpdateRequested) {
                                    this.$toast.error('프로필 심사중 입니다')
                                    this.$nativeBridge.postMessage({
                                        action: 'sendFirebaseEvent',
                                        value: {
                                            category: 'SettingPage_Click_TasteTag_error',
                                        },
                                    })
                                    return
                                }

                                this.$nativeBridge.postMessage({
                                    action: 'sendFirebaseEvent',
                                    value: {
                                        category: 'SettingPage_Click_TasteTag',
                                    },
                                })

                                this.$stackRouter.push({
                                    name: 'EditProfilePage',
                                    props: { initialTab: 'INTEREST_TAG' },
                                })
                            },
                        },
                        {
                            key: 'BADGE',
                            handler: () => {
                                this.$nativeBridge.postMessage({
                                    action: 'sendFirebaseEvent',
                                    value: {
                                        category: 'SettingPage_Click_Badge',
                                    },
                                })
                                this.$stackRouter.push({ name: 'VerificationBadgesPage' })
                            },
                        },
                    ],
                    DATING: [
                        {
                            key: 'STYLE',
                            status: this.styleCompleted ? '' : '미완성',
                            handler: () => this.$stackRouter.push({ name: 'EditStylePage' }),
                        },
                        {
                            key: 'DATING_PREFERENCE',
                            status: `${
                                Array.from(new Set((this.me.dating_preferences || []).map(x => x.dating_preference_id)))
                                    .length
                            }/${this.settings.dating_preference_max}`,
                            handler: () => this.$stackRouter.push({ name: 'EditDatingPreferencePage' }),
                        },
                    ],
                    ETC: [
                        {
                            key: 'GOODBYE_TO_ONS',
                            status: this.noOnsParticipate === 1 ? '참여완료' : '참여하기',
                            handler: () => {
                                if (this.noOnsParticipate === 0) {
                                    this.$stackRouter.push({ name: 'GoodbyeOnsCampaignPage' })
                                } else if (this.noOnsParticipate === -1) {
                                    this.$toast.error(this.$translate('CAMPAIGN_BANNED_USER'))
                                } else if (this.noOnsParticipate === 1) {
                                    this.$toast.success(this.$translate('CAMPAIGN_ALREADY_PARTICIPATED'))
                                }
                            },
                        },
                    ],
                }
            else
                return {
                    EDIT_PROFILE: [
                        {
                            key: 'PHOTO',
                            status: `${(this.me.photos || []).length}/6`,
                            handler: () => {
                                if (this.profileUpdateRequested) {
                                    this.$toast.error('프로필 심사중 입니다')
                                    this.$nativeBridge.postMessage({
                                        action: 'sendFirebaseEvent',
                                        value: {
                                            category: 'SettingPage_Click_Photo_error',
                                        },
                                    })
                                    return
                                }

                                this.$nativeBridge.postMessage({
                                    action: 'sendFirebaseEvent',
                                    value: {
                                        category: 'SettingPage_Click_Photo',
                                    },
                                })

                                this.$stackRouter.push({
                                    name: 'EditProfilePage',
                                    props: { initialTab: 'PHOTO' },
                                })
                            },
                        },
                        {
                            key: 'PROFILE_INTRODUCTION',
                            status: this.settings.profile_completed ? '' : '미완성',
                            handler: () => {
                                if (this.profileUpdateRequested) {
                                    this.$toast.error('프로필 심사중 입니다')
                                    this.$nativeBridge.postMessage({
                                        action: 'sendFirebaseEvent',
                                        value: {
                                            category: 'SettingPage_Click_ProfileUpdate_error',
                                        },
                                    })
                                    return
                                }

                                this.$nativeBridge.postMessage({
                                    action: 'sendFirebaseEvent',
                                    value: {
                                        category: 'SettingPage_Click_ProfileUpdate',
                                    },
                                })
                                this.$stackRouter.push({
                                    name: 'EditProfilePage',
                                    props: { initialTab: 'PROFILE' },
                                })
                            },
                        },
                        {
                            key: 'INTEREST_TAG',
                            handler: () => {
                                if (this.profileUpdateRequested) {
                                    this.$toast.error('프로필 심사중 입니다')
                                    this.$nativeBridge.postMessage({
                                        action: 'sendFirebaseEvent',
                                        value: {
                                            category: 'SettingPage_Click_TasteTag_error',
                                        },
                                    })
                                    return
                                }

                                this.$nativeBridge.postMessage({
                                    action: 'sendFirebaseEvent',
                                    value: {
                                        category: 'SettingPage_Click_TasteTag',
                                    },
                                })

                                this.$stackRouter.push({
                                    name: 'EditProfilePage',
                                    props: { initialTab: 'INTEREST_TAG' },
                                })
                            },
                        },
                        {
                            key: 'BADGE',
                            handler: () => {
                                this.$nativeBridge.postMessage({
                                    action: 'sendFirebaseEvent',
                                    value: {
                                        category: 'SettingPage_Click_Badge',
                                    },
                                })
                                this.$stackRouter.push({ name: 'VerificationBadgesPage' })
                            },
                        },
                    ],
                    DATING: [
                        {
                            key: 'STYLE',
                            status: this.styleCompleted ? '' : '미완성',
                            handler: () => this.$stackRouter.push({ name: 'EditStylePage' }),
                        },
                        {
                            key: 'DATING_PREFERENCE',
                            status: `${
                                Array.from(new Set((this.me.dating_preferences || []).map(x => x.dating_preference_id)))
                                    .length
                            }/${this.settings.dating_preference_max}`,
                            handler: () => this.$stackRouter.push({ name: 'EditDatingPreferencePage' }),
                        },
                    ],
                    ETC: [
                        {
                            key: 'GOODBYE_TO_ONS',
                            status: this.noOnsParticipate === 1 ? '참여완료' : '참여하기',
                            handler: () => {
                                if (this.noOnsParticipate === 0) {
                                    this.$stackRouter.push({ name: 'GoodbyeOnsCampaignPage' })
                                } else if (this.noOnsParticipate === -1) {
                                    this.$toast.error(this.$translate('CAMPAIGN_BANNED_USER'))
                                } else if (this.noOnsParticipate === 1) {
                                    this.$toast.success(this.$translate('CAMPAIGN_ALREADY_PARTICIPATED'))
                                }
                            },
                        },
                        {
                            key: 'DATING_REVIEW',
                            status: '보러가기',
                            handler: () => {
                                // this.$stackRouter.push({ name: 'DatingCandidateSelectPage' })
                                this.$stackRouter.push({ name: 'DatingReviewsPage' })
                            },
                        },
                    ],
                }
        },
    },
}
</script>

<style lang="scss" scoped>
.edit-profile-menu {
    .list {
        padding: 24px 16px 0;

        &:not(:last-child) {
            border-bottom: solid 1px $grey-01;
        }
    }

    .category {
        color: $grey-05;
        font-size: 14px;
        font-weight: normal;
        @include f-regular;
    }

    .item {
        padding: 20px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .key {
            color: black;
            font-size: 16px;
        }

        .status {
            color: $purple-primary;
            font-size: 16px;

            @include f-medium;
        }
    }
}
</style>
