<template>
    <div class="suggest-message">
        <div class="sender">
            <div class="avatar-wrapper">
                <img class="avatar" src="@/assets/images/chat/requested_dating.svg" />
            </div>
            <span class="suggested f-regular">소개팅 제안</span>
        </div>
        <SourceProfile
            class="m-l-32 m-b-8"
            :suggest="suggest"
            :photos="[suggest.photo1, suggest.photo2]"
            :source="suggest.source"
            :isOpened="isOpened"
            :rejected="isRejected"
            :accepted="isAccepted"
            :expired="isExpired"
            :isDday="isDday"
            @clickReject="onClickReject"
            @clickAccept="onClickAccept"
            @clickProfile="onClickProfile"
        />
        <AgentMessage class="m-l-32" :suggest="suggest" />
    </div>
</template>

<script>
import SourceProfile from './SourceProfile.vue'
import AgentMessage from './AgentMessage'
import SuggestMessage from '@/routes/chat/suggested-dating/SuggestMessage'

export default {
    name: 'SuggestMessage',
    components: { SourceProfile, AgentMessage },
    props: {
        suggest: {
            type: Object,
            required: true,
            default: () => ({}),
        },
    },
    setup({ suggest }, { emit }) {
        const { makeComputed } = SuggestMessage()

        // computed
        const { isRejected, isAccepted, isOpened, isExpired, isDday } = makeComputed(suggest)

        // method
        const onClickReject = () => emit('clickReject')
        const onClickAccept = () => emit('clickAccept')
        const onClickProfile = () => emit('clickProfile')

        return {
            isRejected,
            isAccepted,
            isOpened,
            isExpired,
            isDday,
            onClickReject,
            onClickAccept,
            onClickProfile,
        }
    },
}
</script>

<style lang="scss" scoped>
.suggest-message {
    .sender {
        display: flex;

        .avatar-wrapper {
            width: 28px;
            height: 28px;
            border-radius: 12px;
            border: solid 1px $grey-02;
            background-color: white;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 6px;

            .avatar {
                width: 18px;
                height: 18px;
            }
        }

        .suggested {
            font-size: 12px;
            color: $grey-08;
        }
    }

    .source-profile {
        max-width: 260px;
    }

    @media (max-width: 360px) {
        .source-profile {
            max-width: calc(100vw - 100px);
        }
    }
}
</style>
