<template>
    <div class="refund-2 center-refund">
        <div class="page-header">
            <div class="title">환급 절차</div>
        </div>
        <div class="steps">
            <div class="step m-b-12">
                <div class="flex-row items-center m-b-12">
                    <span class="circle"></span>
                    <span class="step-title">환급 신청 접수</span>
                </div>
                <div class="box">환급 사유와 첨부자료(스크린샷)를 제출하여, 환급 신청해 주세요!</div>
            </div>
            <div class="step important m-b-12">
                <div class="flex-row items-center m-b-12">
                    <span class="circle"></span>
                    <span class="step-title red">상대에게 환급 의사 전달</span>
                </div>
                <div class="box">환급 신청시, 만남 의사가 없음을 알리는 메시지가 상대에게 전송됩니다.</div>
            </div>
            <div class="step m-b-8">
                <div class="flex-row items-center m-b-8">
                    <span class="circle"></span>
                    <span class="step-title">주선자에게 확인 요청</span>
                </div>
                <div class="box"></div>
            </div>
            <div class="step m-b-8">
                <div class="flex-row items-center m-b-8">
                    <span class="circle"></span>
                    <span class="step-title">상대 의사 확인</span>
                </div>
                <div class="box"></div>
            </div>
            <div class="step m-b-12">
                <div class="flex-row items-center">
                    <span class="circle"></span>
                    <span class="step-title">소개팅권 환급 / 거절 처리</span>
                </div>
                <div class="box no-border">
                    주선자, 소개 상대와의 확인 절차 후 환급 처리됩니다. 환급 정책에 부합하지 않거나, 제출한 자료가
                    불충분할 경우 환급이 거절될 수 있어요.
                </div>
            </div>
        </div>
        <div class="bottom">
            <div class="flex items-start">
                <span class="please-check f-medium">확인해주세요</span>
                <span class="detail"
                    >환급 조건을 의도적으로 악용하거나 환급 절차를 준수하지 않을 경우 일차적으로 경고 조치, 이용 정지
                    처리가 가능하며, 사안의 심각성에 따라 법적 조치를 취할 수 있습니다.</span
                >
            </div>
            <button class="next-btn" @click="onClick">네, 잘 이해했어요</button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Refund2Page',
    computed: {
        refundType() {
            return this.$store.getters.refundType
        },
    },
    methods: {
        onClick() {
            if (this.refundType === 'realFriend') {
                this.$router.push({ name: 'Refund4ReasonPage' })

                return
            }

            this.$router.push({ name: 'Refund3DatingPage' })
        },
    },
}
</script>
