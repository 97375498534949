<template>
    <div class="evaluated-agent-profile">
        <PhotoWithBottomProBadge :src="agent.photos[0].url" />
        <div class="name-nickname m-t-28" v-html="nameNickname" />
        <div class="profile m-b-20" v-html="profile" />
        <div class="evaluated-rating flex-row items-center">
            <StarRating class="m-r-12" :length="1" :default="1" :fixed="true" />
            <span v-html="ratingToString" />
        </div>
    </div>
</template>

<script>
import PhotoWithBottomProBadge from './PhotoWithBottomProBadge'

export default {
    name: 'EvaluatedAgentProfile',
    components: { PhotoWithBottomProBadge },
    props: ['agent', 'starRating'],
    computed: {
        profile() {
            if (!this.agent) return ''

            return `${this.$translate(
                this.agent.gender === 0 ? 'MALE' : 'FEMALE',
            )}<span>ㅣ</span>${this.$options.filters.asAge(this.agent.profile.birthday)}세<span>ㅣ</span>${
                this.agent.profile.job_name
            }`
        },
        nameNickname() {
            if (!this.agent) return ''
            return `<span class="f-medium">${this.agent.name}</span>(${this.agent.profile.nickname})`
        },
        ratingToString() {
            const strings = ['별로예요', '아쉬워요', '보통이에요', '좋아요:)', '최고예요!']
            return `<strong>${this.starRating}점</strong>${strings[this.starRating - 1]}`
        },
    },
}
</script>

<style lang="scss" scoped>
.evaluated-agent-profile {
    padding: 28px 0 36px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .name-nickname {
        font-size: 15px;
        color: $grey-09;

        span {
            font-size: 16px;
        }
    }

    .profile {
        font-size: 14px;
        color: $grey-07;

        span {
            color: $gray-divider;
        }
    }

    .evaluated-rating {
        font-family: NotoSans-Medium;
        font-size: 15px;
        color: $grey-07;

        strong {
            font-family: NotoSans-Bold;
            color: $brand-gold;
            font-weight: normal;

            &::after {
                content: 'ㅣ';
                font-family: NotoSans-Medium;
                color: $grey-03;
            }
        }
    }
}
</style>
