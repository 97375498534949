import { computed, ref } from '@vue/composition-api'

const ChatsPage = () => {
    const getSuggestsUnreadsCount = suggests => {
        const suggestsVal = suggests.value || []
        return suggestsVal.filter(suggest => !suggest.user_checked && suggest.status === 'request').length
    }
    const chatType = chat => {
        if (isDateCoach(chat)) return 'DateCoachItem'

        return 'ChatItem'
    }
    const isDateCoach = chat => {
        return chat.is_coaching
    }

    const scrollTop = ref(0)

    // computed
    const makeComputed = getters => {
        const loading = computed(() => getters.loading.chats)
        const connected = computed(() => getters.connected)
        const chats = computed(() => {
            return (getters.chats || []).filter(chat => chat.users !== null) || []
        })
        const me = computed(() => getters.me)
        const settings = computed(() => getters.settings)
        const suggests = computed(() => {
            return getters.suggests || []
        })
        const suggestChatItem = computed(() => {
            // 변화를 감지하는 객체는 ref로 싸여있기 때문에 해당 값을 불러오려면 .value 체이닝 필요
            const suggestVal = suggests.value
            if (suggestVal.length === 0) return

            const recentSuggest = suggestVal[suggestVal.length - 1]

            return {
                unread: getSuggestsUnreadsCount(suggests),
                ...recentSuggest,
            }
        })

        return { loading, connected, chats, me, settings, suggests, suggestChatItem }
    }

    const onScroll = event => {
        scrollTop.value = event.target.scrollTop
    }

    return { makeComputed, chatType, isDateCoach, onScroll, scrollTop }
}

export default ChatsPage
