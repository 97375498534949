import * as $http from 'axios'

export default {
    all: () => $http.get('v2/products'),
    buy: (data, productId) => $http.post(`v2/products/${productId}/buy`, data),
    createOrder: (productId, payload) => $http.post(`v2/products/${productId}/orders`, payload),
    discountUsers: productType => $http.get(`v2/discount_users?ptype=${productType}`),
    inAppPurchase: (productId, payload) => $http.post(`v2/products/${productId}/inapp_purchase`, payload),
    inAppConfirm: (productId, payload) => $http.post(`v2/products/${productId}/inapp_confirm`, payload),
}
