import * as $http from 'axios'

export default {
    detail: (userId, agentId) => {
        const params = {}
        if (agentId) params.agent_id = agentId

        return $http.get(`v2/users/${userId}`, { params })
    },
    me: () => $http.get('v2/users/me'),
    updateTerms: payload => $http.put('v2/users/me', payload),
    settings: () => $http.get('v2/users/me/settings'),
    chunkedNotifications: offset => $http.get(`users/chunked_notifications?offset=${offset}`),
    registerDevice: payload => $http.post('users/register_device', payload),
    badges: userId => $http.get(`v2/users/${userId}/badge`),
    deleteToken: registrationId => $http.post('users/logout_delete_token', { registration_id: registrationId }),
    checkNickname: nickname => $http.get(`v2/users/check_nickname?nickname=${nickname}`),
    checkEmail: email => $http.get(`v2/users/check_email?email=${email}`),
    checkSignup: (email, phoneNumber) => $http.get(`v2/users/check_signup?email=${email}&phone_number=${phoneNumber}`),
    checkBirth: params => $http.get('v2/users/check_birth', { params }),
    create: payload => $http.post('v2/users', payload),
    createStyles: style => $http.post('v2/users/me/styles', style),
    signupFriends: () => $http.post('v2/users/me/signup_friends'),
    changeFriends: () => $http.post('v2/users/me/change_friends'),
    update: payload => $http.put('v2/users/me', payload),
    photosWithShorten: (userId, shorten) => $http.get(`v2/users/${userId}/photos?shorten=${shorten}&is_new=true`),
    checkDropout: () => $http.get('users/check_shared_contact'),
    dropout: reason => $http.put('dropout', reason),
    changePassword: payload => $http.post('users/change_password', payload),
    dormant: payload => $http.put('v2/users/me/dormant', payload),
    notices: () => $http.get('v2/notices'),
    updateMainPhoto: photoId => $http.put(`v2/users/me/photos/${photoId}/set_main`),
    getUnabledPhotos: ids => $http.get(`v2/users/me/photos/unabled?ids=${ids}`),
    items: () => $http.get('users/me/items'),
    checkAppVersion: (platform, appVersion) =>
        $http.get(`users/check_app_version?version_number=${appVersion}&platform=${platform}`),
    checkThirdParty: payload => $http.post('v2/users/check_third_party', payload),
    updateProfileUpdateRequest: (id, payload) => $http.put(`v2/users/me/profile_update_requests/${id}`, payload),
    verificationCode: {
        create: ({ phone_number }) =>
            $http.post('v2/verification_codes', {
                phone_number,
            }),
        confirm: ({ code, phone_number }) =>
            $http.post('v2/verification_codes/confirm', {
                code,
                phone_number,
            }),
    },
    resetPassword: ({ userId, responseId, newPassword }) =>
        $http.put(`v2/users/${userId}/reset_password`, {
            response_id: responseId,
            new_password: newPassword,
        }),
    reportUser: {
        create: (userId, payload) => $http.post(`v2/users/${userId}/report_users`, payload),
        update: (userId, reportUserId, payload) =>
            $http.put(`v2/users/${userId}/report_users/${reportUserId}`, payload),
    },
    sendSms: (userId, payload) => $http.post(`v2/users/${userId}/send_sms`, payload),
    giveTicketDiscount: userId => $http.post(`v2/users/${userId}/give_ticket_discount`),
    checkOrderHistory: (userId, { ptype, pay_result }) =>
        $http.post(`v2/users/${userId}/check_order_history`, { ptype, pay_result }),
    useInvitationPoint: (userId, payload) => $http.post(`v2/users/${userId}/use_invitation_point`, payload),
    premiumAgent: (userId, agentId) => $http.get(`v2/users/${userId}/premium_agent?agent_id=${agentId || ''}`),
    premiumCounsel: userId => $http.put(`v2/users/${userId}/premium_counsel`),
    updateNickname: (userId, payload) => $http.put(`v2/users/${userId}/update_nickname`, payload),
    registerContacts: (userId, payload) => $http.post(`v2/users/${userId}/multi_contacts`, payload),
    getContactsLength: userId => $http.get(`v2/users/${userId}/contacts_length`),
    hostCheck: () => $http.get('/v2/users/me/host_checked'),
    registerDeviceId: ({ userId, deviceId, adid }) =>
        $http.post(`/v2/users/${userId}/register_device_id`, { device_id: deviceId, adid }),
    uploadPhotos: (userId, payload) => $http.post(`v2/users/${userId}/upload_photos`, payload),
    sendInvite: number => $http.post('v2/users/me/send_invite', { number }),
    participateCampaign: () => $http.put(`v2/users/me/no_ons_campaign`),
    referrers: payload => $http.post('v2/users/me/referrers/', payload),
}
