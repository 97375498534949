<template>
    <div class="self-introduction">
        <h3 class="title" v-html="$translate('INTRODUCTION')" />
        <p class="content" v-html="intro" />
    </div>
</template>

<script>
export default {
    name: 'SelfIntroduction',
    props: {
        intro: String,
    },
}
</script>

<style lang="scss" scoped>
.self-introduction {
    .title {
        margin-bottom: 20px;
        color: black;
        font-size: 18px;
        font-weight: 500;

        @include f-medium;
    }

    .content {
        color: black;
        font-size: 15px;
        line-height: 1.6;
        letter-spacing: -0.2px;
        white-space: pre-line;
    }
}
</style>
