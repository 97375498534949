import { computed } from '@vue/composition-api'

const SourceProfile = () => {
    const makeComputed = prop => {
        const premium = computed(() => prop.source.premium)
        const premiumInfo = computed(() => prop.source.premium_info || null)
        const showPremiumComment = computed(() => {
            return !!prop.source.premium && !!premiumInfo.value.agent_intro
        })
        const statusString = computed(() => {
            if (prop.accepted) {
                return '소개 받을게요!'
            } else if (prop.rejected) return '패스했습니다'
            else if (prop.expired) return '기한 만료'

            return ''
        })
        const statusChanged = computed(() => {
            return prop.accepted || prop.rejected || prop.expired
        })

        return { premium, showPremiumComment, premiumInfo, statusString, statusChanged }
    }

    return { makeComputed }
}

export default SourceProfile
