<template>
    <div class="photo-with-bottom-pro-badge p-relative">
        <img class="photo" :src="src" />
        <span class="pro-badge p-absolute" v-html="$translate('PRO')" />
    </div>
</template>

<script>
export default {
    name: 'PhotoWithBottomProBadge',
    props: ['src'],
}
</script>

<style lang="scss" scoped>
.photo-with-bottom-pro-badge {
    width: 76px;
    height: 76px;

    .photo {
        width: 100%;
        height: 100%;
        border-radius: 40%;
    }

    .pro-badge {
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: NotoSans-Bold;
        bottom: -11px;
        transform: translateX(50%);
        width: 38px;
        height: 22px;
        border-radius: 6px;
        background-color: $purple-primary;
        color: white;
        font-size: 12px;
    }
}
</style>
