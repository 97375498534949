<template>
    <div class="toggler" :class="{ on: value }" @click="toggle">
        <div class="handle" />
    </div>
</template>
<script>
export default {
    name: 'Toggler',
    props: ['value'],
    methods: {
        toggle(event) {
            event.stopPropagation()
            this.$emit('input', this.value !== true)
        },
    },
}
</script>
