<template>
    <div class="signup basics nickname">
        <div class="content">
            <div class="title">닉네임</div>
            <div class="body">
                <div class="input-wrapper nickname">
                    <input
                        ref="nickname"
                        class="flex-fill"
                        placeholder="닉네임을 입력해주세요"
                        v-model="nickname"
                        @keydown="onKeydown"
                    />
                    <i v-if="nickname" class="material-icons flex-wrap" @click="nickname = ''">cancel</i>
                </div>
                <div class="ok" v-if="nicknameOk">
                    <i class="material-icons c-primary">check_circle</i>
                    {{ nicknameOk }}
                </div>
                <div class="error" v-if="error">
                    <i class="material-icons">error_outline</i>
                    {{ error }}
                </div>
            </div>
        </div>
        <div class="button-wrapper">
            <button
                class="btn btn-primary"
                :class="{ disabled: disabled }"
                @click="next"
                v-html="$translate('NEXT')"
            ></button>
        </div>
    </div>
</template>

<script>
import userService from '@/services/user'
import debounce from '@/modules/debounce'

export default {
    name: 'NicknamePage',
    data: () => ({
        nicknameOk: null,
        error: null,
        disabled: true,
        nickname: '',
    }),
    mounted() {
        this.nickname = this.$store.getters.payloads.signupData.nickname
        this.$refs.nickname.focus()
        this.checkNickname()

        this.$nativeBridge.postMessage({
            action: 'sendFirebaseEvent',
            value: {
                category: 'NicknamePage_Enter_NicknamePage',
                option: {
                    provider: this.$store.getters.payloads.signupData.provider || 'email',
                },
            },
        })

        // if (this.$store.getters.payloads.signupData.provider === 'kakao') {
        const gender = (this.$store.getters.payloads.signupData.gender || {}).name.toLowerCase()
        if (gender) {
            this.$nativeBridge.postMessage({
                action: 'sendAirbridgeEvent',
                value: {
                    category: `signup_choose_gender_${gender}`,
                },
            })

            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: `signup_choose_gender_${gender}`,
                },
            })
        }
        // }
    },
    methods: {
        onKeydown: debounce(function () {
            this.nickname = this.$refs.nickname.value
            this.checkNickname()
        }, 200),
        async checkNickname() {
            if (!this.nickname) {
                this.error = null
                this.nicknameOk = null
                this.disabled = true
                return
            }

            try {
                const temp = this.nickname
                const res = await userService.checkNickname(temp)
                this.error = res.result ? null : res.msg
                this.nicknameOk = res.result ? '사용 가능한 닉네임입니다 :)' : null
                this.disabled = !this.nicknameOk

                if (res.result) {
                    this.$store.commit('setPayloads', {
                        signupData: {
                            nickname: temp,
                        },
                    })
                    if (this.fbPayload) {
                        this.fbPayload.nickname = this.nickname
                    }
                }
            } catch (e) {}
        },
        next() {
            if (this.error || this.disabled) return

            // if (this.$store.getters.payloads.signupData.gender.name != null) {
            this.$router.push({ name: 'StatePage' })
            // } else {
            // this.$router.push({ name: 'GenderPage' })
            // }
        },
    },
}
</script>
