<template>
    <div class="gift-list">
        <StackRouterHeaderBar />
        <div class="gift-header flex">
            <div class="flex-wrap">
                <span class="gift-receiver">{{ agentName }}({{ agentNickname }})</span>
                <span>님에게</span>
            </div>
            <span>기프티콘 선물하기🎁</span>
        </div>
        <div class="gift-body">
            <div class="gift m-r-12" v-for="item in product" :key="item.id" @click="onClickGift(item)">
                <div class="gift-image">
                    <img :src="item.photo_url" />
                </div>
                <div class="gift-detail">
                    <div class="detail-wrapper">
                        <div class="franchise m-b-2">
                            {{ item.description }}
                        </div>
                        <div class="gift-name m-b-8">{{ item.name }}</div>
                    </div>
                    <div class="gift-price m-b-24">{{ item.price.toLocaleString() }}원</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import productService from '@/services/product'
export default {
    name: 'GiftListPage',
    props: {
        agent: Object,
    },
    data: () => ({
        product: null,
    }),
    mounted() {
        this.init()
    },
    computed: {
        agentName() {
            return this.agent.name
        },
        agentNickname() {
            return this.agent.nickname
        },
    },
    methods: {
        async init() {
            try {
                if (!this.agent) {
                    this.$stackRouter.pop()
                    return
                }

                const products = await productService.all()
                this.product = products.gift.filter(product => {
                    if (product.position !== 3 && product.position !== 4) {
                        return product
                    }
                })
                // this.product = products.gift
            } catch (e) {}
        },
        onClickGift(item) {
            this.$stackRouter.push({
                name: 'GiftPayPage',
                props: {
                    product: item,
                    agentId: this.agent.id,
                },
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.gift-list {
    padding-bottom: 32px;

    .gift-header {
        padding: 0 16px;
        padding-bottom: 28px;
    }
}

span {
    font-family: NotoSans;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.58;
    letter-spacing: -0.4px;
    color: black;
}
.gift-receiver {
    font-weight: bold;
}

.gift-body {
    padding: 0 16px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    .gift {
        display: flex;
        flex-direction: column;

        .gift-image {
            width: calc((100vw - 44px) / 2);
            height: calc((100vw - 44px) / 2);
            margin-bottom: 8px;
            img {
                border-radius: 12px;
                width: 100%;
                height: 100%;
            }
        }
        .gift-detail {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            justify-content: space-between;
            .detail-wrapper {
                .franchise {
                    font-family: NotoSans-Regular;
                    font-size: 12px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    color: $grey-06;
                }
                .gift-name {
                    font-family: NotoSans-Regular;
                    color: $grey-09;
                    font-size: 14px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.36;
                    letter-spacing: normal;
                    color: $grey-09;
                    flex-grow: 0;
                }
            }

            .gift-price {
                font-family: NotoSans-Medium;
                font-size: 15px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: black;
            }
        }
    }
}
</style>
