<template>
    <div class="dating-preference">
        <h3 class="title" v-html="$translate('DATING_PREFERENCE')" />
        <ul>
            <li class="qna" v-for="question in qna" :key="question.id">
                <div class="question">{{ questionString(question.question) }}</div>
                <p class="answer" v-for="answer in question.answer" :key="answer.id">{{ answer.answer }}</p>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'DatingPreference',
    data: () => ({
        qna: [],
    }),
    props: {
        user: {
            type: Object,
            required: true,
        },
    },
    watch: {
        user() {
            this.qna = []
            this.init()
        },
    },
    methods: {
        init() {
            this.user.dating_preferences.forEach(p => {
                const pre = this.qna.filter(q => q.id === p.dating_preference_id)
                if (pre.length > 0) {
                    pre[0].answer.push({
                        id: p.dating_preference_answer_id,
                        answer: p.answer,
                    })
                } else {
                    this.qna.push({
                        id: p.dating_preference_id,
                        question: p.question,
                        answer: [
                            {
                                id: p.dating_preference_answer_id,
                                answer: p.answer,
                            },
                        ],
                    })
                }
            })
        },
        questionString(question) {
            return `Q. ${question}`
        },
    },
    mounted() {
        this.init()
    },
}
</script>

<style lang="scss" scoped>
.dating-preference {
    .title {
        margin-bottom: 24px;
        color: black;
        font-weight: 500;

        @include f-medium;
    }

    .qna {
        padding: 14px 20px;
        border-radius: 12px;
        background-color: $grey-01;

        &:not(:last-child) {
            margin-bottom: 16px;
        }

        .question {
            margin-bottom: 8px;
            color: black;
            font-size: 15px;

            @include f-medium;
        }

        .answer {
            font-size: 14px;
            color: $grey-09;
        }
    }
}
</style>
