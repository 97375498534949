import * as $http from 'axios'

export default {
    // GET
    all: (eventType, filter, lastId) =>
        $http.get(
            `v2/events?event_type=${eventType}${filter ? `&filter=${filter}` : ''}${
                lastId ? `&last_id=${lastId}` : ''
            }`,
        ),
    mine: (eventType, lastId) =>
        $http.get(`v2/events/mine?event_type=${eventType}${lastId ? `&last_id=${lastId}` : ''}`),
    interest: (eventType, lastId) =>
        $http.get(`v2/events/interest?event_type=${eventType}${lastId ? `&last_id=${lastId}` : ''}`),
    success: (eventId, participantId) => $http.get(`v2/events/${eventId}/event_participants/${participantId}/success`),
    getLike: eventId => $http.get(`v2/events/${eventId}/get_like`),
    // POST
    create: payload => $http.post('v2/events', payload), // payload = { state_id, category_id, title, content, expire_at, photo }
    request: (eventId, payload) => $http.post(`v2/events/${eventId}/event_participants`, payload),

    // PUT
    update: (eventId, participantId, status) =>
        $http.put(`v2/events/${eventId}/event_participants/${participantId}/${status}`),
    participantChecked: (eventId, participantId) =>
        $http.put(`v2/events/${eventId}/event_participants/${participantId}/participant_checked`),
    hostChecked: eventId => $http.put(`v2/events/${eventId}/event_participants/host_checked`),
    updateViewCount: eventId => $http.put(`v2/events/update_view_count`, { event_id: eventId }),
    updateLike: (eventId, likeStatus) => $http.put(`v2/events/${eventId}/update_like`, { like_status: likeStatus }),

    openGroupChat: (eventId, inviteList) =>
        $http.put(`v2/events/${eventId}/event_participants/open_group_chat`, { ids: inviteList }),
    // put api/v2/events/{event_id}/event_participants/open_group_chat
    cancelGroupMeet: eventId => $http.delete(`v2/events/${eventId}`),
}
