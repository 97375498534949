<template>
    <div class="date-coach">
        <header class="header">
            <i class="material-icons i-back" @click="$router.go(-1)">chevron_left</i>
            <div class="title">
                <h1 class="text f-medium" v-html="$translate('DATE_COACH')" />
            </div>
        </header>

        <main class="main" ref="main">
            <ul>
                <li class="message-row m-b-20 left" v-for="(message, idx) in dateCoachings" :key="message.id">
                    <UnreadSeparator v-if="showUnreadSeparator(message, idx)" />
                    <DailySeparator v-if="showDailySeparator(idx)" :message="message" />
                    <div class="profile-and-name flex-row">
                        <div class="img-profile-wrapper">
                            <img class="img-profile flex-wrap" src="@/assets/images/date_coach.svg" />
                        </div>
                        <div class="name flex-wrap" v-html="$translate('DATE_COACH')" />
                    </div>
                    <DateCoachBasic :message="message" />
                </li>
            </ul>
        </main>

        <footer class="footer">
            <i class="material-icons m-r-8">add</i>
            <div class="fake-input">메시지 입력이 불가능합니다.</div>
        </footer>
    </div>
</template>

<script>
import DailySeparator from '../chatroom/components/DailySeparator'
import UnreadSeparator from '../chatroom/components/UnreadSeparator'
import DateCoachBasic from './components/DateCoachBasic'
import commonService from '@/services/common'

export default {
    name: 'DateCoach',
    components: { DailySeparator, UnreadSeparator, DateCoachBasic },
    data: () => ({
        isSnackbarOpened: false,
        dateCoachings: [],
    }),
    computed: {
        uncheckedCounts() {
            return this.dateCoachings.filter(i => !i.read).length
        },
    },
    methods: {
        initScrollPosition() {
            const dom = this.$refs.main

            if (!dom) return

            const unreadElem = document.querySelector('.unread-separator')

            if (!unreadElem) {
                this.$scroll.down(dom, false)
            } else {
                unreadElem.scrollIntoView(true)
                if (dom.scrollTop + dom.clientHeight < dom.scrollHeight) {
                    dom.scrollTop -= dom.clientHeight / 4
                }
            }
        },
        showDailySeparator(idx) {
            if (idx === 0) {
                return true
            }

            const cur = this.dateCoachings[idx]
            if (!cur || !cur.created_at) return false

            const prev = this.dateCoachings[idx - 1]
            return (
                this.$moment(prev.created_at).format('YYYY-MM-DD') !== this.$moment(cur.created_at).format('YYYY-MM-DD')
            )
        },
        showUnreadSeparator(instant, idx) {
            if (this.uncheckedCounts === 0 || this.dateCoachings.length === 1) return false

            if (this.dateCoachings.length - this.uncheckedCounts === idx) return true

            return false
        },
        async init() {
            this.dateCoachings = await commonService.dateCoachings()
        },
    },
    mounted() {
        this.$nextTick(() => {
            this.initScrollPosition()
        })
        this.init()
    },
}
</script>

<style lang="scss" scoped>
.date-coach {
    $header-height: 56px;
    $footer-height: 52px;

    .header {
        position: relative;
        height: $header-height;
        display: flex;
        align-items: center;
        padding: 10px 16px;
        border-bottom: solid 1px $grey-03;

        .title {
            display: flex;
            width: 100%;
            justify-content: center;
        }

        .text {
            margin: 0;
            color: black;
            font-size: 16px;
            font-weight: 500;
            margin-right: 6px;
        }
    }

    .main {
        height: calc(100vh - (#{$header-height} + #{$footer-height}));
        overflow-y: scroll;
        background-color: #fafafa;
        padding-top: 16px;
        padding-left: 16px;

        .floating {
            position: fixed;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            width: calc(100vw - 32px);
            left: 16px;
            top: calc(#{$header-height} + 12px);
            padding: 12px;
            border: solid 1px $grey-02;
            border-radius: 12px;
            background-color: white;
            color: $grey-08;
            z-index: 1;

            .count {
                text-decoration: underline;
                color: $blue-suggest;
            }
        }
    }

    .message-row {
        .profile-and-name {
            .img-profile-wrapper {
                width: 28px;
                height: 28px;
                background-color: white;
                border-radius: 40%;

                @include center;
                @include flex-wrap;
            }

            .img-profile {
                width: 24px;
                height: 24px;
            }

            .name {
                margin-left: 8px;
                font-size: 12px;
                color: $grey-07;
                font-family: NotoSans-Medium;

                &.dark {
                    color: #dad9df;
                }
            }
        }

        &:last-child {
            padding-top: 0;
        }

        &.left {
            .date-coach-basic {
                margin-top: -6px;
                margin-left: 36px;
            }
        }
    }

    .footer {
        border-top: solid 1px $grey-03;
        height: $footer-height;
        display: flex;
        align-items: center;
        padding: 8px 16px;

        .material-icons {
            font-size: 18px;
            border-radius: 4px;
            border: solid 1px $grey-07;
        }

        .fake-input {
            width: 100%;
            height: 36px;
            padding: 8px 16px;
            border-radius: 10px;
            border: solid 1px $grey-03;
            font-size: 14px;
            color: $grey-05;
            background-color: #fafafa;
        }
    }
}
</style>
