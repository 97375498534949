import realfriendMeetingService from '@/services/realfriend-meeting'

const defaultState = () => ({
    realfriendMeeting: {
        allPosts: [],
        myPosts: [],
        interestedPosts: [],
    },
})

const state = defaultState()

const getters = {
    realfriendMeeting: state => state.realfriendMeeting,
}

const actions = {
    async loadRealfriendMeetingPosts({ commit }) {
        try {
            const allPosts = await realfriendMeetingService.all()
            const myPosts = await realfriendMeetingService.mine()
            const interestedPosts = await realfriendMeetingService.interest()
            commit('setRealfriendMeeting', { allPosts, myPosts, interestedPosts })
        } catch (e) {
            return Promise.reject(e)
        }
    },

    async reloadForMyPosts({ state, commit }) {
        try {
            const allPosts = await realfriendMeetingService.all()
            const myPosts = await realfriendMeetingService.mine()
            commit('setRealfriendMeeting', {
                allPosts,
                myPosts,
                interestedPosts: state.realfriendMeeting.interestedPosts,
            })
        } catch (e) {
            return Promise.reject(e)
        }
    },
}

const mutations = {
    setRealfriendMeeting(state, realfriendMeeting) {
        state.realfriendMeeting = { ...realfriendMeeting }
    },
    setRealfriendMeetingAllPosts(state, posts) {
        if (!posts) return

        state.realfriendMeeting.allPosts = [...posts]
    },
    setRealfriendMeetingMyPosts(state, posts) {
        if (!posts) return

        state.realfriendMeeting.myPosts = [...posts]
    },
    setRealfriendMeetingInterestedPosts(state, posts) {
        if (!posts) return

        state.realfriendMeeting.interestedPosts = [...posts]
    },
    updateRequestPost(state, { id, request }) {
        // 관심있어요 클릭 시 관심있어요 피드로 카드 이동
        const target = state.realfriendMeeting.allPosts.find(post => post.id === id)
        target.my_request = request

        const prevInterested = state.realfriendMeeting.interestedPosts
        state.realfriendMeeting.interestedPosts = [target, ...prevInterested].sort((a, b) => b.id - a.id)
    },
    updateReadInterest(state, id) {
        // 관심있어요 탭에서 게시글 조회 시 읽음여부 체크
        const allTarget = state.realfriendMeeting.allPosts.find(post => post.id === id)
        const interestTarget = state.realfriendMeeting.interestedPosts.find(post => post.id === id)

        allTarget.my_request.participant_checked = true
        interestTarget.my_request.participant_checked = true
    },
    updateCardStatus(state, card) {
        // 카드플립, 채팅방 오픈 등 카드와 관련된 유저 동작 시 상태 업데이트
        const { accept_status: status, introduce_id: postId, id: introduceReqId, chat_id: chatId } = card
        const allPost = state.realfriendMeeting.allPosts.find(post => post.id === postId) || {}
        const myPost = state.realfriendMeeting.myPosts.find(post => post.id === postId) || {}
        const changeList = [allPost, myPost]

        if (status === 'request') {
            changeList.forEach(obj => {
                const requests = obj.requests || []
                const request = requests.find(req => req.id === introduceReqId)
                if (request) {
                    request.accept_status = 'checked'
                    request.host_checked = true
                    request.participant_checked = false
                }
            })
        } else if (status === 'checked') {
            changeList.forEach(obj => {
                const requests = obj.requests || []
                const request = requests.find(req => req.id === introduceReqId)
                if (request) {
                    request.accept_status = 'accepted'
                    request.chat_id = chatId
                }
            })
        }
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
    defaultState,
}
