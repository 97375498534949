<template>
    <div class="arrowed-snackbar">
        {{ msg }}
    </div>
</template>

<script>
export default {
    name: 'ArrowedSnackbar',
    props: ['msg'],
}
</script>

<style lang="scss" scoped>
.arrowed-snackbar {
    position: absolute;
    z-index: 1;
    background: $grey-09;
    border-radius: 8px;
    padding: 12px;
    font-size: 14px;
    color: white;

    //things to decide
    top: 20px;
    width: 340px;
    height: 70px;
}

.arrowed-snackbar:after {
    position: absolute;
    content: '';
    pointer-events: none;
    border: 5px solid transparent;
    border-bottom-color: $grey-09;
    //things to decide
    bottom: 100%;
    left: 15%;
}
</style>
