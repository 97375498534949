<template>
    <div class="source-profile-wrapper" :class="{ accepted: accepted, isPremium: !!premium }">
        <div class="source-profile" @click="onClickProfile">
            <div class="photo-wrapper flex-wrap" :class="{ blurred: !isOpened && !accepted }">
                <div v-if="rejected" class="overlay" />
                <img class="photo" :src="considerBlankProfile(photos[0])" @error="$event.target.src = $blankProfile" />
            </div>
            <section class="section">
                <div class="flex-row">
                    <div :class="{ 'm-t-8': accepted || rejected || expired }">
                        <div
                            v-if="statusChanged"
                            class="reject-or-accept f-medium"
                            :class="{ accepted: accepted, isPremium: !!premium }"
                            v-html="statusString"
                        />
                        <div class="name-age f-medium" :class="{ 'smaller f-regular': statusChanged }">
                            {{ source.nickname || '---' }}, {{ source.age || '' }}
                        </div>
                        <div class="region-job lines-1" :class="{ 'm-b-8': !statusChanged }">
                            {{ source.region | noDetail }}ㅣ{{ source.job || '' }}
                        </div>
                    </div>
                    <!-- <div v-if="isDday" class="d-day" v-html="'D-1'" /> -->
                </div>

                <div v-if="!statusChanged" class="buttons">
                    <template v-if="isOpened">
                        <button class="btn-pass" v-html="$translate('PASS')" @click.stop="onClickPass" />
                        <button class="btn-accept" v-html="acceptCTA" @click.stop="onClickAccept" />
                    </template>
                    <template v-else>
                        <button class="btn-open" v-html="$translate('OPEN_PROFILE')" />
                    </template>
                </div>
            </section>
        </div>
        <div class="premium-comment" v-if="showPremiumComment">
            <div class="title">
                <img class="m-r-4 m-r-4" :src="require('@/assets/images/premium/premium_gold_crown.svg')" />
                <span>주선자 코멘트</span>
            </div>
            <div class="content" v-html="premiumInfo.agent_intro" />
        </div>
    </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import SourceProfile from '@/routes/chat/suggested-dating/components/SourceProfile'
export default {
    name: 'SourceProfile',
    props: {
        suggest: {
            type: Object,
            required: true,
        },
        source: {
            type: Object,
            required: true,
        },
        photos: {
            type: Array,
            required: true,
        },
        isOpened: {
            type: Boolean,
            default: false,
        },
        rejected: {
            type: Boolean,
            default: false,
        },
        accepted: {
            type: Boolean,
            default: false,
        },
        expired: {
            type: Boolean,
            default: false,
        },
        isDday: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { root: { $store, $translate, $toast, $modal, $blankProfile }, emit }) {
        const { makeComputed } = SourceProfile()

        // computed
        const { premium, showPremiumComment, premiumInfo, statusString, statusChanged } = makeComputed(props)
        const acceptCTA = computed(() => $translate('INTRODUCE_ME'))
        const vanillaForAcceptSuggest = computed(() => {
            const { vanilla } = $store.getters.settings || {}

            // return vanilla.accept_suggest || 3
            return vanilla.accept_suggest
        })
        const me = computed(() => $store.getters.me)

        // methods
        const onClickProfile = () => {
            if (props.expired || props.rejected) {
                $toast.error('거절했거나 2일 이내 처리 안한 프로필은 다시 열어볼 수 없습니다')

                return
            }

            emit('clickProfile')
        }
        const onClickPass = () => {
            emit('clickReject')
            props.isOpened = true
        }
        const onClickAccept = () => {
            $modal
                .basic({
                    title: 'ACCEPT_SUGGEST_INSTANT_TITLE',
                    body: $translate('ACCEPT_SUGGEST_INSTANT_BODY')
                        .replace('%s', vanillaForAcceptSuggest.value)
                        .replace('%s', props.suggest.agent.name),
                    buttons: [
                        {
                            label: 'CANCEL',
                            class: 'btn-primary',
                        },
                        {
                            label: 'CONFIRM',
                            class: 'btn-primary',
                        },
                    ],
                })
                .then(idx => {
                    if (idx === 1) {
                        emit('clickAccept')
                    }
                })
        }
        const considerBlankProfile = photo => {
            if (!photo) return $blankProfile
            else return photo
        }

        return {
            premium,
            showPremiumComment,
            premiumInfo,
            statusString,
            statusChanged,
            acceptCTA,
            vanillaForAcceptSuggest,
            me,
            onClickProfile,
            onClickPass,
            onClickAccept,
            considerBlankProfile,
        }
    },
}
</script>

<style lang="scss" scoped>
.premium-comment {
    border-top: 1px solid $grey-02;
    margin-top: 6px;
    padding: 6px;

    .title {
        color: $yellow-premium;
        font-size: 13px;
        @include f-medium;
    }
    .content {
        font-size: 15px;
        color: black;
        letter-spacing: -0.2px;
        @include f-regular;
    }
}
.source-profile-wrapper {
    background-color: white;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.06), 0 2px 6px 0 rgba(138, 116, 255, 0.05);
    border-radius: 8px;
    width: 280px;

    &.accepted {
        border: solid 1px $purple-primary;

        &.isPremium {
            border: solid 1px $yellow-premium;
        }
    }
}
.source-profile {
    padding: 6px;
    display: flex;
    align-items: center;

    .photo-wrapper {
        position: relative;
        width: 80px;
        height: 80px;
        margin-right: 12px;

        .photo {
            width: 100%;
            height: 100%;
            border-radius: 8px;
        }

        .overlay {
            width: 100%;
            height: 100%;
            border-radius: 8px;
            background: rgba(255, 255, 255, 0.6);
        }

        &.blurred::before {
            position: absolute;
            width: 100%;
            height: 100%;
            backdrop-filter: blur(3px);
            pointer-events: none;
            border-radius: 8px;
            content: '';
        }
    }

    .section {
        display: flex;
        flex-direction: column;
        width: calc(100% - 92px); // padding + photo
        height: 80px;

        .reject-or-accept {
            font-size: 14px;
            margin-bottom: 8px;

            &.accepted {
                color: $purple-primary;

                &.isPremium {
                    color: $yellow-premium;
                }
            }
        }

        .name-age {
            color: black;
            font-size: 14px;

            &.smaller {
                font-size: 12px;
                color: $grey-08;
            }
        }

        .region-job {
            font-size: 12px;
            line-height: 18px;
            color: $grey-08;
        }

        .d-day {
            max-width: 32px;
            min-width: 32px;
            max-height: 16px;
            position: relative;
            text-align: center;
            // padding: 0 7px;
            opacity: 0.35;
            border-radius: 8px;
            background-color: black;
            color: white;
            font-size: 11px;
            @include f-medium;
        }

        .buttons {
            display: flex;
            width: 100%;

            button {
                height: 32px;
                border-radius: 6px;
                padding: 6px 10px;
                font-size: 13px;
                border: 0;
                font-weight: normal;
                white-space: nowrap;

                @include f-regular;

                &.btn-pass {
                    background-color: $grey-02;
                    color: $grey-09;
                    margin-right: 6px;
                    flex: 1;
                }

                &.btn-accept {
                    background-color: $purple-primary;
                    color: white;
                    flex: 2;
                }

                &.btn-open {
                    font-size: 14px;
                    color: $grey-08;
                    border: solid 1px $grey-03;
                }
            }
        }
    }
}
</style>
