import Vue from 'vue'
import productService from '@/services/product'

const defaultState = () => ({
    orderedProduct: null,
    inAppHistory: null,
})

const state = defaultState()

const getters = {
    orderedProduct: state => state.orderedProduct,
}

const actions = {
    requestInAppPurchase({ commit }, product) {
        commit('setOrderedProduct', product)

        Vue.prototype.$loading(true)
        Vue.prototype.$nativeBridge.postMessage({
            action: 'inAppPurchase',
            value: product,
        })
    },
    async purchaseInAppProduct({ state, commit }, data) {
        try {
            const { platform, receipt, purchase } = data

            let payload = { receipt }

            if (platform === 'ios') {
                const { transactionId, productId } = purchase
                payload = { ...payload, transactionId, productId }
            } else if (platform === 'android') {
                const { signatureAndroid: signature, productId } = purchase
                payload = { ...payload, signature, productId }
            }

            let product = state.orderedProduct

            if (!product) {
                product = JSON.parse(window.localStorage.getItem('lastOrderedProduct'))
            }

            const ih = await productService.inAppPurchase(product.id, payload)
            commit('setInAppHistory', ih)

            Vue.prototype.$nativeBridge.postMessage({
                action: 'confirmInAppPurchase',
                value: purchase,
            })
        } catch (e) {
            return e
        }
    },
    async finishInAppPurchase({ state, dispatch, commit }) {
        try {
            const product =
                state.orderedProduct || Vue.prototype.$mustParse(window.localStorage.getItem('lastOrderedProduct'))
            const ih = state.inAppHistory

            const { msg } = await productService.inAppConfirm(product.id, ih)

            Vue.prototype.$toast.success(msg)
            dispatch('loadBadges')
            dispatch('loadMe')
            commit('removeOrderedProduct')
            commit('setInAppHistory', null)
            Vue.prototype.$loading(false)
        } catch (e) {
            console.error(e)
        }
    },
}

const mutations = {
    setOrderedProduct(state, product) {
        if (!product) return
        window.localStorage.setItem('lastOrderedProduct', JSON.stringify(product))
        state.orderedProduct = product
    },
    removeOrderedProduct(state) {
        window.localStorage.removeItem('lastOrderedProduct')
        state.orderedProduct = null
    },
    setInAppHistory(state, ih) {
        state.inAppHistory = ih
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
    defaultState,
}
