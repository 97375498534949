<template>
    <div class="home">
        <Loading :loading="true" />
    </div>
</template>
<script>
import commonService from '@/services/common'
import userService from '@/services/user'

export default {
    name: 'HomePage',
    data: () => ({
        me: null,
        terms: null,
        idx: 0,
    }),
    mounted() {
        this.init()
    },
    methods: {
        async init() {
            try {
                await this.initMe()
                await this.checkAppVersion()
                await this.initTerms()

                const registerDevice = () => {
                    setTimeout(() => {
                        // device id 있는지 5번까지 체크
                        if (this.$store.getters.device) {
                            this.$store.dispatch('registerDeviceId')
                        } else if (this.idx < 5) {
                            this.idx += 1
                            registerDevice()
                        }
                    }, 500)
                }
                registerDevice()

                if (this.me.is_waitlist === 1) {
                    this.$router.push({
                        name: 'ManagerWaitlistPage',
                        params: {
                            user: this.me,
                        },
                    })

                    return
                }

                this.loadRewards()

                if (this.checkTerms()) {
                    // console.log(this.$moment(this.me.created_at).diff(this.$moment(), 'minutes'))
                    this.$router.push({
                        name: 'NewTermsPage',
                        params: {
                            updatedServiceTermsVersion: this.terms.service.version,
                            updatedPrivacyTermsVersion: this.terms.privacy.version,
                            updatedPrivacyThirdTermsVersion: this.terms.privacy_third.version,
                            serviceTermsId: this.terms.service.id,
                            privacyTermsId: this.terms.privacy.id,
                            privacyThirdTermsId: this.terms.privacy_third.id,
                        },
                    })
                } else {
                    const reuploadRequired = () => {
                        const photoNull = !this.me.photos.length
                        const newBieUser = this.$moment().diff(this.me.created_at, 'minutes') <= 5

                        return photoNull && !newBieUser
                    }

                    if (reuploadRequired()) {
                        this.$router.push({ name: 'ReuploadPhotoPage' })
                        return
                    }

                    this.checkProfileUpdateRequest()
                    this.checkNPS()
                    this.$router.push({ name: 'ChatsPage', params: { preventSetChatNull: true } })
                    // if (this.me.done_referrer === true || [3, 4, 5, 6, 7, 8, 9].indexOf(this.me.id % 10) !== -1) {
                    // if (this.me.done_referrer === true || this.me.how_old < 2) {
                    //     this.$router.push({ name: 'ChatsPage', params: { preventSetChatNull: true } })
                    // } else {
                    //     this.$router.push({ name: 'ReferrerFirstPage' })
                    // }
                    // this.$router.push({ name: 'ChatsPage', params: { preventSetChatNull: true } })
                }
            } catch (e) {}
        },
        async initMe() {
            try {
                await this.$store.dispatch('loadMe')
                const me = this.$store.getters.me || {}
                this.me = me
            } catch (e) {
                return Promise.resolve()
            }
        },
        async initTerms() {
            await this.$store.dispatch('loadTerms')
            const { terms } = this.$store.getters.terms || {}
            this.terms = terms
        },
        checkTerms() {
            return (
                this.me.service_terms_version < this.terms.service.version ||
                this.me.privacy_terms_version < this.terms.privacy.version ||
                this.me.privacy_third_terms_version < this.terms.privacy_third.version
            )
        },
        async loadRewards() {
            try {
                const lastId = window.localStorage.getItem('lastRewardId') || null
                const rewards = await commonService.rewards(lastId)

                rewards.forEach(reward => {
                    this.$nativeBridge.postMessage({
                        action: 'sendAirbridgeEvent',
                        value: {
                            category: 'match_success_cnt_as_dating_target',
                        },
                    })
                })

                if (rewards.length > 0) {
                    window.localStorage.setItem('lastRewardId', `${rewards[0]}`)
                }
            } catch (e) {
                console.error(e)
            }
        },
        checkProfileUpdateRequest() {
            const { profile_update_request: pur } = this.me

            if (pur && ['created', 'confirmed'].indexOf(this.me.profile.status) === -1) {
                this.$router.push({
                    name: 'ProfileUpdateRequestPage',
                    params: { pur },
                })

                throw new Error()
            }
        },
        async checkAppVersion() {
            if (!this.$store.getters.device) {
                // 브라우저인 경우
                return Promise.resolve()
            }

            try {
                const { os, app_version } = this.$store.getters.device
                const { update_required: required, landing_url: url } = await userService.checkAppVersion(
                    os,
                    app_version,
                )

                if (required) {
                    this.$router.push({ name: 'ForceAppUpdatePage', params: { url } })
                    return Promise.reject()
                }
            } catch (e) {
                return Promise.reject(e)
            }
        },
        checkNPS() {
            const { nps_on: npsOn } = this.me

            if (npsOn) {
                const date = new Date()
                const userId = this.me.id
                if (this.$isIOS()) {
                    const alreadySeenToday = window.localStorage.getItem(
                        `nts${date.getFullYear()}${date.getMonth() + 1}${date.getDate()}${userId}`,
                    )
                    if (!alreadySeenToday) {
                        this.$router.push({ name: 'NTSMainPage' })

                        throw new Error()
                    }
                } else {
                    const alreadySeenToday = window.localStorage.getItem(
                        `nps${date.getFullYear()}${date.getMonth() + 1}${date.getDate()}${userId}`,
                    )

                    if (!alreadySeenToday) {
                        this.$router.push({ name: 'NPSMainPage' })

                        throw new Error()
                    }
                }
            }
        },
    },
}
</script>
