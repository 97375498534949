<template>
    <div class="today-meeting">
        <!-- <TodayMeetingNeedCert v-if="showNeedCert" /> -->
        <nav class="tabs">
            <div
                class="tab"
                :class="{ selected: item.selected }"
                v-for="item in tabItems"
                :key="item.label"
                @click="onClickTab(item)"
            >
                <div class="content">
                    <p class="label" v-html="$translate(item.label)" />
                    <div v-if="item.type !== 'all' && showNewBadge(item.type)" class="new-badge" />
                </div>
            </div>
        </nav>

        <main class="main" @scroll="onScroll">
            <!-- <TodayMeetingHotPosts
                :posts="hotPosts"
                v-if="selectedTab === 'all' && hotPosts.length"
                :onClickPost="onClickPost"
            /> -->
            <!-- <TodayMeetHowToUse v-if="selectedTab === 'all'" /> -->
            <div class="party-meet-info" v-if="selectedTab === 'all'">
                <span class="white-b">셀소 이용법 </span>
                <i class="material-icons f-20" @click="partyMeetingInfo">info</i>
            </div>
            <TodayMeetingFilter
                @selectFilter="selectFilter"
                :filterType="filterType"
                :filterTypes="filterTypes"
                v-if="selectedTab === 'all'"
            />

            <span v-if="selectedTab == 'all' && hots.length > 0" class="hot-posts">인기 게시글🔥</span>
            <div class="hots" v-if="selectedTab == 'all' && hots.length > 0">
                <TodayMeetingHot
                    v-for="hot in hots"
                    :key="hot.type"
                    :post="hot"
                    :type="selectedTab"
                    @click.native="onClickPost(hot)"
                />
            </div>
            <hr v-if="selectedTab == 'all' && hots.length > 0" />
            <div class="posts">
                <TodayMeetingPost
                    v-for="post in posts"
                    :key="post.type"
                    :post="post"
                    :type="selectedTab"
                    @click.native="onClickPost(post)"
                />
            </div>
            <div v-if="posts.length === 0" class="empty-posts">
                <div v-if="this.selectedTab !== 'interested'">아직 등록된 게시물이 없습니다</div>
                <div v-else>호감 보낸 게시물이 없습니다</div>
            </div>
        </main>

        <!-- <button v-show="selectedTab === 'all' && !this.showNeedCert" class="btn" @click="onClickMakePost"> -->
        <button v-show="selectedTab === 'all'" class="btn" @click="onClickMakePost">
            <i class="material-icons">edit</i>
        </button>
    </div>
</template>

<script>
import eventService from '@/services/event'
// import result from '@/assets/translations'

export default {
    name: 'TodayMeetingPage',
    components: {
        TodayMeetingPost: () => import('./components/TodayMeetingPost'),
        TodayMeetingHot: () => import('./components/TodayMeetingHot'),
        // TodayMeetingHotPosts: () => import('./components/TodayMeetingHotPosts'),
        // TodayMeetHowToUse: () => import('./components/TodayMeetHowToUse'),
        TodayMeetingFilter: () => import('./components/TodayMeetingFilter'),
        // TodayMeetingNeedCert: () => import('./components/TodayMeetingNeedCert'),
    },
    data: () => ({
        selectedTab: 'all',
        filterType: null,
        // allPostsFiltered: [],
        tabItems: [
            {
                type: 'all',
                label: 'ALL',
                selected: true,
            },
            {
                type: 'myPost',
                label: 'MY_POST',
                selected: false,
            },
            {
                type: 'interested',
                label: 'INTERESTED',
                selected: false,
            },
        ],
        loading: false,
        lastScrollTop: 0,
    }),
    // watch: {
    //     showNeedCert() {},
    // },
    computed: {
        // me() {
        //     return this.$store.getters.me
        // },
        todayMeeting() {
            return this.$store.getters.todayMeeting
        },
        allPosts() {
            return this.todayMeeting.allPosts || []
        },
        filter() {
            return this.filterType || []
        },
        hotPosts() {
            return this.todayMeeting.hotPosts || []
        },
        myPosts() {
            return this.todayMeeting.myPosts || []
        },
        interestedPosts() {
            return this.todayMeeting.interestedPosts || []
        },
        posts() {
            // if (this.selectedTab === 'all') {
            //     // return this.selectFilter()
            //     // return this.allPostsFiltered()
            //     if (this.filterType === null || this.filterType === undefined) {
            //         return this.allPosts || []
            //     } else {
            //         return this.filteredPosts || []
            //     }
            // }
            if (this.selectedTab === 'all') {
                const posts = []
                // return this.allPosts.slice(0, 2)
                this.allPosts.forEach(post => {
                    if (post.hot === false) {
                        posts.push(post)
                    }
                })
                return posts
            }
            if (this.selectedTab === 'myPost') return this.myPosts
            if (this.selectedTab === 'interested') return this.interestedPosts

            return []
        },
        hots() {
            if (this.selectedTab === 'all') {
                const hots = []
                // return this.allPosts.slice(0, 2)
                this.allPosts.forEach(post => {
                    if (post.hot === true) {
                        hots.push(post)
                    }
                })
                return hots || []
            }

            return []
        },
        hasUncheckedMyPostParticipants() {
            if (this.myPosts.length === 0) return false

            return this.myPosts.some(post => {
                if ((post.participants || []).length === 0) return false

                return post.participants.some(p => !p.host_checked)
            })
        },
        hasUncheckedInterestedPost() {
            if (this.interestedPosts.length === 0) return false

            return this.interestedPosts.some(({ participant }) => {
                if (!participant) return false

                return !participant.participant_checked
            })
        },
        // showNeedCert() {
        //     if (this.$store.getters.me.id === 190282) return false

        //     return (
        //         (this.$store.getters.me || {}).gender === 0 &&
        //         (this.$store.getters.settings || {}).show_today_meeting_cert === false
        //     )
        // },
        filterTypes() {
            return [
                {
                    title: '전체',
                    gender: null,
                },
                {
                    title: '남성',
                    gender: 0,
                },
                {
                    title: '여성',
                    gender: 1,
                },

                // {
                //     title: '데이트',
                //     category_id: 30,
                // },
                // {
                //     title: '셀소',
                //     category_id: 31,
                // },
                // {
                //     title: '미팅',
                //     category_id: 32,
                // },
                // {
                //     title: '소통',
                //     category_id: 33,
                // },
                // {
                //     title: '벙개',
                //     category_id: 34,
                // },
            ]
        },
    },
    methods: {
        async init() {
            try {
                this.$loading(true)
                await this.$store.dispatch('loadTodayMeetingPosts', this.filterType)
            } catch (e) {
                this.$toast.error(e.data)
            } finally {
                this.$loading(false)
            }
        },
        // fireModal() {
        //     const checked = localStorage.getItem('activation-check')
        //     if (!checked && !this.showNeedCert) {
        //         this.$modal.custom({
        //             component: 'ModalActivationPage',
        //             options: {
        //                 preventCloseOnMousedownMask: true,
        //                 title: '오늘만나',
        //                 gender: this.$store.getters.me.gender,
        //             },
        //         })
        //     }
        // },
        onClickTab(item) {
            item.selected = true

            this.tabItems.forEach(tab => {
                if (tab.type !== item.type) {
                    tab.selected = false
                }
            })

            this.selectedTab = item.type
        },
        partyMeetingInfo() {
            this.$nativeBridge.postMessage({
                action: 'openInAppBrowser',
                value: 'https://vbproduct.notion.site/317d3e71589342ad9e255dcc7c133eb8',
            })
        },
        onClickMakePost() {
            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: 'todayMeetingEvent',
                    option: {
                        funnel: 'click_today_meeting_fab_icon',
                    },
                },
            })

            this.lastScrollTop = 0
            this.$stackRouter.push({
                name: 'TodayMeetingPostMakePage',
            })
        },
        selectFilter(filterType) {
            // if (filterType.includes(0)) {
            //     if (filterType.length === 1) {
            //         filterType.splice(filterType.indexOf(0), 1)
            //     }
            // }
            this.filterType = [filterType]
            this.lastScrollTop = 0
            this.init()
        },
        // async allPostsFiltered(filter) {
        //     try {
        //         const lastId = null
        //         const res = await eventService.all('party_meeting', filter, lastId)
        //         if ((res || []).length > 0) {
        //             this.$store.commit('setTodayMeetingAllPosts', res)
        //         }
        //     } catch (e) {
        //         // this.$toast.error('글 없음')
        //         console.log(e)
        //         return Promise.reject(e)
        //     // } finally {
        //     //     this.$loading(false)
        //     }
        // },
        onClickPost(post) {
            // this.lastScrollTop = 0
            if (this.selectedTab === 'myPost') {
                eventService.hostChecked(post.id)
                this.$store.commit('updateHostCheck', post.id)
            }

            if (this.selectedTab === 'interested') {
                eventService.participantChecked(post.id, post.participant.id)
                this.$store.commit('updateParticipantCheck', post.id)
            }

            eventService.updateViewCount(post.id)
            // eventService.getLike(post.id)

            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: 'todayMeetingEvent',
                    option: {
                        funnel: 'click_today_meeting_post',
                    },
                },
            })

            this.$stackRouter.push({
                name: 'TodayMeetingPostDetailPage',
                props: {
                    post,
                    filterType: this.filterType,
                },
            })
        },
        async onScroll({ target }) {
            if (this.allPosts.length < 30 || this.loading) return

            const { scrollHeight, clientHeight, scrollTop } = target

            // 스크롤 방향이 upwards면 리턴
            if (scrollTop < this.lastScrollTop) return

            this.lastScrollTop = scrollTop <= 0 ? 0 : scrollTop

            if (scrollTop + clientHeight > scrollHeight - 100) {
                this.loading = true
                await this.loadMore()
                this.loading = false
            }
        },
        async loadMore() {
            try {
                this.$loading(true)
                switch (this.selectedTab) {
                    case 'all': {
                        const filter = [this.filterType]
                        const lastId = this.allPosts[this.allPosts.length - 1].id
                        console.log(filter, lastId)
                        const res = await eventService.all('party_meeting', filter, lastId)

                        if ((res || []).length > 0) {
                            this.$store.commit('setTodayMeetingAllPosts', this.allPosts.concat(res))
                        }
                        break
                    }
                    case 'myPost': {
                        const lastId = this.myPosts[this.myPosts.length - 1].id
                        const res = await eventService.mine('party_meeting', lastId)

                        if ((res || []).length > 0) {
                            this.$store.commit('setTodayMeetingMyPosts', this.myPosts.concat(res))
                        }

                        break
                    }
                    case 'interested': {
                        const lastId = this.interestedPosts[this.interestedPosts.length - 1].id
                        const res = await eventService.interest('party_meeting', lastId)

                        if ((res || []).length > 0) {
                            this.$store.commit('setTodayMeetingInterestedPosts', this.interestedPosts.concat(res))
                        }

                        break
                    }
                }
            } catch (e) {
                this.$toast.error(e.data)
            } finally {
                this.$loading(false)
            }
        },
        showNewBadge(type) {
            if (type === 'myPost') {
                return this.hasUncheckedMyPostParticipants
            } else if (type === 'interested') {
                return this.hasUncheckedInterestedPost
            }
        },
    },
    // mounted() {
    //     this.fireModal()
    // },
    created() {
        this.init()
    },
}
</script>

<style lang="scss" scoped>
.today-meeting {
    $tab-height: 48px;
    overflow: hidden;

    .today-meeting-need-cert {
        width: 100% !important;
        height: calc(100vh - 64px - 56px) !important;
        position: absolute;
    }

    .tabs {
        display: flex;
        width: 100%;

        & > .tab {
            height: $tab-height;
            flex: 1;

            @include center;

            &.selected {
                color: black;
                border-color: black;
            }

            .content {
                position: relative;
            }

            .new-badge {
                position: absolute;
                width: 5px;
                height: 5px;
                border-radius: 50%;
                background-color: $pink-deep;
                top: 0;
                right: -6px;
            }
        }
    }

    .main {
        $item-width: calc((100vw - 12px - 32px) / 3);
        height: calc(100vh - (#{$tab-height} + #{$root-header-height} + #{$control-panel-height} + 12px));
        padding: 16px 0;
        overflow-y: auto;

        .hot-posts {
            font-size: 17px;
            padding: 16px;
            font-weight: bold;
        }

        .hots {
            padding: 16px;
            padding-top: 16px;
            display: grid;
            gap: 24px 12px;
            grid-template-columns: repeat(2, calc((100vw - 12px - 32px) / 2));
        }

        .posts {
            padding: 16px;
            padding-top: 30px;
            display: grid;
            gap: 24px 12px;
            grid-template-columns: repeat(3, $item-width);
        }

        .empty-posts {
            color: $grey-07;
            font-size: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            margin-top: -40px;
        }

        .today-meeting-post {
            height: auto;

            ::v-deep .photo {
                width: $item-width;
                height: $item-width;
            }
        }

        .today-meeting-hot {
            height: auto;

            ::v-deep .photo {
                width: calc((100vw - 12px - 32px) / 2);
                height: calc((100vw - 12px - 32px) / 2);
            }
        }

        .party-meet-info {
            float: right;
            padding-right: 15px;
            margin-top: 3px;
            margin-left: 10px;

            .white-b {
                font-size: 13px;
                vertical-align: top;
                color: $grey-08;
                @include f-bold;
            }
        }
    }

    .btn {
        position: absolute;
        bottom: calc(#{$header-height} + 16px);
        right: 16px;
        width: 64px;
        height: 64px;
        border-radius: 50%;
        box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.1), 2px 2px 8px 0 rgba(0, 0, 0, 0.15);
        background-image: linear-gradient(135deg, #ba90ff, $purple-primary);

        .material-icons {
            font-size: 28px;
        }
        @include center;
    }
}
</style>
