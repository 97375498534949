<template>
    <div v-if="$store.getters.me" class="control-panel">
        <div class="menu-items">
            <!--            flex-row items-center  flex-fill-->
            <MenuButton
                @click="button.action"
                :icon="button.icon"
                :badgeCount="button.badgeCount"
                :label="button.label"
                :selected="button.selected"
                :key="button.label"
                v-for="button in buttons"
            />
        </div>
    </div>
</template>

<script>
import MenuButton from './MenuButton'

export default {
    components: {
        MenuButton,
    },
    computed: {
        numUnreads() {
            const chatUnreads = (this.$store.getters.chats || []).reduce((total, chat) => total + chat.unread || 0, 0)
            const unreadsSuggests = (this.$store.getters.suggests || []).filter(
                suggest => !suggest.user_checked && suggest.status === 'request',
            ).length

            return chatUnreads + unreadsSuggests
        },
        myDatingBadgeCount() {
            const localStorageData = Number(window.localStorage.getItem('myDatingBadge')) || 0

            if (localStorageData) {
                this.$store.commit('setMyDatingBadge', localStorageData)
            }

            return this.$store.getters.myDatingBadge ? 1 : 0
        },
        badges() {
            return this.$store.getters.badges || {}
        },
        userAge() {
            return this.$options.filters.asAge(this.$store.getters.me.profile.birthday)
        },
        buttons() {
            const btns = [
                {
                    action: () => this.navigate('ChatsPage'),
                    icon: this.icon('chat'),
                    label: 'HOME',
                    badgeCount: this.numUnreads,
                    selected: this.$route.name === 'ChatsPage',
                },
                {
                    action: () => this.navigate('MyDatingPage'),
                    icon: this.icon('dating'),
                    label: 'MY_DATING',
                    badgeCount: this.myDatingBadgeCount,
                    selected: this.$route.name === 'MyDatingPage',
                },
                // {
                //     action: () => {
                //         this.$nativeBridge.postMessage({
                //             action: 'sendFirebaseEvent',
                //             value: {
                //                 category: 'IntroduceMyFriend',
                //                 option: {
                //                     funnel: 'click_introduce_my_friend_menu',
                //                 },
                //             },
                //         })

                //         this.navigate('RealFriendMeetingPage')
                //     },
                //     icon: this.icon('friend'),
                //     label: 'REAL_FRIEND',
                //     selected: this.$route.name === 'RealFriendMeetingPage',
                // },
                {
                    action: () => {
                        this.$nativeBridge.postMessage({
                            action: 'sendFirebaseEvent',
                            value: {
                                category: 'todayMeetingEvent',
                                option: {
                                    funnel: 'click_today_meeting_menu',
                                },
                            },
                        })

                        this.navigate('TodayMeetingPage')
                    },
                    // icon: this.icon('today_meeting'), // 베타테스트 끝나면 돌리기
                    icon: this.icon('beta_today_meeting'),
                    label: 'TODAY_MEETING',
                    badgeCount: this.myDatingBadgeCount,
                    selected: this.$route.name === 'TodayMeetingPage',
                },
                // {
                //     action: () => this.navigate('SocialMeetingPage'),
                //     icon: this.icon('social'),
                //     label: 'SOCIAL_MEETING',
                //     selected: this.$route.name === 'SocialMeetingPage',
                // },
                {
                    action: () => {
                        this.navigate('MyPage')
                        this.$nativeBridge.postMessage({
                            action: 'sendFirebaseEvent',
                            value: {
                                category: 'BottomNav_Click_ProfileMenu',
                            },
                        })
                    },
                    icon: this.icon('profile'),
                    label: 'PROFILE',
                    selected: this.$route.name === 'MyPage',
                },
            ]

            if (!this.$store.getters.me) return btns

            const userId = this.$store.getters.me.id

            const inhouseUsers = [
                8176,
                7091,
                95085,
                155787,
                145574,
                52738,
                12341,
                10490,
                30121,
                114325,
                105324,
                173447,
                35753,
                165846,
                190282,
                4891,
                162753,
                139149,
                173451,
                173450,
                165537,
                48,
                // 여기부터는 지인
                173596,
                116198,
                59789,
                156523,
                173686,
                175138,
            ]
            // 내부 직원 하드코딩
            if (inhouseUsers.includes(userId)) return btns

            const dontShowTodayMeetingUser = [391673]
            if (dontShowTodayMeetingUser.includes(userId)) {
                const idx = btns.findIndex(b => b.label === 'TODAY_MEETING')
                btns.splice(idx, 1)
            }

            // 새로운 필터링 조건에 따른 오늘만나 노출
            // const dontShowTodayMeeting = !(this.$store.getters.settings || {}).show_today_meeting
            // if (dontShowTodayMeeting || this.userAge > 39) {
            //     const idx = btns.findIndex(b => b.label === 'TODAY_MEETING')
            //     btns.splice(idx, 1)
            // }

            // 테스트계정 내친소 노출 방지
            // if (this.$isTester()) {
            //     const idx = btns.findIndex(b => b.label === 'REAL_FRIEND')
            //     btns.splice(idx, 1)
            // }

            // 고령자는 내친소같은거 안보여줌
            // if (this.userAge > 39) {
            //     let idx = btns.findIndex(b => b.label === 'REAL_FRIEND')
            //     btns.splice(idx, 1)
            //     idx = btns.findIndex(b => b.label === 'SOCIAL_MEETING')
            //     btns.splice(idx, 1)
            //     btns.push({
            //         action: () => {
            //             this.navigate('MyPage')
            //         },
            //         icon: this.icon('profile'),
            //         label: 'PROFILE',
            //         selected: this.$route.name === 'MyPage',
            //     })
            // }

            // 히든인 유저 내친소, 오늘만나, 소셜 못하게 수정
            if (this.$store.getters.me && this.$store.getters.me.hidden === true) {
                let idx = btns.findIndex(b => b.label === 'REAL_FRIEND')
                btns.splice(idx, 1)
                idx = btns.findIndex(b => b.label === 'SOCIAL_MEETING')
                btns.splice(idx, 1)
                idx = btns.findIndex(b => b.label === 'TODAY_MEETING')
                btns.splice(idx, 1)
                btns.push({
                    action: () => {
                        this.navigate('MyPage')
                    },
                    icon: this.icon('profile'),
                    label: 'PROFILE',
                    selected: this.$route.name === 'MyPage',
                })
            }

            return btns
        },
    },
    methods: {
        navigate(name) {
            this.$router.push({ name }).catch(e => {})
        },
        icon(icon) {
            return {
                active: require(`@/assets/images/tab/icon_${icon}_active@2x.png`),
                inactive: require(`@/assets/images/tab/icon_${icon}_inactive@2x.png`),
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.control-panel {
    z-index: 1;
    position: fixed;
    width: 100%;
    bottom: 0;
    background: white;
    border-top: 1px solid $grey-02;
    height: $header-height;

    .menu-items {
        padding: 6px 22px;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
}
</style>
