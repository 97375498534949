<template>
    <ProfileOptionSelector
        :model="'school'"
        :nextRoute="item => (item.id === 5 ? 'JobCategoryPage' : 'SchoolTypePage')"
    />
</template>

<script>
export default {
    name: 'SchoolPage',
    mounted() {
        const { provider, gender } = this.$store.getters.payloads.signupData

        this.$nativeBridge.postMessage({
            action: 'sendFirebaseEvent',
            value: {
                category: 'SchoolTypePage_Enter_SchoolTypePage',
                option: {
                    provider: provider || 'email',
                    gender: gender.name === 'MALE' ? 0 : 1,
                },
            },
        })
    },
}
</script>
