<template>
    <div class="user-detail-base">
        <div class="body">
            <div class="section p-t-20 p-b-20">
                <div
                    v-if="status"
                    class="status m-b-4"
                    v-html="$translate(`SUGGESTED_STATUS_${status.toUpperCase()}`)"
                />
                <div
                    @click="onClickParticipate"
                    v-if="user.profile.no_ons_campaign === 1"
                    class="no-ons-campaign btn-facebook-grad"
                >
                    <span>#Goodbye_To_ONS 캠페인 참여 중</span>
                    <i class="material-icons">info</i>
                </div>
                <div class="name" v-html="nameOrNickname" />
                <div class="basic-info">
                    <div class="flex-row">
                        <div v-html="`${$options.filters.asAge(user.profile.birthday)}세`" />
                        <div class="divider" v-html="'ㅣ'" />
                        <div v-html="`${user.profile.height}cm`" />
                    </div>
                    <div class="flex-row m-t-8">
                        <div v-html="region" />
                        <Distance
                            v-if="showDistanceInfo"
                            :distance="$distance($store.getters.me.location, user.location)"
                        />
                    </div>
                </div>
                <VerificationBadges v-if="verificationBadges.length" :verificationBadges="verificationBadges" />
            </div>
        </div>

        <template v-if="user.id !== $store.getters.me.id && !$isTester() && from !== 'TodayMeetingCard'">
            <AppearanceRating :card="{ source_id: user.id }" />
            <hr />
        </template>

        <div
            v-if="from === 'SuggestedDatingPage' || from === 'SuggestedWaiting'"
            class="requested-instant-agent-message"
        >
            <div class="agent-photo-name">
                <div class="img-profile" v-img-cover="agentPhoto" @click="goToAgentDetail" />
                <span class="name">주선자 {{ agent.name }}</span>
            </div>
            <div class="agent-message">
                <div :class="{ 'lines-2': shouldClamp }" ref="agentMessage" v-html="agentMessage" />
                <div v-if="shouldClamp" class="c-primary btn-more f-medium" @click="shouldClamp = false">더 보기</div>
            </div>
        </div>
    </div>
</template>

<script>
import VerificationBadges from '@/routes/user-detail/components/VerificationBadges'
export default {
    name: 'UserDetailBase',
    components: {
        VerificationBadges,
    },
    props: {
        user: {
            type: Object,
            required: true,
        },
        from: String,
        agentId: Number,
        agentPhoto: String,
        agentMessage: String,
        agent: Object,
        status: String,
    },
    data: () => ({
        shouldClamp: true,
        rating: null,
    }),
    mounted() {
        const dom = this.$refs.agentMessage
        if (dom) {
            this.shouldClamp = dom.scrollHeight - 1 > dom.clientHeight
        }
    },
    computed: {
        phoneNumber() {
            return this.$store.getters.me.phone_number
        },
        intro() {
            return this.user.profile.intro.trim() || ''
        },
        region() {
            return this.user.profile.region_name || ''
        },
        nameOrNickname() {
            if (['RequestedSuccess', 'ActionRefundStatus'].indexOf(this.from) > -1) {
                return this.user.name || ''
            } else {
                return this.user.profile.nickname || ''
            }
        },
        showDistanceInfo() {
            // 본인 프로필 열람 시 거리정보 노출하지 않기
            return this.$store.getters.me.id !== this.user.id
        },
        verificationBadges() {
            const badges = (this.user.verification_badges || []).filter(
                ({ confirmed, show_profile: show }) => confirmed && show,
            )
            badges.sort((badgeA, badgeB) => {
                if (!(badgeA.display_method || '').includes('photo') && (badgeB.display_method || '').includes('photo'))
                    return -1

                if (badgeA.category === badgeB.category) return badgeA.position - badgeB.position
            })

            return badges
        },
    },
    methods: {
        onClickParticipate() {
            this.$modal.custom({
                component: 'ModalEnterCampaign',
            })
        },
        getRate(rating) {
            this.rating = rating
        },
        asString(obj) {
            return typeof obj === 'string' ? obj : obj.name
        },
        goToAgentDetail() {
            this.$stackRouter.push({
                name: 'AgentDetailPage',
                props: {
                    agentId: this.agentId,
                    from: 'UserDetailPage',
                },
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.user-detail-base {
    hr {
        height: 1px;
        background-color: $grey-02;
        border: none;
    }

    .no-ons-campaign {
        padding: 0 16px;
        margin-bottom: 16px;
        color: white;
        font-size: 14px;
        display: flex;
        align-items: center;
        width: fit-content;
        height: 26px;
        border-radius: 8px;
        @include f-medium;

        i {
            color: white;
            font-size: 16px;
            margin-left: 5px;
        }
    }

    .section {
        padding: 32px 16px;
        border-bottom: 1px solid $grey-02;
        @include flex;
    }

    .status {
        color: $purple-primary;
        font-size: 12px;
        letter-spacing: -0.2px;
        line-height: normal;
        @include f-bold;
    }

    .name {
        line-height: normal;
        font-size: 20px;
        color: black;
        @include f-medium;
    }

    .basic-info {
        margin-top: 24px;
        z-index: 3;

        .flex-row > * {
            flex: 0 0 auto;
        }

        .distance {
            margin-left: 6px;
        }
    }

    ::v-deep .appearance-rating {
        position: relative;
        margin-left: 16px;
        width: calc(100vw - 32px);

        .rating {
            padding: 20px 0;
            font-size: 15px;
            color: $grey-09;

            &-content {
                width: 100%;
                background-color: $grey-01;
                border-radius: 16px;
                height: 131px;
                padding: 14px 0 16px;
            }

            .star-rating,
            .star-rating-wrapper {
                display: flex;
                justify-content: center;

                .star-wrapper {
                    width: 32px;
                    height: 32px;
                }

                .star-wrapper:not(:last-child) {
                    margin-right: 10px;
                }
            }

            .title {
                margin-bottom: 17px;
                color: $grey-09;
                font-size: 16px;
                line-height: normal;

                @include f-regular;
                @include center;

                em {
                    font-style: normal;
                    @include f-bold;
                }
            }

            .content {
                margin-top: 14px;
                white-space: nowrap;
                color: $grey-07;
                font-size: 12px;
                @include f-regular;
                @include center;
            }

            .snackbar {
                padding: 14px 16px;
                position: initial;
                max-width: initial;
                border-radius: 12px;
                background-color: $grey-08;
                box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15), 0 1px 3px 0 rgba(0, 0, 0, 0.1);

                i {
                    color: $grey-05;
                    font-size: 16px;
                    margin-top: 2px;
                }
            }

            .material-icons {
                font-size: 16px;
                color: $grey-06;
            }
        }
    }

    .requested-instant-agent-message {
        padding: 32px 16px 0;

        @include flex;

        .agent-photo-name {
            margin-bottom: 6px;
            @include flex-row;
            @include items-center;
        }

        .img-profile {
            width: 32px;
            height: 32px;
            margin-right: 8px;
        }

        .name {
            font-size: 14px;
            color: $grey-07;
            letter-spacing: -0.2px;
            @include f-medium;
        }

        .agent-message {
            padding: 12px;
            border-radius: 0 16px 16px 16px;
            border: solid 1px $grey-02;
            color: $grey-09;
            line-height: normal;
            letter-spacing: -0.2px;

            .btn-more {
                text-align: end;
                text-decoration: underline;
            }
        }
    }

    .source-introduce {
        white-space: pre-line;

        .title {
            color: black;
            margin-bottom: 16px;
            font-size: 18px;
            @include f-medium;
        }

        .content {
            width: 100%;
            color: $grey-09;
            border-radius: 8px;
            line-height: 24px;
        }
    }
}
</style>
