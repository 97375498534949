<template>
    <div class="vacation">
        <span><span class="m-r-1">🏖</span>오늘은 쉬는 날이에요. 양해 부탁드려요! 🏝</span>
    </div>
</template>

<script>
export default {
    name: 'VacationStatus',
}
</script>

<style scoped lang="scss">
.vacation {
    width: 100vw;
    height: 32px;
    background-color: $grey-09;
    color: white;
    font-size: 14px;
    @include center;
    @include f-medium;
}
</style>
