<template>
    <div class="my-page" @scroll="onScroll">
        <div class="top-border" v-if="scrollTop !== 0" />
        <div class="padding" />
        <div class="basic-profile" @click="onClickMyProfile">
            <div class="img-wrapper">
                <div class="img-profile flex-wrap" v-img-cover="$store.getters.mainPhoto" />
                <i class="material-icons">search</i>
            </div>
            <div>
                <div class="name-nickname">
                    <div v-if="me.name">
                        <span class="name" v-html="me.name" />
                        <span class="nickname" v-html="`(${(me.profile || {}).nickname})`" />
                    </div>
                    <div v-else>
                        <span class="name" v-html="(me.profile || {}).nickname" />
                    </div>
                </div>
                <div class="age-height-gender" v-html="ageHeightGender" />
            </div>
        </div>

        <div class="verify-banner b" @click="onClickRealNameVerifyCreate">
            <div class="text">
                <span class="c-black f-regular f-15" v-html="'프로필 신뢰도'"></span>
                <div class="achive">
                    <span
                        class="point c-primary f-bold f-20"
                        v-html="`${trustBadgePercent()}<span class='f-14'>%</span>`"
                    ></span>
                    <i class="arrow material-icons c-primary">chevron_right</i>
                </div>
            </div>
            <div class="bar" :style="{ width: `${trustBadgePercent()}%` }" />
        </div>

        <EditProfileMenu />

        <div class="hr thick" />

        <MyPageMenu />
    </div>
</template>

<script>
import MyPageMenu from './components/MyPageMenu'
import EditProfileMenu from './components/EditProfileMenu'

export default {
    name: 'MyPage',
    components: {
        MyPageMenu,
        EditProfileMenu,
    },
    data: () => ({
        cta: `프로필 인증하면 <span class='f-bold'>바닐라 최대 250개</span>`,
        showVerificationBanner: false,
        scrollTop: 0,
    }),
    computed: {
        me() {
            return this.$store.getters.me || {}
        },
        isVerifiedUser() {
            return (this.$store.getters.myVerificationBadges || []).some(badge => badge.name === '실명인증')
        },
        gender() {
            return this.me.gender
        },
        ageHeightGender() {
            const { profile } = this.me
            if (!profile) return

            const age = this.$options.filters.asAge(profile.birthday)
            const gender = this.gender === 0 ? '남성' : '여성'

            return `${age}세<span>ㅣ</span>${profile.height}cm<span>ㅣ</span>${gender}`
        },
    },
    created() {
        this.$store.dispatch('loadMe')

        if (!this.$store.getters.verificationBadges) {
            this.$store.dispatch('loadVerificationBadges')
            this.$store.dispatch('loadMyVerificationBadges')
        }
    },
    methods: {
        onClickMyProfile() {
            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: 'SettingPage_Click_MyProfileDetail',
                },
            })

            this.$stackRouter.push({
                name: 'UserDetailPage',
                props: {
                    userId: this.me.id,
                },
            })
        },
        onClickRealNameVerifyCreate() {
            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: 'SettingPage_Click_TrustVerification',
                },
            })

            const badge = this.$store.getters.verificationBadges.find(b => b.name === '실명인증')
            if (badge) {
                this.$stackRouter.push({
                    name: 'VerificationTrustBadgesPage',
                })
            }
        },
        isTrustBadge(badge) {
            return badge.id >= 15 && badge.id <= 19
        },
        trustBadgePercent() {
            const badgeCnt = this.$isStudentByProfile() ? 3 : 5
            const num =
                ((this.me.verification_badges || []).filter(b => {
                    return this.isTrustBadge(b) && b.confirmed
                }).length /
                    badgeCnt) *
                100
            return Math.floor(num)
        },
        async onScroll({ target }) {
            this.scrollTop = target.scrollTop
        },
    },
}
</script>

<style lang="scss" scoped>
.my-page {
    .padding {
        height: 8px;
    }

    .top-border {
        position: absolute;
        top: 48px;
        height: 1px;
        width: 100vw;
        background-color: $grey-02;
    }

    .hr {
        height: 1px;
        background: $grey-02;

        &.thick {
            height: 8px;
        }
    }

    .basic-profile {
        display: flex;
        align-items: center;
        margin: 0 16px 16px;
        padding: 10px 16px;
        background-color: $grey-01;
        border-radius: 20px;

        .img-wrapper {
            position: relative;
            margin-right: 20px;

            .img-profile {
                width: 80px;
                height: 80px;
                border: solid 1px $grey-03;
            }

            .material-icons {
                position: absolute;
                border: solid 2px $grey-01;
                border-radius: 50%;
                background-color: $grey-08;
                color: white;
                font-size: 18px;
                bottom: 0;
                right: 0;
            }
        }

        .name-nickname {
            margin-bottom: 4px;
        }

        .name {
            color: black;
            font-size: 18px;

            @include f-medium;
        }

        .nickname {
            margin-left: 4px;
            font-size: 16px;
            color: $grey-08;

            @include f-demilight;
        }

        .age-height-gender {
            font-size: 15px;
            color: $grey-09;

            ::v-deep span {
                color: $grey-04;
            }
        }
    }

    .verify-banner {
        margin: 0 16px 16px 16px;
        padding: 13px 16px;
        height: 48px;
        background-image: linear-gradient(278deg, #ba90ff, $purple-primary);
        border-radius: 12px;

        @include between;

        &.b {
            // display: block;
            background-color: rgba(138, 116, 255, 0.08);
            background-image: none;

            display: block;
            align-items: center;
            justify-content: normal;

            .text {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .achive {
                    float: right;
                    min-width: 150px;
                    text-align: right;
                    .point {
                        // margin-left: 50%;
                        @include spoqa-f-bold;
                    }

                    .arrow {
                        vertical-align: top;
                        color: $purple-primary;
                        margin-right: -6px;
                    }
                }
            }

            .bar {
                height: 4px;
                width: calc(100% + 24px);
                margin-top: 6px;
                margin-left: -12px;
                border-radius: 2px;
                background-image: linear-gradient(271deg, #b475ff, $purple-primary) !important;
            }
        }
    }
}
</style>
