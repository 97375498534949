<template>
    <div
        class="textarea-with-x textarea-wrapper flex-row"
        :class="{ focus: focus, 'social-focus': focus && from === 'social_meeting' }"
    >
        <input
            v-if="isInputMode"
            ref="textarea"
            v-model="text"
            class="flex-fill"
            :placeholder="placeholder"
            @focus="onFocus"
            @blur="onBlur"
            @keyup="onKeyUp"
        />
        <textarea
            v-else
            ref="textarea"
            v-model="text"
            class="flex-fill"
            :placeholder="placeholder"
            @focus="onFocus"
            @blur="onBlur"
            @keyup="onKeyUp"
        />
        <i
            v-if="(text || '').length > 0"
            @click="clear"
            class="material-icons flex-wrap"
            style="height: 18px; display: block !important"
            >cancel</i
        >
    </div>
</template>

<script>
export default {
    name: 'TextareaWithX',
    props: ['value', 'placeholder', 'from', 'isInputMode'],
    data: () => ({
        text: '',
        focus: false,
    }),
    mounted() {
        setTimeout(() => {
            this.text = this.value
        })
    },
    methods: {
        onKeyUp() {
            setTimeout(() => {
                this.text = this.$refs.textarea.value
                this.$emit('input', this.text)
            })
        },
        clear() {
            this.text = ''
            this.$emit('input', this.text)
        },
        onFocus() {
            this.focus = true
            this.$emit('focus')
        },
        onBlur() {
            this.focus = false
            this.$emit('blur')
        },
    },
}
</script>
<!--<style scoped lang="scss">-->

<!--</style>-->
