import * as $http from 'axios'

export default {
    all: category => $http.get(`v2/verification_badges${category ? `?category=${category}` : ''}`),
    get: userId => {
        if (!userId) return Promise.reject()

        return $http.get(`v2/user_verification_badges?user_id=${userId}`)
    },
    create: (userId, payload) => $http.post(`v2/users/${userId}/user_verification_badges`, payload),
    updateBadge: payload => $http.post(`v2/user_verification_badges/update_badge`, payload),
}
