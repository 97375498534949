<template>
    <div class="event-banner">
        <Carousel :per-page="1" :paginationEnabled="false">
            <Slide class="review-item" :key="idx" v-for="(banner, idx) in banners">
                <BannerImages :idx="idx" :banner="banner" :bannerLength="banners.length" />
            </Slide>
        </Carousel>
    </div>
</template>

<script>
import Vue from 'vue'
import BannerImages from '@/routes/chat/chats/components/BannerImages'

export default {
    name: 'EventBanner',
    components: {
        BannerImages,
    },
    data: () => ({
        banners: [],
    }),
    computed: {
        me() {
            return this.$store.getters.me || {}
        },
        userSettings() {
            return this.$store.getters.settings || {}
        },
    },
    mounted() {
        this.initBanners()
    },
    methods: {
        initBanners() {
            console.log(this.me.gp_target)
            if (this.me.gp_target) {
                const hackleUser = this.$store.getters.hackleUser
                const manWord = this.$hackleClient.variation(27, hackleUser)
                const womanWord = this.$hackleClient.variation(28, hackleUser)
                if (this.me.gp_target === 'male') {
                    const maleEvent =
                        manWord === 'A'
                            ? 'click_gpbanner_male_A'
                            : manWord === 'B'
                            ? 'click_gpbanner_male_B'
                            : 'click_gpbanner_male_C'
                    this.banners = [
                        {
                            src:
                                manWord === 'A'
                                    ? require('@/assets/images/chat/banners/gp_promotion_male_1.png')
                                    : manWord === 'B'
                                    ? require('@/assets/images/chat/banners/gp_promotion_male_2.png')
                                    : require('@/assets/images/chat/banners/gp_promotion_male_3.png'),
                            handler: () => {
                                Vue.prototype.$nativeBridge.postMessage({
                                    action: 'openInAppBrowser',
                                    value:
                                        manWord === 'A'
                                            ? 'https://www.goodperson.date/lady?utm_source=vanillabridge&utm_medium=organic&utm_campaign=w_vanillabridge_banner&utm_content=zero'
                                            : manWord === 'B'
                                            ? 'https://www.goodperson.date/lady?utm_source=vanillabridge&utm_medium=organic&utm_campaign=w_vanillabridge_banner&utm_content=qualified'
                                            : 'https://www.goodperson.date/lady?utm_source=vanillabridge&utm_medium=organic&utm_campaign=w_vanillabridge_banner&utm_content=meetingfeedback',
                                })
                                Vue.prototype.$nativeBridge.postMessage({
                                    action: 'sendAirbridgeEvent',
                                    value: {
                                        category: maleEvent,
                                    },
                                })
                                this.$hackleClient.onReady(() => {
                                    this.variation = this.$hackleClient.track(maleEvent, hackleUser)
                                })
                            },
                        },
                        {
                            src: require('@/assets/images/chat/banners/goodbye_ons.png'),
                            handler: () => {
                                Vue.prototype.$stackRouter.push({ name: 'GoodbyeOnsCampaignPage' })
                            },
                        },
                    ]
                } else if (this.me.gp_target === 'female') {
                    const femaleEvent =
                        womanWord === 'A'
                            ? 'click_gpbanner_female_A'
                            : womanWord === 'B'
                            ? 'click_gpbanner_female_B'
                            : 'click_gpbanner_female_C'
                    this.banners = [
                        {
                            src:
                                womanWord === 'A'
                                    ? require('@/assets/images/chat/banners/gp_promotion_female_1.png')
                                    : womanWord === 'B'
                                    ? require('@/assets/images/chat/banners/gp_promotion_female_2.png')
                                    : require('@/assets/images/chat/banners/gp_promotion_female_3.png'),
                            handler: () => {
                                Vue.prototype.$nativeBridge.postMessage({
                                    action: 'openInAppBrowser',
                                    value:
                                        womanWord === 'A'
                                            ? 'https://www.goodperson.date/lady?utm_source=vanillabridge&utm_medium=organic&utm_campaign=w_vanillabridge_banner&utm_content=zero'
                                            : womanWord === 'B'
                                            ? 'https://www.goodperson.date/lady?utm_source=vanillabridge&utm_medium=organic&utm_campaign=w_vanillabridge_banner&utm_content=salary'
                                            : 'https://www.goodperson.date/lady?utm_source=vanillabridge&utm_medium=organic&utm_campaign=w_vanillabridge_banner&utm_content=certification',
                                })
                                Vue.prototype.$nativeBridge.postMessage({
                                    action: 'sendAirbridgeEvent',
                                    value: {
                                        category: femaleEvent,
                                    },
                                })
                                this.$hackleClient.onReady(() => {
                                    this.variation = this.$hackleClient.track(femaleEvent, hackleUser)
                                })
                            },
                        },
                        {
                            src: require('@/assets/images/chat/banners/goodbye_ons.png'),
                            handler: () => {
                                Vue.prototype.$stackRouter.push({ name: 'GoodbyeOnsCampaignPage' })
                            },
                        },
                    ]
                }
            } else {
                this.banners = [
                    {
                        src: require('@/assets/images/chat/banners/goodbye_ons.png'),
                        handler: () => {
                            Vue.prototype.$stackRouter.push({ name: 'GoodbyeOnsCampaignPage' })
                        },
                    },
                ]
            }
        },
    },
}
</script>

<style scoped lang="scss">
.event-banner {
    width: 100%;
    padding: 0 16px 16px 16px;

    ::v-deep .VueCarousel-slide {
        position: relative;
    }
}
</style>
