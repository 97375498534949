<template>
    <div class="refund-6-description center-refund">
        <div class="page-header">
            <div class="title m-b-28">환급 사유 작성</div>
            <div class="info">
                <div class="line1">
                    연락처 교환 후, <span class="f-bold">만남이 이루어지지 않게 된 상황</span>에 대해 자세하게
                    설명해주세요!
                </div>
                <div class="line2">
                    ex) 상대에게 주말에 만남을 갖자고 제안했으나, 바쁘다는 이유로 약속잡기를 미루다가 답장을 하지
                    않습니다.
                </div>
            </div>
        </div>
        <TextareaWithX class="m-l-20 m-r-20" v-model="description" :placeholder="'사유를 작성해주세요.'" />
        <BottomButton @click="goToNextPage" :disabled="description.length === 0" :label="'NEXT'" />
    </div>
</template>

<script>
export default {
    name: 'Refund6DescriptionPage',
    data: () => ({
        description: '',
    }),
    computed: {
        refund() {
            return this.$store.getters.payloads.refund
        },
        reason() {
            return this.refund.reason
        },
    },
    mounted() {
        if (this.refund.description) this.description = this.refund.description

        if (!this.reason) return this.$router.push({ name: 'HomePage' })
    },
    methods: {
        goToNextPage() {
            if (this.description.length === 0) {
                this.$alert('만남이 이루어지 않은 경위에 대해 알려주세요.')
                return
            }

            this.$store.commit('setPayloads', { refund: { ...this.refund, description: this.description } })

            this.$router.push({ name: 'Refund7PhotosPage' })
        },
    },
}
</script>
