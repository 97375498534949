<template>
    <div class="date-coach-basic">
        <div class="date-coach-layout card">
            <div class="content">
                <div class="card">
                    <div class="img-cover" v-img-cover="content.url" />
                    <div class="title" v-html="content.title" />
                    <div class="body">
                        <div ref="msg" class="msg flex-fill" :class="{ expanded: expanded }" v-html="content.msg" />
                        <div
                            v-if="shouldShowLoadMore"
                            @click="expand"
                            class="btn-load-more flex-wrap"
                            v-html="$translate('MORE')"
                        />
                        <button
                            v-if="content.cta"
                            @click="onClickAlimiCta"
                            class="btn btn-alimi"
                            v-html="content.cta"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="timestamp flex-wrap">{{ message.created_at | formatDate('A h:mm') }}</div>
    </div>
</template>
<script>
export default {
    name: 'ActionAlimiBasic',
    props: ['message'],
    data: () => ({
        shouldShowLoadMore: false,
        dom: null,
        expanded: false,
    }),
    mounted() {
        this.dom = this.$refs.msg
        if (!this.dom) return

        // 이거 이상하네... 일단 threshold를 10px로 줘봄
        this.shouldShowLoadMore = this.dom.scrollHeight > this.dom.clientHeight + 10

        this.message.content = this.message.content.replace(/=>/g, ':')
    },
    computed: {
        content() {
            return this.$mustParse(this.message.content) || {}
        },
    },
    methods: {
        async onClickAlimiCta() {
            if (!this.content.action) return

            if (this.content.action.includes('http://') || this.content.action.includes('https://')) {
                this.$nativeBridge.postMessage({
                    action: 'openInAppBrowser',
                    value: this.content.action,
                })
                return
            }

            // Do whatever this.content.action denotes
            switch (this.content.action) {
                case 'chats': {
                    const chat = (this.$store.getters.chats || []).find(c => c.id === this.content.chat_id)
                    if (chat !== null) {
                        await this.$store.dispatch('loadChat', {
                            chatId: chat.id,
                        })

                        this.$router.push({ name: 'ChatroomPage' })
                    } else {
                        this.$toast.error('대화방이 없습니다.')
                    }
                    break
                }
                default:
            }
        },
        expand() {
            this.expanded = true
            this.shouldShowLoadMore = false
        },
    },
}
</script>

<style lang="scss" scoped>
.date-coach-basic {
    display: flex;
    flex-direction: row;
    align-items: flex-end;

    .date-coach-layout {
        background: white;
        width: calc(100vw - 120px);
        max-width: 300px;

        .content {
            .card {
                .img-cover {
                    width: 100%;
                    height: 200px;
                }

                .title {
                    font-size: 16px;
                    color: black;
                    padding: 12px;
                    line-height: 24px;
                    margin-bottom: 12px;
                    font-family: NotoSans-Medium;
                }

                .body {
                    font-size: 13px;
                    line-height: normal;
                    letter-spacing: -0.2px;
                    color: $grey-09;
                    padding: 0 12px 12px;

                    .msg {
                        white-space: pre-line;

                        &:not(.expanded) {
                            overflow: hidden;
                            max-height: 120px;
                            @include lines-3;
                        }
                    }

                    .btn-load-more {
                        color: $purple-primary;
                        text-decoration: underline;
                        cursor: pointer;
                    }
                }

                .btn-alimi {
                    background: $grey-02;
                    color: $grey-09;
                    margin-top: 24px;
                    width: 100%;
                    @include f-regular;
                }
            }
        }
    }

    .timestamp {
        font-size: 11px;
        color: $grey-05;
    }
}
</style>
