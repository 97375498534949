<template>
    <div class="my-agent">
        <div class="top">
            <div class="agents-all">
                <div
                    class="agent"
                    v-for="agent in premiumFirstSorted"
                    :class="[$isPremium(agent.id) ? 'premium' : 'basic']"
                    :key="agent.id"
                    @click="goAgentPage(agent.id)"
                >
                    <div class="left">
                        <img :src="agent.photo_url" alt="" />
                    </div>
                    <div class="center">
                        <div class="highlight text-bold">
                            <span class="name" v-html="'담당 주선자<br>'" />
                        </div>
                        <div class="agent-name">
                            <span class="text-bold">{{ agent.name }}</span
                            >님
                        </div>
                        <div class="agent-rating">
                            <img
                                v-if="agent.ratings"
                                :src="require('@/assets/images/rating/icon_star_rate_active_noborder.svg')"
                            />
                            <img v-else :src="require('@/assets/images/rating/icon_star_inactive_silver.svg')" />
                            <span class="f-14" :class="{ estimating: !agent.ratings }">{{
                                agent.ratings || '측정 중'
                            }}</span>
                            (<span class="f-14">{{ agent.review || 0 }}</span
                            >)
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MyAgent',
    computed: {
        friends() {
            return this.$store.getters.friends || []
        },
        premiumFirstSorted() {
            const premium = []
            const basic = []
            this.friends.forEach(friend => {
                const { is_premium: isPremium } = friend.agent
                if (isPremium) premium.push(friend.agent)
                else basic.push(friend.agent)
            })
            return [...premium, ...basic]
        },
    },
    methods: {
        goAgentPage(id) {
            console.log('this:', this.agent)
            this.$stackRouter.push({
                name: 'AgentDetailPage',
                props: {
                    agentId: id,
                    from: 'MyAgent',
                },
            })
        },
    },
}
</script>

<style scoped lang="scss">
.my-agent {
    padding: 0;
    height: 102px;
    flex-shrink: 0;
    width: 100vw;

    .agents-all::-webkit-scrollbar {
        display: none;
    }
    .agents-all {
        overflow-x: auto;
        display: flex;
        margin-top: 20px;
        margin-bottom: 32px;

        &::before {
            content: '';
            min-width: 16px;
        }
        &::after {
            content: '';
            min-width: 16px;
        }
        .agent:nth-last-child(1) {
            margin-right: 0;
        }

        .agent {
            flex: none;
            width: calc(100vw - 32px);
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 12px;
            border-radius: 16px;
            background: $purple-review;
            margin-right: 16px;

            .left {
                display: flex;
                flex-direction: row;
                align-items: center;

                img {
                    width: 64px;
                    height: 64px;
                    border-radius: 25.6px;
                    margin-right: 20px;
                }
            }
            .center {
                flex: 1;
                display: flex;
                margin-left: auto;
                flex-direction: column;
                align-items: flex-start;
                .highlight {
                    color: #8a74ff;
                    font-weight: 700;
                    font-size: 14px;
                }
                .agent-name {
                    color: black;
                    font-size: 20px;
                    line-height: 30px;

                    span {
                        @include f-medium;
                    }
                }
                .agent-rating {
                    img {
                        width: 17px;
                        height: 17px;
                        margin-right: 3px;
                    }
                    span {
                        font-size: 16px;
                        line-height: 20px;
                        color: black;

                        &.estimating {
                            color: $grey-05 !important;
                            font-size: 16px !important;
                            line-height: 20px;
                            @include f-regular;
                        }
                    }
                }
            }
        }
    }
}
</style>
