<template>
    <div class="rating-options-selector">
        <div class="title m-b-4" v-html="selectorTitle" />
        <div class="sub-title m-b-28">복수 선택 가능</div>
        <ul class="options">
            <li
                v-for="option in ratingOptions"
                :key="option.id"
                class="option"
                :class="{ selected: option.selected }"
                @click="onClickOption(option)"
            >
                <span>{{ option.name }}</span>
                <div class="checkbox" :class="{ checked: option.selected }">
                    <i class="material-icons" v-if="option.selected">check</i>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'RatingOptionsSelector',
    props: ['ratingOptions', 'optionType'],
    computed: {
        selectorTitle() {
            return this.optionType === 'positive' ? '어떤 점이 좋으셨나요?' : '어떤 점이 아쉬우셨나요?'
        },
    },
    methods: {
        onClickOption(option) {
            this.$set(option, 'selected', !option.selected)
            this.$emit('onToggle', option)
        },
    },
}
</script>

<style lang="scss" scoped>
.rating-options-selector {
    .title {
        font-family: NotoSans-Medium;
        font-size: 18px;
        color: black;
    }

    .sub-title {
        font-size: 13px;
        color: $grey-06;
    }

    .options {
        margin: 0;
        padding: 0;
    }

    .option {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 16px;
        border-radius: 8px;
        border: solid 1px $grey-02;
        margin-bottom: 8px;

        &.selected {
            border-color: $purple-primary;
        }
    }
}
</style>
