<template>
    <div class="message flex-row" :class="[me ? 'justify-end' : '', friendType]">
        <div class="timestamp flex-wrap" v-if="showTimestamp && me">
            {{ message.created_at | formatDate('A h:mm') }}
        </div>
        <MessageAction
            v-if="message.mtype !== 'text' && message.mtype !== 'premium-counsel' && message.mtype !== 'intro-new-user'"
            class="flex-wrap"
            :message="message"
        />
        <MessageText
            v-if="message.mtype === 'text' || message.mtype === 'premium-counsel' || message.mtype == 'intro-new-user'"
            class="flex-wrap"
            :class="{ 'premium-me': premium && me, 'premium-agent': premium && !me }"
            :message="message"
        />
        <div class="timestamp flex-wrap" v-if="showTimestamp && !me">
            {{ message.created_at | formatDate('A h:mm') }}
        </div>
    </div>
</template>
<script>
import MessageAction from './MessageAction'
import MessageText from './MessageText'

export default {
    name: 'Message',
    props: ['message', 'premium', 'me'],
    components: {
        MessageAction,
        MessageText,
    },
    computed: {
        chat() {
            return this.$store.getters.chat
        },
        friendType() {
            if (this.chat.chat_type === 'real_friend') return 'real-friend'
            if (this.chat.chat_type === 'social_group') return 'social'

            const friendType = this.chat.expire_at ? 'recommend' : 'normal'

            this.$set(this.message, '$$friendType', friendType)
            return friendType
        },
        showTimestamp() {
            return (
                [
                    'multi-photo',
                    'profile-photo',
                    'photo',
                    'contact-done-sender',
                    'contact-done-receiver',
                    'gift-info',
                ].indexOf(this.message.mtype) === -1
            )
        },
    },
}
</script>
