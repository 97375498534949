<template>
    <div @click="onClick" class="bottom-button" :class="{ nonFixed: this.service === 'activation' || nonFixed }">
        <button
            class="btn btn-block"
            :class="disabled ? 'disabled' : btnColor"
            v-html="$translate(label || 'CONFIRM')"
        />
    </div>
</template>

<script>
export default {
    name: 'BottomButton',
    props: {
        label: String,
        disabled: Boolean,
        service: String,
        nonFixed: Boolean,
    },
    computed: {
        btnColor() {
            if (this.service === 'realfriendMeet') {
                return 'btn-facebook'
            } else if (this.service === 'todayMeet') {
                return 'btn-primary'
            } else if (this.service === 'socialMeet') {
                return 'btn-facebook'
            } else if (this.service === 'cancel_hostmeet') {
                return 'btn-normal'
            } else if (this.service === 'goodbye_ons') {
                return 'btn-facebook-grad'
            } else {
                return 'btn-primary'
            }
        },
    },
    methods: {
        onClick() {
            if (!this.disabled) {
                this.$emit('click')
            } else {
                if (this.service === 'refund') this.$toast.error('환급 사유를 선택해주세요')
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.bottom-button {
    &.nonFixed {
        position: static !important;
        padding: 0;
        border: none !important;
    }
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 16px 20px;
    border-top: 1px solid $grey-02;
    display: flex;
    align-items: flex-end;
    background: white;
    z-index: 3;

    button {
        height: 48px;
        font-size: 15px;

        &.btn-social {
            color: #fff;
            background: $social;
        }
    }
}
</style>
