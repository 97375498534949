<template>
    <div class="agent-message">
        <div class="message-wrapper m-r-4">
            <p class="name f-medium">주선자 {{ name }}:</p>
            <p class="message" v-html="message" />
        </div>
        <span class="time flex-wrap">{{ createdAt | formatDate('A h:mm') }}</span>
    </div>
</template>

<script>
export default {
    name: 'AgentMessage',
    props: {
        suggest: {
            type: Object,
            required: true,
        },
    },
    computed: {
        name() {
            return this.suggest.agent.name || ''
        },
        message() {
            return this.suggest.message || ''
        },
        createdAt() {
            return this.suggest.created_at
        },
    },
}
</script>

<style lang="scss" scoped>
.agent-message {
    align-items: flex-end;
    @include flex-row;

    .message-wrapper {
        padding: 8px 12px;
        border-radius: 0 12px 12px 12px;
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.06), 0 0 6px 0 rgba(138, 116, 255, 0.05);
        background-color: white;
        max-width: 260px;
    }

    @media (max-width: 360px) {
        .message-wrapper {
            max-width: calc(100vw - 100px);
        }
    }

    p {
        margin: 0;
    }

    .name {
        font-size: 13px;
        line-height: 20px;
        color: $grey-06;
    }

    .message {
        font-size: 15px;
        line-height: normal;
        color: $grey-09;
    }

    .time {
        color: $grey-05;
        font-size: 10px;
    }
}
</style>
