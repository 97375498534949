<template>
    <ProfileOptionSelector
        :model="'jobCategory'"
        :nextRoute="item => (item.verification_required ? 'CompanyPage' : 'JobPage')"
    />
</template>

<script>
export default {
    name: 'JobCategoryPage',
    mounted() {
        this.$nativeBridge.postMessage({
            action: 'sendFirebaseEvent',
            value: {
                category: 'signUpEvent',
                option: {
                    funnel: this.$route.name,
                    provider: this.$store.getters.payloads.signupData.provider || 'email',
                },
            },
        })
    },
}
</script>
