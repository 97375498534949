import * as $http from 'axios'

export default {
    // get
    all: (limit, lastId) => $http.get(`v2/introduces?limit=${limit || 15}${lastId ? `&last_id=${lastId}` : ''}`),
    mine: (limit, lastId) => $http.get(`v2/introduces/mine?limit=${limit || 15}${lastId ? `&last_id=${lastId}` : ''}`),
    interest: (limit, lastId) =>
        $http.get(`v2/introduces/interest?limit=${limit || 15}${lastId ? `&last_id=${lastId}` : ''}`),
    checkRefundCount: (introduceId, requestId) =>
        $http.get(`v2/introduces/${introduceId}/introduce_requests/${requestId}/check_refund_count`),

    // post
    create: payload => $http.post('v2/introduces', payload), // payload = { user_id, gender, age, state_id, title, text, photo_url },

    // test
    request: introductId => $http.post(`v2/introduces/${introductId}/introduce_requests`),

    // put
    check: (introduceId, introduceRequestId) => {
        $http.put(`v2/introduces/${introduceId}/introduce_requests/${introduceRequestId}/check`)
    },
    hostCheck: introduceId => $http.put(`v2/introduces/${introduceId}/introduce_requests/host_checked`),
    participantCheck: (introduceId, introduceRequestId) =>
        $http.put(`v2/introduces/${introduceId}/introduce_requests/${introduceRequestId}/participant_checked`),
    accept: (introduceId, introduceRequestId) =>
        $http.put(`v2/introduces/${introduceId}/introduce_requests/${introduceRequestId}/accept`),
    confirmMeeting: (introduceId, requestId, payload) =>
        $http.post(`v2/introduces/${introduceId}/introduce_requests/${requestId}/confirm_meeting`, payload), // status = 'meeting' | 'refund'
    confirmRefund: (introduceId, requestId, payload) =>
        $http.put(`v2/introduces/${introduceId}/introduce_requests/${requestId}/confirm_refund`, payload), // { refund_status: 1 | 2, refuse_description }
    updateViewCount: introduceId => $http.put(`v2/introduces/update_view_count`, { introduce_id: introduceId }),
}
