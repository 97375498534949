<template>
    <div class="suggested-pass-accept">
        <p class="info">
            <strong>패스/수락</strong>을 선택하면, 다음 프로필<strong class="c-primary">({{ count }}개 남음)</strong>로
            이동합니다!
        </p>
        <div class="flex-row w-100">
            <button v-if="alreadyAccepted" class="btn btn-primary" :disabled="true" v-html="acceptCTA" />
            <template v-else>
                <button class="btn btn-brd" @click="onClickButton('rejected')" v-html="$translate('PASS')" />
                <button class="btn btn-primary" @click="onClickAccept" v-html="acceptCTA" />
            </template>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SuggestedPassAcceptButtons',
    props: {
        suggest: {
            type: Object,
            required: true,
        },
        count: {
            type: Number,
        },
    },
    data: () => ({}),
    computed: {
        alreadyAccepted() {
            return this.suggest.status === 'user_accept' || this.suggest.status === 'agent_accept'
        },
        alreadyPassed() {
            return this.suggest.status === 'user_reject'
        },
        vanillaForAcceptSuggest() {
            const { vanilla } = this.$store.getters.settings || {}

            // return vanilla.accept_suggest || 3
            return vanilla.accept_suggest
        },
        acceptCTA() {
            return this.$translate('INTRODUCE_ME')
        },
        me() {
            return this.$store.getters.me
        },
    },
    methods: {
        async onClickButton(status) {
            try {
                this.$loading(true)

                if (status === 'rejected') {
                    const { msg } = await this.$store.dispatch('rejectSuggest', this.suggest)
                    this.$toast.success(msg)
                } else if (status === 'accepted') {
                    const { msg } = await this.$store.dispatch('acceptSuggest', this.suggest)
                    this.$store.dispatch('loadBadges')
                    this.$toast.success(msg)
                } else return

                this.$emit('action', status)
            } catch (e) {
                this.$toast.error(e.data.msg)
            } finally {
                this.$loading(false)
            }
        },
        onClickAccept() {
            this.$modal
                .basic({
                    title: 'ACCEPT_SUGGEST_INSTANT_TITLE',
                    body: this.$translate('ACCEPT_SUGGEST_INSTANT_BODY')
                        .replace('%s', this.vanillaForAcceptSuggest)
                        .replace('%s', this.suggest.agent.name),
                    buttons: [
                        {
                            label: 'CANCEL',
                            class: 'btn-primary',
                        },
                        {
                            label: 'CONFIRM',
                            class: 'btn-primary',
                        },
                    ],
                })
                .then(idx => {
                    if (idx === 1) {
                        this.onClickButton('accepted')
                    }
                })
        },
    },
    mounted() {},
}
</script>

<style lang="scss" scoped>
.suggested-pass-accept {
    align-items: center;
    @include flex;
}

.info {
    margin: 0 0 12px;
    font-size: 12px;

    strong {
        font-weight: normal;
        @include f-bold;
    }
}
</style>
