<template>
    <div class="floating-components">
        <template v-if="!isSocialOrRealFriend">
            <AgentEvaluationBanner v-if="!isInstantChat" />
            <VacationStatus v-if="chat.chat_type === 'pro_agent' && chat.user.vacation" />

            <!-- premium ad component-->
            <PremiumAd v-if="showPremiumAd" />

            <PremiumStatus v-if="isPremium" />

            <div class="instant-room" v-if="isInstantChat">
                <InstantUser />
            </div>
            <!--            <PremiumReceiverMessage v-if="isPremiumReceiver" :agentName="agentName" :agentId="agentId" />-->
            <PrivacyStatus v-if="isPrivacy" :isPremium="isPremium" :isInstantChat="!!isInstantChat" />
            <RefundStatus v-if="refundState" :refundState="refundState" />
        </template>

        <template v-else>
            <RealFriendSocialTop :chat="chat" />
        </template>
    </div>
</template>

<script>
import AgentEvaluationBanner from './AgentEvaluationBanner'
import PremiumStatus from './PremiumStatus'
import PrivacyStatus from './PrivacyStatus'
import InstantUser from './InstantUser'
import RealFriendSocialTop from './RealFriendSocialTop'
import VacationStatus from '@/routes/chat/chatroom/components/floating-components/VacationStatus'
import PremiumAd from '@/routes/chat/chatroom/components/floating-components/PremiumAd'
import RefundStatus from '@/routes/chat/chatroom/components/floating-components/RefundStatus'

export default {
    name: 'FloatingComponents',
    components: {
        AgentEvaluationBanner,
        PremiumStatus,
        PrivacyStatus,
        InstantUser,
        RealFriendSocialTop,
        VacationStatus,
        PremiumAd,
        RefundStatus,
    },
    props: {
        chat: {
            type: Object,
            required: true,
        },
    },
    computed: {
        isPrivacy() {
            if (!this.$store.getters.me) return false

            return this.$isPrivacy()
        },
        isPremium() {
            if (!this.chat) return false

            return this.$isPremium(this.agentId)
        },
        isMale() {
            return this.$store.getters.me.gender === 0
        },
        isMyPremiumAgent() {
            // 현재 채팅방이 나의 프리미엄 주선자와의 채팅방인 경우
            const { products } = this.$store.getters.me
            const isMyPremiumAgent = products.some(p => p.ptype === 'premium' && p.agent_id === this.chat.agent_id)

            return isMyPremiumAgent
        },
        refundState() {
            const refunds = this.$store.getters.refunds
            return (
                refunds.find(refund => {
                    return refund.status === 0 && refund.agent.id === this.agentId
                }) || false
            )
        },
        showPremiumAd() {
            return (
                this.chat.chat_type === 'pro_agent' &&
                this.isMale &&
                !this.$isPremium() &&
                !this.isInstantChat &&
                !this.$isTester() &&
                !this.refundState
            )
        },
        agentName() {
            return this.chat.user.name
        },
        agentId() {
            return this.chat.agent_id
        },
        isInstantChat() {
            return this.$isInstantChat(this.chat)
        },
        isSocialOrRealFriend() {
            return this.chat.chat_type === 'social_group' || this.chat.chat_type === 'real_friend'
        },
    },
    methods: {
        onClickPrivacy() {
            this.$modal.custom({
                component: 'ModalAlertNoX',
                options: {
                    title: 'SUPER_PRIVACY_ON',
                    body: `회원님의 동의가 없으면 누구에게도 프로필이 공개되지 않습니다.`,
                },
            })
        },
        privacyRemainDay() {
            const privacy = this.$store.getters.me.products.filter(p => p.ptype === 'privacy' && p.enabled === true)
            if (privacy === null || privacy === []) return

            const validUntil = privacy[0].valid_until.split('T')[0]

            return this.$moment(validUntil).diff(this.$moment(), 'days')
        },
    },
}
</script>

<style lang="scss" scoped>
.floating-components {
    position: absolute;
    top: 42px;
    left: 0;
    right: 0;
    padding-bottom: 2px;
    z-index: 3;
}
</style>
