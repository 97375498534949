<template>
    <div class="dormant">
        <div v-if="fromStackRouter" class="page-header">
            <StackRouterHeaderBar />
        </div>
        <div class="dormant-body" v-if="this.me.is_dormant">
            <p><span class="c-primary f-bold">계정을 활성화</span>하고,</p>
            <p>다시 주선자에게 소개팅을 받아보세요!</p>
        </div>
        <div class="dormant-body" v-else>
            <p>회원님의 계정이 <span class="c-primary f-bold">휴면 처리</span>됩니다.</p>
            <p>언제든 다시 활성화할 수 있으며,</p>
            <span class="f-bold">주선자와 친구 상태는 유지</span>되니 안심하세요!
        </div>
        <div class="dormant-btn">
            <button
                class="item btn btn-primary"
                @click="askDormant"
                v-html="this.me.is_dormant ? $translate('ACTIVATE') : $translate('DORMANT')"
            />
        </div>
    </div>
</template>

<script>
import userService from '@/services/user'

export default {
    name: 'dormantPage',
    computed: {
        me() {
            return this.$store.getters.me || {}
        },
        fromStackRouter() {
            return this.$store.getters.pageStack.some(stack => stack.name === 'DormantPage')
        },
    },
    mounted() {
        this.$registerBackHandler(() => {
            if (this.me.is_dormant) {
                this.$toast.error('휴면을 해지해주세요')
                return false
            }
            return true
        })
        this.$nativeBridge.postMessage({
            action: 'allowSwipeGesture',
            value: false,
        })
    },
    beforeDestroy() {
        this.$nativeBridge.postMessage({
            action: 'allowSwipeGesture',
            value: true,
        })
    },
    methods: {
        async dormant() {
            try {
                const ret = await userService.dormant({
                    dormant: !this.me.is_dormant,
                })

                if (ret.is_dormant === null || ret.is_dormant === undefined) {
                    this.$modal
                        .basic({
                            title: 'MODAL_DEFAULT_TITLE',
                            body: ret.msg,
                            buttons: [
                                {
                                    label: 'CONFIRM',
                                    class: 'btn-default',
                                },
                            ],
                        })
                        .then(() => {
                            this.$stackRouter.clear()
                            this.$router.push({ name: 'MyDatingPage' })
                        })
                } else {
                    this.me.is_dormant = ret.is_dormant

                    if (!this.me.is_dormant) {
                        this.$router.push({ name: 'HomePage' })
                        await this.$store.dispatch('afterAuthCallbacks', { isSignIn: false })
                    }
                }
            } catch (e) {
                this.$toast.error(e.data)
            }
        },
        askDormant() {
            if (this.me.is_dormant) {
                this.dormant()
            } else {
                this.$modal
                    .basic({
                        title: 'DORMANT_TITLE',
                        body: this.$translate('DORMANT_BODY'),
                        buttons: [
                            {
                                label: 'CANCEL',
                                class: 'btn-default',
                            },
                            {
                                label: 'DORMANT',
                                class: 'btn-primary',
                            },
                        ],
                    })
                    .then(selectedBtnIdx => {
                        if (selectedBtnIdx === 1) this.dormant()
                    })
            }
        },
    },
}
</script>
