// 각 페이지의 상태(스크롤 위치, 선택된 탭 등)를 기억하기 위한 스토어
const defaultState = () => ({
    pageStates: {
        agentList: {
            scrollTop: null,
            agentType: 'pro',
        },
        chats: {
            scrollTop: null,
            tab: 'ALL',
        },
        myDating: {
            tab: 'REQUESTED',
            scrollTopFriends: null,
        },
        // 한 번에 한 주선자의 친구만 들고 있으면 될 듯 함
        agentFriends: {
            friends: null,
            scrollTop: null,
        },
    },
})

const state = defaultState()

const getters = {
    pageStates: state => state.pageStates,
}

const actions = {}

const mutations = {
    /*
        USAGE:
        this.$store.commit('setPageStates', {
            agentList: {
                scrollTop: 320,
            },
        }
    */
    setPageStates(state, pageStates) {
        Object.keys(pageStates).forEach(pageName => {
            Object.keys(pageStates[pageName]).forEach(key => {
                state.pageStates[pageName][key] = pageStates[pageName][key]
            })
        })
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
    defaultState,
}
