<template>
    <div class="reset-password">
        <div class="page-header">
            <div class="title" v-html="$translate('RESET_PASSWORD')" />
        </div>
        <div class="body">
            <div class="email" v-html="$route.params.email" />
            <div class="form-control">
                <input
                    type="password"
                    :placeholder="$translate('PLACEHOLDER_PASSWORD')"
                    v-model="password"
                    @input="onInputPassword"
                />
            </div>
            <div class="form-control">
                <input
                    type="password"
                    :placeholder="$translate('PLACEHOLDER_PASSWORD_CONFIRM')"
                    v-model="passwordConfirm"
                    @input="onInputPasswordConfirm"
                />
            </div>
            <div v-if="error" class="error">
                <i class="material-icons-outlined">info</i>
                {{ error | translate }}
            </div>
        </div>
        <div class="bottom">
            <button
                @click="onClickResetPassword"
                class="btn btn-primary btn-block"
                :disabled="disabled"
                v-html="$translate('RESET_PASSWORD')"
            />
        </div>
    </div>
</template>

<script>
import userService from '@/services/user'

export default {
    name: 'ResetPasswordPage',
    data: () => ({
        password: null,
        passwordConfirm: null,
        error: null,
        disabled: true,
    }),
    mounted() {
        if (!this.$route.params.email || !this.$route.params.userId || !this.$route.params.responseId) {
            this.$router.replace({ name: 'FrontPage' })
        }
    },
    methods: {
        checkPassword() {
            if (this.password && this.password.length < 6) {
                this.error = '비밀번호는 6자리 이상이어야 합니다.'
                this.disabled = !!this.error
                return
            }

            if (this.password && this.passwordConfirm && this.password !== this.passwordConfirm) {
                this.error = 'ERROR_PASSWORD_CONFIRM'
                this.disabled = !!this.error
                return
            }

            this.error = null
            this.disabled = !this.password || !this.passwordConfirm
        },
        onInputPassword(event) {
            this.password = event.target.value
            this.checkPassword()
        },

        onInputPasswordConfirm(event) {
            this.passwordConfirm = event.target.value
            this.checkPassword()
        },
        async onClickResetPassword() {
            try {
                const data = await userService.resetPassword({
                    userId: this.$route.params.userId,
                    responseId: this.$route.params.responseId,
                    newPassword: this.password,
                })
                this.$modal
                    .basic({
                        title: 'MODAL_DEFAULT_TITLE',
                        body: data.msg,
                        buttons: [
                            {
                                label: 'CONFIRM',
                                class: 'btn-primary',
                            },
                        ],
                    })
                    .then(idx => {
                        if (idx === 0) this.$router.push({ name: 'LoginPage' })
                    })
            } catch (e) {
                this.$toast.error(e.data)
            }
        },
    },
}
</script>
