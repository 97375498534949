<template>
    <div class="chats bg-white">
        <Disconnected v-if="!loading" />
        <Loading :loading="loading" />

        <div v-show="!loading" class="ver-scroll layout" @scroll="onScroll">
            <div class="top-border" v-if="scrollTop !== 0" />
            <div class="padding" />
            <EventBanner />
            <div ref="ver-scroll" class="flex-fill">
                <SuggestedDatingChatItem v-if="suggests.length > 0" :chat="suggestChatItem" />
                <component v-for="chat in chats" :is="chatType(chat)" :chat="chat" :key="chat.id" />
            </div>

            <div class="empty f-bold" v-if="!chats">
                <div class="empty-title" v-html="$translate('CHAT_EMPTY')" />
            </div>
        </div>
    </div>
</template>

<script>
import ChatItem from './components/ChatItem'
import SuggestedDatingChatItem from './components/SuggestedDatingChatItem'
import ProductIcons from './components/ProductIcons'
import Disconnected from '@/components/common/Disconnected'
import EventBanner from '@/routes/chat/chats/components/EventBanner'
import ChatsPage from '@/routes/chat/chats/ChatsPage'
import { onMounted } from '@vue/composition-api'

export default {
    name: 'ChatsPage',
    components: {
        ChatItem,
        Disconnected,
        SuggestedDatingChatItem,
        ProductIcons,
        EventBanner,
    },
    props: {},
    setup(props, { root: { $isTester, $modal, $store, $route } }) {
        const { makeComputed, chatType, isDateCoach, onScroll, scrollTop } = ChatsPage()
        const { loading, connected, chats, me, settings, suggests, suggestChatItem } = makeComputed($store.getters)

        const init = () => {
            if ($store.getters.chat && !$route.params.preventSetChatNull) {
                $store.commit('setChat', { chat: null })
            }
            $store.dispatch('loadRefunds', { force: true })
            $store.dispatch('loadSuggests')
        }
        // const showModalIntroduction = () => {
        //     if (
        //         window.localStorage.getItem('readIntroduction') === 'true' ||
        //         $isTester() ||
        //         $route.params.preventSetChatNull
        //     ) {
        //         if ((settings.value || {}).show_woojoomarry && !localStorage.getItem('watch-wm-promotion')) {
        //             $modal.custom({ component: 'ModalWmPromotion' })
        //         }
        //         return
        //     }

        //     $modal.custom({ component: 'ModalIntroduction' }).then(() => {
        //         if ((settings.value || {}).show_woojoomarry && !localStorage.getItem('watch-wm-promotion')) {
        //             $modal.custom({ component: 'ModalWmPromotion' })
        //         }
        //     })
        // }
        onMounted(() => init())
        // onMounted(() => showModalIntroduction())

        return {
            loading,
            connected,
            chats,
            suggests,
            me,
            settings,
            suggestChatItem,
            chatType,
            isDateCoach,
            onScroll,
            scrollTop,
        }
    },
}
</script>

<style lang="scss" scoped>
.chats {
    .layout {
        height: 100%;
        @include flex;

        .padding {
            height: 8px;
        }

        .top-border {
            position: absolute;
            top: 48px;
            height: 1px;
            width: 100vw;
            background-color: $grey-02;
        }
    }

    .banner {
    }

    .empty {
        margin: 24px;
        text-align: center;

        img {
            width: 40px;
            margin-bottom: 16px;
        }
    }

    .chats-tab {
        .count {
            margin-top: -2px;
        }
    }

    .chat-title {
        margin: 0 16px 16px;
        font-size: 24px;
        color: black;
        line-height: normal;
        font-weight: 500;
        letter-spacing: -0.24px;
    }
}
</style>
