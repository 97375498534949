<template>
    <div
        class="progress-bar"
        :class="overlayClass"
        :style="{
            height: `${height || 20}px`,
        }"
    >
        <div
            class="progress-fill"
            :class="fillClass"
            :style="{
                width: `${percent}%`,
            }"
        />
    </div>
</template>

<script>
export default {
    name: 'ProgressBar',
    props: ['height', 'percent', 'overlayClass', 'fillClass'],
}
</script>

<style lang="scss" scoped>
.progress-bar {
    border-radius: 6px;
    overflow: hidden;
    position: relative;
    background: $grey-01;

    .progress-fill {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        background: linear-gradient(276deg, #b475ff, #8a74ff);
        transition: all 0.2s ease;
    }
}
</style>
