<template>
    <div class="refund-5-exclude center-refund">
        <div class="page-header">
            <div class="title m-b-28">환급 제외 사유 확인</div>
            <div class="info">
                아래는 <span class="f-bold c-primary">환급 제외 사유</span>입니다.<br />
                해당이 되지 않는다면 체크를 눌러주세요!
            </div>
        </div>
        <div class="exclusions">
            <div
                :key="exclude.id"
                v-for="exclude in excludes"
                :class="{ checked: exclude.checked }"
                class="exclusion"
                @click="checkExclusion(exclude)"
            >
                <div class="contents">
                    <div class="title" v-html="exclude.title"></div>
                    <div v-if="exclude.description" class="description" v-html="exclude.description"></div>
                </div>
                <i class="material-icons">check_circle</i>
            </div>
        </div>
        <BottomButton @click="goToNextPage" :disabled="disabled" :label="'확인했어요'" />
    </div>
</template>

<script>
import centerService from '@/services/center'

export default {
    name: 'Refund5ExcludePage',
    data: () => ({
        excludes: [],
    }),
    mounted() {
        if (!this.reason) return this.$router.push({ name: 'HomePage' })

        this.loadExcludes()
    },
    computed: {
        refund() {
            return this.$store.getters.payloads.refund
        },
        reason() {
            return this.refund.reason
        },
        disabled() {
            return this.excludes.length === 0 || !this.excludes.every(e => e.checked)
        },
    },
    methods: {
        async loadExcludes() {
            try {
                this.$loading(true)
                const { refund_exclude } = await centerService.refundExcludes()
                this.excludes = refund_exclude.map(e => {
                    this.$set(e, 'checked', false)
                    return e
                })
            } catch (e) {}
            this.$loading(false)
        },
        checkExclusion(exclude) {
            if (!exclude) return

            this.excludes.forEach(e => {
                if (e.id === exclude.id) e.checked = !e.checked
            })
        },
        goToNextPage() {
            if (this.disabled) {
                this.$alert('체크하지 않은 항목이 있습니다')
                return
            }

            this.$router.push({ name: 'Refund6DescriptionPage' })
        },
    },
}
</script>
