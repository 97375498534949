<template>
    <div class="agent-evaluation-options">
        <div class="header bordered">
            <i class="material-icons" @click="$stackRouter.pop()">chevron_left</i>
            <h2 class="page-title">주선자 평가</h2>
            <button
                class="btn btn-text"
                :class="{ disabled: disabled }"
                :disabled="disabled"
                v-html="$translate(step === 'selectOptions' ? 'NEXT' : 'SUBMIT')"
                @click="next"
            />
        </div>
        <div class="body">
            <EvaluatedAgentProfile :agent="agent" :starRating="starRating" />
            <hr class="hor-divider" />
            <RatingOptionsSelector
                v-if="step === 'selectOptions'"
                :ratingOptions="currentOptionSet"
                :optionType="optionType"
                @onToggle="onToggleOption"
            />
            <EvaluationReviewInput v-if="step === 'writeReview'" @onInput="onInput" />
        </div>
    </div>
</template>

<script>
import EvaluatedAgentProfile from './components/EvaluatedAgentProfile'
import RatingOptionsSelector from './components/RatingOptionsSelector'
import EvaluationReviewInput from './components/EvaluationReviewInput'

import commonService from '@/services/common'
import agentService from '@/services/agent'

export default {
    name: 'AgentEvaluationOptionsPage',
    components: {
        EvaluatedAgentProfile,
        RatingOptionsSelector,
        EvaluationReviewInput,
    },
    props: {
        optionType: {
            type: String,
            require: true,
        },
    },
    data: () => ({
        ratingOptions: [],
        selectedPositiveOptionIds: [],
        selectedNegativeOptionIds: [],
        step: '',
        review: '',
    }),
    computed: {
        starRating() {
            return this.$store.getters.payloads.agentEvaluationData.starRating
        },
        agent() {
            return this.$store.getters.payloads.agentEvaluationData.agent
        },
        currentOptionSet() {
            return this.ratingOptions.filter(option => {
                return (
                    option.option_type === this.optionType &&
                    this.starRating >= option.min_rate &&
                    this.starRating <= option.max_rate
                )
            })
        },
        disabled() {
            if (this.step === 'writeReview') return false

            return this.optionType === 'positive'
                ? this.selectedPositiveOptionIds.length === 0
                : this.selectedNegativeOptionIds.length === 0
        },
    },
    mounted() {
        this.init()
        this.$registerBackHandler(this.backHandler)
    },
    methods: {
        async init() {
            this.step = 'selectOptions'
            try {
                this.ratingOptions = await commonService.ratingOptions()
            } catch (e) {}
        },
        onToggleOption(option) {
            if (!option) return

            if (option.selected) {
                this.optionType === 'positive'
                    ? this.selectedPositiveOptionIds.push(option.id)
                    : this.selectedNegativeOptionIds.push(option.id)
            } else {
                if (this.optionType === 'positive') {
                    const idx = this.selectedPositiveOptionIds.findIndex(id => {
                        return id === option.id
                    })

                    if (idx > -1) {
                        this.selectedPositiveOptionIds.splice(idx, 1)
                    }
                } else {
                    const idx = this.selectedNegativeOptionIds.findIndex(id => {
                        return id === option.id
                    })

                    if (idx > -1) {
                        this.selectedNegativeOptionIds.splice(idx, 1)
                    }
                }
            }
        },
        next() {
            if (this.step === 'writeReview') this.submit()

            if (this.disabled) return

            if (this.optionType === 'positive' && (this.starRating === 3 || this.starRating === 4)) {
                this.optionType = 'negative'
                return
            }

            if ((this.optionType === 'positive' && this.starRating === 5) || this.optionType === 'negative') {
                this.finish()
            }
        },
        finish() {
            const data = this.$store.getters.payloads.agentEvaluationData
            data.ratingOptions = [...this.selectedPositiveOptionIds, ...this.selectedNegativeOptionIds]
            this.$store.commit('setPayloads', {
                agentEvaluationData: data,
            })
            this.step = 'writeReview'
        },
        async submit() {
            const { ratingOptions } = this.$store.getters.payloads.agentEvaluationData

            try {
                const { msg } = await agentService.agentRatings(this.agent.id, {
                    rating_options: ratingOptions,
                    rtype: 'friend',
                    rate: this.starRating,
                    review: this.review,
                })
                this.$store.commit('setPayloads', {
                    agentEvaluationData: {},
                })
                this.$toast.success(msg)
                this.$stackRouter.clear()
                setTimeout(() => this.$router.push({ name: 'ChatsPage' }), 500)
            } catch (e) {
                console.error(e)
            }
        },
        onInput(review) {
            this.review = review
        },
        backHandler() {
            if (this.step === 'selectOptions') {
                if (this.optionType === 'negative' && this.starRating > 2) {
                    this.optionType = 'positive'
                } else {
                    this.$unregisterBackHandler()
                    return true
                }
            } else if (this.step === 'writeReview') {
                this.step = 'selectOptions'
            }

            return false
        },
    },
}
</script>

<style lang="scss" scoped>
.agent-evaluation-options {
    line-height: normal;

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px;
        height: $header-height;

        &.bordered {
            border-bottom: solid 1px $grey-03;
        }

        .page-title {
            font-family: NotoSans-Medium;
            font-size: 16px;
            color: black;
            margin: 0;
            font-weight: normal;
        }

        .btn {
            color: $purple-primary;
            font-size: 16px;
            height: 24px;

            &.disabled {
                color: $grey-05;
                background-color: white;
            }
        }
    }

    .body {
        display: flex;
        flex-direction: column;
        padding: 0 16px 32px;
        overflow-y: scroll;
    }

    .hor-divider {
        height: 1px;
        background-color: $grey-02;
        margin-bottom: 32px;
    }
}
</style>
