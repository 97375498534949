<template>
    <div class="waiting">
        <div class="top">
            <span class="title">진행중인 소개팅</span>
        </div>
        <EmptyList v-if="waitingApi.length === 0" :showImage="false" :message="'진행중인 소개팅이 없어요 😢'" />
        <div class="accepted-suggest">
            <div
                class="accepted-suggest-item m-r-12 m-b-20"
                v-for="(item, idx) in waitingCli"
                v-show="idx < waitingCount"
                :key="item.id"
            >
                <div class="accepted-suggest-item" @click="goToUserDetail(item)">
                    <img
                        :class="{ 'accepted-suggest-item-image-rejected': item.status === 'rejected' }"
                        :src="considerBlankProfile(item)"
                        @error="$event.target.src = $blankProfile"
                    />
                    <div class="accepted-suggest-item-container">
                        <div class="accepted-suggest-item-text-agent m-b-5">
                            <span
                                >주선자
                                <span class="agent-name">{{ item.agent.name }}</span>
                            </span>
                        </div>

                        <div class="accepted-suggest-item-text-nickname">
                            {{ item.user.nickname || '---' }},
                            {{ item.user.birthday | asAge }}
                        </div>
                        <div v-if="item.status === 'opened'" class="acceptedSuccess" />

                        <div class="accepted-suggest-item-text">
                            <span :class="{ 'c-text-lighter': item.status === 'rejected' }">
                                {{ acceptedStatus(item.status) }}
                            </span>
                            <i
                                v-if="item.status !== 'rejected'"
                                class="material-icons m-l-4 f-18"
                                @click.stop="showStatusModal(item.status)"
                            >
                                help_outline
                            </i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <button v-if="hasMoreWaiting" class="btn btn-block" @click="addMaxWaiting(3)">더 보기</button>
    </div>
</template>

<script>
import suggestService from '@/services/suggest'
import EmptyList from '@/components/common/EmptyList'

export default {
    name: 'SuggestedWaiting',
    components: { EmptyList },
    data: () => ({
        waitingApi: [],
        waitingCli: [],
        showRejectedWaiting: true,
        waitingCount: null,
    }),
    watch: {
        showRejectedWaiting: {
            handler: function (newVal) {
                window.localStorage.setItem('showRejectedWaiting', `${newVal}`)
                this.setWaitingCli()
            },
        },
    },
    computed: {
        hasMoreWaiting() {
            return this.waitingCli.length > this.waitingCount
        },
    },
    mounted() {
        this.loadWaiting()
        // this.initShowRejectedWaiting()
        this.waitingCount = 3
        document.addEventListener('click', this.onClickOutsideSnackBar)
    },
    beforeDestroy() {
        document.removeEventListener('click', this.onClickOutsideSnackBar)
    },
    methods: {
        async loadWaiting() {
            const { waiting } = await suggestService.waiting()
            this.waitingApi = waiting || []
            this.setWaitingCli()
        },
        initShowRejectedWaiting() {
            const data = window.localStorage.getItem('showRejectedWaiting')
            if (!data) {
                // localStorage does not allow boolean data
                window.localStorage.setItem('showRejectedWaiting', 'false')
                this.showRejectedWaiting = false
            } else {
                this.showRejectedWaiting = data !== 'false'
            }
        },
        setWaitingCli() {
            if (this.showRejectedWaiting) {
                this.waitingCli = this.waitingApi
            } else {
                this.waitingCli = this.waitingApi.filter(item => item.status !== 'rejected')
            }
        },
        acceptedStatus(status) {
            return this.$translate(`SUGGESTED_STATUS_${status.toUpperCase()}`)
        },
        onClickOutsideSnackBar(event) {
            if (!event.target.closest('.waiting-snackbar-icon')) {
                this.hideWaitingSnackBar = true
            }
        },
        showWaitingSnackBar() {
            this.hideWaitingSnackBar = !this.hideWaitingSnackBar
        },
        addMaxWaiting(num) {
            this.waitingCount += num
        },
        considerBlankProfile(item) {
            if (this.isDormantUser(item)) return this.$blankProfile

            return item.photo1_url || item.photo2_url || this.$blankProfile
        },
        isDormantUser(item) {
            return item.user.is_dormant
        },
        isDropOutUser(item) {
            return item.user.nickname === null && item.photo1_url === null && item.photo2_url === null
        },
        goToUserDetail(item) {
            // 휴면: is_dormant === false
            // 탈퇴: nickname, photo1_url, photo2_url === null
            if (this.isDormantUser(item) || this.isDropOutUser(item)) {
                this.$toast.error('탈퇴 혹은 휴면했거나 활동이 정지된 회원입니다.')
                return
            }

            try {
                const photos = []
                const photo1 = { url: item.photo1_url }
                const photo2 = { url: item.photo2_url }
                photos.push(photo1, photo2)

                this.$stackRouter.push({
                    name: 'UserDetailPage',
                    props: {
                        from: 'SuggestedWaiting',
                        userId: item.user.id,
                        photos: photos,
                        agent: item.agent,
                        agentId: item.agent.id,
                        agentMessage: item.message,
                        agentPhoto: item.agent.photo_url,
                        status: item.status,
                    },
                })
            } catch (e) {}
        },
        showStatusModal(status) {
            this.$modal.custom({
                component: 'ModalSuggestedStatus',
                options: {
                    title: this.acceptedStatus(status),
                    body: this.$translate(`MODAL_${status.toUpperCase()}_BODY`),
                    status: status,
                },
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.waiting {
    position: relative;
    padding-bottom: 4px;

    &-snackbar {
        top: 87px;
        left: 16px;
        font-size: 14px;
        padding: 12px;
        white-space: nowrap;
    }

    .switch-wrapper {
        width: 100%;
        height: 40px;
        border-radius: 8px;
        border: solid 1px $grey-02;
        padding: 0 16px;
        margin-bottom: 20px;

        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        &-front {
            display: flex;
            flex-direction: row;
            align-items: center;
            font-size: 14px;
            color: $grey-09;
        }
    }

    .accepted-suggest {
        &-item {
            display: flex;

            img {
                width: 80px;
                height: 80px;
                border-radius: 32px;
                border: 1px solid $grey-02;
                margin-right: 20px;
            }
            &-image-rejected {
                opacity: 0.4;
            }

            &-container {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }

            &-text-agent > span {
                white-space: nowrap;
                background-color: $grey-01;
                color: $grey-08;
                padding: 1px 8px;
                font-size: 12px;
                border-radius: 6px;

                .agent-name {
                    @include f-medium;
                }
            }
            &-text-nickname {
                @include f-medium;
                font-size: 16px;
                color: black;
            }

            &-text {
                display: flex;
                align-items: center;
                color: $purple-primary;
                font-size: 13px;
                @include f-medium;

                i {
                    color: $purple-primary;
                }
            }
        }
    }
    .btn-block {
        border: 1px solid $grey-03;
        color: $grey-08;
    }
}
</style>
