import * as $http from 'axios'

export default {
    all: () => $http.get('v2/suggests'),
    accept: suggestId => $http.put(`v2/suggests/${suggestId}/user_accept`),
    reject: suggestId => $http.put(`v2/suggests/${suggestId}/user_reject`),
    update: (suggestId, payload) => $http.put(`v2/suggests/${suggestId}`, payload),
    waiting: () => $http.get('v2/suggests/waiting'),
    checked: () => $http.put('v2/suggests/user_checked'),
    userSuggests: agentId => $http.get(`v2/suggests/user_suggests?agent_id=${agentId}`),
}
