import * as $http from 'axios'

export default {
    all: () => $http.get('v2/chats'),
    detail: chatId => $http.get(`v2/chats/${chatId}`),
    // tester chat_id = 3147592, 3126298
    block: chatId => $http.put(`v2/chats/${chatId}/block`),
    findFriend: chatId => $http.get(`v2/chats/${chatId}/find_friends`),
    messages: (chatId, firstId) => $http.get(`v2/chats/${chatId}/messages?first_id=${firstId}`),
    // payload: { user_id: 대화 상대 (chatUser), source_id: 소개할 사람 (introducingUser) }
    sendPhotoMessage: (chatId, data) => $http.post(`v2/chats/${chatId}/send_photo`, data),
    acceptContact: (chatId, messageId) => $http.put(`v2/chats/${chatId}/messages/${messageId}/accept_contact`, {}),
    acceptDating: (chatId, messageId) => $http.put(`v2/chats/${chatId}/messages/${messageId}/accept_dating`, {}),
    requestProfile: (chatId, userId) => $http.post(`v2/chats/${chatId}/request_profile`, { source_id: userId }),
    acceptProfile: (chatId, messageId, acceptStatus) =>
        $http.put(`v2/chats/${chatId}/messages/${messageId}/accept_status`, {
            accept_status: acceptStatus,
        }),
    openProfile: (chatId, messageId) => $http.put(`v2/chats/${chatId}/messages/${messageId}/open_profile`),
    openCounselChat: userId => $http.post(`v2/chats/open_counsel_chat`, { user_id: userId }),
    requestIntroduce: (chatId, payload) => $http.post(`v2/chats/${chatId}/messages/request_introduce`, payload),
    acceptIntroduce: (chatId, messageId) => $http.put(`v2/chats/${chatId}/messages/${messageId}/accept_introduce`, {}),
    closeChat: chatId => $http.put(`v2/chats/${chatId}/close`),
    referral: (chatId, payload) => $http.post(`v2/chats/${chatId}/referral`, payload),
}
