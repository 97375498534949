<template>
    <div class="chat-item" @click="onClickChatItem">
        <div class="flex-row">
            <ChatItemPhoto class="center flex-wrap m-r-12" :src="photoUrl" :isSuggest="true" />
            <div class="content-wrapper">
                <div class="info">
                    <div class="name-and-badge">
                        <div class="name lines-1 flex-wrap f-medium">소개팅 제안</div>
                    </div>
                    <div class="last-message lines-2 m-t-8" v-html="lastMessage" />
                </div>
                <div class="right flex-wrap">
                    <div class="timestamp flex-wrap" :class="{ 'o-0': lastMessageAt === 'NONE' }">
                        {{ lastMessageAt }}
                    </div>
                    <div v-if="chat.unread > 0" class="badge badge-count flex-wrap m-t-12">
                        {{ chat.unread }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ChatItemPhoto from './ChatItemPhoto'

export default {
    name: 'SuggestedDatingChatItem',
    props: {
        chat: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        photoUrl: require('@/assets/images/chat/requested_dating.svg'),
    }),
    components: { ChatItemPhoto },
    computed: {
        lastMessage() {
            return `주선자 ${this.chat.agent.name}님의 소개팅 제안이 도착했어요!`
        },
        lastMessageAt() {
            const result = this.$options.filters.formatDate(this.chat.created_at, 'chatList') || 'NONE'

            return result
        },
    },
    methods: {
        onClickChatItem() {
            this.$router.push({ name: 'SuggestedDatingPage' })
        },
    },
}
</script>

<style lang="scss" scoped>
.chat-item {
    user-select: none;
    padding: 12px 16px;

    .content-wrapper {
        display: flex;
        justify-content: space-between;
    }

    .info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: 50vw;

        .name-and-badge {
            display: flex;
            align-items: center;
        }

        .name {
            max-width: 120px;
            color: black;
            font-size: 15px;
        }
    }

    .age-region {
        font-size: 11px;
        margin-top: 2px;
        color: $grey-07;
    }

    .last-message {
        font-size: 12px;
        line-height: 16px;
        // margin-right: 8px;
    }

    .right {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .column {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
        }

        .timestamp {
            font-size: 12px;
            letter-spacing: -0.4px;
            color: $grey-05;
            text-align: right;
            display: table;
            margin-top: 2px;
        }

        .badge-count {
            margin-left: 8px;
            padding: 0 5px;
            min-width: 18px;
            height: 18px;
            border-radius: 9px;
            background: $purple-primary;
        }

        .source-photo-wrapper {
            width: 34px;
            height: 34px;
            border-radius: 8px;
            border: solid 1px $grey-02;
            margin-top: 6px;

            .source-photo {
                width: 100%;
                height: 100%;
                border-radius: 8px;
            }
        }
    }

    &.selected {
        background: rgba(0, 0, 0, 0.06);
    }
}
</style>
