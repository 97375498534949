<template>
    <div class="success" v-if="success.length !== 0">
        <div class="top">
            <span class="title">성사된 소개팅</span>
            <i class="material-icons snackbar-info success-snackbar-icon" @click="showSuccessSnackBar">info</i>
        </div>
        <div
            class="def-text suggested-snackbar success-snackbar"
            v-if="!hideSuccessSnackBar"
            v-html="$translate('SUCCESS_SNACKBAR_TEXT').replace(/\n/, '<br>')"
        />

        <div class="success-request">
            <div
                class="success-request-item"
                v-for="(item, idx) in success"
                v-show="idx < successCount"
                :key="item.user_id"
                @click="goToUserDetail(item)"
            >
                <img
                    :class="{ 'refund-complete': isRefunded(item) }"
                    :src="considerBlankProfile(item)"
                    @error="$event.target.src = $blankProfile"
                />
                <div class="dimmed" />
                <div class="success-request-item-info">
                    <div>
                        <div class="user">{{ $nameOrNick(item) }}, {{ item.birthday | asAge }}</div>
                        <div class="user-feedback">
                            <button
                                class="feedback"
                                :class="{ 'feedback-complete': item.is_feedback }"
                                @click.stop="onClickFeedback(item)"
                            >
                                {{ item.is_feedback ? '피드백 완료' : '피드백하기' }}
                            </button>
                        </div>
                    </div>
                    <div class="status">
                        <div class="remain-text">{{ successDaysLeft(item.created_at) }}</div>
                        <div v-if="isRefunded(item)" class="refund-complete-text">환급 완료</div>
                    </div>
                </div>
            </div>
        </div>
        <button v-if="hasMoreSuccess" class="btn btn-block" @click="addMaxSuccess(3)">더 보기</button>
    </div>
</template>

<script>
import datingService from '@/services/dating'

export default {
    name: 'SuggestedSuccess',
    data: () => ({
        success: [],
        hideSuccessSnackBar: true,
        successCount: null,
    }),
    watch: {
        '$store.getters.datingSuccess': {
            handler(newVal) {
                this.success = [...newVal]
            },
        },
    },
    computed: {
        hasMoreSuccess() {
            return this.success.length > this.successCount
        },
    },
    mounted() {
        this.loadSuccess()
        this.successCount = 3
        document.addEventListener('click', this.onClickOutsideSnackBar)
    },
    beforeDestroy() {
        document.removeEventListener('click', this.onClickOutsideSnackBar)
    },
    methods: {
        async loadSuccess() {
            this.success = await this.$store.dispatch('loadDatingSuccess')
        },
        onClickOutsideSnackBar(event) {
            if (!event.target.closest('.success-snackbar-icon')) {
                this.hideSuccessSnackBar = true
            }
        },
        showSuccessSnackBar() {
            this.hideSuccessSnackBar = !this.hideSuccessSnackBar
        },
        successDaysLeft(createdAt) {
            return `D-${14 - this.$moment().diff(this.$moment(createdAt), 'days')}`
        },
        addMaxSuccess(num) {
            this.successCount += num
        },
        considerBlankProfile(item) {
            return item.photo_url ? item.photo_url : this.$blankProfile
        },
        goToUserDetail(item) {
            if (!item.photo_url) return

            const photos = []
            const photo = { url: item.photo_url }
            photos.push(photo)

            this.$stackRouter.push({
                name: 'UserDetailPage',
                props: {
                    from: 'SuggestedSuccess',
                    userId: item.user_id,
                    photos: photos,
                },
            })
        },
        isRefunded(item) {
            if (!item) return false
            const refundCode = [5, 6, 7, 8, 9, 11]
            return refundCode.includes(item.status)
        },
        async onClickFeedback(item) {
            if (item.is_feedback) {
                this.$toast.error('이미 피드백을 완료했어요')

                return
            }

            try {
                this.$loading(true)
                const dating = await datingService.datings(item.user_id)
                const user = await this.$store.dispatch('loadUserDetail', { userId: item.user_id })
                const photos = [{ url: item.photo_url }]

                this.$stackRouter.push({
                    name: 'DatingStatusCheckPage',
                    props: {
                        user,
                        photos,
                        dating: dating[0],
                    },
                })
            } catch (e) {
                console.error(e)
            } finally {
                this.$loading(false)
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.success {
    position: relative;

    &-snackbar {
        top: 45px;
        left: 16px;
        width: calc(100vw - 32px);
        padding: 12px;
    }

    .success-request {
        &-item {
            display: flex;
            align-items: center;
            margin-bottom: 24px;

            img {
                width: 80px;
                height: 80px;
                border: 1px solid $grey-02;
                border-radius: 32px;
            }

            .refund-complete {
                opacity: 0.4;
            }

            &-info {
                display: flex;
                justify-content: space-between;
                width: 100%;

                .user {
                    padding: 0 20px;
                    margin-bottom: 12px;
                    font-size: 16px;
                    color: black;
                    @include f-medium;
                }
                .feedback {
                    background-color: $grey-02;
                    padding: 4px 31px;
                    margin-left: 20px;
                    font-size: 13px;
                    height: 28px;
                    border-radius: 8px;
                    color: $grey-09;
                    border: transparent;
                    font-weight: unset !important;
                    @include f-medium;

                    &.feedback-complete {
                        color: $grey-05;
                    }
                }
                .status {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .remain-text {
                        font-size: 11px;
                        width: 40px;
                        height: 18px;
                        color: white;
                        @include f-medium;
                        background-color: black;
                        opacity: 0.5;
                        border-radius: 25px;
                        text-align: center;
                    }
                    .refund-complete-text {
                        color: $pink-deep;
                        font-size: 12px;
                        @include f-medium;
                    }
                }
            }
        }
    }
    .btn-block {
        border: 1px solid $grey-03;
    }
}
</style>
