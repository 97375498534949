<template>
    <div class="refund-1 center-refund">
        <div class="page-header">
            <div class="title">소개팅권 환급 정책</div>
        </div>
        <div class="details">
            <div class="detail-top">
                <p>만남이 성사되지 않을 경우,</p>
                <p>소개팅권을 환급해드립니다.</p>
            </div>
            <div class="detail-middle">
                <p>소개팅권 1장은 <span class="c-primary f-bold">최대 3회</span>까지 환급 가능합니다.</p>
                <p class="detail-exclude">* 환급 제외 사유에 해당하는 경우, 환급이 불가합니다.</p>
            </div>
            <div class="detail-bottom m-t-30">
                <div class="refund-count-procedure" v-for="cnt in 3" :key="cnt">
                    <div class="circle m-r-16 f-13 c-primary f-bold" v-html="`${cnt}회차`" />
                    <div class="procedure" v-if="cnt < 3" v-html="$translate(`REFUND_COUNT_${cnt}`)" />
                    <div class="procedure" v-else v-html="refundCnt3" />
                </div>
            </div>
        </div>
        <BottomButton @click="$router.push({ name: 'Refund2Page' })" :label="'환급 신청하기'" />
    </div>
</template>

<script>
export default {
    name: 'Refund1Page',
    computed: {
        refundCnt3() {
            const sentence = this.$translate(`REFUND_COUNT_3`)
            if (!this.$refundPolicySplit()) {
                return sentence.replace(/%s/, `1만`).replace(/%p/, `700(15000원 상당)`)
            }

            return sentence.replace(/%s/, `3,500`).replace(/%p/, `300`)
        },
    },
}
</script>
