import { computed } from '@vue/composition-api'
import Vue from 'vue'

const SuggestMessage = () => {
    // computed
    const makeComputed = prop => {
        const isRejected = computed(() => prop.status === 'user_reject')
        const isAccepted = computed(() => {
            return prop.status === 'user_accept' || prop.status === 'agent_accept' || prop.status === 'agent_reject'
        })
        const isOpened = computed(() => prop.user_opened)
        const isExpired = computed(() => {
            const diff = Vue.prototype.$moment().diff(prop.expire_at)

            return prop.status === 'request' && diff > 0
        })
        const isDday = computed(() => {
            // const diff = Vue.prototype.$moment().diff(prop.expire_at, 'days')
            return false
            // return (
            //     (prop.status === 'request' || prop.status === 'user_reject') &&
            //     diff === 0 &&
            //     (prop.user_id % 10 === 0 || prop.user_id % 10 === 1)
            //     // 이곳 롤백해야함
            // )
        })

        return { isRejected, isAccepted, isOpened, isExpired, isDday }
    }

    return { makeComputed }
}

export default SuggestMessage
