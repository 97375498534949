export const stackRouterComponents = {
    UserDetailPage: () => import('@/routes/user-detail/UserDetailPage'),
    AgentDetailPage: () => import('@/routes/agent-detail/AgentDetailPage'),
    DatingCandidateSelectPage: () => import('@/routes/dating-review/DatingCandidateSelectPage'),
    DatingReviewPostPage: () => import('@/routes/dating-review/DatingReviewPostPage'),
    DatingReviewInfoPage: () => import('@/routes/dating-review/DatingReviewInfoPage'),
    DatingReviewsPage: () => import('@/routes/dating-review/DatingReviewsPage'),
    GiftListPage: () => import('@/routes/gifticon/GiftListPage'),
    GiftPayPage: () => import('@/routes/gifticon/GiftPayPage'),
    AgentEvaluationIntroPage: () => import('@/routes/agent-evaluation/AgentEvaluationIntroPage'),
    AgentEvaluationOptionsPage: () => import('@/routes/agent-evaluation/AgentEvaluationOptionsPage'),
    PgStorePage: () => import('@/routes/store/PgStorePage'),
    VirtualAccountPage: () => import('@/routes/store/VirtualAccountPage'),
    RefundPolicyPage: () => import('@/routes/refund/RefundPolicyPage'),
    RefundPolicyDetailsPage: () => import('@/routes/refund/RefundPolicyDetailsPage'),
    AgentRatingsReviewsPage: () => import('@/routes/agent-ratings-reviews/AgentRatingsReviewsPage'),
    SearchKeywordsPage: () => import('@/routes/my-page/SearchKeywordsPage'),
    ReportSelectReasonPage: () => import('@/routes/report/ReportSelectReasonPage'),
    ReportSubmitPage: () => import('@/routes/report/ReportSubmitPage'),
    DatingStatusCheckPage: () => import('@/routes/user-feedback/DatingStatusCheckPage'),
    DatingFeedbackPage: () => import('@/routes/user-feedback/DatingFeedbackPage'),
    CommunicationFeedbackPage: () => import('@/routes/user-feedback/CommunicationFeedbackPage'),
    RecommendablePersonPage: () => import('@/routes/user-feedback/RecommendablePersonPage'),
    RecommendableReasonPage: () => import('@/routes/user-feedback/RecommendableReasonPage'),
    UnrecommendableReasonPage: () => import('@/routes/user-feedback/UnrecommendableReasonPage'),
    DatingSincerityPage: () => import('@/routes/user-feedback/DatingSincerityPage'),
    DatingInsincerePage: () => import('@/routes/user-feedback/DatingInsincerePage'),
    DatingMannerPage: () => import('@/routes/user-feedback/DatingMannerPage'),
    DatingNoMannerPage: () => import('@/routes/user-feedback/DatingNoMannerPage'),
    PremiumIntroductionPage: () => import('@/routes/premium-introduction/PremiumIntroductionPage'),
    TodayMeetingPostDetailPage: () => import('@/routes/today-meeting/TodayMeetingPostDetailPage'),
    TodayMeetingPostMakePage: () => import('@/routes/today-meeting/TodayMeetingPostMakePage'),
    SocialMeetingPostDetailPage: () => import('@/routes/social-meeting/SocialMeetingPostDetailPage'),
    SocialMeetingPostMakePage: () => import('@/routes/social-meeting/SocialMeetingPostMakePage'),
    KakaoMapPage: () => import('@/routes/social-meeting/components/KakaoMapPage'),

    InAppStorePage: () => import('@/routes/store/InAppStorePage'),
    InvitationFriendPage: () => import('@/routes/invitationFriend/InvitationFriendPage'),
    ContactsListPage: () => import('@/routes/invitationFriend/ContactsListPage'),
    BlockContactsPage: () => import('@/routes/block-contacts/BlockContactsPage'),
    SuperPrivacyIntroductionPage: () => import('@/routes/super-privacy-introduction/SuperPrivacyIntroductionPage'),
    PremiumSurveyStartPage: () => import('@/routes/premium-survey/PremiumSurveyStartPage'),
    PremiumSurveyQuestionPage: () => import('@/routes/premium-survey/PremiumSurveyQuestionPage'),
    VerificationBadgesPage: () => import('@/routes/verification-badges/VerificationBadgesPage.vue'),
    VerificationBadgesCreatePage: () => import('@/routes/verification-badges/VerificationBadgesCreatePage.vue'),
    VerificationTrustBadgesPage: () => import('@/routes/verification-badges/VerificationTrustBadgesPage.vue'),
    BlindUserDetailPage: () => import('@/routes/user-detail/BlindUserDetailPage'),
    FriendReferralPage: () => import('@/routes/invitationFriend/FriendReferralPage'),

    RealFriendMeetingPostMakePage: () => import('@/routes/realfriend-meeting/RealFriendMeetingPostMakePage'),
    RealFriendMeetingPostDetailPage: () => import('@/routes/realfriend-meeting/RealFriendMeetingPostDetailPage'),
    RealFriendPrivacyPage: () => import('@/routes/chat/real-friend-privacy/RealFriendPrivacyPage'),
    RealFriendMeetingConfirmPage: () => import('@/routes/realfriend-meeting/RealFriendMeetingConfirmPage'),
    RealFriendMeetingRefundCheckPage: () => import('@/routes/realfriend-meeting/RealFriendMeetingRefundCheckPage'),
    RealFriendMeetingRefundRejectPage: () => import('@/routes/realfriend-meeting/RealFriendMeetingRefundRejectPage'),

    SendContactPage: () => import('@/routes/chat/send-contact/SendContactPage'),
    SocialChatMembersPage: () => import('@/routes/chat/chatroom/SocialChatMembersPage'),
    SocialMeetProfilePage: () => import('@/routes/social-meeting/components/SocialMeetProfilePage'),

    // 프로필 페이지 스텍라우터
    EditProfilePage: () => import('@/routes/my-page/EditProfilePage'),
    EditStylePage: () => import('@/routes/my-page/EditStylePage'),
    EditDatingPreferencePage: () => import('@/routes/my-page/EditDatingPreferencePage'),
    NoticesPage: () => import('@/routes/home/NoticesPage'),
    RefundHistoriesPage: () => import('@/routes/RefundHistoriesPage'), //
    FAQPage: () => import('@/routes/faq/FAQPage'), //
    ReportAgentPage: () => import('@/routes/report-agent/ReportAgentPage'), //
    ChangePhoneNumberPage: () => import('@/routes/change-phone-number/ChangePhoneNumberPage'),
    SettingsPage: () => import('@/routes/setting/SettingsPage'),
    ChangePasswordPage: () => import('@/routes/setting/ChangePasswordPage'),
    DormantPage: () => import('@/routes/setting/DormantPage'),
    GoodbyeOnsCampaignPage: () => import('@/routes/my-page/GoodbyeOnsCampaignPage'),
    NotificationDisplayPage: () => import('@/routes/setting/NotificationDisplayPage'),
    WMPromotionPage: () => import('@/routes/wm-promotion/WMPromotionPage'),
}

export function isStackRoute(name) {
    if (!name) return false

    return Object.keys(stackRouterComponents).findIndex(key => key === name) > -1
}
