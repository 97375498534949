<template>
    <div class="timer">
        {{ timeLeft }}
    </div>
</template>
<script>
export default {
    props: ['end'],
    name: 'Timer',
    data: () => ({
        interv: null,
        timeLeft: null,
    }),
    mounted() {
        if (!this.end) return

        let diff
        let h
        let m
        let s

        this.interv = setInterval(_ => {
            diff = this.end.diff(this.$moment(), 'seconds')
            h = Math.floor(diff / 3600)
            m = Math.floor((diff - h * 3600) / 60)
            s = Math.floor(diff - h * 3600 - m * 60)

            if (h < 10) h = `0${h}`
            if (m < 10) m = `0${m}`
            if (s < 10) s = `0${s}`

            this.timeLeft = `${h}:${m}:${s}`
        }, 1000)
    },
    beforeDestroy() {
        clearInterval(this.interv)
    },
}
</script>
