import verificationBadgeService from '@/services/verification-badge'
import Vue from 'vue'

const defaultState = () => ({
    verificationBadges: null,
    myVerificationBadges: null,
})

const state = defaultState()

const getters = {
    verificationBadges: state => state.verificationBadges,
    myVerificationBadges: state => state.myVerificationBadges,
}

const actions = {
    async loadVerificationBadges({ getters, commit }) {
        try {
            let badges = await verificationBadgeService.all()
            badges = Vue.prototype.$isStudentByProfile() ? badges.filter(b => ![16, 17].includes(b.id)) : badges
            commit('setVerificationBadges', badges)

            return badges
        } catch (e) {
            return e
        }
    },
    async loadMyVerificationBadges({ getters, commit }) {
        try {
            let badges = await verificationBadgeService.get(getters.me.id)
            badges = Vue.prototype.$isStudentByProfile() ? badges.filter(b => ![16, 17].includes(b.id)) : badges
            commit('setMyVerificationBadges', badges)

            return badges
        } catch (e) {
            return e
        }
    },
}

const mutations = {
    setVerificationBadges(state, badges) {
        state.verificationBadges = [...badges]
    },
    setMyVerificationBadges(state, badges) {
        state.myVerificationBadges = [...badges]
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
    defaultState,
}
