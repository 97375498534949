<template>
    <div class="nts-main">
        <h2 class="question f-bold" v-html="question" />
        <NTS @select="onSelectScore" />
    </div>
</template>

<script>
import NTS from './components/NTS'

export default {
    name: 'NTSMainPage',
    components: { NTS },
    data: () => ({
        question: '바닐라브릿지의 회원들은<br>진지한 만남을 목적으로<br>앱을 사용하는것 같나요?',
    }),
    mounted() {
        this.$registerBackHandler(this.backHandler)
        const date = new Date()
        const userId = this.$store.getters.me.id
        window.localStorage.setItem(`nts${date.getFullYear()}${date.getMonth() + 1}${date.getDate()}${userId}`, true)
    },
    methods: {
        onSelectScore(score) {
            this.$store.commit('setPayloads', {
                ntsData: { score },
            })
            setTimeout(() => {
                this.$router.push({ name: 'NTSFeedbackPage', params: { score } })
            }, 500)
        },
        backHandler() {
            this.$modal
                .basic({
                    title: 'NTS_CANCEL_TITLE',
                    body: this.$translate('NTS_CANCEL_BODY'),
                    buttons: [
                        {
                            label: 'NTS_BUTTON_CANCEL',
                            class: 'btn-default',
                        },
                        {
                            label: 'NTS_BUTTON_EVALUATE',
                            class: 'btn-primary',
                        },
                    ],
                })
                .then(idx => {
                    if (idx === 0) {
                        this.$unregisterBackHandler()
                        this.$router.go(-1)
                    }
                })
        },
    },
}
</script>

<style lang="scss" scoped>
.nts-main {
    padding: 20px;

    .question {
        margin: 0;
        margin-bottom: 80px;
        font-size: 24px;
        line-height: 1.5;
        color: black;
        font-weight: normal;
    }
}
</style>
