import Vue from 'vue'
import axios from '@/modules/axios'
import router from '@/router'
import autotextService from '@/services/auto-text'
import userService from '@/services/user'

const transformLoginResponseToHeader = ({ user, device }) => ({
    'X-User-Email': user.email,
    'X-User-Token': user.token,
    'X-User-Role': 'user',
    'X-User-OS': device ? device.os : '',
    'X-User-Agent': device ? JSON.stringify(device) : '',
})

const setHeaderAndRedirectToMain = async ({ getters, commit, dispatch, data, noRedirect }) => {
    if (!data) {
        return Promise.reject(data)
    }

    const header = transformLoginResponseToHeader(data)
    axios.setHeader(header)
    commit('setHeader', header)

    await dispatch('afterAuthCallbacks', { isSignIn: true })

    if (!noRedirect) {
        router.push({ name: 'HomePage' })
    }
}

const defaultState = () => ({
    me: null,
    settings: null,
    autoTexts: null,
    badges: null,
    header: null,
    notices: null,
    notifications: null,
    device: null,
    items: null,
    thirdPartySignInData: null,
    signupFriends: null,
    facebookData: null,
})

const state = defaultState()

const getters = {
    me: state => state.me,
    hasStyle: state => {
        if (!state.me) return

        return !!state.me.style
    },
    settings: state => state.settings,
    mainPhoto: state => {
        if (!state.me || (state.me.photos || []).length === 0) return

        return state.me.photos[0].url
    },
    autoTexts: state => state.autoTexts,
    badges: state => state.badges,
    header: state => state.header,
    notices: state => state.notices,
    notifications: state => state.notifications,
    device: state => state.device,
    items: state => state.items,
    thirdPartySignInData: state => state.thirdPartySignInData,
    signupFriends: state => state.signupFriends,
    facebookData: state => state.facebookData,
    introduceCTATestMinId: _ => 188270,
}

const actions = {
    async loadAuthToken({ commit, dispatch, getters }) {
        const header = JSON.parse(window.localStorage.getItem('header'))

        if (!header) {
            window.localStorage.removeItem('header')
            return Promise.reject({ status: 401 })
        }

        if (header['X-User-Role'] !== 'user') return dispatch('signOut')

        if ((!header['X-User-OS'] || !header['X-User-Agent']) && getters.device) {
            header['X-User-OS'] = getters.device.os
            header['X-User-Agent'] = JSON.stringify(getters.device)
        }
        axios.setHeader(header)
        commit('setHeader', header)
    },
    async loadMe({ commit }) {
        try {
            const me = await userService.me()
            commit('setMe', me)
            if (me.is_dormant) {
                router.push({ name: 'DormantPage' })
                return Promise.reject()
            }
        } catch (e) {
            return Promise.reject(e)
        }
    },
    async loadSettings({ commit }) {
        try {
            const settings = await userService.settings()
            commit('setSettings', settings)
            if (settings.login_reward && settings.login_reward > 0) {
                Vue.prototype.$toast.success(
                    `오늘도 찾아주셔서 감사해요 :) 바닐라 ${settings.login_reward}개를 선물로 드렸어요!`,
                )
            }
        } catch (e) {
            return Promise.reject(e)
        }
    },
    async loadBadges({ state, commit }) {
        if (!state.me) return

        const data = await userService.badges(state.me.id)
        commit('setBadges', data)
    },
    async signIn({ commit, getters, dispatch }, payload) {
        const data = await Vue.prototype.$http.post('v2/users/login', payload)

        if (data.role === 'agent') {
            Vue.prototype.$toast.error(data.msg)
        } else {
            Vue.prototype.$nativeBridge.postMessage({
                action: 'sendAirbridgeEvent',
                value: {
                    category: 'user_login',
                },
            })
            Vue.prototype.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: 'user_login',
                },
            })
            data.device = getters.device

            setHeaderAndRedirectToMain({ getters, commit, dispatch, data, noRedirect: payload.noRedirect })
        }
    },
    async signInThirdParty({ commit, getters, dispatch }, payload) {
        try {
            Vue.prototype.$nativeBridge.postMessage({
                action: 'sendAirbridgeEvent',
                value: {
                    category: 'user_login',
                },
            })
            Vue.prototype.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: 'user_login',
                },
            })
            payload.device = getters.device
            const data = payload
            setHeaderAndRedirectToMain({ getters, commit, dispatch, data, noRedirect: payload.noRedirect })
        } catch (e) {
            Vue.prototype.$toast.error(e.data)
        }
    },
    async signOut({ commit, getters }) {
        // 디바이스 정보를 잠시 기억해놓고 스토어 지워진 다음 다시 set device 해줘야 다시 로그인할때 디바이스 정보 남아있음
        const device = getters.device

        window.localStorage.clear()

        commit('initAppData')
        commit('setDevice', device)
        if (router.currentRoute.name !== 'FrontPage') {
            router.push({ name: 'FrontPage' })
        }

        if (getters.deviceToken) {
            userService.deleteToken(getters.deviceToken)
        }
    },
    async loadAutoTexts({ commit }) {
        try {
            const data = await autotextService.all()
            commit('setAutoTexts', data)
        } catch (e) {
            return Promise.reject(e)
        }
    },
    async loadNotices({ commit }) {
        try {
            const data = await userService.notices()
            commit('setNotices', data)
        } catch (e) {
            return Promise.reject(e)
        }
    },
    async loadNotifications({ state, commit }, offset) {
        try {
            const data = await userService.chunkedNotifications(
                offset === 0 ? offset : (state.notifications || []).length,
            )
            commit('setNotifications', {
                offset: offset,
                items: data.notifications,
            })
        } catch (e) {
            return Promise.reject(e)
        }
    },
    async loadItems({ commit }) {
        try {
            const { bag } = await userService.items()
            commit('setItems', bag.item)
        } catch (e) {
            return Promise.reject(e)
        }
    },
}

const mutations = {
    setHeader(state, header) {
        state.header = header
    },
    setMe(state, me) {
        state.me = me
    },
    setSettings(state, settings) {
        state.settings = settings
    },
    setAutoTexts(state, autoTexts) {
        state.autoTexts = autoTexts
    },
    setBadges(state, badges) {
        state.badges = badges
    },
    setNotices(state, notices) {
        state.notices = notices
    },
    setNotifications(state, payload) {
        if (!payload) return

        if (payload.offset === 0 || !state.notifications) {
            state.notifications = payload.items
        } else {
            state.notifications = state.notifications.concat(payload.items)
        }
    },
    setDevice(state, device) {
        if (!device) return

        state.device = device
    },
    setItems(state, items) {
        state.items = items
    },
    setThirdPartySignInData(state, thirdPartySignInData) {
        state.thirdPartySignInData = thirdPartySignInData
    },
    setFacebookData(state, facebookData) {
        state.facebookData = facebookData
    },
    setSignupFriends(state, signupFriends) {
        state.signupFriends = signupFriends
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
    defaultState,
}
