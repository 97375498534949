<template>
    <div v-if="profile" class="school-job" :class="{ simple: simple }">
        <div class="job">
            <i class="material-icons flex-wrap">work</i>
            <div class="job-name-company">
                <span>{{ profile.job_name }}</span>
                <span class="divider" v-html="'ㅣ'" />
                <span class="company" v-if="company" v-html="company" />
            </div>
            <i v-if="profile.company_verified" class="badge badge-verified flex-wrap m-l-4" />
        </div>

        <div class="school flex-row items-center m-t-4" v-if="!profile.is_student && !simple">
            <i class="material-icons flex-wrap">location_city</i>
            <div class="flex-wrap">{{ profile | school }}</div>
            <i v-if="profile.university_verified" class="badge badge-verified flex-wrap m-l-4" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'SchoolJob',
    props: ['profile', 'simple'],
    computed: {
        company() {
            return this.$options.filters.company(this.profile)
        },
    },
}
</script>
