<template>
    <div class="notifications flex">
        <EmptyList v-if="notifications.length === 0" :message="'받은 알림이 없어요'" />
        <div class="notifications-wrapper flex-fill">
            <div @scroll="onScrollNotifications" class="notifications-list">
                <Notification
                    @close="showNotifications = false"
                    :notification="notification"
                    :key="notification.id"
                    v-for="notification in notifications"
                />
            </div>
        </div>
    </div>
</template>
<script>
import Notification from './components/Notification'
import EmptyList from '@/components/common/EmptyList'

export default {
    name: 'NotificationsPage',
    components: { Notification, EmptyList },
    computed: {
        notifications() {
            const nList = this.$store.getters.notifications
            if (!this.searchValue) return nList

            return nList.filter(n => n.content.includes(this.searchValue))
        },
    },
    beforeDestroy() {
        const nList = this.$store.getters.notifications
        nList.forEach(n => {
            if (!n.read) n.read = true
        })

        this.$store.commit('setNotifications', {
            offset: 0,
            items: nList,
        })
    },
    data: () => ({
        searchValue: null,
        showNotifications: false,
    }),
    methods: {
        async onScrollNotifications(event) {
            const t = event.target
            const isBottom = t.scrollHeight - t.scrollTop <= t.clientHeight
            if (isBottom) {
                await this.$store.dispatch('loadNotifications')
            }
        },
        onSearch(searchValue) {
            this.searchValue = searchValue
        },
    },
}
</script>
