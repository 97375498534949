<template>
    <div
        class="privacy-status flex-row"
        :class="{ premium: isPremium, instant: isInstantChat }"
        v-if="isPrivacy"
        @click="onClickPrivacy"
    >
        <i class="material-icons flex-wrap security">security</i>
        <div class="privacy-remain" v-html="`${privacyRemainDay()}일 남음`" />
    </div>
</template>

<script>
export default {
    name: 'PrivacyStatus',
    props: {
        isPremium: {
            type: Boolean,
            default: false,
        },
        isInstantChat: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        isPrivacy() {
            if (!this.$store.getters.me) return false

            return this.$isPrivacy()
        },
    },
    methods: {
        onClickPrivacy() {
            this.$modal.custom({
                component: 'ModalAlertNoX',
                options: {
                    title: 'SUPER_PRIVACY_ON',
                    body: `회원님의 동의가 없으면 누구에게도 프로필이 공개되지 않습니다.`,
                },
            })
        },
        privacyRemainDay() {
            const privacy = this.$store.getters.me.products.filter(p => p.ptype === 'privacy' && p.enabled === true)
            if (privacy === null || privacy === []) return

            const validUntil = privacy[0].valid_until.split('T')[0]

            return this.$moment(validUntil).diff(this.$moment(), 'days')
        },
    },
}
</script>

<style lang="scss" scoped>
.privacy-status {
    width: 103px;
    height: 32px;
    border-radius: 16px;
    background-color: $blue-facebook;
    right: 0;
    position: absolute;
    margin-right: 16px;
    margin-top: 10px;
    padding: 6px 12px 6px 10px;
    align-items: center;

    &.premium {
        margin-top: 8px;
    }
    .security {
        font-size: 20px;
        margin-right: 6px;
        color: white;
    }
    .privacy-remain {
        color: white;
        font-size: 13px;
        @include f-bold;
    }
}
</style>
