<template>
    <div class="nts-feedback">
        <h2 class="header f-bold" v-html="header" />
        <!-- <p class="description" v-html="description" /> -->
        <textarea
            :placeholder="'내용을 작성해주세요'"
            :value="feedback"
            @input="onInput"
            @focus="inputFocused = true"
            @blur="inputFocused = false"
        />
        <div class="buttons">
            <button class="btn btn-primary btn-submit" :disabled="isEmpty" @click="onClickSubmit">제출하기</button>
            <button class="btn btn-brd btn-skip" v-show="isEmpty" @click="onClickSkip">건너뛰기</button>
        </div>
    </div>
</template>

<script>
import ntsService from '@/services/nts'

export default {
    name: 'NTSFeedbackPage',
    data: () => ({
        feedback: '',
        inputFocused: false,
        insertedNts: null,
    }),
    computed: {
        isNegative() {
            return this.$route.params.score < 9
        },
        header() {
            // return '어떤 점에서 진지한 만남을 위해<br>사용한다고(혹은 아니라고) 생각하셨나요?'
            if (this.isNegative) {
                return '어떤 점에서<br>진지한 만남을 위해 사용하는게<br>아닌것 같다고 생각하셨나요?'
            }

            return '어떤 점에서<br>진지한 만남을 위해<br>사용한다고 생각하셨나요?'
        },
        description() {
            if (this.isNegative) {
                return '더 나은 서비스로 개선할 수 있도록<br>솔직한 피드백을 남겨주세요 🙏'
            }

            return '더 만족할 수 있는 서비스를 만들겠습니다 ☺️<br>감사합니다!'
        },
        isEmpty() {
            return this.feedback.length === 0
        },
    },
    mounted() {
        this.feedback = this.$store.getters.payloads.ntsData.feedback || ''
    },
    methods: {
        async onClickSubmit() {
            await this.createNTS()
            // if (this.isNegative) {
            this.goBack()
            // return
            // }

            // this.openAppReviewModal()
        },
        async onClickSkip() {
            await this.createNTS()
            // if (this.isNegative) {
            this.goBack()
            // return
            // }

            // this.openAppReviewModal()
        },
        async createNTS() {
            try {
                const { msg, nts } = await ntsService.createNTS({
                    score: this.$route.params.score,
                    feedback: this.feedback,
                })
                this.insertedNts = nts
                this.$toast.success(msg)
            } catch (e) {}
        },
        openAppReviewModal() {
            this.$modal
                .custom({
                    component: 'ModalRequestAppReview',
                    options: {
                        feedback: this.feedback,
                        nts: this.insertedNts,
                    },
                })
                .then(() => {
                    this.goBack()
                })
        },
        goBack() {
            this.$router.go(-2)
        },
        onInput(event) {
            this.feedback = event.target.value
            this.$store.commit('setPayloads', {
                ntsData: { feedback: this.feedback },
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.nts-feedback {
    padding: 20px;

    .header {
        margin: 0;
        margin-bottom: 16px;
        font-size: 24px;
        line-height: 1.5;
        color: black;
        font-weight: normal;
    }

    .description {
        font-size: 15px;
        color: $grey-09;
        line-height: normal;
        margin: 0;
        margin-bottom: 32px;
    }

    textarea {
        width: 100%;
        height: 260px;
        padding: 16px;
        -webkit-appearance: none;
    }

    .buttons {
        width: calc(100vw - 40px);
        margin-top: 20px;

        .btn {
            width: 100%;
            height: 48px;
            font-size: 15px;
        }

        .btn.btn-skip {
            color: $grey-08;
        }

        .btn.btn-submit {
            &:disabled {
                color: $grey-05;
            }
            box-shadow: none;
        }

        .btn:first-child {
            margin-bottom: 10px;
        }
    }
}
</style>
