<template>
    <transition name="fade">
        <div v-if="premiumInfo">
            <div class="premium-status" :class="{ openDropDown }">
                <div class="flex-row items-center">
                    <div class="flex-row">
                        <div class="items flex-fill">
                            <div class="period">
                                <div class="key" v-html="'프리미엄 '" />
                                <div class="value" v-html="`(${period})`" />
                            </div>
                            <div class="history flex-row">
                                <div class="sent flex-between">
                                    <div class="key" v-html="'소개받은 이성'" />
                                    <div class="value m-l-4">{{ isStart ? premiumInfo.suggest_used_count : 0 }}명</div>
                                </div>
                                <div class="success flex-between">
                                    <div class="key" v-html="'성사된 소개팅'" />
                                    <div class="value m-l-4">{{ isStart ? premiumInfo.dating_count : 0 }}건</div>
                                </div>
                                <div @click="toggleDropDown" class="dropdown m-l-16">
                                    <i v-if="openDropDown" class="material-icons c-white">keyboard_arrow_up</i>
                                    <i v-else class="material-icons c-white">keyboard_arrow_down</i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="openDropDown" class="operation-time premium-status f-12">
                <div class="content items-center" v-for="text in operationTimeAlert" :key="text">
                    <div class="dot" />
                    <span class="text" v-html="text" />
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import userService from '@/services/user'

export default {
    name: 'PremiumStatus',
    data: () => ({
        openDropDown: true,
        premiumInfo: null,
    }),
    watch: {
        msgCnt: {
            handler() {
                if (!this.isStart) return

                const newMsg = this.chat.$$messages[0]
                if (newMsg.mtype === 'profile-photo') {
                    this.premiumInfo.suggest_used_count += 1
                }
            },
        },
    },
    mounted() {
        this.init()
        this.$bus.$on('updateDatingCount', () => {
            this.premiumInfo.dating_count += 1
        })
    },
    beforeDestroy() {
        this.$bus.$off('updateDatingCount')
    },
    computed: {
        showOperationTimeAlert() {
            const hour = this.$moment().format('HH')
            return ['Saturday', 'Sunday'].includes(this.$moment().format('dddd')) || hour < 13 || hour >= 22
        },
        me() {
            return this.$store.getters.me || {}
        },
        chat() {
            return this.$store.getters.chat
        },
        msgCnt() {
            return this.chat.$$messages.length
        },
        isStart() {
            return this.premiumInfo.premium_info.is_start
        },
        operationTimeAlert() {
            return [
                '주선자의 확인 후, 프리미엄 주선이 시작됩니다.',
                '총 14일 <span class="f-bold">(영업일 기준 10일)</span>간 프리미엄 주선이 진행됩니다.<br> 일주일 중 2일 정도는 주선자가 휴식을 할 수 있고, 휴식일은 주선자마다 다를 수 있어요(주선자가 안내해 드릴거에요)',
                '<span class="f-bold">휴식일 응대는 주선자의 재량</span>이므로,<br>' +
                    '응대가 되지 않더라도 양해 부탁드립니다.',
                '부득이하게 주선을 받지 못한 날이 있다면,<br> 주선자에게 말씀해 주세요! 기간을 연장해드립니다.',
            ]
        },
        agentId() {
            return this.chat.agent_id
        },
        period() {
            const p = this.premiumInfo

            if (this.isStart) {
                const diffSec = Math.round((new Date(p.valid_until).getTime() - new Date().getTime()) / 1000)
                if (diffSec <= 0) return '만료'

                const validFrom = this.$moment(p.valid_from).format('MM-DD')
                const validUntil = this.$moment(p.valid_until).format('MM-DD')

                return `${validFrom} ~ ${validUntil}`
            }

            return '주선시작 후부터 14일'
        },
    },
    methods: {
        init() {
            if (!this.chat || this.chat.user.id === 0) return

            this.openDropDown = localStorage.getItem('fold-premium-banner')

            const asyncJobs = async () => {
                const { premium_infos: premiumInfos } = await userService.me()
                this.premiumInfo = premiumInfos[0]
            }
            asyncJobs()
        },
        toggleDropDown() {
            if (this.openDropDown) localStorage.removeItem('fold-premium-banner')
            else localStorage.setItem('fold-premium-banner', true)

            this.openDropDown = !this.openDropDown
        },
        businessHours() {
            // const agent10to19 = [17531, 17532, 17533, 5257]
            const agent13to22 = [17696, 17697, 17698]
            // 프리미엄 주선자 업무시간 관련 처리인데 디비에 칼럼추가하기그래서 주선자 아이디랑 업무 시간을 하드코딩으로 박아놓음
            // 나중에 프로퍼티나 어드민 추가해서 처리하거나..
            if (this.agentId && agent13to22.find(i => i === this.agentId)) {
                return { startTime: '오후 1', endTime: '오후 10' }
            } else {
                return { startTime: '오전 10', endTime: '오후 7' }
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.operation-time {
    color: white;
    line-height: normal;
    letter-spacing: -0.2px;
    margin-top: 0 !important;
    border-radius: 0 0 12px 12px !important;
    background-image: none !important;
    background-color: #ffe195;

    .content {
        @include flex-row;
        color: $grey-08;
        position: relative;

        &:not(:last-child) {
            margin-bottom: 6px;
        }
    }

    .dot {
        position: absolute;
        top: 8px;
        left: 0;
        width: 3px;
        height: 3px;
        margin-right: 8px;
        opacity: 0.5;
        border-radius: 2px;
        background-color: $grey-08;
    }
    .text {
        padding-left: 8px;
        letter-spacing: -0.2px;
    }
}
.premium-status {
    margin: 12px 16px 0px;
    font-size: 13px;
    border-radius: 12px;
    padding: 10px 16px;
    background-image: $yellow-premium-grad;

    &.openDropDown {
        border-radius: 12px 12px 0 0;
    }

    .period {
        font-size: 15px;
        margin-bottom: -2px;
        display: flex;

        .key {
            color: white;
            line-height: 24px;
            margin-right: 3px;
            @include f-medium;
        }

        .value {
            line-height: 24px;
            color: white;
            @include f-medium;
        }
    }

    .history {
        margin-top: 12px;
        .sent,
        .success {
            display: flex;
            justify-content: center;
            width: fit-content;
            height: 24px;
            border-radius: 6px;
            // margin: 0px 4px 0px 4px;
            align-items: center;
            padding: 0 30px;
            background-color: rgba(255, 255, 255, 0.3);
            font-size: 13px;

            .key {
                color: $grey-09;
                @include f-medium;
            }

            .value {
                color: $grey-09;
                @include f-bold;
            }
        }

        .sent {
            margin-right: 8px;
        }
    }
}
</style>
