<template>
    <div>
        <Loading :loading="loading" :animation="'dot-falling'" />
        <div v-if="!loading">
            <div class="waitlist">
                <div class="top text-bold">
                    안녕하세요, <span>{{ this.me.name }}</span
                    >님<br />
                    담당주선자 <span v-if="friends.length > 0">{{ this.friends[0].agent.name }}</span
                    >입니다.<br />
                </div>
                <div class="time text-bold">
                    <span class="highlight text-underline" v-html="'오전 11시 쯤에'" />
                    <span class="normal" v-html="' 연락드리겠습니다 😊<br>'" />
                </div>
                <MyAgent />
                <div class="hr" />
                <ul class="body bullet-list">
                    <li class="list-item f-regular">
                        담당 주선자 <span v-if="friends.length > 0">{{ this.friends[0].agent.name }}</span
                        >님이 오늘 오전 11시부터 근무를 시작할 예정입니다.<br />
                    </li>
                    <li
                        class="list-item f-regular"
                        v-html="
                            '주선자가 근무를 시작하면 앱이 활성화되고 대화방을 통해 회원님게 인사를 드릴거에요. 그 때 부터 설레는 소개팅 주선이 시작됩니다 😊<br>'
                        "
                    />
                    <li
                        class="list-item f-regular"
                        v-html="
                            '사진이 많을수록 매칭 확률이 올라가요. 사진을 최대한 많이 올려주세요!  (주선자가 잘 나온 사진을 골라서 사용합니다.)<br>'
                        "
                    />
                </ul>
                <footer class="footer">
                    <button class="btn btn-primary" @click="goPhotoPage">사진 보충하기</button>
                </footer>
            </div>
        </div>
    </div>
</template>

<script>
import MyAgent from '@/routes/manager-waitlist/components/MyAgent'

export default {
    name: 'ManagerWaitlistPage',
    components: { MyAgent },
    data: () => ({
        loading: false,
        signupFinished: false,
        terms: null,
    }),
    computed: {
        me() {
            return this.$store.getters.me
        },
        friends() {
            return this.$store.getters.friends || []
        },
        profileUpdateRequestedPhoto() {
            return this.me.profile_update_request !== null && this.me.profile_update_request.photo_ids !== null
        },
    },
    mounted() {
        this.updateFriend()
        this.$registerBackHandler(() => {})
    },
    beforeDestroy() {
        this.$unregisterBackHandler()
    },
    methods: {
        goPhotoPage() {
            if (this.profileUpdateRequestedPhoto) {
                this.$toast.error('사진 심사중 입니다')
                return
            }
            this.$stackRouter.push({
                name: 'EditProfilePage',
                props: {
                    initialTab: 'PHOTO',
                    from: 'ManagerWaitlistPage',
                    viewOnlyPhoto: true,
                },
            })
        },
        async updateFriend() {
            await this.$store.dispatch('loadMe')
            await this.$store.dispatch('loadFriends', { isSignIn: false })
        },
    },
}
</script>

<style scoped lang="scss">
.waitlist {
    display: flex;
    flex-direction: column;
    align-items: left;
    height: calc(100vh - 52px);
    overflow-x: hidden;
    .top {
        font-weight: 700;
        font-size: 20px;
        line-height: 36px;
        text-align: left;
        color: #111111;
        margin-top: 66px;
        margin-left: 16px;
        margin-right: 25px;
        width: 100%;
    }
    .time {
        font-weight: 700;
        font-size: 16px;
        line-height: 36px;
        text-align: left;
        margin-top: 6px;
        margin-left: 16px;
        width: 100%;

        .highlight {
            color: #8a74ff;
            font-weight: 700;
        }
        .normal {
            color: #111111;
            font-weight: 500;
        }
    }
    .body {
        font-weight: 400;
        font-size: 15px;
        line-height: 36px;
        text-align: left;
        color: #434245;
        margin-left: 16px;
        width: 100%;
        .bullet-list {
            list-style: none;
            padding: 0;
            overflow-wrap: break-word;
        }
        .list-item {
            margin-bottom: 16px;
            position: relative;
            padding-left: 20px;
            margin-right: 25px;
            line-height: 24px;
            word-wrap: break-word;
            max-width: calc(100vw - 40px);
        }
        .list-item::before {
            content: '·';
            position: absolute;
            left: 0;
            top: 1%;
            margin-right: 3px;
            display: inline-block;
            font-size: 1.2em;
        }
    }
    .hr {
        width: calc(100% - 40px);
        height: 1px;
        background: $grey-02;
        margin-top: 36px;
        margin-bottom: 20px;
        margin-left: 20px;
        margin-right: 20px;
    }
    .footer {
        position: absolute;
        bottom: 0;
        padding: 16px 20px;
        width: 100%;

        .btn {
            width: 100%;
            height: 48px;
        }
    }
}
</style>
