import suggestService from '@/services/suggest'

const defaultState = () => ({
    suggests: null,
})

const state = defaultState()

const getters = {
    suggests: state => state.suggests,
}

const actions = {
    async loadSuggests({ state, commit, getters }) {
        try {
            const suggests = await suggestService.all()
            commit('setSuggests', suggests)
        } catch (e) {
            return Promise.reject(e)
        }
    },
    async acceptSuggest({ dispatch, commit }, suggest) {
        if (!suggest) return

        try {
            const res = await suggestService.accept(suggest.id)
            commit('setSuggest', { id: suggest.id, command: 'accept' })

            return res
        } catch (e) {
            return Promise.reject(e)
        }
    },
    async rejectSuggest({ dispatch, commit }, suggest) {
        if (!suggest) return

        try {
            const res = await suggestService.reject(suggest.id)
            commit('setSuggest', { id: suggest.id, command: 'reject' })

            return res
        } catch (e) {
            return Promise.reject(e)
        }
    },
}

const mutations = {
    setSuggests(state, suggests) {
        state.suggests = suggests
    },
    setSuggest(state, { id, command }) {
        const all = state.suggests
        const target = all.find(suggest => suggest.id === id)
        if (!target) return
        target.user_opened = true

        if (command === 'accept') target.status = 'user_accept'
        else if (command === 'reject') target.status = 'user_reject'
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
    defaultState,
}
