<template>
    <div class="signup upload-photo">
        <div class="content">
            <div class="title">프로필 사진</div>
            <div class="body">
                <div class="item-desc m-b-32">
                    얼굴이 분명하게 나온 사진 <span class="f-bold">2장</span>이 꼭 필요해요!
                </div>
                <div class="photos">
                    <div
                        :key="photo.url"
                        v-for="(photo, i) of photos"
                        class="photo"
                        :class="{ required: photo.required }"
                        @click.stop="!photo.url ? onClickPhoto(i) : null"
                        :style="{ backgroundImage: `url('${photo.url}')` }"
                    >
                        <span v-if="photo.required && !photo.url" class="badge">필수</span>
                        <i v-if="!photo.url" class="material-icons">add</i>
                        <i v-if="photo.url" class="material-icons close-icon" @click.stop="removePhoto(i)">close</i>
                        <span v-if="photo.url" class="modify" @click.stop="onClickPhoto(i)">
                            <i class="material-icons modify-icon">create</i>
                        </span>
                    </div>
                    <input
                        ref="imageUploader"
                        type="file"
                        class="image-input display-none"
                        accept="image/*"
                        @change="onChangeImage"
                    />
                </div>
            </div>
        </div>
        <div class="button-wrapper">
            <button
                class="btn btn-primary"
                :class="{ disabled: disabled }"
                :disabled="loading"
                @click="next"
                v-html="$translate('NEXT')"
            ></button>
        </div>
    </div>
</template>

<script>
import userService from '@/services/user'

export default {
    name: 'UploadPhotoPage',
    data: () => ({
        photos: null,
        selectedIndex: null,
        loading: false,
        terms: null,
    }),
    computed: {
        disabled() {
            if (!this.photos) return true
            return !this.photos.filter(p => p.required).every(p => p.blob)
        },
    },
    methods: {
        back() {
            this.$router.go(-1)
        },
        async next() {
            const { provider, gender } = this.$store.getters.payloads.signupData

            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: 'UploadPhotoPage_Click_NextBtn',
                    option: {
                        provider: provider || 'email',
                        gender: gender.name === 'MALE' ? 0 : 1,
                    },
                },
            })

            if (this.disabled) return

            this.$store.commit('setPayloads', {
                signupData: {
                    photos: this.photos.filter(p => p.blob),
                },
            })
            if (provider === 'kakao') {
                try {
                    this.$loading(true)
                    await this.createUser()
                } catch (e) {
                    console.error(e)
                } finally {
                    this.$loading(false)
                }
            } else {
                this.$router.push({ name: 'VerificationPage' })
            }
        },
        onClickPhoto(index) {
            this.selectedIndex = index

            this.$refs.imageUploader.click()

            let category = ''

            switch (index) {
                case 0:
                    category = 'Click_FirstPhotoUploadBox'
                    break
                case 1:
                    category = 'Click_SecondPhotoUploadBox'
                    break
            }

            if (category) {
                const { provider, gender } = this.$store.getters.payloads.signupData

                this.$nativeBridge.postMessage({
                    action: 'sendFirebaseEvent',
                    value: {
                        category,
                        option: {
                            provider: provider || 'email',
                            gender: gender.name === 'MALE' ? 0 : 1,
                        },
                    },
                })
            }
        },
        removePhoto(index) {
            this.photos[index].blob = null
            this.photos[index].url = null
            this.photos[index].name = null
        },
        async onChangeImage(event) {
            const selectedPhoto = this.photos[this.selectedIndex]
            const imgFile = event.target.files[0]
            this.$refs.imageUploader.value = ''
            selectedPhoto.name = imgFile.name

            await this.$modal
                .custom({
                    component: 'ModalCropper',
                    options: {
                        imgFile,
                    },
                })
                .then(croppedFile => {
                    if (!croppedFile) return
                    selectedPhoto.url = URL.createObjectURL(croppedFile)
                    selectedPhoto.blob = croppedFile
                    this.selectedIndex = null
                })
        },

        async createUser() {
            try {
                const signupData = this.$store.getters.payloads.signupData
                this.loading = true

                await this.initTerms()
                const payload = this.preparedPayload(signupData)
                const { user } = await userService.create(payload)

                // 사진 업로드하는 시간을 고려하여 사진은 별도로 업로드
                userService.uploadPhotos(user.id, this.photosPayload(signupData.photos, user.token))

                // 회원 생성 시점에 가입 완료로 간주, 이벤트 전송
                const { provider, gender } = this.$store.getters.payloads.signupData

                this.$nativeBridge.postMessage({
                    action: 'sendFirebaseEvent',
                    value: {
                        category: 'Complete_Signup',
                        option: {
                            provider: provider || 'email',
                            gender: gender.name === 'MALE' ? 0 : 1,
                        },
                    },
                })

                await this.$store.dispatch('signInThirdParty', {
                    user,
                    uid: signupData.uid,
                    noRedirect: true,
                })
                this.sendAirbridgeEvent(signupData)

                this.$router.push({ name: 'PreferencesIntroPage' })
            } catch (e) {
                if (e.data.key === 'blocked_user') {
                    this.$modal.basic({
                        title: 'MODAL_DEFAULT_TITLE',
                        body: e.data.msg,
                        buttons: [
                            {
                                label: 'CONFIRM',
                                class: 'btn-primary',
                            },
                        ],
                    })
                } else {
                    this.$toast.error(e.data)
                }
            } finally {
                this.loading = false
            }
        },
        async initTerms() {
            await this.$store.dispatch('loadTerms')
            const { terms } = this.$store.getters.terms || {}
            this.terms = terms
        },
        preparedPayload(signupData) {
            const formData = new FormData()
            Object.entries(signupData).forEach(([key, value]) => {
                if (key === 'gender') {
                    formData.append(key, value.name === 'MALE' ? '0' : '1')
                } else if (key === 'smoking') {
                    formData.append(key, value.name === 'SMOKING' ? '1' : '0')
                } else if (['job', 'company', 'university'].indexOf(key) !== -1) {
                    formData.append(`${key}_id`, value.id)
                    formData.append(`${key}_name`, value.name)
                } else if (
                    ['state', 'station', 'region', 'job_category', 'school', 'school_type'].indexOf(key) !== -1
                ) {
                    // 커스텀 인풋 허용 안되는 값들은 id만 넘김
                    formData.append(`${key}_id`, value.id)
                } else if (key === 'profile_option_ids') {
                    formData.append(key, JSON.stringify(value))
                } else {
                    formData.append(key, value)
                }
            })

            formData.append('service_terms_version', this.terms.service.version)
            formData.append('privacy_terms_version', this.terms.privacy.version)
            formData.append('privacy_third_terms_version', this.terms.privacy_third.version)
            formData.append('optional_consent', 0)

            return formData
        },
        photosPayload(photos = [], token) {
            if (photos.length === 0 || !token) return

            const formData = new FormData()

            photos.forEach((photo, i) => {
                formData.append(`photo${i + 1}`, photo.blob, photo.name)
            })

            formData.append('token', token)

            return formData
        },
        sendAirbridgeEvent({ gender }) {
            if (!gender) return

            this.$nativeBridge.postMessage({
                action: 'sendAirbridgeEvent',
                value: {
                    category: `user_signup_complete_${gender.name.toLowerCase()}`,
                },
            })

            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: `signup_complete_${gender.name.toLowerCase()}`,
                },
            })
        },
    },
    created() {
        this.photos = Array(6)
            .fill({})
            .map((p, i) => {
                p = { blob: null, name: null, url: null }
                this.$set(p, 'required', i < 2)
                return p
            })

        const storedPhotos = this.$store.getters.payloads.signupData.photos
        if (storedPhotos) {
            ;(storedPhotos || []).forEach((photo, idx) => {
                this.photos[idx] = storedPhotos[idx]
            })
        }
    },
    mounted() {
        this.$bus.$on('onRequestPhotoFromGallery', this.onChangeImage)
        const { provider, gender } = this.$store.getters.payloads.signupData

        this.$nativeBridge.postMessage({
            action: 'sendFirebaseEvent',
            value: {
                category: 'UploadPhotoPage_Enter_UploadPhotoPage',
                option: {
                    provider: provider || 'email',
                    gender: gender.name === 'MALE' ? 0 : 1,
                },
            },
        })
    },
    beforeDestroy() {
        this.$bus.$off('onRequestPhotoFromGallery', this.onChangeImage)
    },
}
</script>
