import eventService from '@/services/event'

const defaultState = () => ({
    socialMeeting: {
        allPosts: [],
        myPosts: [],
        interestedPosts: [],
    },
    meetPlace: '',
})

const state = defaultState()

const getters = {
    socialMeeting: state => state.socialMeeting,
    meetPlace: state => state.meetPlace,
}

const actions = {
    async loadSocialMeetingPosts({ commit }) {
        try {
            const allPosts = await eventService.all('social_meeting')
            const myPosts = await eventService.mine('social_meeting')
            const interestedPosts = await eventService.interest('social_meeting')
            commit('setSocialMeeting', { allPosts: allPosts.all, hotPosts: allPosts.hot, myPosts, interestedPosts })
        } catch (e) {
            return Promise.reject(e)
        }
    },
}

const mutations = {
    setSocialMeeting(state, socialMeeting) {
        state.socialMeeting = { ...socialMeeting }
    },
    setSocialMeetingAllPosts(state, posts) {
        if (!posts) return

        state.socialMeeting.allPosts = [...posts]
    },
    setSocialMeetingMyPosts(state, posts) {
        if (!posts) return

        state.socialMeeting.myPosts = [...posts]
    },
    setSocialMeetingInterestedPosts(state, posts) {
        if (!posts) return

        state.socialMeeting.interestedPosts = [...posts]
    },
    setMeetPlace(state, place) {
        if (!place) {
            state.meetPlace = ''
            return
        }
        state.meetPlace = {
            address_name: place.address_name,
            place_name: place.place_name,
            x: place.x,
            y: place.y,
        }
    },

    updateSocialParticipantCheck(state, id) {
        const allTarget = state.socialMeeting.allPosts.find(post => post.id === id) || {}
        const interestedTarget = state.socialMeeting.interestedPosts.find(post => post.id === id) || {}

        if (allTarget.participant) allTarget.participant.participant_checked = true
        if (interestedTarget.participant) interestedTarget.participant.participant_checked = true
    },

    updateSocialHostCheck(state, id) {
        const allPost = state.socialMeeting.allPosts.find(post => post.id === id) || {}
        const myPost = state.socialMeeting.myPosts.find(post => post.id === id) || {}

        const changeList = [allPost, myPost]
        changeList.forEach(arr => {
            const participants = arr.participants || []
            participants.forEach(participant => {
                participant.host_checked = true
            })
        })
    },

    // 단톡 초대 이후 액션
    inviteParticipantsipants(state, { post, inviteList, chatId }) {
        const allTarget = state.socialMeeting.allPosts.find(p => p.id === post.id) || {}
        const myTarget = state.socialMeeting.myPosts.find(p => p.id === post.id) || {}

        const changeList = [allTarget, myTarget]
        changeList.forEach(obj => {
            changeList.chat_id = chatId
            const participants = obj.participants || []
            participants.forEach(participant => {
                if (inviteList.includes(participant.id)) {
                    participant.status = 'accepted'
                }
            })

            if (!obj.chat_users) obj.chat_users = inviteList.length + 1
            else obj.chat_users += inviteList.length
        })
    },

    // cta 처리 완료 시 관심피드로 이동
    updateSocialClickInterest(state, participant) {
        const targetPost = state.socialMeeting.allPosts.find(p => p.id === participant.event_id)
        targetPost.participant = participant

        const prevInterest = state.socialMeeting.interestedPosts
        state.socialMeeting.interestedPosts = [targetPost, ...prevInterest].sort((a, b) => b.id - a.id)
    },
    // 삭제된 게시물의 경우 store에서 삭제
    updateRemovedPost(state, id) {
        state.socialMeeting.myPosts = state.socialMeeting.myPosts.filter(p => p.id !== id)
        state.socialMeeting.allPosts = state.socialMeeting.allPosts.filter(p => p.id !== id)
    },
    updateReq2Check(state, { eventId, id }) {
        const allPost = state.socialMeeting.allPosts.find(post => post.id === eventId) || {}
        const myPost = state.socialMeeting.myPosts.find(post => post.id === eventId) || {}

        const changeList = [allPost, myPost]
        changeList.forEach(obj => {
            const participants = obj.participants || []
            const participant = participants.find(participant => participant.id === id)
            if (participant) {
                participant.status = 'checked'
                participant.host_checked = true
                participant.participant_checked = false
            }
        })
    },
    updateCardStateForFriend(state, { me, status, eventId, id }) {
        const allPost = state.socialMeeting.allPosts.find(post => post.id === eventId) || {}
        const myPost = state.socialMeeting.myPosts.find(post => post.id === eventId) || {}
        const interestPost = state.socialMeeting.interestedPosts.find(post => post.id === eventId) || {}

        const changeList = [allPost, myPost, interestPost]
        if (status === 'request') {
            changeList.forEach(obj => {
                const participants = obj.participants || []
                const participant = participants.find(participant => participant.id === id)
                if (participant) {
                    participant.status = 'checked'
                    participant.host_checked = true
                    participant.participant_checked = false
                }
            })
        } else if (status === 'checked') {
            changeList.forEach(obj => {
                const participants = obj.participants || []
                const participant = participants.find(participant => participant.id === id)
                if (participant) participant.status = 'accepted'
            })
        } else if (status === 'accepted') {
            try {
                changeList.forEach(obj => {
                    if (me === 'host') {
                        const participants = obj.participants || []
                        const participant = participants.find(participant => participant.id === id)
                        if (participant) participant.host_paid = true
                    } else if (me === 'participant') {
                        const participant = obj.participant
                        if (participant) participant.participant_paid = true
                    }
                })
            } catch (e) {
                console.error(e)
            }
        }
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
    defaultState,
}
