<template>
    <div class="suggested-dating">
        <header class="header">
            <div class="header">
                <i class="material-icons i-back" @click="$router.go(-1)">chevron_left</i>
                <div class="title">
                    <h1 class="text f-medium">소개팅 제안</h1>
                    <i class="material-icons i-info" @click="isSnackbarOpened = !isSnackbarOpened">info</i>
                    <div v-if="isSnackbarOpened" class="snackbar">
                        <ul class="list">
                            <li class="item">
                                <p class="item-title f-medium">👀 소개팅 제안은 무엇인가요?</p>
                                <p class="content">다른 회원의 담당 주선자가 제안한 소개팅이에요!</p>
                            </li>
                            <li class="item">
                                <p class="item-title f-medium">👀 소개팅 제안을 수락하면 어떻게 되나요?</p>
                                <p class="content">
                                    주선자가 소개팅 상대의 의사를 확인해요.<br />
                                    <span class="paren">(상대의 의사를 먼저 확인했을 수도 있어요!)</span><br />
                                    양쪽의 소개팅 의사가 확인되면<br />
                                    주선자가 대화방을 열어 연락처를 교환해줘요!
                                </p>
                            </li>
                            <li class="item">
                                <p class="item-title f-medium">👀 수락한 후, 진행 상황은 어떻게 확인하나요?</p>
                                <p class="content">
                                    '내 소개팅 > 수락한 소개팅 제안'에서<br />
                                    진행 상황을 확인할 수 있어요!
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </header>

        <main class="main" ref="main">
            <ul>
                <li class="instant m-b-20" v-for="(suggest, idx) in suggests" :key="suggest.id">
                    <UnreadSeparator v-if="showUnreadSeparator(suggest, idx)" />
                    <DailySeparator v-if="showDailySeparator(idx)" :message="suggest" />
                    <SuggestMessage
                        :suggest="suggest"
                        @clickReject="onClickReject(suggest)"
                        @clickAccept="onClickAccept(suggest)"
                        @clickProfile="onClickProfile(suggest)"
                    />
                </li>
            </ul>
        </main>

        <footer class="footer">
            <i class="material-icons m-r-8">add</i>
            <div class="fake-input">메시지 입력이 불가능합니다.</div>
        </footer>
    </div>
</template>

<script>
import DailySeparator from '../chatroom/components/DailySeparator'
import UnreadSeparator from '../chatroom/components/UnreadSeparator'
import SuggestMessage from './components/SuggestMessage'
import suggestService from '@/services/suggest'
// import AcceptSuggestEvent from '../chatroom/components/AcceptSuggestEvent'

export default {
    name: 'SuggestedDating',
    components: { SuggestMessage, DailySeparator, UnreadSeparator },
    data: () => ({
        isSnackbarOpened: false,
    }),
    computed: {
        suggests() {
            const suggests = this.$store.getters.suggests || []

            return this.sortByIdAsc(suggests || [])
        },
        uncheckedCounts() {
            return this.suggests.filter(i => !i.user_checked).length
        },
        notRespondingCounts() {
            return 0
            // return this.suggests.filter(i => i.status === 0 && !i.pending).length
        },
    },
    methods: {
        onClickReject(suggest) {
            try {
                this.$loading(true)

                this.$store.dispatch('rejectSuggest', suggest)
                this.$toast.success('제안을 거절했습니다.')
            } catch (e) {
                this.$toast.error(e.data.msg)
            } finally {
                this.$loading(false)
            }
        },
        async onClickAccept(suggest) {
            try {
                this.$loading(true)

                const { msg } = await this.$store.dispatch('acceptSuggest', suggest)

                if (!window.localStorage.getItem('acceptedInstantBefore')) {
                    this.$modal
                        .custom({
                            component: 'ModalAlertNoX',
                            options: {
                                title: '소개팅 제안 수락',
                                from: 'requested-dating-page',
                                body:
                                    '<span class="f-bold">내소개팅 > 내가 수락한 소개팅</span>에서<br>소개팅 진행 상황을 확인할 수 있어요!',
                            },
                        })
                        .then(() => {
                            window.localStorage.setItem('acceptedInstantBefore', 'true')
                        })
                } else {
                    this.$toast.success(msg)
                }

                window.localStorage.setItem('myDatingBadge', 1)
                this.$store.commit('setMyDatingBadge', true)
                this.$store.dispatch('loadBadges')
            } catch (e) {
                this.$toast.error(e.data.msg)
            } finally {
                this.$loading(false)
            }
        },
        onClickProfile(suggest) {
            if (!suggest.source.available) {
                this.$toast.error('탈퇴 혹은 휴면했거나 활동이 정지된 회원이에요')
                return
            }

            if (!suggest.user_opened) {
                this.$store.commit('setSuggest', { id: suggest.id })
                suggestService.update(suggest.id, { user_opened: true })
            }

            const photos = [{ url: suggest.photo1 }, { url: suggest.photo2 }]

            this.$stackRouter.push({
                name: 'UserDetailPage',
                props: {
                    from: 'SuggestedDatingPage',
                    userId: suggest.source_id,
                    photos,
                    suggest,
                    agent: suggest.agent,
                    agentId: suggest.agent_id,
                    agentMessage: suggest.message,
                    agentPhoto: suggest.agent.photo_url,
                },
            })
        },
        initScrollPosition() {
            const dom = this.$refs.main

            if (!dom) return

            const unreadElem = document.querySelector('.unread-separator')

            if (!unreadElem) {
                this.$scroll.down(dom, false)
            } else {
                unreadElem.scrollIntoView(true)
                if (dom.scrollTop + dom.clientHeight < dom.scrollHeight) {
                    dom.scrollTop -= dom.clientHeight / 4
                }
            }
        },
        showDailySeparator(idx) {
            if (idx === 0) {
                return true
            }

            const cur = this.suggests[idx]
            if (!cur || !cur.created_at) return false

            const prev = this.suggests[idx - 1]
            return (
                this.$moment(prev.created_at).format('YYYY-MM-DD') !== this.$moment(cur.created_at).format('YYYY-MM-DD')
            )
        },
        showUnreadSeparator(suggest, idx) {
            if (this.uncheckedCounts === 0 || this.suggests.length === 1) return false

            if (this.suggests.length - this.uncheckedCounts === idx) return true

            return false
        },
        sortByIdAsc(list) {
            return list.sort((a, b) => a.id - b.id)
        },
        // showAcceptEvent() {
        //     if ((this.$store.getters.me.id - 1) % 10 < 6) {
        //         return true
        //     } else {
        //         return false
        //     }
        // },
    },
    mounted() {
        this.$nextTick(() => {
            this.initScrollPosition()
        })

        suggestService.checked()
    },
}
</script>

<style lang="scss" scoped>
.suggested-dating {
    $header-height: 42px;
    $footer-height: 52px;

    .header {
        .header {
            position: relative;
            height: $header-height;
            padding: 10px 16px;
            border-bottom: solid 1px $grey-03;
            @include center;

            .i-back {
                justify-self: flex-start;
                margin-right: auto;
            }

            .title {
                display: flex;
                margin-right: auto;
            }

            .text {
                margin: 0;
                color: black;
                font-size: 16px;
                font-weight: 500;
                margin-right: 6px;
            }

            .i-info {
                font-size: 18px;
                color: $grey-07;
            }

            .snackbar {
                width: calc(100vw - 32px);
                min-width: calc(100vw - 32px);
                left: 16px;
                top: 40px;

                .list {
                    p {
                        margin: 0;
                    }

                    .item {
                        letter-spacing: -0.2px;
                        line-height: normal;

                        &-title {
                            font-size: 15px;
                            color: #59defe;
                            margin-bottom: 2px;
                        }

                        &:not(:last-child) {
                            margin-bottom: 16px;
                        }

                        .content {
                            padding-left: 22px;

                            .paren {
                                color: $grey-05;
                            }
                        }
                    }
                }
            }
        }
    }

    .main {
        height: calc(100vh - (#{$header-height} + #{$footer-height}));
        overflow-y: scroll;
        background-color: #fafafa;
        padding-left: 16px;

        .floating {
            position: fixed;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            width: calc(100vw - 32px);
            left: 16px;
            top: calc(#{$header-height} + 12px);
            padding: 12px;
            border: solid 1px $grey-02;
            border-radius: 12px;
            background-color: white;
            color: $grey-08;
            z-index: 1;

            .count {
                text-decoration: underline;
                color: $blue-suggest;
            }
        }
    }

    .footer {
        border-top: solid 1px $grey-03;
        height: $footer-height;
        display: flex;
        align-items: center;
        padding: 8px 16px;

        .material-icons {
            font-size: 18px;
            border-radius: 4px;
            border: solid 1px $grey-07;
        }

        .fake-input {
            width: 100%;
            height: 36px;
            padding: 8px 16px;
            border-radius: 10px;
            border: solid 1px $grey-03;
            font-size: 14px;
            color: $grey-05;
            background-color: #fafafa;
        }
    }
}
</style>
