<template>
    <div class="referrer-second">
        <div @click="onClickCloseButton" class="left flex-row flex-wrap center">
            <i class="back-button material-icons flex-wrap">
                {{ 'close' }}
            </i>
        </div>
        <div class="question" v-html="question" />
        <!-- <div class="desc" v-html="'* 복수 선택 가능'" /> -->
        <div
            class="options"
            v-for="option in options"
            :key="option.id"
            :class="{ selected: selected === option.id }"
            @click="onClick(option.id)"
            v-html="option.title"
        ></div>
        <div class="bottom-cont">
            <div class="button" :class="{ activated: selected >= 0 }" v-html="'다음'" @click="onNext()"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ReferrerSecondPage',
    data: () => ({
        question: '희망하는 결혼 시기는 어떻게 되나요? (하나만 선택)',
        options: [],
        selected: -1,
    }),
    mounted() {
        this.options = (this.$store.getters.categories || []).filter(c => c.category === 'when_marry')
        // this.$nativeBridge.postMessage({
        //     action: 'sendFirebaseEvent',
        //     value: {
        //         category: 'Enter_UsedServicePage',
        //     },
        // })
    },
    beforeDestroy() {},
    methods: {
        async onClickCloseButton() {
            try {
                const idx = await this.$modal.basic({
                    body: '설문조사를 그만두시겠습니까?',
                    buttons: [
                        {
                            label: 'CANCEL',
                            class: 'btn-default',
                        },
                        {
                            label: 'CONFIRM',
                            class: 'btn-primary',
                        },
                    ],
                })
                if (idx === 1) {
                    await this.$router.push({ name: 'ChatsPage', params: { preventSetChatNull: true } })
                }
            } catch (e) {
                this.$toast.error(e.data.msg)
            }
        },
        onClick(id) {
            // if (this.selected.includes(id)) {
            //     const idx = this.selected.indexOf(id)
            //     this.selected.splice(idx, 1)
            // } else {
            //     this.selected.push(id)
            // }
            this.selected = id
        },
        onNext() {
            if (this.selected < 0) {
                // this.$toast.error('답변을 선택해주세요!')
            } else {
                const categoryId1 = this.$route.params.category_1_id
                const categoryId2 = this.selected
                const payload = {
                    category_1_id: categoryId1,
                    category_2_id: categoryId2,
                }
                this.$router.push({ name: 'ReferrerThirdPage', params: payload })
            }
        },
        // async onNext() {
        //     if (this.selected.length === 0) this.$toast.error('답변을 선택해주세요!')
        //     else {
        //         try {
        //             this.$loading(true)
        //             const referrer = this.$route.params.referrer
        //             const otherApp = this.selected
        //             const payload = {
        //                 referrer,
        //                 other_app: otherApp,
        //             }
        //             await userService.referrers(payload)
        //             this.$toast.success('가입 완료! 이제 반쪽을 찾아보세요!')
        //             this.$router.push({ name: 'FeedPage' })
        //         } catch (e) {
        //             this.$toast.error(e.data)
        //         } finally {
        //             this.$loading(false)
        //         }
        //     }
        // },
    },
}
</script>

<style lang="scss" scoped>
.referrer-second {
    padding-top: 24px;
    padding-bottom: 80px;

    .left {
        justify-content: flex-start;
        margin: 0 0 20px 20px;
    }

    .back-button {
        color: $grey-08;
    }

    .question {
        margin: 0 20px 8px 20px;
        font-size: 24px;
        line-height: normal;
        color: black;
        font-weight: 500;
        @include f-medium;
    }

    .desc {
        margin: 0 20px 40px 20px;
        color: $purple-primary;
        font-size: 14px;
    }

    .options {
        margin: 0 20px 12px 20px;
        height: 48px;
        line-height: 48px;
        border: solid 1px $grey-02;
        border-radius: 8px;
        font-size: 15px;
        color: $grey-09;
        text-align: center;

        &.selected {
            color: $purple-primary;
            border: solid 1px $purple-primary;
        }
    }

    .bottom-cont {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 80px;
        padding: 16px;
        border-top: solid 1px $grey-02;

        .button {
            width: 100%;
            height: 48px;
            font-size: 15px;
            text-align: center;
            line-height: 48px;
            background: $grey-02;
            color: $grey-05;
            border-radius: 8px;
            background: white;

            &.activated {
                background: $purple-primary;
                color: white;
            }
        }
    }
}
</style>
