<template>
    <div class="refund-status">
        <div class="status-dropdown">
            <div class="top f-14">
                <span>환급 진행 상태 - 주선자 확인중</span>
                <i v-if="!toggleDropDown" @click="toggleDropDown = !toggleDropDown" class="material-icons c-pink-deep"
                    >keyboard_arrow_down</i
                >
                <i v-else @click="toggleDropDown = !toggleDropDown" class="material-icons c-pink-deep"
                    >keyboard_arrow_up</i
                >
            </div>
            <div v-if="toggleDropDown" class="notice-content">
                <div class="notice" v-for="idx in 4" :key="idx">
                    {{ $translate(`REFUND_CHATROOM_NOTICE_${idx}`) }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'RefundStatus',
    props: {
        refundState: Object,
    },
    data: () => ({
        toggleDropDown: false,
    }),
}
</script>

<style scoped lang="scss">
.refund-status {
    padding: 8px 16px;

    .status-dropdown {
        width: 100%;
        border: 1px solid $pink-deep;
        border-radius: 12px;
        padding: 10px 22px 10px 16px;
        background: white;

        .top {
            @include between;
            @include f-medium;
            color: $pink-deep;
        }
        .notice {
            margin-bottom: 12px;
            font-size: 12px;
            color: $grey-09;

            &:nth-child(1) {
                margin-top: 16px;
            }

            &:nth-child(4) {
                margin-bottom: 0;
            }
        }
    }
}
</style>
