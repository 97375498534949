<template>
    <div class="signup preferences">
        <div class="content">
            <div class="title">
                소개받고 싶은 이성의<br />
                <span class="brand-primary f-bold">나이</span>가 어떻게 되나요?
            </div>
            <Slider ref="ageSlider" :range="ageValue" :unit="'세'" :slider-options="sliderOptions" />
        </div>
        <div class="button-wrapper">
            <button class="btn btn-primary" @click="next">다음</button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PreferencesAgePage',
    data: () => ({
        type: 'age',
        sliderOptions: {
            min: 20,
            max: 49,
            tooltip: 'none',
        },
        ageValue: [23, 30],
    }),
    mounted() {
        const preferences = this.$store.getters.payloads.preferences
        if (preferences.min_age && preferences.max_age) {
            this.ageValue[0] = preferences.min_age
            this.ageValue[1] = preferences.max_age
            this.$forceUpdate()
        }
    },
    methods: {
        next() {
            this.$store.getters.payloads.preferences.min_age = this.$refs.ageSlider.myRange[0]
            this.$store.getters.payloads.preferences.max_age = this.$refs.ageSlider.myRange[1]
            this.$router.push({ name: 'PreferencesHeightPage' })
        },
    },
}
</script>
