import eventService from '@/services/event'

const defaultState = () => ({
    todayMeeting: {
        allPosts: [],
        // hotPosts: [],
        filteredPosts: [],
        myPosts: [],
        interestedPosts: [],
    },
})

const state = defaultState()

const getters = {
    todayMeeting: state => state.todayMeeting,
}

const actions = {
    async loadTodayMeetingPosts({ commit }, filter) {
        try {
            const myPosts = await eventService.mine('party_meeting')
            const interestedPosts = await eventService.interest('party_meeting')
            if (filter) {
                const allPosts = await eventService.all('party_meeting', filter)
                commit('setTodayMeeting', { allPosts, myPosts, interestedPosts })
            } else {
                const allPosts = await eventService.all('party_meeting')
                commit('setTodayMeeting', { allPosts, myPosts, interestedPosts })
            }
            // commit('setTodayMeeting', { allPosts: allPosts.all, hotPosts: allPosts.hot, myPosts, interestedPosts })
        } catch (e) {
            return Promise.reject(e)
        }
    },

    async updateAllMyPosts({ commit, state }) {
        try {
            // 프로필 열람 시 all, mypost만 업데이트
            const allPosts = await eventService.all('party_meeting')
            const myPosts = await eventService.mine('party_meeting')
            commit('setTodayMeeting', {
                // allPosts: allPosts.all,
                // hotPosts: allPosts.hot,
                allPosts,
                filteredPosts: state.todayMeeting.filteredPosts,
                myPosts,
                interestedPosts: state.todayMeeting.interestedPosts,
            })
        } catch (e) {
            return Promise.reject(e)
        }
    },
}

const mutations = {
    setTodayMeeting(state, todayMeeting) {
        state.todayMeeting = { ...todayMeeting }
    },
    setTodayMeetingAllPosts(state, posts) {
        if (!posts) return

        state.todayMeeting.allPosts = [...posts]
    },
    // setTodayMeetingHotPosts(state, posts) {
    //     if (!posts) return

    //     state.todayMeeting.hotPosts = [...posts]
    // },
    setTodayMeetingFilteredPosts(state, posts) {
        if (!posts) return

        state.todayMeeting.filteredPosts = [...posts]
    },
    setTodayMeetingMyPosts(state, posts) {
        if (!posts) return

        state.todayMeeting.myPosts = [...posts]
    },
    setTodayMeetingInterestedPosts(state, posts) {
        if (!posts) return

        state.todayMeeting.interestedPosts = [...posts]
    },
    updateClickInterest(state, participant) {
        // allpost, hotpost(인기계시글에서 관심있어요 클릭 시) participant 업데이트
        const targetPost = state.todayMeeting.allPosts.find(post => post.id === participant.event_id)
        // const hotTarget = state.todayMeeting.hotPosts.find(post => post.id === participant.event_id)

        targetPost.participant = participant

        // if (hotTarget) hotTarget.participant = participant

        // 해당 포스트 관심포스트로 이동
        const prevInterest = state.todayMeeting.interestedPosts
        state.todayMeeting.interestedPosts = [targetPost, ...prevInterest].sort((a, b) => b.id - a.id)
    },

    updateParticipantCheck(state, id) {
        const allTarget = state.todayMeeting.allPosts.find(post => post.id === id) || {}
        const interestedTarget = state.todayMeeting.interestedPosts.find(post => post.id === id) || {}

        if (allTarget.participant) allTarget.participant.participant_checked = true
        if (interestedTarget.participant) interestedTarget.participant.participant_checked = true
    },

    updateHostCheck(state, id) {
        const allPost = state.todayMeeting.allPosts.find(post => post.id === id) || {}
        // const hotPost = state.todayMeeting.hotPosts.find(post => post.id === id) || {}
        const myPost = state.todayMeeting.myPosts.find(post => post.id === id) || {}

        // const changeList = [allPost, hotPost, myPost]
        const changeList = [allPost, myPost]
        changeList.forEach(arr => {
            const participants = arr.participants || []
            participants.forEach(participant => {
                participant.host_checked = true
            })
        })
    },

    updateProcess(state, { me, status, eventId, id }) {
        const allPost = state.todayMeeting.allPosts.find(post => post.id === eventId) || {}
        // const hotPost = state.todayMeeting.hotPosts.find(post => post.id === eventId) || {}
        const myPost = state.todayMeeting.myPosts.find(post => post.id === eventId) || {}

        // const changeList = [allPost, hotPost, myPost]
        const changeList = [allPost, myPost]
        if (status === 'request') {
            changeList.forEach(obj => {
                const participants = obj.participants || []
                const participant = participants.find(participant => participant.id === id)
                if (participant) {
                    participant.status = 'checked'
                    participant.host_checked = true
                    participant.participant_checked = false
                }
            })
        } else if (status === 'checked') {
            changeList.forEach(obj => {
                const participants = obj.participants || []
                const participant = participants.find(participant => participant.id === id)
                if (participant) participant.status = 'accepted'
            })
        } else if (status === 'accepted') {
            changeList.forEach(obj => {
                const participants = obj.participants || []
                const participant = participants.find(participant => participant.id === id)
                if (participant) {
                    if (me === 'host') participant.host_paid = true
                    if (me === 'participant') participant.participant_paid = true
                }
            })
        }
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
    defaultState,
}
