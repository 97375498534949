<template>
    <ProfileOptionSelector :model="'religion'" :nextRoute="() => 'UploadPhotoPage'" />
</template>

<script>
export default {
    name: 'ReligionPage',
    mounted() {
        this.$nativeBridge.postMessage({
            action: 'sendFirebaseEvent',
            value: {
                category: 'signUpEvent',
                option: {
                    funnel: this.$route.name,
                    provider: this.$store.getters.payloads.signupData.provider || 'email',
                },
            },
        })
    },
}
</script>
