<template>
    <div class="front">
        <div class="entrance">
            <div class="head f-light" @click="increaseCounter">
                <div class="head-line center">주선자가 해주는</div>
                <div class="dots center">
                    <div class="head-line" v-for="(char, index) in ['맞', '춤', '소', '개', '팅']" :key="char">
                        <div class="dot" />
                        <div class="char f-bold" :class="{ 'm-r-8': index === 1 }" v-html="char" />
                    </div>
                </div>
            </div>

            <div class="foot">
                <button type="button" class="btn btn-primary m-b-12 signup-kakao" @click="signupWithKakao">
                    <img class="img-kakao" src="@/assets/images/kakaotalk.svg" />
                    <span>{{ 'SIGNUP_KAKAO' | translate }}</span>
                </button>
                <button type="button" class="btn btn-primary m-b-12 signup-facebook" @click="signupWithFB">
                    <img class="img-facebook" src="@/assets/images/facebook_white.svg" />
                    <span>{{ 'SIGNUP_FACEBOOK' | translate }}</span>
                </button>
                <button v-if="$isIOS()" type="button" class="btn btn-primary signup-apple" @click="signupWithApple">
                    <img class="img-apple" src="@/assets/images/apple_white.svg" />
                    <span>{{ 'LOGIN_APPLE' | translate }}</span>
                </button>
                <div class="flex-row m-t-28">
                    <button
                        type="button"
                        ref="signupButton"
                        class="btn btn-primary signup-email"
                        @click="signupWithOthers"
                    >
                        <span>{{ 'SIGNUP_OTHER' | translate }}</span>
                    </button>
                </div>
                <div class="hr m-t-16" />
                <div class="consent-text m-t-16">
                    <span class="text" v-html="'로그인하시면 '" />
                    <span class="text-bold" @click="openConsent('service')" v-html="'이용약관'" />
                    <span class="text" v-html="', '" />
                    <span class="text-bold" @click="openConsent('privacy')" v-html="'개인정보처리방침'" />
                    <span class="text" v-html="', <br>'" />
                    <span class="text-bold" @click="openConsent('privacy_third')" v-html="'개인정보제3자제공및활용'" />
                    <span class="text" v-html="'에 동의하는 것으로 간주합니다.'" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import commonService from '@/services/common'
import userService from '@/services/user'
// import axios from '@/modules/axios'

export default {
    name: 'FrontPage',
    data: () => ({
        counter: 0,
        counterInterv: null,
        provider: null,
    }),
    watch: {
        counter(newVal) {
            if (newVal !== 5) return

            this.$modal.custom({
                component: 'ModalSelectEnv',
                options: {
                    preventCloseOnMousedownMask: true,
                },
            })
        },
        '$store.getters.thirdPartySignInData': {
            handler(newVal) {
                if (this.provider === 'kakao') {
                    this.checkAlreadySignup(newVal)
                } else {
                    this.checkThirdParty(newVal)
                }
            },
            deep: true,
        },
        '$store.getters.facebookData': {
            handler(newVal) {
                this.checkThirdParty(newVal)
            },
            deep: true,
        },
        '$store.getters.deviceToken': {
            handler() {
                this.registerTempDevice()
            },
        },
    },
    methods: {
        async checkAlreadySignup(data) {
            try {
                const res = await userService.checkSignup(data.email, data.phone_number)

                if (res.phone_number === null) {
                    this.$modal.basic({
                        body: '카카오톡에 등록된 휴대폰 번호가 유효하지 않습니다. 다른 방법으로 가입해 주세요.',
                        buttons: [
                            {
                                label: 'CONFIRM',
                                class: 'btn-primary',
                            },
                        ],
                    })

                    this.$nativeBridge.postMessage({
                        action: 'sendFirebaseEvent',
                        value: {
                            category: 'Alert_ErrorPhoneNumber',
                        },
                    })
                    return
                }

                if (res.res_email && res.res_phone) {
                    await this.$store.dispatch('signInThirdParty', {
                        user: {
                            email: res.email,
                            token: res.token,
                        },
                    })
                    // await this.$store.dispatch('signInThirdParty', {
                    //     user: { email: data.email, token: data.token },
                    //     uid: data.uid,
                    // })
                } else if (res.res_email) {
                    // email phonenumber 중복 있는지 체크하기
                    this.$router.push({
                        name: 'SignupCheckPage',
                        params: {
                            provider: data.provider,
                            email: data.email,
                            phoneNumber: res.phone_number,
                            uid: res.uid,
                            checkType: 'email',
                        },
                    })
                } else if (res.res_phone) {
                    // email phonenumber 중복 있는지 체크하기
                    this.$router.push({
                        name: 'SignupCheckPage',
                        params: {
                            provider: data.provider,
                            email: data.email,
                            phoneNumber: res.phone_number,
                            uid: res.uid,
                            checkType: 'phone',
                        },
                    })
                } else {
                    // this.$router.push({ name: 'NicknamePage', params: { provider: data.provider } })
                    this.checkThirdParty(data)
                }
            } catch (e) {
                this.$toast.error(e.data)
            }
        },
        registerTempDevice() {
            const platform = this.$store.getters.deviceToken ? this.$store.getters.deviceToken.platform : null
            const registrationId = this.$store.getters.deviceToken
                ? this.$store.getters.deviceToken.registration_id
                : null

            const payload = {
                platform: platform,
                registration_id: registrationId,
                page_status: 0,
            }

            commonService.create(payload)
        },
        watchCounter() {
            this.counterInterv = setInterval(() => {
                if (this.counter > 0) this.counter -= 1
            }, 1000)
        },
        increaseCounter() {
            if (this.counter < 5) this.counter++
        },
        signupWithEmail() {
            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: 'FrontPage_Click_EmailSignup',
                },
            })
            this.sendAirbridgeEvent()
            this.provider = 'email'

            this.$store.commit('setPayloads', { signupData: { provider: this.provider } })
            this.$router.push({ name: 'EmailPage' })
        },
        signupWithKakao() {
            const appVersion = this.$store.getters.device.app_version || '1.2.3'

            if (appVersion < '1.2.7') {
                this.$toast.error('바닐라브릿지 앱 업데이트가 필요합니다.')
                return
            }

            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: 'FrontPage_Click_KakaoStart',
                },
            })
            this.sendAirbridgeEvent()
            this.provider = 'kakao'
            this.$nativeBridge.postMessage({ action: 'signupWithKakao' })
        },
        signupWithFB() {
            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: 'FrontPage_Click_FacebookStart',
                },
            })
            this.sendAirbridgeEvent()
            this.provider = 'facebook'
            this.$nativeBridge.postMessage({ action: 'signupWithFB' })
        },
        signupWithApple() {
            const { app_version: version } = this.$store.getters.device

            if (version < '1.0.3') {
                this.$toast.error('앱 업데이트 후 사용 가능합니다.')
                return
            }

            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: 'FrontPage_Click_AppleStart',
                },
            })
            this.sendAirbridgeEvent()
            this.provider = 'apple'
            this.$nativeBridge.postMessage({ action: 'signupWithApple' })
        },
        login() {
            this.$router.push({ name: 'LoginPage' })
        },
        async checkThirdParty(data) {
            try {
                data.provider = this.provider

                if (data.user_id) {
                    data.uid = data.user_id
                }

                if (!data.email && data.secondEmail) data.email = data.secondEmail
                data.phone_number = this.$store.getters.thirdPartySignInData.phone_number
                data.device_id = this.$store.getters.device.id

                const { user, login_status: status } = await userService.checkThirdParty(data)

                if (status === 'sign_up') {
                    this.startSignup(data, user)
                } else if (status === 'sign_in') {
                    this.$toast.success(this.$translate(`LOGIN_WITH_EXISTING_${this.provider.toUpperCase()}_ACCOUNT`))
                    this.$store.dispatch('signInThirdParty', { user })
                }
            } catch (e) {
                this.$toast.error(e.data)
            }
        },
        startSignup(signupData, user) {
            const thirdPartySignInData = this.$store.getters.thirdPartySignInData
            const date = new Date()

            let errMsg = ''
            if (thirdPartySignInData.birthyear && date.getFullYear() - thirdPartySignInData.birthyear + 1 < 20) {
                errMsg = '바닐라브릿지에서는 미성년자를 위한 서비스를 제공하고 있지 않습니다. 양해 부탁드립니다.'
            } else if (thirdPartySignInData.birthyear && date.getFullYear() - thirdPartySignInData.birthyear + 1 > 49) {
                errMsg =
                    '바닐라브릿지는 현재 20~49세의 회원을 대상으로 서비스를 운영중이며, 향후 서비스 범위 확장을 계획중입니다. 감사합니다.'
            }

            if (errMsg) {
                this.$nativeBridge.postMessage({
                    action: 'sendFirebaseEvent',
                    value: {
                        category: 'FrontPage_Toast_NotInAgeGroup',
                    },
                })

                this.$toast.error(errMsg)
                return
            }

            const gender =
                thirdPartySignInData.gender === '남성' || thirdPartySignInData.gender === 'male'
                    ? { name: 'MALE' }
                    : thirdPartySignInData.gender === '여성' || thirdPartySignInData.gender === 'female'
                    ? { name: 'FEMALE' }
                    : { name: thirdPartySignInData.gender }
            const payload = {
                email: signupData.email || user.email,
                uid: signupData.uid,
                provider: this.provider,
                token: signupData.token,
                birthyear: thirdPartySignInData.birthyear,
                birthday: thirdPartySignInData.birthday,
                gender: gender,
                phone_number: thirdPartySignInData.phone_number,
            }

            this.$store.commit('setPayloads', { signupData: payload })

            this.signupProcess()
        },
        signupProcess() {
            const { provider, email, gender, birthyear, birthday } = this.$store.getters.payloads.signupData

            if (provider.match(/facebook|apple/i) && email) {
                // this.$router.push({ name: 'NicknamePage' })
                this.$router.push({ name: 'GenderPage' })
            } else if (provider.match(/kakao/i) && email) {
                // this.$router.push({ name: 'NicknamePage' })
                if (gender === null || gender.name === null) {
                    this.$router.push({ name: 'GenderPage' })
                } else if (birthyear === null || birthday === null) {
                    this.$router.push({ name: 'BirthdayPage' })
                } else {
                    this.$router.push({ name: 'NicknamePage' })
                }
                // this.$router.push({ name: 'NicknamePage' })
            } else {
                // this.$router.push({ name: 'SignupCheckPage' })
                this.$router.push({ name: 'EmailPage' })
            }
        },
        sendAirbridgeEvent() {
            this.$nativeBridge.postMessage({
                action: 'sendAirbridgeEvent',
                value: {
                    category: 'user_signup_start',
                },
            })
        },
        signupWithOthers() {
            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: 'FrontPage_Click_OtherStart',
                },
            })
            this.$modal.custom({ component: 'ModalSelectSignupMethod' }).then(button => {
                switch (button) {
                    case 'signupWithEmail':
                        this.signupWithEmail()
                        break
                    case 'signinWithEmail':
                        this.login()
                        break
                    case 'signupWithFacebook':
                        this.signupWithFB()
                        break
                }
            })
        },
        openConsent(type) {
            this.$nativeBridge.postMessage({
                action: 'openInAppBrowser',
                value: `https://api.vanillabridge.com/api/users/terms?term_type=${type}`,
                // value: `${axios.defaults.baseURL}users/terms?term_type=${type}`,
            })
        },

        authNotice() {
            this.$modal
                .basic({
                    body: `<img class="auth-notice w-100" src="https://photo.vanillabridge.com/app_photos/auth_notice_2.png">`,
                    buttons: [
                        {
                            label: 'CONFIRM',
                            class: 'btn-primary',
                        },
                    ],
                })
                .then(idx => {
                    if (idx === 0) {
                        this.$emit('close')
                    }
                })
        },
    },
    mounted() {
        this.authNotice()

        // 로그아웃 하는 경우에도 여기 오게 되는데, 그 경우에도 loadConstants를 해줘야 회원가입시 문제없음
        this.$store.dispatch('loadConstants')
        this.$nativeBridge.postMessage({
            action: 'setBackgroundColor',
            value: {
                top: '#8A74FF',
                bottom: '#FFFFFF',
            },
        })

        const entered = window.localStorage.getItem('vanillabridge.FrontPageEntered') || false
        if (!entered) {
            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: 'FrontPage_Enter_FrontPage',
                },
            })

            window.localStorage.setItem('vanillabridge.FrontPageEntered', 'true')
        }

        this.watchCounter()
        this.registerTempDevice()
    },
    beforeDestroy() {
        this.$nativeBridge.postMessage({
            action: 'setBackgroundColor',
            value: '#FFFFFF',
        })
        if (this.counterInterv) {
            clearInterval(this.counterInterv)
            this.counterInterv = null
        }
    },
}
</script>

<style lang="scss" scoped>
.front {
    background-image: linear-gradient(to bottom, $purple-primary 29%, $white 60%) !important;
    $footer-height: 152px;
    height: 100vh !important;

    .entrance {
        height: 100%;
        padding: 20px;
        padding-bottom: 40px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .head {
            color: white;
            padding-top: 136px;

            &-line {
                font-size: 32px;
                line-height: 44px;
            }

            .dots {
                margin-top: 16px;
                display: flex;
                flex-direction: row;
                align-items: baseline;

                .dot {
                    width: 6px;
                    height: 6px;
                    border-radius: 50%;
                    background: white;
                    margin-left: 12px;
                }

                &-line {
                    &:nth-child(3) {
                        margin-left: 8px;
                    }

                    &:nth-child(5) {
                        margin-right: 8px;
                    }
                }
            }

            .img-b {
                width: 130px;
            }

            .img-c {
                width: calc(100vw - 40px);
                // max-width: 300px;
            }
        }

        .foot {
            .divider {
                border-bottom: solid 1px rgba(255, 255, 255, 0.25);
            }

            .bar {
                color: $grey-04;
                margin: 10px;
            }

            button {
                height: 48px;
                width: 100%;
                font-size: 15px;
                background: white;

                &.signup-email {
                    color: $grey-09;
                    font-size: 14px;

                    @include f-regular;

                    .material-icons {
                        margin-right: 10px;
                        color: $purple-primary;
                    }
                }

                &.signup-kakao {
                    color: black;
                    background-color: $yellow-kakao;

                    .img-kakao {
                        margin-left: 16px;
                        left: 0;
                        position: absolute;
                    }
                }

                &.signup-facebook {
                    color: white;
                    background-color: $blue-facebook;

                    .img-facebook {
                        margin-left: 16px;
                        left: 0;
                        position: absolute;
                    }
                }

                &.signup-apple {
                    color: white;
                    background-color: black;

                    .img-apple {
                        margin-left: 16px;
                        left: 0;
                        position: absolute;
                    }
                }
            }

            .others {
                width: 100%;
                text-align: center;

                .text-btn {
                    color: white;
                    font-size: 14px;
                }

                .vertical-bar {
                    margin: 0 32px;
                    color: white;
                }
            }

            .hr {
                width: 100vw;
                margin-left: -20px;
                height: 1px;
                background: $grey-01;
            }

            .consent-text {
                padding: 0 21px 0 21px;
                text-align: center;
                font-size: 12px;
                @include f-medium;
                color: $grey-05;

                .text-bold {
                    text-decoration: underline;
                    @include f-bold;
                }
            }
        }
    }
}
</style>
