<template>
    <PreferencesSelectType :type="'SPEC'" />
</template>

<script>
import PreferencesSelectType from './components/PreferencesSelectType'

export default {
    name: 'PreferencesSpecTypePage',
    components: { PreferencesSelectType },
}
</script>
