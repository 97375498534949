<template>
    <div class="f-14 f-medium c-pink-deep" v-html="matchResult(match)" />
</template>
<script>
export default {
    name: 'Mbti',
    props: {
        userMbti: {
            type: String,
        },
        targetMbti: {
            type: String,
        },
    },
    computed: {
        userType() {
            if (this.userMbti[1] === 'N') {
                return this.userMbti.substring(1, 3)
            } else {
                return this.userMbti[1] + this.userMbti[3]
            }
        },
        targetType() {
            if (this.targetMbti[1] === 'N') {
                return this.targetMbti.substring(1, 3)
            } else {
                return this.targetMbti[1] + this.targetMbti[3]
            }
        },
        match() {
            let compatibility
            /* compatibility
             * 1: 궁합 최악
             * 2: 최악은 아니지만, 좋은 것도 아님
             * 3: 안 맞는 부분도 있지만, 맞는 부분도 있음
             * 4: 잘 맞아요!
             * 5: 천생연분!
             * */
            switch (this.userType) {
                case 'NF':
                    if (this.targetType === 'NF' || this.targetType === 'NT') {
                        compatibility = 4
                        if (this.userMbti[0] !== this.targetMbti[0] && this.userMbti[3] !== this.targetMbti[3])
                            compatibility = 5
                    }
                    if (this.targetType === 'SP') {
                        if (this.targetMbti === 'ISFP') compatibility = 5
                    }
                    break
                case 'NT':
                    if (this.targetType === 'NF' || this.targetType === 'NT') {
                        compatibility = 4
                        if (this.userMbti[1] === this.targetMbti[1]) {
                            if (
                                this.userMbti[0] !== this.targetMbti[0] &&
                                this.userMbti[2] !== this.targetMbti[2] &&
                                this.userMbti[3] !== this.targetMbti[3]
                            )
                                compatibility = 5
                        }
                        if (this.userMbti[2] === this.targetMbti[2]) {
                            if (
                                this.userMbti[0] !== this.targetMbti[0] &&
                                this.userMbti[1] !== this.targetMbti[1] &&
                                this.userMbti[3] !== this.targetMbti[3]
                            )
                                compatibility = 5
                        }
                    }
                    if (this.targetType === 'SJ') {
                        if (this.targetMbti === 'ESTJ') compatibility = 5
                    }
                    break
                case 'SP':
                    if (this.targetType === 'SJ') {
                        if (this.userMbti[0] !== this.targetMbti[0]) compatibility = 5
                    }
                    if (this.targetType === 'NF') {
                        if (this.targetMbti === 'ENFJ') compatibility = 5
                    }
                    break
                case 'SJ':
                    if (this.targetType === 'SP') {
                        if (this.userMbti[0] !== this.targetMbti[0]) compatibility = 5
                    }
                    if (this.targetType === 'NT') {
                        if (this.targetMbti === 'INTP') compatibility = 5
                    }
                    break
                default:
                    compatibility = null
                    break
            }

            return compatibility
        },
    },
    methods: {
        matchResult(compatibility) {
            switch (compatibility) {
                case 5:
                    return '<span>😍천생연분!</span>'
                case 4:
                    return '<span>😊잘 맞아요!</span>'
            }
        },
    },
}
</script>
<style lang="scss" scoped></style>
