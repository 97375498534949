<template>
    <div class="matching-stat">
        <div class="grid-col">
            <div class="agent-stat-box">
                <div class="c-primary stats">
                    {{ agent.datings_stat.week_count > 5 ? agent.datings_stat.week_count : '5회 미만' }}
                    <span v-if="agent.datings_stat.week_count > 5">회</span>
                </div>
                <div class="basis">주선<span class="divider">ㅣ</span>지난 7일</div>
            </div>
            <div class="agent-stat-box">
                <div class="stats c-primary">
                    {{ agent.datings_stat.total_count > 5 ? agent.datings_stat.total_count : '5회 미만' }}
                    <span v-if="agent.datings_stat.total_count > 5">회</span>
                </div>
                <div class="basis">주선<span class="divider">ㅣ</span>누적</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MatchingStat',
    props: ['agent'],
}
</script>
