<template>
    <div class="agent-evaluation-intro">
        <div class="header">
            <i class="material-icons" @click="$stackRouter.pop()">close</i>
        </div>
        <div class="body">
            <div class="top">
                <div class="intro" v-html="intro" />
                <PhotoWithBottomProBadge :src="agent.photos[0].url" />
            </div>
            <div class="gray-bg">
                <StarRating :default="starRating" @onRating="onRating" />
            </div>
            <div class="bottom">
                <div class="thick-border" />
                <p class="desc">별점과 리뷰는 익명으로 반영됩니다.</p>
                <p class="detail-desc">
                    <span class="c-primary f-medium">3명의 평가가 쌓이면, 한 번에 반영됩니다.</span><br />
                    주선자는 작성자가 누구인지 알 수 없으니,<br />
                    솔직하게 평가해주세요 :)
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import PhotoWithBottomProBadge from './components/PhotoWithBottomProBadge'

export default {
    name: 'AgentEvaluationIntroPage',
    components: { PhotoWithBottomProBadge },
    props: {
        from: String,
        agent: Object,
    },
    computed: {
        intro() {
            if (!this.agent) return

            return `${this.agent.name}님이 해주는<br><span>소개팅, </span>어떠셨나요?`
        },
        starRating() {
            return this.$store.getters.payloads.agentEvaluationData.starRating
        },
    },
    mounted() {
        this.init()
        this.$registerBackHandler(this.backHandler, 'AgentEvaluationIntroPage')
    },
    methods: {
        async init() {
            const agentEvaluationData = this.$store.getters.payloads.agentEvaluationData
            agentEvaluationData.agent = this.agent
            this.$store.commit('setPayloads', { agentEvaluationData })
        },
        onRating(rating) {
            const agentEvaluationData = this.$store.getters.payloads.agentEvaluationData
            agentEvaluationData.starRating = rating
            this.$store.commit('setPayloads', { agentEvaluationData })

            setTimeout(
                () =>
                    this.$stackRouter.push({
                        name: 'AgentEvaluationOptionsPage',
                        props: {
                            optionType: rating > 2 ? 'positive' : 'negative',
                        },
                    }),
                500,
            )
        },
        backHandler() {
            if (!this.from) {
                this.$toast.success('나중에 주선자 프로필에서 평가할 수 있어요 :)')
            }
            this.disableCurrentChat()
            this.$store.commit('setPayloads', {
                agentEvaluationData: {
                    ratingOptions: [],
                    starRating: null,
                    agent: null,
                    review: '',
                },
            })
            this.$unregisterBackHandler('AgentEvaluationIntroPage')
            return true
        },
        disableCurrentChat() {
            const chat = this.$store.getters.chat
            if (!chat) return

            window.localStorage.setItem(`ratingDisabledAt:${chat.id}`, true)
        },
    },
}
</script>

<style lang="scss" scoped>
.agent-evaluation-intro {
    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px;
        height: $header-height;
    }

    .body {
        padding: 20px;
    }

    .top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 40px;

        .intro {
            font-family: NotoSans-Bold;
            font-size: 24px;
            line-height: 36px;
            color: black;

            span {
                color: $purple-primary;
            }
        }
    }

    .gray-bg {
        height: 90px;
        border-radius: 12px;
        background-color: $grey-02;
        margin-bottom: 48px;
        display: flex;
        align-items: center;
        justify-content: center;

        .star-wrapper {
            width: 30px !important;
            height: 30px !important;

            &:not(:last-child) {
                margin-right: 10px !important;
            }
        }
    }

    .bottom {
        .thick-border {
            width: 40px;
            height: 3px;
            background-color: $grey-02;
        }

        .desc {
            margin-top: 16px;
            margin-bottom: 12px;
            font-family: NotoSans-Medium;
            font-size: 14px;
            color: $grey-09;
        }

        .detail-desc {
            font-size: 12px;
            line-height: 16px;
            color: $grey-07;
        }
    }
}
</style>
