<template>
    <div class="refund-policy">
        <StackRouterHeaderBar />
        <div class="policy-header">
            <div class="title" v-html="$translate('TICKET_REFUND_POLICY')" />
        </div>
        <div class="policy-body">
            <div class="policy-body-content">
                <div class="policy-body-title" v-html="$translate('WHAT_IS_TICKET_REFUND_POLICY')" />
                <div class="policy-body-wrapper" v-for="content in policyContents" :key="content">
                    <div class="policy-body-text-wrapper">
                        <div class="text" v-html="$translate(content).replace(/\n/, '<br>')"></div>
                    </div>
                </div>
                <div class="policy-body-bottom m-t-30">
                    <div class="refund-count-procedure" v-for="cnt in 3" :key="cnt">
                        <div class="circle m-r-16 f-13 c-primary f-bold" v-html="`${cnt}회차`" />
                        <div class="procedure" v-if="cnt < 3" v-html="$translate(`REFUND_COUNT_${cnt}`)" />
                        <div class="procedure" v-else v-html="refundCnt3" />
                    </div>

                    <div class="desc-imgs m-t-10">
                        <div class="desc" v-for="(obj, idx) in refundObj" :key="idx">
                            <img :src="obj.src" alt="" />
                            <div v-html="obj.desc" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="policy-body-content">
                <div class="policy-body-title" v-html="$translate('REFUND_ASSOCIATED_ISSUES')" />
                <div class="policy-body-abusing">
                    <div class="policy-body-abusing-text-wrapper">
                        <div class="title" v-html="$translate('ABUSING_TITLE')" />
                        <div class="text" v-html="abusingText" />
                    </div>
                </div>
                <div class="policy-body-small">
                    <div class="policy-body-title-small" v-html="$translate('REFUND_POSSIBLE_CASE')" />
                    <div class="policy-body-wrapper-small" v-for="content in issuesPossibleContents" :key="content">
                        <!-- <div class="policy-body-text-wrapper">
                            <div class="text text-small" v-html="$translate(content)" />
                        </div> -->
                        <div
                            class="text text-small"
                            v-if="!content.includes('9')"
                            v-html="$translate(content).replace(/\n/, '<br>')"
                        ></div>
                        <div class="text text-small" v-else>
                            기타 자세한 사유는
                            <span
                                class="detail-page f-bold text-underline"
                                v-html="$translate('DETAIL_INFO')"
                                @click="readDetails"
                            ></span>
                            참조
                        </div>
                    </div>
                </div>
                <div class="policy-body-small">
                    <div class="policy-body-title-small" v-html="$translate('REFUND_IMPOSSIBLE_CASE')"></div>
                    <div
                        class="policy-body-wrapper-small policy-body-wrapper-small-red"
                        v-for="content in issuesImpossibleContents"
                        :key="content"
                    >
                        <div class="policy-body-text-wrapper">
                            <div
                                class="text text-small"
                                v-if="!content.includes('12')"
                                v-html="$translate(content).replace(/\n/, '<br>')"
                            ></div>
                            <div class="text text-small" v-else>
                                기타 자세한 사유는
                                <span
                                    class="detail-page f-bold text-underline"
                                    v-html="$translate('DETAIL_INFO')"
                                    @click="readDetails"
                                ></span>
                                참조
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="policy-body-content">
                <div class="policy-body-title" v-html="$translate('REFUND_MANUAL')" />
                <div
                    class="policy-body-wrapper-small policy-body-wrapper-small-brand"
                    v-for="content in manualContents"
                    :key="content"
                >
                    <div class="policy-body-text-wrapper">
                        <div class="text text-small" v-html="$translate(content)" />
                    </div>
                </div>
            </div>
            <div class="policy-body-content">
                <div class="policy-body-title" v-html="$translate('REFUND_PRECAUTIONS')" />
                <div
                    class="policy-body-wrapper-small policy-body-wrapper-small-brand"
                    v-for="content in precautionsContents"
                    :key="content"
                >
                    <div class="policy-body-text-wrapper">
                        <div class="text text-small" v-html="$translate(content)" />
                    </div>
                </div>
                <div class="policy-body-text-wrapper-text">
                    자세한 내용은
                    <span
                        class="detail-page f-bold text-underline"
                        v-html="$translate('DETAIL_INFO')"
                        @click="readDetails"
                    ></span
                    >을 참조해주세요.
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'RefundPolicyPage',
    data: () => {
        return {
            policyContents: [],
            issuesPossibleContents: [],
            issuesImpossibleContents: [],
            manualContents: [],
            precautionsContents: [],
        }
    },
    computed: {
        abusingText() {
            return this.$translate('ABUSING_TEXT').split('\n').join('<br>')
        },
        refundObj() {
            return [
                {
                    desc: `소개팅권<br>${!this.$refundPolicySplit() ? '1만원' : '3,500원'} 할인 쿠폰`,
                    src: require('@/assets/images/refund/refund-ticket.png'),
                },
                {
                    desc: `프리미엄<br>${!this.$refundPolicySplit() ? '1만원' : '3,500원'} 할인 쿠폰`,
                    src: require('@/assets/images/refund/refund-premium.png'),
                },
                {
                    desc: `바닐라 ${
                        !this.$refundPolicySplit()
                            ? `700개<br><span>(15,000원 상당)</span>`
                            : `300개<br><span>(7,000원 상당)</span>`
                    }`,
                    src: require('@/assets/images/refund/refund-vanilla.png'),
                },
            ]
        },
        refundCnt3() {
            const sentence = this.$translate(`REFUND_COUNT_3`)
            if (!this.$refundPolicySplit()) {
                return sentence.replace(/%s/, `1만`).replace(/%p/, `700(15000원 상당)`)
            }

            return sentence.replace(/%s/, `3,500`).replace(/%p/, `300`)
        },
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            // 가독성 위해 나눠서 initialize
            for (let i = 1; i < 4; ++i) {
                this.policyContents.push(`POLICY_TEXT_${i}`)
            }

            for (let i = 1; i < 10; ++i) {
                this.issuesPossibleContents.push(`ISSUES_POSSIBLE_TEXT_${i}`)
            }

            for (let i = 1; i < 5; ++i) {
                this.precautionsContents.push(`PRECAUTIONS_TEXT_${i}`)
            }

            for (let i = 1; i < 13; ++i) {
                this.issuesImpossibleContents.push(`ISSUES_IMPOSSIBLE_TEXT_${i}`)
            }

            for (let i = 1; i < 3; ++i) {
                this.manualContents.push(`MANUAL_TEXT_${i}`)
            }
        },
        readDetails() {
            this.$stackRouter.push({
                name: 'RefundPolicyDetailsPage',
            })
        },
    },
}
</script>
