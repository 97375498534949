<template>
    <div class="refund-4-reason center-refund">
        <div class="page-header">
            <div class="title">환급 사유 선택</div>
        </div>
        <div class="reasons">
            <div
                class="reason"
                :class="{ selected: (reason || {}).id === item.id }"
                :key="item.id"
                v-for="item in reasons"
                @click="select(item)"
            >
                <div class="title">{{ item.title }}</div>
                <div v-if="item.description" class="description" v-html="item.description"></div>
            </div>
        </div>
        <BottomButton @click="goToNextPage" :disabled="!reason" :label="'NEXT'" :service="`refund`" />
    </div>
</template>

<script>
import centerService from '@/services/center'

export default {
    name: 'Refund4ReasonPage',
    data: () => ({
        reasons: [],
        reason: null,
    }),
    mounted() {
        if (!this.dating) return this.$router.push({ name: 'HomePage' })

        this.loadReasons()
    },
    computed: {
        refund() {
            return this.$store.getters.payloads.refund
        },
        refundType() {
            return this.$store.getters.refundType
        },
        dating() {
            return this.refund.dating
        },
    },
    methods: {
        async loadReasons() {
            try {
                this.$loading(true)
                const m = this.$moment(this.dating.created_at).add(36, 'hours')
                if (this.$moment().isBefore(m)) {
                    const refundReasons = await centerService.refundReasons()
                    this.reasons = [...refundReasons.refund_reason]
                } else {
                    const refundReasons = await centerService.refundReasonsLater()
                    this.reasons = [...refundReasons.refund_reason]
                }
                this.reasons =
                    this.refundType === 'realFriend'
                        ? this.reasons.filter(reason => reason.is_active && reason.reduce_count)
                        : this.reasons.filter(reason => reason.is_active)
            } catch (e) {}
            this.$loading(false)
        },
        select(item) {
            this.$store.commit('setPayloads', {
                refund: {
                    dating: this.$store.getters.payloads.refund.dating,
                    reason: item,
                },
            })
            this.reason = item
        },
        goToNextPage() {
            // 아예 답장이 오지 않는 케이스만 안내 바텀시트 노출
            if (!this.reason.reduce_count) {
                this.$modal.custom({
                    component: 'ModalNotSubstract',
                    options: {
                        reason: this.reason,
                    },
                })
                return
            }

            this.$router.push({ name: 'Refund5ExcludePage' })
        },
    },
}
</script>
