<template>
    <div class="signup preferences-select-type">
        <div class="content">
            <div class="title" v-html="$translate(`MODAL_${type}_TYPE`).replace(/\n/, '<br>')"></div>
            <div class="body">
                <div
                    v-for="item in items"
                    :key="item.title"
                    class="item"
                    :class="{ selected: styles[`${type.toLowerCase()}_type`] === item.value }"
                    @click="onClickItem(item.value)"
                >
                    {{ item.title }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import userService from '@/services/user'

export default {
    name: 'PreferencesSelectType',
    props: ['type'],
    computed: {
        styles() {
            return this.$store.getters.payloads.preferences
        },
        items() {
            return this.$vbConstants.types[this.type.toLowerCase()]
        },
        nextRoute() {
            if (this.type === 'FRIEND') return 'PreferencesSpecTypePage'
            if (this.type === 'SPEC') return 'PreferencesSmokingTypePage'

            return ''
        },
    },
    methods: {
        async finish() {
            try {
                await userService.createStyles(this.styles)

                this.$router.push({ name: 'OnboardingPage' })
            } catch (e) {
                this.$toast.error(e.msg)
            }
        },
        onClickItem(value) {
            this.styles[`${this.type.toLowerCase()}_type`] = value

            // 그 경우가 아니면 다음 라우트로
            setTimeout(() => {
                this.$router.push({
                    name: this.nextRoute,
                })

                // 흡연 타입은 최종페이지이므로 API콜하고 주선자 리스트로 ㄱㄱ
                if (this.type === 'SMOKING') {
                    this.finish()
                }
            }, 500)
        },
    },
}
</script>
